import React, { useEffect, useState } from 'react';
import * as mui from '../common/materialUi'
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import {formatNum, formatDate, getUser} from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { common } from '@material-ui/core/colors';
import { LoadingSpinner } from '../common/commonParts';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { 
  houdySirvice, houdayKasan, chiikiKubun, unitPrice,
  serviceSyubetu, ketteiScode,
} from './BlCalcData';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { FormatAlignJustifyOutlined } from '@material-ui/icons';
import { 
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from './blMakeData';
import { makeStyles } from '@material-ui/core/styles';
import {
  SOUGEY_SVC_CODE, // 送迎サービスコード
  // KATEI_SVC_CODE,
  // HOUMON_SVC_CODE,
  // IREN_SVC_CODE,
} from './BlCalcData';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import teal from '@material-ui/core/colors/teal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { KanriKekka, PriceLimit } from '../common/StdFormParts';
import { findByLabelText } from '@testing-library/react';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { UpperLimitKanri } from '../schedule/SchUpperLimit';
import {LinksTab} from '../common/commonParts';
import CheckProgress, {doCheckProgress} from '../common/CheckProgress';

// 売上情報を表示するモジュール。
// billingDt masterRec はstore stateに持ってく予定

// fontAWESOMEアイコンのスタイル
const iconStyle = { padding: 0, fontSize: 18, marginRight: 8 };
const appPageStyle = { paddingTop: 92 }
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  nodata: {
    maxWidth: 400,
    textAlign:'center',
    margin: '0 auto',
  },
  proseedDiv: {
    pageBreakInside: 'avoid',
    marginBottom: 24,
    ' & .H': {
      backgroundColor: teal[50],
      padding: 8,
      paddingLeft: 16,
      paddingTop: 12,
      borderBottom: '1px solid ' + teal[900],

    },
  },
  narrow: {
    maxWidth: 600,
    margin: '24px auto',
  },
  printCntRoot: {
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#ffffff66',
    padding: 8,
    top: 60,
    left: '10%',
    // transform: 'translate(-50%, 0)',
    '& .MuiButtonBase-root': {
      width: 200,
      margin: '0 8px',
      '& .MuiSvgIcon-root': {
        marginInlineEnd: '8px',
      },
    },
    '& .subButtons': {
      padding: 8,
      '& .MuiButtonBase-root': {
        width: 'auto',
      },
    },
  },
  upperLimitRoot: {
    width: 800,
    margin: '24px auto',

    '& .userWrap': {
      display: 'flex',
      borderBottom: '1px solid #aaa',
      '& .icon':{
        right: 2,
        top: 2,
        fontSize: '1.2rem',
        position:'absolute',
        color:teal[800],
      },
      '& .Num': {
        width: 32,
        padding: '6px 4px',
        textAlign: 'right',
      },
      '& .user': {
        width: '40%',
      },
      '& .detail': {
        width: '60%',
        '& .detailRow': {
          backgroundColor: 'transparent',
          '>div': {
            padding: '6px 4px',
          }
        },
        '& .detailRow:last-child': {
          borderBottom: 'none',
        },
      },
    },
    '& .user':{
      display: 'flex',
      flexWrap: 'wrap',
      '& >div' :{
        padding: '6px 4px',
      },
      '& .userName':{
        width: '50%',
        cursor:'pointer',
        position: 'relative',
      },
      '& .haibunStatus': {
        width: '50%',
        fontSize: '.8rem',
      },
      '& .userInfo': {
        width: '50%',
      },
      '& .userInfo>span': {
        width: '50%',
        textAlign: 'right',
        display: 'inline-block',
      },
    },
    '& .titleOuter': {
      display: 'flex',
      '& .Num': {
        width: 32,
        padding: '6px 4px',
      },
      '& .titleLeft' :{
        width: '40%',
      },
      '& .titleRight':{
        width: '60%',
      },
    },
    '& .small':{
      fontSize: '.7rem',
    }
  },
  H: {
    backgroundColor: teal[50],
    padding: 8,
    paddingLeft: 16,
    paddingTop: 12,
  },
  innerButton:{
    padding: 0,
  }
}));

const subMenu = [
  { link: "/proseed", label: "一般" },
  { link: "/proseed/upperlimit", label: "上限管理" },
];

// 上限管理編集用のコンポーネントをラップするダイアログ
const UpperLimitWrapDialog = (props) =>{
  const { open, setOpen, close, uid, specifyType } = props;
  const titleStr = (specifyType) ? '協力事業所' : '管理事業所';
  const stdDate = useSelector(state => state.stdDate);
  const users = useSelector(state=>state.users);
  const monthStr = stdDate.substr(0, 4) + '年' + stdDate.substr(5, 2) + '月';
  const thisUser = getUser(uid, users);
  return (
    <Dialog 
      open={open} onClose={close}
    >
      <div className='formTitle'>上限管理({titleStr})</div>
      <div className='formSubTitle'>
        <div className='date'>{monthStr}</div>
        <div className='user'>{thisUser.name}</div>
        <div className='age'>{thisUser.ageStr}</div>
      </div>
      <UpperLimitKanri uid={uid} specifyType={specifyType} close={close} />
    </Dialog>
  )
}
// 表示するためのデータを作成。
const makeData = (prms) =>{
  // const {stdDate, schedule, users, com, service, dispatch} = prms;
  // const useResult = makeUseResult(prms);
  const { billingDt, masterRec } = setBillInfoToSch(prms);
  console.log('billingDt', billingDt);
  console.log('masterRec', masterRec);
  // const billing = makeBiling(billingDt, masterRec);
  // const upperLimit = makeJugenkanri(billingDt, masterRec);
  // const useResult = makeTeikyouJisseki(billingDt, masterRec);
  // console.log('upperLimit', upperLimit);
  // console.log('useResult', useResult);
  // return { useResult, upperLimit, billing, billingDt, masterRec };
  return { billingDt, masterRec};
}
// 売上サマリを出力する
const ProseedSummary = (props) =>{
  const classes = useStyles();
  const data = props.data;
  let kanriOk = true;
  const Summary = ()=>{
    const sumDt = {
      利用人数: 0,
      利用回数: 0,
      単位数: 0,
      算定額: 0,
      利用者負担額: 0,
      実費: 0,
      国保連請求額: 0,
    }
    if (data) {
      data.map(e => {
        sumDt.利用回数 += parseInt(e.countOfUse);
        sumDt.単位数 += parseInt(e.tanniTotal);
        sumDt.算定額 += parseInt(e.userSanteiTotal);
        sumDt.利用者負担額 += isNaN(e.ketteigaku)? 0: parseInt(e.ketteigaku);
        sumDt.実費 += parseInt(e.actualCost);
        // 上限管理完了フラグは一度でもfalseになったらfalse
        kanriOk = (kanriOk && e.kanriOk)? true : false;
      });
      sumDt.国保連請求額 = sumDt.算定額 - sumDt.利用者負担額;
      sumDt.利用人数 = data.length;
    }
    const rt = Object.keys(sumDt).map((e, i)=>{
      return(
          <div key={i} className='flxRow' >
            <div className='rowh w50'>{e}</div>
            <div className='rowd w50 right' >{
              comMod.formatNum(sumDt[e], 1)}
            </div>
          </div>

      )
    });
    return (<>
      <div className={classes.proseedDiv}>
        <div className='H'>
          売上概要
        </div>
        <div className={classes.narrow}>
          {rt}
          {kanriOk &&
            <div className='comment'>利用者負担額は未確定です。</div>
          }
        </div>
      </div>
    </>);
  }
  const NoData = () => {
    return (
      <div className={classes.nodata}>
        <div className='icon'>
          <faCalculator fontSize='large'/>
        </div>
        <div className='text'>売上計算実行ボタンを押して下さい。</div>
      </div>

    )
  }
  return(<>
    { data && <Summary />}
    { !data && <NoData /> }
  </>)
}
// ユーザー別売り上げを表示するための配列を作成する
// dataはここではbillingDt
export const proseedByUsersDt = (users, data) => {
  let outa = []; // 出力用配列
  outa = users.map(e => {
    // このユーザーの請求データ
    const thisBdt = data.find(f => e.hno === f.hno);
    if (!thisBdt) return false; //請求データが無いときはfalseを返す
    // サービスコードカウントオブジェクトより送迎に関するコードを抽出
    const sougei = thisBdt.itemTotal.filter(
      f => SOUGEY_SVC_CODE.indexOf(f.s) > -1
    )
    // 送迎回数を算出
    let sougeiCnt = 0;
    sougei.map(f => {
      sougeiCnt += f.count;
    });
    const userFutan = thisBdt.kanrikekkagaku ? thisBdt.kanrikekkagaku : 0;
    const kokuhoSeikyuu = thisBdt.userSanteiTotal - userFutan;
    return ({
      userName: e.name,
      tanni: thisBdt.tanniTotal,
      santei: thisBdt.userSanteiTotal,
      userFutan,
      kokuhoSeikyuu,
      sougeiCnt,
      kanriOk: thisBdt.kanriOk,
      countOfUse: thisBdt.countOfUse,
      actualCost: thisBdt.actualCost,
      actualCostDetail: thisBdt.actualCostDetail,
      hno: thisBdt.hno,
      UID: thisBdt.UID,
    });
  });
  outa = outa.filter(e => e !== false);
  return outa;
}
// 利用者ごとの売上を求める
// ユーザー名、単位数、算定額、負担額、請求額
const ProseedByUsers = (props) => {
  const classes = useStyles();
  const {users, data} = props;
  let outa = []; // 出力用配列
  // 出力用配列を作成
  if (data){
    outa = proseedByUsersDt(users, data);
    console.log('outa', outa)
  }
  const userRows = outa.map((e, i)=>{
    return(
        <div className='flxRow' key={i}>
          <div className='wmin rowd right'>{i + 1}</div>
          <div className='w30 rowd'>{e.userName}</div>
          <div className='w15 rowd right'>
            {comMod.formatNum(e.tanni, 1)}
          </div>
          <div className='w20 rowd right'>
            {comMod.formatNum(e.santei, 1)}
          </div>
          <div className='w15 rowd right'>
            {comMod.formatNum(e.userFutan, 1)}
          </div>
          <div className='w15 rowd right'>
            {comMod.formatNum(e.actualCost, 1)}
          </div>
          <div className='w20 rowd right'>
            {comMod.formatNum(e.kokuhoSeikyuu, 1)}
          </div>
          <div className='w10 rowd right'>{e.sougeiCnt}</div>
          <div className='w10 rowd right'>{e.countOfUse}</div>
        </div>

    )
  })
  if (!data){
    return null;
  }
  else {
    return(
      <div className={classes.proseedDiv}>
        <div className='H'>
          利用者別売上
        </div>
        <div className='byUser'>
          <div className='flxTitle'>
            <div className='wmin rowd'>No</div>
            <div className='w30 rowd'>利用者名</div>
            <div className='w15 rowd'>単位数</div>
            <div className='w20 rowd'>算定額</div>
            <div className='w15 rowd'>負担額</div>
            <div className='w15 rowd'>実費</div>
            <div className='w20 rowd'>国保請求</div>
            <div className='w10 rowd'>送迎</div>
            <div className='w10 rowd'>利用</div>

          </div>
          {userRows}
        </div>
      </div>

    )    
  }
}
// サービスコード別の売上を集計する
const ProseedByService = (props) =>{
  const classes = useStyles();
  const { users, data } = props;
  let outa = [];
  let itemTotal = [];
  if (data){
    // ユニークなサービスコード作成
    // サービスアイテムの個人別集計を取り出す
    const uscd = new Set();
    data.map(e=>{
      e.itemTotal.map(f=>{
        uscd.add(f.s);
        itemTotal.push(f);
      });
    });
    // 配列化してソート
    const uscda = Array.from(uscd);
    uscda.sort((a, b)=>(a > b ? 1 : -1));
    uscda.map(e=>{
      itemTotal.map(f=>{
        if (e === f.s){
          const ndx = outa.findIndex(g=>g.s === e);
          // すでに配列に存在する場合は合算
          if (ndx > -1){
            outa[ndx].count += f.count;
            outa[ndx].tanniNum += f.tanniNum;
          }
          // 配列に存在しない場合はそのままpush
          else{
            outa.push(f);
          }
        }
      });
    });
  }
  
  console.log('outa', outa);
  
  const serviceRows = outa.map((e, i) => {
    return (
      <div className='flxRow' key={i}>
        <div className='wmin rowd right'>{i + 1}</div>
        <div className='w10 rowd'>{e.s}</div>
        <div className='w50 rowd'>{(e.c.slice(0, -1))}</div>
        <div className='w10 rowd right'>{comMod.formatNum(e.v, 1)}</div>
        <div className='w10 rowd right'>{comMod.formatNum(e.count, 1)}</div>
        <div className='w15 rowd right'>{comMod.formatNum(e.tanniNum, 1)}</div>
      </div>
    )
  })

  if (!data){
    return null;
  }
  else{
    return (
      <div className={classes.proseedDiv}>
        <div className='H'>
          サービスコード別売上一覧
        </div>
        <div className='serviceCode'>
          <div className='flxTitle'>
            <div className='wmin rowd'>No</div>
            <div className='w10 rowd'>コード</div>
            <div className='w50 rowd'>サービス名</div>
            <div className='w10 rowd'>単位</div>
            <div className='w10 rowd'>数量</div>
            <div className='w15 rowd'>単位数</div>
          </div>
          {serviceRows}
        </div>
      </div>
    )
  }
}

// 上限管理の表示
const UpperLimitView = (props) =>{
  const classes = useStyles();
  const { 
    billingDt , open, setOpen, dialogPrms, setDailogPrms, ...others
  } = props;
  if (!billingDt) return null;
  // 管理事業所データ
  const kanri = billingDt.filter(e => e.kanriType === "管理事業所");
  const kyouryoku = billingDt.filter(e => e.kanriType === "協力事業所");
  const DetailOutput = (props) => {
    if (!props.dt) return null;
    const thisDetail = props.dt.map((e, i)=>{
      return (
        <div className='flxRow detailRow' key = {i}>
          <div className='w50 noBkColor textEclips'>
            {e.name === 'thisOffice' &&
              '当事業所'
            }
            {e.name !== 'thisOffice' &&
              e.name              
            }
            {e.name !== 'thisOffice' &&
              <span 
                className='small'
                style={{marginInlineStart: '8px'}}
              >
                ({e.no})
              </span>
            }
          </div>
          <div className='w20 right noBkColor'>{formatNum(e.amount, 1)}</div>
          <div className='w15 right noBkColor'>{formatNum(e.ichiwari, 1)}</div>
          <div className='w15 right noBkColor'>{formatNum(e.kettei, 1)}</div>
        </div>
      )
    });
    return thisDetail;
  }
  // 配分が配列のどこに書いてあるかわからない！？
  const getHaibun = (ary) =>{
    let v = '未定';
    ary.map(e=>{
      if (e.haibun) v = e.haibun;
    });
    return v;
  }
  const userClickHandler = (ev) =>{
    const uid = ev.currentTarget.getAttribute('uid');
    const specifyType = ev.currentTarget.getAttribute('specify-type');
    setDailogPrms({uid, specifyType});
    setOpen(true);
  }
  const kanriOutput = kanri.map((e, i)=>{
    const haibun = (e.協力事業所) ? 
      getHaibun(e.協力事業所) : '未定'
    return(
      <div className='userWrap' key = {i}>
        <div className='Num'>{i + 1}</div>
        <div className='user'>
          <div className='userName textEclips' 
            onClick={(ev)=>userClickHandler(ev)}
            uid={e.UID} 
            // 管理事業所の場合はtypeをundefinedで送信する
            // specify-type={0}

          >
            {e.name}
            <EditIcon className='icon'/>
          </div>
          <div className='haibunStatus'>
            {'管理 / ' + haibun}
          </div>
          <div className='userInfo'>
            算定額　 <span>{formatNum(e.userSanteiTotal, 1)}</span>
          </div>
          <div className='userInfo'>
            上限額　 <span>{formatNum(e.priceLimit, 1)}</span>
          </div>
          <div className='userInfo'>
            決定額　 <span>
              {(e.ketteigaku) ? formatNum(e.ketteigaku, 1): 0}
            </span>
          </div>
        </div>
        <div className='detail'>
          <DetailOutput dt={e.協力事業所} />
        </div>
      </div>
    )
  });
  // 管理結果フラグに対応したラベルを返す
  const kanriKekkaName = (v) => {
    v = isNaN(v)? 0: v;
    return ['未設定', '管理事業所充当', '上限内', '調整済み',][parseInt(v)]
  }
  const kyouryokuOutput = kyouryoku.map((e, i)=>{
    console.log('kanriKekka', e.kanriKekka);
    return(
      <div className='userWrap' key={i}>
        <div className='Num'>{i + kanri.length + 1}</div>
        <div className='user'>
          <div className='userName textEclips'
            onClick={(ev) => userClickHandler(ev)}
            uid={e.UID} specify-type={0}
          >
            {e.name}
            <EditIcon className='icon' />
          </div>
          <div className='haibunStatus'>
            {'協力 / ' + kanriKekkaName(e.kanriKekka)}
          </div>
          <div className='userInfo'>
            算定額　 <span>{formatNum(e.userSanteiTotal, 1)}</span>
          </div>
          <div className='userInfo'>
            上限額　 <span>{formatNum(e.priceLimit, 1)}</span>
          </div>
          <div className='userInfo'>
            決定額　 <span>
              {(e.ketteigaku) ? formatNum(e.ketteigaku, 1) : 0}
            </span>
          </div>
        </div>
        <div className='detail'>
          <div className='flxRow detailRow' key={0}>
            <div className='w50 noBkColor textEclips' >
              管理事業所
            </div>
            <div className='w50 noBkColor textEclips'>
              {e.jougenJiName}
              <span
                style={{ marginInlineStart: '8px' }}
              >
                ({e.jougenJi})
              </span>
            </div>
          </div>
          <div className='flxRow detailRow' key={1}>
            <div className='w50 noBkColor textEclips' >
              当事業所
            </div>
            <div className='w35 right noBkColor'></div>
            <div className='w15 right noBkColor'>
              {formatNum(
                (isNaN(e.kanrikekkagaku)?0 : e.kanrikekkagaku), 1
              )}
            </div>
          </div>
        </div>
      </div>
    )

  });
  const kanriTitle = (
    <div className='titleOuter'>
      <div className='flxTitle'>
        <div className='wmin noBkColor'>No</div>
      </div>
      <div className='titleLeft'>
        <div className='flxTitle'>
          <div className='w50 noBkColor'>利用者 / 概要</div>
        </div>
      </div>
      <div className='titleRight'>
        <div className='flxTitle'>
          <div className='w50 noBkColor'>事業所名</div>
          <div className='w20 noBkColor'>算定額</div>
          <div className='w15 noBkColor'>一割</div>
          <div className='w15 noBkColor'>決定額</div>
        </div>
      </div>
    </div>
  )
  return(<>
    <div className={classes.H}>上限管理一覧</div>
    <div className={classes.upperLimitRoot}>
      {kanriTitle}
      {kanriOutput}
      {kyouryokuOutput}
    </div>
  </>);
}
// 再計算ボタンと印刷ボタンを提供
// 印刷ボタンは考え直すかも
const RecalcButton = () =>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com); // 会社と事業所の情報
  const serviceSt = useSelector(state => state.service);
  const serviceItems = useSelector(state => state.serviceItems);
  // サービス未設定のときの設定を行う
  const service = (serviceSt) ? serviceSt : serviceItems[0];
  const billingDt = useSelector(state => state.billingDt);
  const clickHandler = () => {
    const prms = { stdDate, schedule, users, com, service, dispatch };
    console.log('clicked');
    const { billingDt, masterRec } = setBillInfoToSch(prms);
    dispatch(Actions.setStore({ billingDt, masterRec }));
    // setproseedDatas(makeData(prms));
  }
  return(<>
    <div className={classes.printCntRoot + ' noprint'} >
      <Button
        onClick={clickHandler}
        variant='contained'
      >
        <FontAwesomeIcon icon={faCalculator} style={iconStyle} />
          売上計算する
        </Button>
    </div>
    {billingDt &&
      <div className={classes.printCntRoot + ' noprint'}>
        <Button
          onClick={() => { window.print() }}
          variant='contained'
        >
          <PrintIcon />
              印刷する
          </Button>
      </div>
    }

  </>)
}

export const ProseedUpperLimit = () =>{
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogPrms, setDailogPrms] = useState({ uid: '', specifyType: '' });
  const closeHandler = () => setOpen(false);
  const billingDt = useSelector(state => state.billingDt);
  const allState = useSelector(state=>state);
  const ls = comMod.getLodingStatus(allState);
  if (!ls.loaded){
    return (
      <LoadingSpinner />
    )
  }
  else if (ls.error){
    return (<div>error occured.</div>)
  }
  else{
    return (<>
      <LinksTab menu={subMenu} />
      <div className="AppPage proseed" style={appPageStyle}>
        <CheckProgress inline />
        <RecalcButton />
        <div style={{ height: 16 }}></div>
        <UpperLimitView
          billingDt={billingDt}
          open={open} setOpen={setOpen}
          dialogPrms={dialogPrms} setDailogPrms={setDailogPrms}
        />
        <UpperLimitWrapDialog
          open={open} setOpen={setOpen}
          close={closeHandler}
          uid={dialogPrms.uid} specifyType={dialogPrms.specifyType}
        />
      </div>
    </>)
  }
}

const Proseed = () => {
  const classes = useStyles();
  const users = useSelector(state => state.users);
  // // const [proseedDatas, setproseedDatas] = useState('');
  // // ダイアログ用のあれこれを準備
  // // const { open, setOpen, closeHandler, uid, specifyType } = props;
  // const [open, setOpen] = useState(false);
  // const [dialogPrms, setDailogPrms] = useState({uid: '', specifyType: ''});
  // const closeHandler = () => setOpen(false);
  const allState = useSelector(state=>state);
  const billingDt = useSelector(state=>state.billingDt);
  const ls = comMod.getLodingStatus(allState);
  if (!ls.loaded){
    return (
      <LoadingSpinner />
    )
  }
  else if (ls.error){
    return (<div>error occured.</div>)
  }
  else{
    return(<>
      <LinksTab menu={subMenu} />
      <div className="AppPage proseed" style={appPageStyle} >
        <CheckProgress inline />
        <RecalcButton />
        <div style={{height:16}}></div>
        <ProseedSummary data={billingDt} />
        <ProseedByUsers data={billingDt} users={users}/>
        <ProseedByService data={billingDt} />
      </div>
    </>)
  
  }

}
export default Proseed;