import React, { useEffect, useState } from 'react';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import {formatNum, formatDate, getUser} from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { UpperLimitKanri } from '../schedule/SchUpperLimit';
import {LinksTab} from '../common/commonParts';
import {
  setBillInfoToSch, 
} from '../Billing/blMakeData';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { colors } from '@material-ui/core';

// 進行の確認を行う
// 当月の予定に全て実績フラグが経っているか。
// 上限管理がされているか
// 仮の保保険番号が残っていないか
// またこれらのチェックを行う関数も提供する

const useStyles = makeStyles({
  fixRoot:{
    position: 'fixed',
    fontSize: '.8rem',
    bottom: 30,
    right: 20,
    ' & .item':{
      display: 'flex',
      padding: '2px 4px 1px',
      margin: '2px 0',
      borderBottom: '1px #d6d6d6 solid',
      ' & .rhead': {width: '12ch',},
      ' & .result': {width: '10ch',
        fontWeight:'bold',
        ' & .OK': {color: teal[900]},
        ' & .NG': {color: red[900]},
        ' & .NODATA': {color: blue[900]},
      },
    },
  },
  inlineRoot:{
    '& .checkResult':{
      display:'flex', justifyContent: 'center', 
      fontSize: '.8rem', margin: '8px 0',
    },
    '& .item':{display:'flex', justifyContent:'center', margin:'0 8px'},
    '& .item > div':{margin:'0 4px'},
    '& .result': {
      fontWeight:'bold',
      '& .OK': {color: teal[900]},
      '& .NG': {color: red[900]},
      '& .NODATA': {color: blue[900]},
    }
  }
});

export const doCheckProgress = (prms) => {
  const { stdDate, schedule, users, com, service, } = prms;
  const { billingDt, masterRec } = setBillInfoToSch(prms);
  // スケジュールの確認
  const schChk = {result: true, cnt: 0};
  Object.keys(schedule).filter(e=>e.indexOf('UID') === 0).map(e=>{
    Object.keys(schedule[e]).filter(f=>f.indexOf('D') === 0).map(f=>{
      const thisSch = schedule[e][f];
      if (!thisSch.useResult) schChk.result = false;
      schChk.cnt++;
    });
  });
  // 上限管理の確認
  const jougenChk = {result: true, cnt: 0};
  billingDt.map(e=>{
    if (!e.kanriOk && e.kanriType) jougenChk.result = false;
    jougenChk.cnt++;
  });
  // 保険番号の確認
  const hnoChk = {result: true, cnt: 0};
  users.map(e=>{
    if (e.hno.length != 10)  hnoChk.result = false;
    hnoChk.cnt++;
  });
  console.log(schChk, 'schChk');
  console.log(jougenChk, 'jougenChk');
  console.log(hnoChk, 'hnoChk');
  return {schChk, jougenChk, hnoChk};
}

export const CheckProgress = (props) => {
  const classes = useStyles();
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state=>state.users);
  const stdDate = useSelector(state=>state.stdDate);
  const com = useSelector(state=>state.com);
  const service = useSelector(state=>state.service);
  const allState = useSelector(state=>state);
  const [chk, setChk] = useState({
    jougen:{result: false, cnt: 0},
    sch:{result: false, cnt: 0},
    hno:{result: false, cnt: 0},
  });
  useEffect(()=>{
    const chkPrms = { stdDate, schedule, users, com, service, allState };
    if (service === '放課後等デイサービス'){
      const chkResult = doCheckProgress(chkPrms);
      setChk({
        jougen: chkResult.jougenChk,
        sch: chkResult.schChk,
        hno: chkResult.hnoChk,
      });  
    }
  }, [service]);
  const {loaded, error} = comMod.getLodingStatus(allState);
  if (!loaded || error){
    return null;
  }
  if (service !== '放課後等デイサービス'){
    return null;
  }


  const ChkDsp = (p) => {
    if (p.result && p.cnt){
      return (<div className='OK'>OK</div>)
    }
    else if (!p.result && p.cnt) {
      return (<div className='NG'>要確認</div>)
    }
    else {
      return (<div className='NODATA'>データなし</div>)
    }
  }
  
  let thisClass = classes.fixRoot;
  if (props.inline){
    thisClass = classes.inlineRoot;
  }
  return (
    <div className={thisClass}>
      <div className='checkResult'>
        <div className='item'>
          <div className='rhead'>利用実績</div>
          <div className='result'><ChkDsp {...chk.sch} /></div>
        </div>
        <div className='item'>
          <div className='rhead'>上限管理</div>
          <div className='result'><ChkDsp {...chk.jougen} /></div>
        </div>
        <div className='item'>
          <div className='rhead'>被保険者番号</div>
          <div className='result'><ChkDsp {...chk.hno} /></div>
        </div>

      </div>
    </div>
  )
}
export default CheckProgress;