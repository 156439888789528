import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import { setUseResult } from '../../Actions';
import * as Actions from '../../Actions';
import * as mui from '../common/materialUi'
import * as afp from '../common/AddictionFormParts'; 
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SchByDateStartEndInput from './SchByDateStartEndInput';
import { useLocation } from 'react-router-dom';
import teal from '@material-ui/core/colors/teal';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYenSign } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from '../common/FormPartsCommon';

const useStyle = makeStyles((theme) => ({
  root: {
    ' & .MuiDialog-paperWidthSm': {
      maxWidth:'initial'
    }
  }
}));

const useLocalStyles = makeStyles({
  faIcon:{
    padding: 0, fontSize: 22, 
    width: 24, textAlign: 'center', display: 'inline-block',
    color:teal[500],
  },
  listItem: {
    '& .MuiSvgIcon-root': {
      color: teal[500],
    }
  },

});

// scheduleの実績、useResultを変更するdispatchをコールする
// bulk = 0 その日だけ
// bulk = -1 以前を含む
// bulk = 1 以降を含む
const callDispatch = (prms, value, selectors ,bulk, path)=>{
  const [date, schedule, users, service, dispatch] = prms;
  const { dateList, hid, bid, stdDate } = selectors;
  const d = comMod.convDid(date); // 日付けKey値 dyyyyymmdd形式

  const targetList = [];
  Object.keys(schedule).forEach(e =>{
    // scheduleのキーでuidを探す
    if (e.indexOf('UID') !== 0){
      return false;
    }
    // uidからサービスを特定。現在ののサービスと一致しているものを探す
    if (comMod.getUser(e, users).service !== service){
      return false;
    }
    // schedule.uidから更に掘って該当するスケジュールオブジェクトを特定する
    // 一括変更のオプションも読んでそのとおりに変更リストを追加する
    Object.keys(schedule[e]).forEach(f=>{
      if (f === d)  targetList.push({UID:e, did: f});
      if (f < d && bulk === -1) targetList.push({ UID: e, did: f });
      if (f > d && bulk === 1) targetList.push({ UID: e, did: f });
    });
  });
  targetList.map(e=>{
    dispatch(setUseResult(e.UID, e.did, value))
  });
  // const saveParams = { dateList, hid, bid, stdDate, schedule, dispatch };
  // comMod.saveLater(saveParams);
  comMod.setSchedleLastUpdate(dispatch, path);

};

// ダイアログを消す前にメニューリストが一瞬表示されてしまうのを防ぐ
const hideMenuList = ()=>{
  const target = document.querySelector('.schDialog');
  target.style.cssText = 'opacity:0;';
}

const MenuList = (props) => {
  const localCls = useLocalStyles();
  const clickHandler = (n) => {
    props.setcontentNdx(n);
  }
  return (
    <>
      <List className="daialogMenuList">
        <ListItem button className={'listItem ' + localCls.listItem}
          onClick={() => clickHandler(1)}
          key={2}
        >
          <CheckIcon color={teal[500]} />
          <span className='text'>日時実績設定</span>
        </ListItem>
        <ListItem button className={'listItem ' + localCls.listItem}
          onClick={() => clickHandler(2)}
          key={3}
        >
          {/* <AddBoxIcon color={teal[500]} /> */}
          <span className={localCls.faIcon} >
            <FontAwesomeIcon icon={faYenSign} />
          </span>

          <span className='text'>日時加算項目</span>
        </ListItem>
        <ListItem button className={'listItem ' + localCls.listItem}
          onClick={() => clickHandler(3)}
          key={4}
        >
          <ScheduleIcon color={teal[500]} />
          <span className='text'>開始終了時刻一括入力</span>
        </ListItem>
      </List>
    </>
  )
}


// 利用実績の付加と削除
const UseResultControle = (props)=>{
  const dateList = useSelector(state => state.dateList);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const stdDate = useSelector(state => state.stdDate);
  const selectors = {dateList, hid, bid, stdDate}
  const path = useLocation().pathname;

  const handleClick = (prms, value, dispatch, bulk = 0) => {
    if (dispatch) callDispatch(prms, value, selectors, bulk);
    props.closehandler();
  };

  return(<>
    <List>
      <ListItem button className='listItem'
        // 実績のセット
        onClick={
          () => handleClick(props.prms, true, true)
        }
        key='1'
      >
        <AddBoxIcon color='primary' />
        <span className='text'>実績にする</span>
      </ListItem>

      <ListItem button className='listItem'
        // 実績のセット
        onClick={() => 
          handleClick(props.prms, true, true, -1)
        }
        key='2'
      >
        <AddBoxIcon color='primary' />
        <span className='text'>この日まで実績</span>
      </ListItem>
      <ListItem button className='listItem'
        // 実績の取り消し
        onClick={() => 
          handleClick(props.prms, false, true)
        }
        key='3'
      >
        <IndeterminateCheckBoxIcon color='primary' />
        <span className='text'>実績を取り消す</span>
      </ListItem>
      <ListItem button className='listItem'
        // 実績の取り消し
        onClick={() => 
          handleClick(props.prms, false, true, 1)
        }
        key='4'
      >
        <IndeterminateCheckBoxIcon color='primary' />
        <span className='text'>この日より取り消す</span>
      </ListItem>
    </List>

  </>)
}


// 日毎の加算を行う
// 今の所放デイ専用のモジュール
const DairyAddiction = (props) =>{
  const disptch = useDispatch();
  const [date, schedule, users, service, dispatch] = props.prms;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const dateList = useSelector(state => state.dateList);
  const stdDate = useSelector(state => state.stdDate);
  const path = useLocation().pathname;

  const handleSubmit = (e) => {
    // 日毎の加算項目をdispatchする
    e.preventDefault();
    const did = comMod.convDid(date);
    // 値が必要なエレメントを用意しておく
    const inputs = document.querySelectorAll('#e56fgth7 input');
    const selects = document.querySelectorAll('#e56fgth7 select');

    // フォームから値を取得
    const formsVal = comMod.getFormDatas([inputs, selects]);
    disptch(Actions.replaceSchedule(service, did, formsVal));
    comMod.setSchedleLastUpdate(dispatch, path)

  }
  const cancelSubmit = ()=>{
    props.closehandler();
  }
  // 設定済み項目チェック
  const comAdic = useSelector(state=>state.com.addiction);
  const makedisabled = (obj, path) =>{
    const v = comMod.findDeepPath(obj, path);
    if (parseInt(v) === -1)  return -1;
    else return v;
  }
  
  return(<>
    <form id='e56fgth7' className="addiction">
      <afp.JidouShidouHaichi did={date} size='middle' dLayer={2} />
      <afp.JiShidouKaHai1 did={date} size='middle' dLayer={2} />
      <afp.JiShidouKaHai2 did={date} size='middle' dLayer={2} />
      <afp.FukushiSenmonHaichi did={date} size='middle' dLayer={2} />
      <afp.TeiinChouka did={date} size='middle' dLayer={2} />
      <afp.KaisyoGensan did={date} size='middle' dLayer={2} />
      <afp.KangoKahai did={date} size='middle' dLayer={2} />
    </form>
    <div className='buttonWrapper'>
      <mui.ButtonGP
        color='secondary'
        label='キャンセル'
        onClick={cancelSubmit}
      />
      <mui.ButtonGP
        color='primary'
        label='書き込み'
        type="submit"
        onClick={handleSubmit}
      />
    </div>
  </>)

}

function SchDialog(props) {
  // props.prms = [date, schedule, users, service, dispatch];
  // bulk = 0 その日だけ
  // bulk = -1 以前を含む
  // bulk = 1 以降を含む
  const [contentNdx, setcontentNdx] = React.useState(0);
  const date = props.prms.date;
  const classes = useStyle();
  const closehandler = () => {
    setcontentNdx(0);
    hideMenuList();
    props.closeThis();
  }

  const titles = [
    '日時設定メニュー',
    '日時利用実績設定',
    '日時加算設定',
    '開始終了時刻一括入力',
  ]

  return (
    <Dialog 
      onClose={closehandler} 
      aria-labelledby="simple-dialog-title" 
      open={props.open}
      className={'schDialog ' + classes.root}
      closehandler={()=>closehandler()}
    >
      <div className='dialogTitle'>
        {titles[contentNdx]}
      </div>
      <div className="date">
        {(props.date === undefined)?
          '':comMod.formatDate(props.date, 'MM月DD日')}
      </div>
      {contentNdx === 0 && <>
        <MenuList {...props} setcontentNdx={setcontentNdx} />
        <div className="buttonWrapper center" >
          <mui.ButtonCancel size='small' onClick={() => closehandler()} />
        </div>
      </>}
      {/* {contentNdx === 1 && <>
        <TestContent />
        <div className="buttonWrapper center" >
          <mui.ButtonCancel size='small' onClick={() => closehandler()} />
        </div>
      </>} */}
      {contentNdx === 1 && <>
        <UseResultControle prms={props.prms} closehandler={()=>closehandler()}/>
        <div className="buttonWrapper center" >
          <mui.ButtonCancel size='small' onClick={() => closehandler()} />
        </div>

      </>}
      {contentNdx === 2 && <>
        <DairyAddiction prms={props.prms} closehandler={()=>closehandler()} />
      </>}
      {contentNdx === 3 && <>
        <SchByDateStartEndInput date={props.date} close={()=>closehandler()} />
      </>}
    </Dialog>

  );
}

export default function SchDailyDialog(props) {
  // props : date = { e.date }
  const dispatch = useDispatch();
  const date = props.date;
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  const prms = [date, schedule, users, service, dispatch];
  const [open, setOpen] = React.useState(false);
  const [pos, setpos] = React.useState({});
  const handleClickOpen = (e) => {
    setpos(e.currentTarget.getBoundingClientRect());
    setOpen(true);
  };

  const closeThis = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="schDailyContUnker" onClick={handleClickOpen}>
        <ExpandMoreIcon />
      </div>
      <SchDialog 
        open={open}
        setOpen={setOpen}
        closeThis={closeThis}
        // const [date, schedule, users, service, dispatch] = prms;
        prms={prms}  
        date={date}
        pos={pos}
      />
    </div>
  );
}
