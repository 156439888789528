// ----------------- 使ってない
// Scheduleの遅延セーブを行う
// comMod.setSchedleLastUpdate(dispatch, true)でstore上のスイッチを制御
// スイッチを検出してタイマーイベント発火
// save(send)のディスパッチを送信したらスイッチをオフにする

import React, { useEffect, useState, useRef } from 'react';
import * as Actions from '../../Actions';
import {
  useDispatch, useSelector,
} from 'react-redux';
import * as comMod from '../../commonModule';
import { useLocation } from 'react-router-dom';



export const SchSaveLater = (props)=>{
  const dispatch = useDispatch();
  const dateList = useSelector(state => state.dateList);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const controleMode = useSelector(state => state.controleMode);
  const path = useLocation().pathname;

  const interval = 3000;
  const sendit = () => {
    // 日付オブジェクトをjsonにすると時差で日付がずれる！
    // ので一旦文字列に変換
    const newList = dateList.map(e => {
      return ({
        date: comMod.formatDate(e.date, 'YYYY-MM-DD'),
        holiday: e.holiday,
      });
    });
    let prms = {
      hid, bid, date: stdDate,
      dateList: JSON.stringify(newList),
      a: 'sendCalender',
    };
    dispatch(Actions.sendCalender(prms));
    prms = {
      hid, bid, date: stdDate,
      schedule: JSON.stringify(schedule),
      a: 'sendSchedule',
    }
    dispatch(Actions.sendSchedule(prms));
  }
  // storeの監視
  let timeoutId;
  useEffect(()=>{
    if (controleMode.scheduleSavePromise){
      timeoutId = setTimeout(() => {
        sendit();
        console.log('saved.')
        comMod.setSchedleLastUpdate(dispatch, path)
      }, interval);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    else{
      clearTimeout(timeoutId);
    }
  }, [schedule, dateList, controleMode]);

  // return (<div>this is SchSaveLater</div>);
  return (null);

}
export default SchSaveLater;