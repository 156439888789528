import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from '../../Actions';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux'
import * as comMod from '../../commonModule';
import SchCalender from './SchCalender'
import { Link, useLocation } from 'react-router-dom';
import * as mui from '../common/materialUi';
import Button from '@material-ui/core/Button';
// import { SchEditTemplate, SchEditTemplateWrapper } from './SchByUserMonthly';

const useStyles = makeStyles({
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
});

const Links = () => {
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  const prms = [
    { link: "/schedule", label: "月間" },
    { link: "/schedule/weekly", label: "週間" },
    { link: "/schedule/users", label: "ユーザー別" },
    { link: "/schedule/calender", label: "休校・休日設定" },
  ];
  const linkList = prms.map((e, i) => {
    let cls = (ref === e.link) ? 'current' : '';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return (<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}


// 稼働率の計算を行う
export const OccupancyRate = () =>{
  const dateList = useSelector(state => state.dateList);
  const schedule = useSelector(state => state.schedule);
  const service = useSelector(state => state.service);
  const comAdc = useSelector(state => state.com.addiction);
  let comEtc = useSelector(state => state.com.etc);
  comEtc = comEtc ? comEtc : {};
  let teiin = comMod.findDeepPath(comAdc, service + '.定員');
  teiin = (teiin)? parseInt(teiin): 0;
  
  // 稼働日
  let workDays = dateList.filter(e=>e.holiday !== 2).length;
  workDays = (comEtc.configOccupancyRate === '休業日を含めて稼働率計算')?
    dateList.length : workDays
  workDays = (comEtc.configOccupancyRate === '休業・休校を含めず稼働率計算') ?
    dateList.filter(e => e.holiday === 0).length : workDays
  // サービス提供回数を調べるためにスケジュールを舐める
  let cnt = 0;
  Object.keys(schedule).map(e=>{
    if (e.indexOf('UID') !== 0) return false; // uid以外はスキップ
    const thisUser = schedule[e];
    Object.keys(thisUser).map(f=>{
      // スケジュールオブジェクト以外はスキップ
      if (f.indexOf('D') !== 0) return false; 
      const sch = thisUser[f];
      if (sch.absence)  return false; // 欠席の場合はスキップ
      if (service === ''){  // サービス未定義の場合は無条件カウント
        cnt++;
        return false;
      }
      if (service === sch.service)  cnt++;
    })
  });
  let occuRate = comMod.formatNum(cnt / workDays / teiin * 100, 0, 0, 2);
  occuRate = isNaN(occuRate) ? '0.00' : occuRate;
  return(
    <div className='occuRate'>
      <div className='rate'>
        <span className='small'>稼働率 : </span>{occuRate.split('.')[0]}.
        <span className='small'>{occuRate.split('.')[1]} %</span> 
      </div>
      <div className='detail'>利用:{cnt} 日数:{workDays} 定員:{teiin}</div>
    </div>
  )
}


class SchHeadNav extends React.Component {
  render (){
    return(
      <Links />
    )
  }
}

// class SchHeadNav extends React.Component{
//   render(){
//     return(
//       <div className='pageNav'>
//         <div className='buttonWrapperPN'>
//           {/* <mui.ServiceChangeButton allowUnSepcified={false}/> */}

//         </div>
//         {/* 次月前月 */}
//         <div className='buttonWrapperPN'>
//           {/* <mui.ButtonMonthNav set={-1} /> */}
//           {/* <mui.ButtonMonthNav set={1} /> */}
//         </div>
//         {/* <div className='month'>
//           <span className='small'>
//             {this.props.stdDate.split('-')[0]}年
//           </span><br/>
//           {this.props.stdDate.split('-')[1]}
//           <span className='small'>月</span>
//         </div> */}

//         {/* <div className='modalAnc'> */}
//           {/* <ModalWindow
//             ModalBody={SchCalender}
//             OpenModalText={'休校・休業日設定'}
//             modalIsOpen={false}
//           /> */}
//         {/* </div> */}
//         <Link className='menuItem' to="/schedule/calender/">
//           <mui.ButtonGP label='休校休日' />
//         </Link>
//         <Link className='menuItem' to="/schedule/users/">
//           <mui.ButtonGP label='利用者別'/>
//         </Link>
//         <Link className='menuItem' to="/schedule/weekly/">
//           <mui.ButtonGP label='週間予定' />
//         </Link>
//         {/* <SchEditTemplateWrapper/> */}
//         {/* <OccupancyRate /> */}
//       </div>
//     )
//   }
// }
function mapStateToProps(state) {
  return (state);
}
export default connect(mapStateToProps, Actions)(SchHeadNav);
