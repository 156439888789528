import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as mui from '../common/materialUi';
import * as comMod from '../../commonModule';
import * as Actions from '../../Actions';
import * as sfp from '../common/StdFormParts';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  cntRow: {
    display: 'flex',
    justifyContent: 'center',
    '& .name' : {
      marginTop: 4,
      marginRight: 8,
      padding: '12px 12px 0 ',
      height: 'calc(1rem + 20px)',
      width: 160,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .offSchool' : {
      backgroundColor: 'rgb(248, 227, 203)',
    },
    '& .off': {
      backgroundColor: 'rgb(202,202,217)',
    },
  },
  formHead:{
    display: 'flex',
    justifyContent: 'center',
    '& .title':{
      width: 160,
      padding: '12px 12px 8px',
    },  
    '& .time': {
      width: 120,
      padding: '12px 0 8px',
    },
  },
});

const OneUser = (props) =>{
  const classes = useStyles();
  const {schedule, did, uid, ...other} = props;
  const dObj = comMod.convDid(did);
  const dStr = comMod.formatDate(dObj, 'MM月DD日 AAA');
  const start = schedule[uid][did].start;
  const end = schedule[uid][did].end;
  const users = useSelector(state=>state.users);
  const thisUser = comMod.getUser(uid, users);
  // 休校日、休日に対応したクラス名を取得
  // let offClass = ['', 'offSchool', 'off'][schedule[uid][did].offSchool];
  // offClass = (!offClass)? '' : offClass;

  const nameStart = uid + '-start';
  const nameEnd = uid + '-end';
  // 欠席扱いの場合
  if (schedule[uid][did].absence) return null;
  return(
    <div className={classes.cntRow}>
      <div className={'name '}>{thisUser.name}</div>
      <sfp.TimeInput 
        size='middle' name={nameStart} label={'開始'} def={start} noLabel 
      />
      <sfp.TimeInput 
        size='middle' name={nameEnd} label={'終了'} def={end} noLabel 
      />
    </div>
  )
}

export const SchByDateStartEndInput = (props)=>{
  const classes = useStyles();
  const {date, ...othe} = props;
  const did = 'D' + comMod.formatDate(date, 'YYYYMMDD');
  const schedule = useSelector(state=>state.schedule);
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const handleSubmit = (ev) => {
    console.log('handlesubmit');
    const inputs = document.querySelectorAll('#hy67a input');
    const fDt = comMod.getFormDatas([inputs]);
    const target = {};
    // fdt={UIDxx-start:'10:30', UIDxx-end:'17:30'}
    // target={UIDxx:{start:'10:30', end:'17:30'}, ...}
    Object.keys(fDt).map(e=>{
      const uid = e.split('-')[0];
      const item = e.split('-')[1]; // start or end
      target[uid] = {...target[uid], [item]: fDt[e]};
    });
    Object.keys(target).map(e=>{
      const newSch = {
         ...schedule[e][did], start: target[e].start, end: target[e].end
      };
      dispatch(Actions.replaceSchedule(e, did, newSch));
    });
    comMod.setSchedleLastUpdate(dispatch, path);
  }
  const cancelSubmit = () => {
    props.close();
  }
  // スケジュールが持つuidの配列
  const ptn = /^UID[0-9]/;
  const tUids = Object.keys(schedule).filter(e=>e.match(ptn));
  // didを含むuidの配列
  const tuids2 = [];
  tUids.map(e=>{
    if (schedule[e][did]){
      tuids2.push(e);
    }
  });
  // usersの順番に並び替え
  const users = useSelector(state=>state.users);
  const uids = [];
  users.map(e=>{
    if (tuids2.find(f=>f === 'UID' + e.uid)){
      uids.push('UID' + e.uid);
    }
  });
  const eachUsers = uids.map((e, i)=>{
    return (<OneUser key={i} schedule={schedule} did={did} uid={e} />)
  });
  return (<>
    <div className={classes.formHead}>
      <div className='title'>名前</div>
      <div className='time'>開始</div>
      <div className='time'>終了</div>
    </div>
    <form id="hy67a" className="dialogForm withSpace">
      {eachUsers}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonGP
        color='secondary'
        label='キャンセル'
        onClick={cancelSubmit}
      />
      <mui.ButtonGP
        color='primary'
        label='書き込み'
        type="submit"
        onClick={handleSubmit}
      />

    </div>
  </>)
}
export default SchByDateStartEndInput;