import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts'; 
import * as sfp from '../common/StdFormParts';  
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { endPoint } from '../../Actions'
import axios from 'axios';
import SnackMsg from '../common/SnackMsg';
import SortIcon from '@material-ui/icons/Sort';
import { IconButton, TextField } from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Delete from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import orange from '@material-ui/core/colors/orange';
// import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import RegistedParams from './RegistedParams';


const useStyles = makeStyles((theme) => ({
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
  userIndexTfRoot:{
    '& .MuiInputBase-input': {
      padding: 0,
      textAlign: 'right',
    },
  },
  actualCostListRoot:{
    display: 'flex',
    '& .MuiFormControl-root': {
      width: 150,
      marginTop: 8,
    },
    '& .MuiIconButton-root': {
      padding: 8,
      marginRight: 16,
      // color: '#ef5350',
      color: red[200],
    },
  },
  actualCostAddRoot:{
    display: 'flex',
    paddingTop: 8,
    '& .MuiFormControl-root': {
      width: 150,
      marginRight: 16,
      marginTop: 8,
    },
    '& .MuiButtonBase-root': {
      padding: 8,
      marginTop: 16,
    },
  }
}));

const Links = ()=>{
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  const prms = [
    { link: "/setting", label: "基本" },
    { link: "/setting/schedule", label: "スケジュール関連" },
    { link: "/setting/view", label: "表示" },
    // { link: "/setting/others", label: "その他" },
    { link: "/setting/reg", label: "他事業所・市区町村" },
    { link: "/setting/addiction", label: "請求・加算" },
  ];
  const linkList = prms.map((e, i)=>{
    let cls =  (ref === e.link) ? 'current':'';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return(<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}

// Storeのstate usersを更新する
const updateToSotedUsers = (prms) => {
  const {
    susers, users, dispatch, 
  } = prms;
  // ソート済みユーザーリストからインデックスを取得して
  // ユーザーにセット
  const newUsers = users.map((e, i) => {
    const ndx = susers.findIndex(_ => e.uid === _.uid);
    e.sindex = susers[ndx].sindex;
    return e;
  });
  newUsers.sort((a, b) => (parseInt(a.sindex) - parseInt(b.sindex)));
  dispatch(Actions.updateUsersAll(newUsers));
}

// ユーザーインデックスを更新する
async function requestUserIndexUpdate (prms){
  // susers ソート済みのユーザー
  // users Storeのユーザー
  const {
    susers, hid, bid, setres
  } = prms;
  
  // dbに送信するための配列作成
  const indexset = susers.map(e=>{
    return [e.uid, e.sindex];
  });
  const jindexset = JSON.stringify(indexset);
  // dbのアップデート
  let res;
  const urlPrms = { 
    hid, bid, indexset: jindexset, a: 'sendUsersIndex'
  };
  try {
    res = await axios.post(endPoint, comMod.uPrms(urlPrms));
    if (!res.data.resulttrue > 0 || res.data.resultfalse) {
      throw new Error(res);
    }
    setres(res);
  }
  catch {
    setres(res);
  }
}

const SetUserIndex = (props) => {
  const classes = useStyles();
  // const {tests, settests, susers, setsusers} = props;
  const {susers, setsusers} = props;
  
  const handleChange = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    const tuid = parseInt(name.replace(/[^0-9]/g, ''));
    const i = susers.findIndex(e=>parseInt(e.uid) === tuid);
    const val = ev.currentTarget.value 
    // settests(val);
    // setsusers([...tmpa]);
    setsusers(_=>{
      const tmpa = [...susers];
      tmpa[i] = { ...tmpa[i], sindex: val};
      return tmpa;
    });
  }
  const a = [...susers];
  const userList = a.map((e, i)=>{
    // console.log(tests)
    return(
      <div className='userForIndex flxRow' key={i}>
        <div className='username w20'>
          {!i && <span className='title'>氏名</span>}
          {e.name}
        </div>
        <div className='choolAge w07'>
          {!i && <span className='title'>学齢</span>}
          {e.ageStr}
        </div>
        <div className='belongs1 w20'>
          {!i && <span className='title'>所属</span>}
          {e.belongs1}
        </div>
        <div className='startDate w15'>
          {!i && <span className='title'>開始日</span>}
          {e.startDate}
        </div>
        <div className={'w10 ' + classes.userIndexTfRoot}>
          {!i && <span className='title'>順番</span>}
          <Input 
            name={'UIDNDX' + e.uid} value={e.sindex} 
            onChange={e=>handleChange(e)}
          />
        </div>
      </div>
    )
  });
  return (
    <div className='usersListForIndex'>
      {userList}
    </div>
  )
}

const userLstForSort = (users, order = 0) => {
  // { value: 0, label: '学齢順に並べる', class: '' },
  // { value: 1, label: '利用開始順に並べる', class: '' },
  // { value: 2, label: '所属・学校順に並べる', class: '' },
  // { value: 3, label: '50音順に並べる', class: '' },
  if (!Array.isArray(users))  return [];
  const tmp = [...users]
  if (parseInt(order) === 1){
    tmp.sort((a, b) => {
      if (a.ageNdx > b.ageNdx) return 1;  
      if (a.ageNdx < b.ageNdx) return -1;  
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  else if (parseInt(order) === 2){
    tmp.sort((a, b) => {
      if (a.startDate > b.startDate) return 1
      if (a.startDate < b.startDate) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  else if (parseInt(order) === 3){
    tmp.sort((a, b) => {
      if (a.belongs1 > b.belongs1) return 1
      if (a.belongs1 < b.belongs1) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
    // tmp.sort((a, b) => ( (a.belongs1 > b.belongs1) ? 1 : -1 ));
  else if (parseInt(order) === 4){
    tmp.sort((a, b) => {
      if (a.kana > b.kana) return 1
      if (a.kana < b.kana) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
    // tmp.sort((a, b) => ((a.kana > b.kana) ? 1 : -1));
  return tmp.map((e, i)=>{
    e.sindex = i * 10 + 100;
    return {
      sindex: e.sindex,
      ageStr: e.ageStr,
      ageNdx: e.ageNdx,
      belongs1: e.belongs1,
      name: e.name,
      kana: e.kana,
      service: e.service,
      uid: e.uid,
      startDate: e.startDate,
    };
  });
}

export const ViewSettings = () => {
  const defaultOrder = 0;
  const dispatch = useDispatch();
  const classes = useStyles();
  const users = useSelector(state => state.users);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  
  // ユーザーリストを一旦、ステイトにセット
  const [susers, setsusers] = useState([]);
  // 並び順の設定 SetUsersSort にわたす
  const [sortOrder, setsortOrder] = useState(defaultOrder);
  // dbapi レスポンス格納用
  const [res, setres] = useState({});
  // スナックバー用
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');

  // ソートの変更
  useEffect(()=>{
    setsusers(userLstForSort(users, sortOrder))
  }, [sortOrder]);

  // 送信後の状態監視
  useEffect(()=>{
    console.log('res', res);
    if (Object.keys(res).length){
      if (res.data.resulttrue && !res.data.resultfalse) {
        setseverity('');
        setmsg('利用者の順番を登録しました。');
        updateToSotedUsers({ susers, users, hid, bid, dispatch, setres });
      }
      else {
        setseverity('error');
        setmsg('利用者の登録に問題が発生しました。');
      }
    }
  }, [res]);
  const keyHandler = (e) => {
    if (e.which === 13) handleSubmit(e);
  }
  const handleSubmit = (e) => {
    // susers, users, hid, bid, dispatch, setres
    requestUserIndexUpdate({susers, users, hid, bid, dispatch, setres});
    console.log('res',res)
  }
  const cancelSubmit = (e) => {
    console.log('hoge');
    dispatch(Actions.resetStore());
  }
  
  // ソートの実行。ユーザーリストを表示するステイトをソートして更新する
  const sortLocal = (e) => {
    const tmpa = [...susers];
    tmpa.sort((a, b) => (a.sindex - b.sindex));
    setsusers(tmpa);
    // setsortOrder(0);
  }

  return(<>
    <Links />
    <div className="AppPage setting">
      <form id='f37fht' onKeyPress={(e) => keyHandler(e)}>
        <sfp.SetUsersSort 
          size='large'
          sortOrder={sortOrder} setsortOrder={setsortOrder}  
        />
        <SetUserIndex 
          susers={susers} 
          setsusers={(v)=>setsusers(v)} 
          // tests={tests}
          // settests={(v)=>settests(v)}
        />
      </form>
      <div className='buttonWrapper fixed'>
        {/* <mui.ButtonGP
          label='整列'
          onClick={sortLocal}
        /> */}
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
          
        />
        <Button
          variant="contained"
          className='sortButton'
          startIcon={<SortIcon />}
          onClick={sortLocal}
        >
          整列
        </Button>
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
    <SnackMsg msg={msg} severity={severity} />
  </>)
}

export const AddictionSettings =() => {
  const dispatch = useDispatch();
  const hid  = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  // const serviceItems = useSelector(state => state.serviceItems);
  // const s = useSelector(state => state.service);
  // const service = (s === '')?serviceItems[0]:s;
  // サービスは放デイ固定
  const service = '放課後等デイサービス';
  // 事業所加算項目
  const comAdc = useSelector(state => state.com.addiction);
  const handleSubmit = (e)=>{
    e.preventDefault();
    // 値が必要なエレメント
    const inputs = document.querySelectorAll('#f37fht input');
    const selects = document.querySelectorAll('#f37fht select');

    const outPutObj = {};
    outPutObj[service] = comMod.getFormDatas([inputs, selects]);
    dispatch(Actions.setAddictionSettingCom(outPutObj));
    const addiction = JSON.stringify(outPutObj);
    dispatch(Actions.sendAddictionOfBrunch(
      { hid, bid, addiction }
    ));
  }

  const keyHandler = (e) =>{
    if (e.which === 13) handleSubmit(e);
  }

  const cancelSubmit = (e)=>{
    dispatch(Actions.resetStore());
  }
  return (<>
    <Links />
    <div className="AppPage setting">
      <form id='f37fht' onKeyPress={(e) => keyHandler(e)}>
        <afp.JoutaiKubun dLayer={0} dispHide size='large'/>
        <afp.ChiikiKubun dLayer={0} dispHide size='large'/>
        <afp.Teiin dLayer={0} dispHide size='large' />
        <afp.JidouShidouHaichi dLayer={0} dispHide size='large' />
        <afp.FukushiSenmonHaichi dLayer={0} dispHide size='large' />
        <afp.JiShidouKaHai1 dLayer={0} dispHide size='large' />
        <afp.JiShidouKaHai2 dLayer={0} dispHide size='large' />
        <afp.KangoKahai dLayer={0} dispHide size='large' />
        {/* <afp.KyouseiService dLayer={0} dispHide size='large' /> */}
        {/* <afp.KyouseiKyouka dLayer={0} dispHide size='large' /> */}
        <afp.ShoguuKaizen dLayer={0} dispHide size='large' />
        <afp.ShoguuTokubetsu dLayer={0} dispHide size='large' />
        <afp.TokuteiSyoguu dLayer={0} dispHide size='large' />
        <afp.ShokuinKetujo dLayer={0} dispHide size='large' />
        <afp.JihatsuKetsujo dLayer={0} dispHide size='large' />
        <afp.KeikakuMisakusei dLayer={0} dispHide size='large' />
        <afp.Jikohyouka dLayer={0} dispHide size='large' />
        <afp.KaisyoGensan dLayer={0} dispHide size='large' />
        <afp.ShinTaikousoku dLayer={0} dispHide size='large' />
        <afp.TeiinChouka dLayer={0} dispHide size='large' />
        <div className="formTextWrapper">
          <div className="text">
            以下の項目は全体の事業所全体の請求設定ではありません。
            ここでは利用者ごと、日付ごとのなどの加算減算設定で表示非表示を切り替えるために設定します。
          </div>
          <div className="text">
            普段利用しない項目は非表示設定にすることにより入力作業などがしやすくなります。
          </div>
          <div className="text">
            非表示設定にすることにより加算を見逃す可能性もあるので十分にご注意ください。
          </div>

        </div>
        <afp.IryouRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.EnchouShien dLayer={0} noOpt dispHide size='large' />
        <afp.TokubetsuShien dLayer={0} noOpt dispHide size='large' />
        <afp.KateiRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.KankeiRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.HoikuKyouiku dLayer={0} noOpt dispHide size='large' />
        <afp.JougenKanri dLayer={0} noOpt dispHide size='large' />
        <afp.HoumonShien dLayer={0} noOpt dispHide size='large' />
        <afp.KessekiTaiou dLayer={0} noOpt dispHide size='large' />

      </form>
      <div className='buttonWrapper fixed'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
  </>)
}

export const StandardSettings = ()=>{
  const dispatch = useDispatch();
  const com = useSelector(state=>state.com);
  const bid = useSelector(state => state.bid);
  const hid = useSelector(state => state.hid);
  // スナックバー用
  const [snackMsg, setSnackMsg] = useState('');
  const [severity, setSeverity] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const formId = '#f38fht '
    const inputs = document.querySelectorAll(formId + 'input');
    const formDt = comMod.getFormDatas([inputs], false, true);
    formDt.bid = bid;
    formDt.hid = hid;
    formDt.kanri = formDt.kanriL + ' ' + formDt.kanriF;
    console.log('formDt', formDt);
    // エラーがないか helperテキストエラーのセレクタを定義
    const errOccured = document.querySelectorAll(
      formId + '.MuiFormHelperText-root.Mui-error'
    );
    if (errOccured.length){
      console.log(errOccured);
      // dispatch(Actions.setSnackMsg(
      //   'エラーが発生している入力項目があります。', 'error'
      // ));
      setSnackMsg('エラーがあります。');
      setSeverity('error');
      return false;
    }
    
    dispatch(Actions.sendBrunch(formDt));
  }
  const keyHandler = (e) =>{
    if (e.which === 13 && e.shiftKey) handleSubmit(e);
  }
  const cancelSubmit = (e) => {
    console.log('canceled.');
    dispatch(Actions.resetStore());
  }
  const kNameProps = {
    nameLname: 'kanriL',
    nameFname: 'kanriF',
    labelLname: '管理者 姓',
    labelFname: '管理者 名前',
    kana: false,
    required: true,
    def: com.kanri,
  }
  // const { defPostal, defAddr1, defAddr2, required } = props;
  const defPostal = isNaN(com.postal) 
    ? com.postal : com.postal.substr(0, 3) + '-' + com.postal.substr(3, 4);
  const paProps = {
    defPostal: defPostal,
    defAddr1: com.city,
    defAddr2: com.address,
    required: true,
  }
  const telProps = { 
    name: 'tel',
    label: '電話番号',
    required: true,
    def: com.tel,
  };
  const faxProps = {
    name: 'fax',
    label: 'FAX番号',
    required: true,
    def: com.fax,
  };
   
  return (<>
    <Links />
    <div className="AppPage setting">
      <form id='f38fht' onKeyPress={(e) => keyHandler(e)}>
        <sfp.JiNumber def={com.jino} />
        <sfp.Bname def={com.bname} />
        <sfp.Sbname def={com.sbname} />
        <div className='cntRow'>
          <sfp.NameInput {...kNameProps} />
        </div>
        <sfp.PostalAndAddress {...paProps} />
        <div className='cntRow'>
          <sfp.PhoneInput {...telProps} />
          <sfp.PhoneInput {...faxProps} />
        </div>
        <div className='cntRow'>
          <sfp.FilenamePreFix />
        </div>
      </form>

      <div className='buttonWrapper'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
    <SnackMsg msg={snackMsg} severity={severity} setmsg={setSnackMsg}/>

  </>);
}

export const OthesSettings = () =>{
  return(<>
    <Links />
    <div className="AppPage setting">
      hoge
    </div>
  </>)
}

export const RegParamsSettings = () =>{
  return(<>
    <Links />
    <div className="AppPage setting">
      <RegistedParams />
    </div>
  </>)
}


const ActtualCostFormParts = (props) =>{
  const classses = useStyles();
  // const acList = useSelector(state => state.config.actualCostList);
  // const { acListS, setAclistS, newItem, setNewItem } = props;
  const { 
    acListS, setAclistS, acListS_Err, setAclistS_Err,
    acListS_Text, setAclistS_Text,
  } = props;
  // // 実費設定入力用ステイト
  // const [acListS, setAclistS] = useState(acList);
  // // 新規要素用のステイト
  const [newItem, setNewItem] = useState({ name: '', value: '' })
  const [newItemErr, setNewItemErr] = useState({ name: false, value: false })
  const [newItemText, setNewItemText] = useState({ name: '', value: '' })
  // チェンジイベントハンドラ
  const handleChange = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    let val = ev.currentTarget.value;
    const t = { ...acListS };
    t[name] = val;
    setAclistS(t);
  }
  // ブラーイベントと入力確認
  const handleBlur = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    let val = ev.currentTarget.value;
    val = comMod.convHankaku(val);
    setAclistS({...acListS, [name]: val});
    if (isNaN(val)){
      setAclistS_Err({ ...acListS_Err, [name]: true })
      setAclistS_Text({ ...acListS_Text, [name]: '数値を入力してください。'})
    }
    else{
      setAclistS_Err({ ...acListS_Err, [name]: false })
      setAclistS_Text({ ...acListS_Text, [name]: '' })
    }
  }
  // クリックイベントハンドラ
  const handleClick = (ev) => {
    let name = ev.currentTarget.getAttribute('name');
    name = name.split('-')[1];
    const t = {...acListS}
    delete t[name];
    setAclistS(t)
  }
  // 追加アイテムチェンジハンドラ
  const handleNewItemChange = (ev) =>{
    const key = ev.currentTarget.getAttribute('name');
    const v = ev.currentTarget.value;
    const t = {...newItem, [key]: v};
    setNewItem(t);

  }
  const handleNewItemBlur = (ev) => {
    const key = ev.currentTarget.getAttribute('name');
    let v = ev.currentTarget.value;
    if (key === 'value'){
      v = comMod.convHankaku(v);
      if (isNaN(v)){
        setNewItemErr({...newItemErr, [key]: true});
        setNewItemText({...newItemText, [key]: '数値を入力してください。'} )
      }
      else{
        setNewItemErr({...newItemErr, [key]: false});
        setNewItemText({...newItemText, [key]: ''} );
        setNewItem({...newItem, [key]: v});
      }
    }
  }
  // 新規実費項目の追加
  // 実費項目選択用のステイトを追加して新規追加用のステイトを初期化する
  const handleAddClick = (ev) => {
    if (!newItem.name){
      setNewItemErr({ ...newItemErr, name: true });
      setNewItemText({ ...newItemText, name: '名前を入力してください' });
      return false;
    }
    else{
      setNewItemErr({ ...newItemErr, name: false });
      setNewItemText({ ...newItemText, name: '' });
    }
    if (!newItem.value) {
      setNewItemErr({ ...newItemErr, value: true });
      setNewItemText({ ...newItemText, value: '金額を入力してください' });
      return false;
    }
    else {
      setNewItemErr({ ...newItemErr, name: false });
      setNewItemText({ ...newItemText, name: '' });
    }
    setAclistS({...acListS, [newItem.name]: newItem.value});
    setNewItem({name:'', value:''});
  }
  
  const acctualCostListFP = Object.keys(acListS).map((e, i) => {
    return (
      <div className={classses.actualCostListRoot} key={i}>
        <TextField key={i}
          label={e}
          name={e}
          value={acListS[e]}
          onChange={(e) => handleChange(e)}
          onBlur={(e)=>handleBlur(e)}
          error={acListS_Err[e]}
          helperText={acListS_Text[e]}
        />
        <IconButton 
          name={'delete-' + e}
          onClick={(e)=>handleClick(e)}
          color={'primary'}
        >
          <Delete />
        </IconButton>
      </div>
    )
  });
  // これを関数コンポーネントにすると変更するたびに再レンダリングが発生する
  // 単純にノードを返す変数にすればok
  // イベントもステイトも外部に保有しているからだと思われる
  const acctualCostAddFP = (
    <div className={classses.actualCostAddRoot}>
      <TextField key='name'
        label='名前'
        name='name'
        value={newItem.name}
        onChange={(e) => handleNewItemChange(e)}
        onBlur={(e)=>handleNewItemBlur(e)}
        error={newItemErr.name}
        helperText={newItemText.name}
      />
      <TextField key='value'
        label='金額'
        name='value'
        value={newItem.value}
        onChange={(e) => handleNewItemChange(e)}
        onBlur={(e) => handleNewItemBlur(e)}
        error={newItemErr.value}
        helperText={newItemText.value}
      />
      <IconButton
        name='add'
        onClick={(e) => handleAddClick(e)}
        color='primary'
      >
        <AddCircleIcon />
      </IconButton>

    </div>
  )
  return (<>
    <div className='acctualCosts'>
      {acctualCostListFP}
    </div>
    <div className='acctualCosts'>
      {acctualCostAddFP}
    </div>
  </>)
}

export const ScheduleSettings = () => {
  const dispatch = useDispatch();
  const classses = useStyles();
  const service = useSelector(state => state.service);
  const template = useSelector(state => state.scheduleTemplate);
  const serviceItems = useSelector(state => state.serviceItems)
  const thisServeice = (service) ? service : serviceItems[0];
  const acList = useSelector(state => state.config.actualCostList);
  const config = useSelector(state => state.config);
  const com = useSelector(state => state.com);
  // テンプレートの初期値読み込み
  const wd = comMod.findDeepPath(
    template, [thisServeice, 'weekday']
  );
  const so = comMod.findDeepPath(
    template, [thisServeice, 'schoolOff']
  );
  
  // 実費設定入力用ステイト
  const [acListS, setAclistS] = useState(acList);
  // エラーステイト
  let tmp = {};
  Object.keys(tmp).map(_ =>{
    tmp._ = false;
  });
  // テキストステイト
  const [acListS_Err, setAclistS_Err] = useState(tmp);
  Object.keys(tmp).map(_ => {
    tmp._ = '';
  });
  const [acListS_Text, setAclistS_Text] = useState(tmp);

  // 入力用のステイト
  const [wdTemplate, setWdTemplate] = useState(wd);
  const [soTemplate, setSoTemplate] = useState(so);
  
  // 送迎先用のstate
  const [destList, setDestList] = useState(config.transferList);

  const handleSubmit = (e) =>{
    e.preventDefault();
    // 平日のデータと休日のデータをそれぞれ取得
    const inputsWd = document.querySelectorAll('#uio908 .schTmpWd input');
    const inputsSo = document.querySelectorAll('#uio908 .schTmpSo input');
    const selectsWd = document.querySelectorAll('#uio908 .schTmpWd select');
    const selectsSo = document.querySelectorAll('#uio908 .schTmpSo select');
    const fdWd = comMod.getFormDatas([inputsWd, selectsWd], false, true);
    const fdSo = comMod.getFormDatas([inputsSo, selectsSo], false, true);
    // 実費項目
    [fdWd, fdSo].map(formsVal=>{
      Object.keys(formsVal.actualCost).map(e => {
        if (formsVal.actualCost[e]) {
          formsVal.actualCost[e] = acListS[e];
        }
        else {
          delete formsVal.actualCost[e];
        }
      });
    });
    // 送迎が配列になっているので処理を追加
    [fdWd, fdSo].map(formsVal => {
      formsVal.transfer = [];
      formsVal.transfer[0] =
        (formsVal.pickup !== undefined) ? formsVal.pickup : '';
      formsVal.transfer[1] =
        (formsVal.send !== undefined) ? formsVal.send : '';
      delete formsVal.pickup; delete formsVal.send;
    });
    // 足りない項目を追加
    [fdWd, fdSo].map(formsVal => {
      formsVal.service = thisServeice;
    });
    fdWd.offSchool = 0;
    fdSo.offSchool = 1;
    const tmp = {...template}
    console.log('dist', { scheduleTemplate: { ...tmp } });
    tmp[thisServeice] = { ...tmp[thisServeice], weekday: { ...fdWd } };
    tmp[thisServeice] = { ...tmp[thisServeice], schoolOff: { ...fdSo } };
    const tmp1 = { scheduleTemplate: { ...tmp } }
    // テンプレートの更新
    dispatch(Actions.setStore({...tmp1}));
    // com.etcに記述する内容
    const etcSelects = document.querySelectorAll('#uio908 .comEtc select');
    const comEtc = comMod.getFormDatas([etcSelects], false, true);
    // comの更新とconfigの更新
    const tmp2 = { 
      ...com, 
      etc: { 
        ...tmp1, ...comEtc, 
        actualCostList: acListS, 
        transferList: destList 
      }
    };
    // stateに追加されていない送迎場所を取得する
    const newDest = document.querySelector('input[name=dest]').value;
    const tmp3 = {...tmp2}; // 参照渡しでstringfyされてしまうので一旦退避
    const tmp4 = {
      ...config,
      actualCostList: acListS,
      transferList: [...destList]
    }
    // 追加ボタンが押されていない新しい送迎先の処理
    if (newDest){
      tmp4.transferList.push(newDest);
      tmp3.etc.transferList.push(newDest);
    }
   
    dispatch(Actions.setStore({ com: tmp2 , config: tmp4}));
    // dbに送信
    dispatch(Actions.sendBrunch(tmp3));
  }
  const cancelSubmit = (e) =>{
    e.preventDefault();
    console.log('canceled');
    dispatch(Actions.resetStore());
  }

  return (<>
    <Links />
    <div className="AppPage setting">
      <form id="uio908">
        <div className='templateWrappOuter'>
          <div className='innerTitle'>
            スケジュール雛形・平日・{thisServeice}
          </div>
          <div className='templatewrappInnner'>
            <div className='cntRow schTmpWd'>
              <sfp.TimeInput
                name='start' label='開始'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.TimeInput
                name='end' label='終了'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='pickup' label='迎え'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='send' label='送り'
                value={wdTemplate}
                required size='middle'
              />
            </div>
            <div className='cntRow schTmpWd'>
              <sfp.ActualCostCheckBox
                key={0}
                value={wdTemplate}
                actualCostList={acListS}
                required size='middle'
              />
            </div>

          </div>

          <div className='innerTitle'>
            休校日
          </div>
          <div className='templatewrappInnner'>
            <div className='cntRow schTmpSo'>
              <sfp.TimeInput
                name='start' label='開始'
                value={soTemplate}
                required size='middle'
              />
              <sfp.TimeInput
                name='end' label='終了'
                value={soTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='pickup' label='迎え'
                value={soTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='send' label='送り'
                value={soTemplate}
                required size='middle'
              />
            </div>
            <div className='cntRow schTmpSo'>
              <sfp.ActualCostCheckBox
                key={1}
                value={soTemplate}
                actualCostList={acListS}
                required size='middle'
              />
            </div>
          </div>
          <div className='comEtc'>
            <sfp.SetOccupancyCalc />
          </div>
        </div>
        <div className='innerTitle'>
          実費項目
        </div>
        <ActtualCostFormParts 
          acListS={acListS} setAclistS={setAclistS}
          acListS_Err={acListS_Err} setAclistS_Err={setAclistS_Err}
          acListS_Text={acListS_Text} setAclistS_Text={setAclistS_Text}
          // newItem={newItem} setNewItem={setNewItem}
        />
        <div style={{height:16}}></div>
        <div className='innerTitle'>
          送迎先
        </div>
        <sfp.TransferListGlobal destList={destList} setDestList={setDestList} />
      </form>
      <div className='buttonWrapper'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>

  </>);
}

const Setting = () => {
  return (
    <>
    <StandardSettings />
    </>

  );
}
export default Setting;