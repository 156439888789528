import React, { useState } from 'react';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import * as comMod from '../../commonModule';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import { useStyles } from './FormPartsCommon';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export const LoadingSpinner =()=>{
  return(
    <div className='loading'>
      <img src="./img/loading3dRing.png" />
      {/* <img src="./img/loading3dSq.png"/> */}
    </div>
  )
}

const useStyles = makeStyles({
  cheeckBoxRoot: {
    padding: 8,
    paddingLeft: 16,
    maxHeight: '75vh',
    '& .MuiCheckbox-root': {
      padding: 4,
    },
  },
  userSelectDialog:{
    padding: 4,
    borderRadius: 2,
  },
  closeButton: {
    // position:'relative',
    // top: 0,
    // right: 0,
  },
  dialogTitle:{
    padding: 0,
    paddingLeft: 26,
  },
  scrollBody: {
    padding: '8px 16px 16px',
  },
  linktabRoot: {
    position:'fixed',
    top: 47,
    width: '100vw',
    zIndex: 998,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },

});

// 日毎の加算の数をドットで表現
// maxdotの値で何ドットまでドット数で表示するか。
// maxdot以上ならドットxで表示する
// maxdot -1ならフォントサイズを小さくする
export const DAddictionContent = (props) => {
  const { did, maxdot, ...other } = props;
  const maxdot_ = (maxdot === undefined) ? 4 : maxdot;
  const schedule = useSelector(state => state.schedule);
  const service = useSelector(state => state.service);
  // 日毎の加算項目をカウント
  const countAddiction = () => {
    if (schedule[service] === undefined) return 0;
    if (schedule[service][did] === undefined) return 0;
    return (Object.keys(schedule[service][did]).length);
  }
  const c = countAddiction();
  if (c >= maxdot_) return (
    <div className="dAddiction">
      <FiberManualRecordIcon fontSize='inherit' />{c}
    </div>
  );
  const a = new Array(c).fill(1);
  const b = a.map((e, i) => {
    return <FiberManualRecordIcon key={i} fontSize='inherit' />
  });
  let styleObj = {};
  // styleObj.fontSize = (c === maxdot_ - 2) ? '.5rem' : 'inherit';
  // styleObj.fontSize = (c === maxdot_ - 1) ? '.4rem' : 'inherit';
  if (c === maxdot_ - 2) styleObj = { fontSize: '.5rem' };
  if (c === maxdot_ - 1) styleObj = { fontSize: '.4rem' };

  return (
    <div className="dAddiction" style={styleObj}>
      {b}
    </div>
  )
}
// ドキュメント印刷などのときにユーザーの選択を行う
// ユーザーのリストはチェックボックスやラジオボタンに利用する
// boolenの値を持ったstateを受け取る
// [{uid:xxx, checked:true/false}, ...]
// ダイアログ自体を提供する
// open closeは親コンポーネントのstateで制御される
// type = 0 : チェックボックス 1 : ラジオボタン
// ラジオボタンはまだ作ってない
export const UserSelectDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen, userList, setUserList, type, ...other } = props;
  const nodeType = (type)? type : 0;
  // checkAllの初期値を決める userListに一個でもfalseがあったらfalse
  let chk = true;
  userList.map(e=>{if (!e.checked) chk = false;});
  const [checkAll, setCheckAll] = useState(chk);
  const users = useSelector(state=>state.users);
  const makeUserLabel = (uid) => {
    const r = comMod.getUser(uid, users);
    return (r.name + ' ' + r.ageStr);
  }
  const handleChange = (ev) => {
    const euid=ev.currentTarget.getAttribute('name');
    console.log('euid', euid);
    const i = userList.findIndex(_=>_.uid === euid);
    const t = [...userList];
    const c = t[i].checked ? false : true;
    t[i] = {...t[i], checked:c};
    setUserList(t);
  }
  const handleChangeAll = (ev) => {
    const c = checkAll ? false: true;
    setCheckAll(c);
    const t = [...userList];
    t.map(e=>{
      e.checked = c;
    });
    setUserList(t);
  }
  const nodesChekBox = userList.map((e, i)=>{
    return(
      <FormControlLabel key={i}
        control={
          <Checkbox checked={e.checked} 
          onChange={(e)=>handleChange(e)} name={e.uid} />
        }
        label={makeUserLabel(e.uid)}
      />
    )
  });
  return (<>
    <Dialog className={classes.userSelectDialog}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormControlLabel
          control={
            <Checkbox checked={checkAll}
              onChange={(e) => handleChangeAll(e)} name='all' />
          }
          label='すべて選択'
        />
        <IconButton 
          color="primary" className={classes.closeButton}
          onClick={()=>setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} className={classes.scrollBody}>
        <div className={classes.cheeckBoxRoot}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {nodesChekBox}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  </>)
}
/// 画面上部に表示するサブメニュー
// propsにはメニュー表示用の配列
// 配列のlinkはrouterで指定されている必要がある
export const LinksTab = (props) => {
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  // const menu = [
  //   { link: "/billing", label: "請求送信" },
  //   { link: "/billing/upperlimit", label: "上限管理" },
  // ];
  const menu = props.menu;
  const linkList = menu.map((e, i) => {
    let cls = (ref === e.link) ? 'current' : '';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return (<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}
