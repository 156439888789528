import React from 'react';
import * as Actions from '../../Actions';
import { connect, useSelector } from 'react-redux';
import * as comMod from '../../commonModule';
import SchDailyDialog from './SchDailyDialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import * as cp from '../common/commonParts';
import {OccupancyRate} from './SchHeadNav';

// 日毎の利用数をカウント
const countVisitOfDate = (props, did) => {
  let cnt = 0;
  const schedule = props.schedule;
  Object.keys(schedule).map(e => {
    if (e.indexOf('UID') !== 0) return false; // uid以外はスキップ
    const thisUser = schedule[e];
    Object.keys(thisUser).map(f => {
      // スケジュールオブジェクト以外はスキップ
      if (f !== did) return false;
      const sch = thisUser[f];
      if (sch.absence) return false; // 欠席の場合はスキップ
      cnt++;
      return false;
    });
  });
  return (cnt);
}
// // 加算の数をドットで表現
// const DAddictionContent = (props)=>{
//   const {did, ...other} = props;
//   const schedule = useSelector(state => state.schedule);
//   const service = useSelector(state => state.service);
//   // 日毎の加算項目をカウント
//   const countAddiction = () => {
//     if (schedule[service] === undefined) return 0;
//     if (schedule[service][did] === undefined) return 0;
//     return (Object.keys(schedule[service][did]).length);
//   }
//   const c = countAddiction();
//   if (c >= 4) return (
//     <div className="dAddiction">
//       <FiberManualRecordIcon fontSize='inherit' />{c}
//     </div>
//   );
//   const a = new Array(c).fill(1);
//   const b = a.map(e=>{
//     return <FiberManualRecordIcon fontSize='inherit' />
//   });
//   const styleObj = (c === 3)? {fontSize: '.4rem'} : {}
//   return(
//     <div className="dAddiction" style={styleObj}>
//       {b}
//     </div>
//   )
// }

const DateOfTitle = (props) =>{
  const holidayClass = ['', 'schoolOff', 'off'];
  const teiin = parseInt(props.teiin);
  const limit = comMod.upperLimitOfUseByDay(teiin);
  const elm = props.value.map((e, i) => {
    const d = 'D' + comMod.formatDate(e.date, 'YYYYMMDD');
    const hClass = holidayClass[e.holiday];
    const visit = countVisitOfDate(props, d);
    // 定員やリミットを超過したときのクラス名定義
    let classOver = visit > teiin ? 'higher ' : '';
    classOver = visit > limit ? 'over ' : classOver;

    return (
      <div
        className={
          'w03 lower small flxCenter dateTitle flxVirtical dateTitle ' + hClass
        }
        key={i} id={d}
      >
        <div className='center'>
          {e.date.getDate()}<br />{comMod.getWd(e.date.getDay()).jp}
        </div>
        <div className={'totalOfDay ' + classOver}>{visit}</div>
        <cp.DAddictionContent did={d} />
        <SchDailyDialog date={e.date}/>
      </div>
    )
  });
  return(elm);
}

class SchTableHead extends React.Component{
  render() {
    const teiin = comMod.findDeepPath(
      this.props, 'com.addiction.' + this.props.service + '.定員'
    );
    return (
      <div className='flxTitle scheduleDays30' >
        <div className='wmin lower noBkColor'>No</div>
        <div className="w15 lower" > <div>氏名</div></div>
        <DateOfTitle
          value={this.props.dateList}
          schedule={this.props.schedule}
          service={this.props.service}
          teiin={teiin}
        />
        <OccupancyRate/>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return (state);
}
export default connect(mapStateToProps, Actions)(SchTableHead);
