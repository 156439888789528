import React from 'react';
// カレンダーラッパー
// ボタンとか付けて呼び出すだけ
// stateのloadingを確認していないのでエラーになりがち
import SchCalender from './SchCalender';
import { menu } from './Schedule';
import { LinksTab } from '../common/commonParts';

export const SchCalWrapp = () => {
  return(<>
    <LinksTab menu={menu} />
    <div className="AppPage schedule calWrapp">
      {/* <SchHeadNav/>     */}
      <SchCalender />
    </div>
  </>)
}

export default SchCalWrapp;