import React from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { makeStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles({
  absencedRoot:{
    '& .inner' :{
      opacity: .4,
    },
    '& .dateCellAbsenceIcon' : {
      position: 'absolute',
      // top: '5px',
      color: '#8f0318',
      opacity: 1,
      width: '40%',
      left: '30%',
      top: '25%',
      '& .MuiSvgIcon-root' : {
        fontSize: '2rem',
      }
    }
  },
  normalRoot:{
    '& .dateCellAbsenceIcon': {
      display: 'none',
    }
  },
  useCheck :{
    position:'absolute',
    top: 0,
    right: 0,
    color: teal[400],
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  },
  transferRoot :{
    '& .transferChildren': {
      display: 'inline-block',
      maxWidth: '4rem',
    },
  },
});

export const EachScheduleContent = (props) => {
  const classes = useStyles();
  if (!props.thisSchedule) return null;
  const e = props.thisSchedule;
  const transfer = e.transfer.concat();
  transfer[0] = (transfer[0] === '') ? '無し' : transfer[0];
  transfer[1] = (transfer[1] === '') ? '無し' : transfer[1];
  // 実費項目をjsxで表現しやすくする
  const actual = () => {
    let text = '';
    let cost = 0;
    let full = '';
    Object.keys(e.actualCost).map(f => {
      text += f + ', ';
      cost += parseInt(e.actualCost[f]);
      full += f + ' : ' + e.actualCost[f] + '円';
    });
    text = text.slice(0, -2); // 末尾二文字を削除
    let short = text;
    const length = Object.keys(e.actualCost).length;
    if (short.length > 4) short = short.substr(0, 4) + '..';
    return { text, short, cost, length, full };
  }
  const actualObj = actual();
  //　加算項目を表現しやすいようにオブジェクト化
  const addiction = () => {
    let long = '';
    let short = '';
    const aKeys = (e.dAddiction === undefined) ? [] : Object.keys(e.dAddiction);
    aKeys.map(f => {
      long += e.dAddiction[f] + ', ';
      short += f + ', ';
    });
    if (short.length > 5) short = short.substr(0, 5) + '..';
    const length = aKeys.length;
    return { long, short, length };
  }
  const addictionObj = addiction();
  console.log('thisAbsenced', e.absence);
  // 欠席の表示を行う
  const AbsenseIcon = () => {
    return (
      <div className='dateCellAbsenceIcon'>
        <NotInterestedIcon />
      </div>
    )
  }
  // 欠席表示のためのクラス設定。
  const rootClass = (e.absence) ? classes.absencedRoot : classes.normalRoot;

  return (
    <div className={"dateContent " + rootClass}>
      {/* 利用実績チェック表示 */}
      {e.useResult === true &&
        <div className={classes.useCheck}>
          <CheckIcon />
        </div>
      }
      <AbsenseIcon />
      <div className="inner">
        <div className='icon'>
          <AccessTimeIcon />
        </div>
        <div className='text'>
          <div className="time">{e.start}</div>
          <div className="time">{e.end}</div>
        </div>
      </div>
      <div className="inner">
        <div className='icon'>
          <DriveEtaIcon />
        </div>
        <div className="text">
          <div className={"transfer " + classes.transferRoot}>
            <div className={'textEclips transferChildren'}>
              {transfer[0]}
            </div>
            <ArrowForwardIosIcon />
            <div className={'textEclips transferChildren'}>
              {transfer[1]}
            </div>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className='icon fa'>
          <i className="fas fa-yen-sign fa-fw "></i>
        </div>
        <div className="text">
          <div className="actual">
            {actualObj.length + '件 ' + actualObj.cost + '円'}
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="icon">
          <AddCircleIcon />
        </div>
        <div className="text">
          <div className="addiction">
            {addictionObj.short + addictionObj.length + '件'}
          </div>

        </div>
      </div>
    </div>
  )
}
export default EachScheduleContent;