import React, {useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux';
import * as Actions from '../../Actions';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import lime from '@material-ui/core/colors/lime';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  snackNormal: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor:teal[900]
    }
  },
  snackWarning: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: lime[900]
    }
  },
  snackSuccess: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: blue[900]
    }
  },
  snackError: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: red[800]
    }
  },
}));

export default function SnackMsg(props) {
  const [open, setOpen] = useState(false);
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');
  const snackState = useSelector(state => state.snackPack);
  const handleClose = (event, reason) => {
    if (props.setmsg){
      props.setmsg('');
    }
    setOpen(false);
    if (reason === 'clickaway') {
      return;
    }
  };
  // propsによる表示
  useEffect(() => {
    if (props.msg){
      setOpen(true);
      setmsg(props.msg);
      setseverity(props.severity);
    }
  }, [props.msg]);
  // dispatchによる表示
  useEffect(()=>{
    const justnow = new Date().getTime();
    const timeAllowed = 3000;
    if ((justnow - snackState.time) < timeAllowed){
      setOpen(true);
      setmsg(snackState.text);
      setseverity(snackState.severity);
    }
  }, [snackState]);

  const classes = useStyles();
  let snackClass;
  if (severity === 'success') snackClass = classes.snackSuccess;
  else if (severity === 'warning') snackClass = classes.snackWarning;
  else if (severity === 'error') snackClass = classes.snackError;
  else snackClass = classes.snackNormal; 
  return (
    <div className={snackClass}>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
}
