import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, ServerStyleSheets } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts';
import * as sfp from '../common/StdFormParts';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import Button from '@material-ui/core/Button';
import {
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from '../Billing/blMakeData';
import {
  proseedByUsersDt
} from '../Billing/Proseed'
import { endPoint } from '../../Actions'
import axios from 'axios';
import { LoadingSpinner, UserSelectDialog } from '../common/commonParts';
import useInterval from 'use-interval';
import GroupIcon from '@material-ui/icons/Group';
import { FormatBold, FullscreenExit } from '@material-ui/icons';
import SnackMsg from '../common/SnackMsg';
import teal from '@material-ui/core/colors/teal';


const useStyles = makeStyles({
  invoiceHead:{
    display:'flex',
    marginTop: 60,
    '& .left' :{
      width: '40%',
    },
    '& .right' : {
      width: '60%',
      textAlign: 'right',
    },
    '& >div>div' : {
      padding: '4px 0',
    },
    '& .large' : {
      fontSize: '1.4rem',
      padding: '6px 0',
    },
    '& .tall': {
      padding: '6px 0',
    },
    '& .small': {
      padding: '2px 0',
      fontSize: '.8rem'
    },
    '& .title': {
      padding: '12px 0 6px',
      fontSize: '2.2rem',
      width: '75%',
      backgroundColor: teal[50],
      marginTop: 72,
      marginBottom: 4,
      textAlign:'center',
      color: teal[900],
    },
    '& .date': {
      marginBottom: 8,
    },
  },
  comment: {
    padding: '8px 0',
    marginTop: 48,
  },
  total: {
    fontSize: '1.2rem',
    padding: '8px 0 2px',
    // borderColor: teal[600],
    borderBottom: '2px solid' + teal[600],
    display:'flex',
    justifyContent: 'space-between',
    width: '60%',
    marginTop:24,
  },
  invtbl:{
    width: '100%',
    marginTop:24,
    '& td ' : {
      padding: '12px 8px 8px',
    },
    '& td.num': {
      textAlign: 'right',
    },
    '& .title': {
      textAlign: 'center',
      borderBottom: '1px solid' + teal[600],
      borderTop: '1px solid' + teal[600],
    },
    '& .tblDetail:nth-of-type(even)': {
      backgroundColor: teal[50],
    },
    // '& tr:nth-of-type(odd)': {
    //   backgroundColor: teal[50],
    // },
    '& .total': {
      borderBottom: '2px solid' + teal[600],
      borderTop: '1px solid' + teal[600],
      // backgroundColor: teal[50],
    },
  },
});

const OnePageOfInvoice = (props) =>{
  const rowLength = 14; //行の長さ
  const classes = useStyles();
  const {
    thisBdt, userList, users, stdDate, com, service, preview, ...others
  } = props
  const thisUser = comMod.getUser(thisBdt.UID, users);
  let title = preview;
  let comment;
  if (title.indexOf('請求書') > -1){
    comment = '下記の通りご請求申し上げます。ご確認いただけますようお願い申し上げます。';
  }
  else if (title.indexOf('受領書') > -1){
    comment = '下記、正に受領致しました。';
  }
  const invoiceDate = comMod.formatDate(new Date(), 'YYYY年MM月DD日');
  // 合計値計算と文字列変換
  let grandTotal = 0;
  thisBdt.actualCostDetail.map(e=>{grandTotal += e.price});
  grandTotal += thisBdt.kanrikekkagaku;
  const grandTotalStr = '\\' + comMod.formatNum(grandTotal, 1) + '.-';
  const invoiceHead = (<>
    <div className={classes.invoiceHead}>
      <div className='left'>
        <div className='large'>{thisUser.pname} 様</div>
        <div>{thisUser.hno + ' ' + thisUser.name} 様</div>
        <div className='title'>{title}</div>
        <div>{stdDate.substr(0, 4) + '年' + stdDate.substr(5, 2) + '月分'}</div>
      </div>
      <div className='right'>
        <div className='date'>{invoiceDate}</div>
        <div>{com.hname}</div>
        <div>{com.bname}</div>
        <div className='small'>{com.postal} {com.city}</div>
        <div className='small'>{com.address}</div>
        <div>TEL:{com.tel}</div>
      </div>
    </div>
    <div className={classes.comment}>{comment}</div>
    <div className={classes.total}>
      <div className='str'>合計額</div>
      <div className='num'>{grandTotalStr}</div>
    </div>
  </>)
  const tabelTitle = (<>
    <tr className='title'>
      <td>項番</td>
      <td>項目</td>
      <td>単価</td>
      <td>数量</td>
      <td>金額</td>
      <td>備考</td>
    </tr>
  </>);
  const rowArray = Array(rowLength).fill('');
  const tabeleAcRows = rowArray.map((e,i)=>{
    const tdContent = (key) =>(
      (i < thisBdt.actualCostDetail.length)? 
        (isNaN(thisBdt.actualCostDetail[i][key])) ?
          thisBdt.actualCostDetail[i][key] : 
          comMod.formatNum(thisBdt.actualCostDetail[i][key], 1):''
    )
    return(
      <tr className='tblDetail'>
        <td className='num'>
          {
            (i < thisBdt.actualCostDetail.length) ? (i + 2) : "　"
          }
        </td>
        <td>{tdContent('name')}</td>
        <td className='num'>{tdContent('unitPrice')}</td>
        <td className='num'>{tdContent('count')}</td>
        <td className='num'>{tdContent('price')}</td>
        <td></td>
      </tr>
    )
  });
  const tableJikofutanRow = (
    <tr className='tblDetail'>
      <td className='num'>1</td>
      <td>
        {
          (thisUser.service === '放課後等デイサービス') ?
          '障害児通所給付費利用者負担額' : '障害児発達支援給付費利用者負担額'
        }
      </td>
      <td className='num'>{comMod.formatNum(thisBdt.kanrikekkagaku, 1)}</td>
      <td className='num'>1</td>
      <td className='num'>{comMod.formatNum(thisBdt.kanrikekkagaku, 1)}</td>
      <td>ご利用回数{thisBdt.countOfUse}回</td>
    </tr>
  );
  const tableTotalRow = (
    <tr className='total'>
      <td></td>
      <td>合計</td>
      <td></td>
      <td></td>
      <td className='num'>{comMod.formatNum(grandTotal, 1)}</td>
      <td></td>
    </tr>

  )
  return(<>
    {invoiceHead}
    <table className={classes.invtbl}>
      {tabelTitle}
      {tableJikofutanRow}
      {tabeleAcRows}
      {tableTotalRow}
    </table>
    <div className={'pageBreak'}></div>
  </>)
}

export default (props) => {
  const nameList = ['請求書', '請求書控え', '受領書', '受領書控え'];
  const {userList, preview, ...others} = props;
  // リストにないプレビューが送られてきたら何もしないで終了
  if (nameList.indexOf(preview) < 0)  return null;
  const dispatch = useDispatch();
  // const classes = useStyles();
  const stdDate = useSelector(state => state.stdDate);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com);
  const serviceItems = useSelector(state => state.serviceItems);
  const classroom = useSelector(state => state.classroom);
  const service = useSelector(state => state.service);
  const account = useSelector(state => state.account);
  const dateList = useSelector(state => state.dateList);
  const prms = { stdDate, schedule, users, com, service };
  const { billingDt, masterRec } = setBillInfoToSch(prms);
  const pagesOfInvoice = users.map((e, i)=>{
    // ユーザーリストにないデータはスキップ
    if (!userList.find(f => f.uid === e.uid))  return null;
    // ユーザーリストでチェックにないデータはスキップ
    if (!userList.find(f => f.uid === e.uid).checked) return null;
    const thisBdt = billingDt.find(f=>f.UID === 'UID' + e.uid);
    if (!thisBdt) return null;
    const oneProps = {
      thisBdt, userList, users, stdDate, com, service , preview
    }
    return( 
      <OnePageOfInvoice {...oneProps}/>
    ) 
  });
  return pagesOfInvoice;
}