import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, ServerStyleSheets } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts';
import * as sfp from '../common/StdFormParts';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import Button from '@material-ui/core/Button';
import {
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from '../Billing/blMakeData';
import {
  proseedByUsersDt
} from '../Billing/Proseed'
import { endPoint } from '../../Actions'
import axios from 'axios';
import { LoadingSpinner, UserSelectDialog } from '../common/commonParts';
import useInterval from 'use-interval';
import GroupIcon from '@material-ui/icons/Group';
// import { 
//   // FormatBold, FullscreenExit ,
//   CloseIcon, PrintIcon,
//   HelpOutlineIcon,
// } from '@material-ui/icons';
import SnackMsg from '../common/SnackMsg';
import Invoice from './Invoice';
import TuusyokyuuhuMeisai from './TuusyokyuuhuMeisai';
import JougenKanri from './JougenKanri';
import TeikyouJisseki from './TeikyouJisseki';
import { Autocomplete } from '@material-ui/lab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import {
  useStyles as useStyleFPC, selectStyle, ChkBoxGp, SelectGp,
}
  from '../common/FormPartsCommon'
import {DateInput} from '../common/StdFormParts';
import CheckProgress, {doCheckProgress} from '../common/CheckProgress';

// import classes from '*.module.css';

const DOCSURL = "http://153.127.61.191/docs";
const ERASE_INTVL = 1 * 1000; // 消去確認用のループインターバル
const ERASE_ERT = 15 * 1000; // リンク消去する経過時間

const useStyles = makeStyles({
  reportButton : {
    width: 160,
    height: 32,
  },
  reportButtonSelected: {
    width: 160,
    height: 32,
    marginTop: 32,
  },
  dialogOpenButtonRoot:{
    position: 'fixed',
    top: 71,
    right: 16,
    '& .buttonText':{
      display: 'flex',
    },
    '& .buttonText soan':{
      display: 'block',
    },
    '& .buttonText span:nth-of-type(1)' :{
      fontSize: '.6rem',
      margin: '.7rem 2px 0',
      marginLeft: '.6rem',
    },
    '& .buttonText span:nth-of-type(2)': {
      fontSize: '1.2rem',
      margin: '0 2px 0'
    },
    '& .buttonText span:nth-of-type(3)': {
      fontSize: '.6rem',
      margin: '.7rem 2px 0'
    },
    '& .scheduleCount' : {
      padding: 6,
      textAlign: 'center',
      '& span' :{
        color:'#00695c',
        fontWeight: 'bold',
      }
    },
  },
  testDiv : {
    width: '60%',
    textAlign: 'center',
    margin: '0 auto',
    '& >a' : {
      display:'block',
      padding: 8,
      cursor: 'pointer',
    }
  },
  printCntRoot : {
    width : '80%',
    left: '10%',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#ffffffcc',
    padding: 8,
    top: 60,
    position: 'fixed',
    // transform: 'translate(-50%, 0)',
    '& .MuiButtonBase-root' : {
      width: 200,
      margin : '0 8px',
      '& .MuiSvgIcon-root' : {
        marginInlineEnd: '8px',
      },
    },
    '& .subButtons' : {
      padding: 8,
      '& .MuiButtonBase-root': {
        width: 'auto',
      },
    },

  },
  setDateForm:{
    display:'flex',
    '& .MuiButtonBase-root':{
      height: 32,
      padding: 8,
      marginTop: 24,
      marginLeft:8,
    },
  }
});

// レポートを出力するボタンのリスト
// link: ダウンロードリンク格納用, 
// created: 作成時のタイムスタンプ, 
// countCheck: レポート作成前にデータ件数のカウントをチェックするかどうか
const nodesDef = [
  {
    discription: '利用者別売り上げ一覧',
    name: 'uribyuser1',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '売上台帳',
    name: 'uriagedaityou',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '請求書',
    name: 'invoice_seikyuu',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '受領書',
    name: 'invoice_juryou',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '請求書控え',
    name: 'invoice_seikyuu_hikae',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '受領書控え',
    name: 'invoice_juryou_hikae',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: '月間予定表',
    name: 'monthriyou',
    link: '', created: 0, countCheck: true,
  },
  {
    discription: 'エラーテスト',
    name: 'errtest',
    link: '', created: 0, countCheck: true,
  },
];


// requestReportで送信するデータを作成する
// dataがあるデータは1シート形式で作成
// sheetsがあるデータは複数シートのブックとして作成するように
// サーバー側で処理を行う
const makeReportDt = (
  billingDt, masterRec, dateList, com, service, account, users, stdDate, dataLayout
) => {
  // Python側で定義されているラベル
  // ONECELLARIAS = [
  //   'label', 'label0', 'label1', 'label2', 'label3',
  //   'date', 'datetime', 'cname', 'scname', 'bname', 'sbname',
  //   'month', 'username'
  // ]

  const content = {};
  const month = stdDate.substr(0, 4) + "年" + stdDate.substr(5, 2) + "月";
  content.date = comMod.formatDate(new Date(), 'YYYY年MM月DD日');
  content.datetime = comMod.formatDate(new Date(), 'YYYY年MM月DD日 hh時mm分');
  content.cname = com.hname;
  content.scname = com.shname;
  content.bname = com.bname;
  content.sbname = com.sbname;
  content.month = month;
  content.address1 = '〒' + com.postal + ' ' + com.city;
  content.address2 = com.address;
  content.username = account.lname + ' ' + account.fname;
  content.kanri = com.kanri;
  content.publisher = [
    com.hname,
    com.bname,
    content.address1,
    content.address2,
    com.ctel,
  ];
  // 法人事業所
  content.combrunch = com.hname + ' ' + com.bname;

  const data = [];
  const sheets = [];
  // このフラグがある場合、多シートに出力せず一シートにすべてまとめる
  // テンプレートのname pageを見て縦に伸ばしていく。
  // 縦に伸ばすのはサーバー側のcgiでの仕事にする
  let intoOne = false;
  if (dataLayout === 'uribyuser1'){
    const tmp = proseedByUsersDt(users, billingDt);
    console.log('proseedByUsersDt', tmp);
    tmp.map((e, i)=>{
      const d = [
        i + 1,  // 項番
        e.userName, // ユーザー名
        e.tanni, // 単位数
        e.santei, //算定額
        e.userFutan, // ユーザー負担
        e.actualCost, // 実否
        e.kanriOk ? '済み': '', // 上限管理済みかどうか
        e.kokuhoSeikyuu, // 国保連請求額
        e.sougeiCnt, // 送迎回数
        e.countOfUse, // 利用回数
      ];
      data.push(d);
    });
    content.hideEmpRows = 'data';
  }
  if (dataLayout === 'uriagedaityou'){
    billingDt.map((e, i)=>{
      const ui = comMod.getUser(e.UID, users);
      if (!ui)  return false;
      const uHead = Array(10).fill('');  // ユーザー見出しの行
      const uDetail = []  // 明細
      const uTotal = Array(11).fill('');  // 集計
      uHead[0] = i + 1;
      uHead[1] = e.name;
      uHead[2] =  '保護者: ' + ui.pname + ' 受給者番号: ' + e.hno + ' '
      if (ui.belongs1 && ui.belongs2)
        uHead[2] += ui.belongs1 + ' / ' + ui.belongs2;
      else if (ui.belongs1 || ui.belongs2)
        uHead[2] += ui.belongs1 + ui.belongs2;
      // サービスごとの明細行
      e.itemTotal.map(f=>{
        const detail = Array(10).fill('');
        detail[2] = f.s;  // サービスコード
        detail[3] = comMod.deleteLast(f.c, '・');  // サービス名
        detail[4] = f.v;  // サービス単位
        detail[5] = f.count; // 提供数
        detail[6] = f.tanniNum; // 単位数
        uDetail.push(detail);
      });
      // サービスの集計行
      const detailSubTotal = Array(10).fill('');
      detailSubTotal[3] = '単位計';
      detailSubTotal[6] = e.tanniTotal;
      uDetail.push(detailSubTotal);
      // 実費の明細
      e.actualCostDetail.map(f=>{
        const detail = Array(10).fill('');
        detail[2] = '実費';
        detail[3] = f.name;
        detail[4] = f.unitPrice;
        detail[5] = f.count;
        detail[6] = f.price;
        uDetail.push(detail);
      });
      // 実費の合計
      const detailSubTotalAc = Array(10).fill('');
      detailSubTotalAc[3] = '実費計';
      detailSubTotalAc[6] = e.actualCost;
      uDetail.push(detailSubTotalAc);
      // ユーザー合計
      uTotal[1] = '利用者計';
      uTotal[4] = '単位単価';
      uTotal[6] = masterRec.unitPrice;
      uTotal[7] = e.userSanteiTotal;
      uTotal[8] = (e.ketteigaku) ? e.ketteigaku : 0;
      uTotal[9] = e.actualCost;
      uTotal[10] = e.userSanteiTotal - ((e.ketteigaku) ? e.ketteigaku : 0);
      uTotal[11] = (e.kanriOk)? '済':'未';
      data.push(Array(11).fill('')); // 空行
      data.push(uHead);
      // data.push(uDetail);
      uDetail.map(f=>{data.push(f)});
      data.push(uTotal);
    });
    console.log('data uriagedaityou', data);
    sheets.push({ sheetname: month });
    content.hideEmpRows = 'data';
  }
  // 請求書受領書と控え、datalayoutの文字の一部で判断する
  if (dataLayout.indexOf('invoice') > -1) {
    if (dataLayout.indexOf('seikyuu') > -1){
      content.label = '請求書';
      content.comment = '下記の通りご請求申し上げます。ご確認いただけますようお願い申し上げます。';
    }
    else if (dataLayout.indexOf('juryou') > -1) {
      content.label = '受領書'
      content.comment = '下記、正に受領致しました。';
    }
    if (dataLayout.indexOf('hikae') > 1) {
      content.label += '(控)'
    }
    // このフラグは動作速度がおそすぎるのでボツにする予定
    // intoOne = true; // 1シートにまとめるためのフラグ。試験的にONにする
    const tmp = proseedByUsersDt(users, billingDt);
    console.log('proseedByUsersDt', tmp);
    tmp.map((e, i) => {
      const userDetail = comMod.getUser(e.UID, users);
      const detail = [[
        1, 
        '障害児通所給付費\\n利用者負担額',
        e.userFutan,
        1,
        e.userFutan,
        'ご利用回数' + e.countOfUse + '回',
      ]];
      e.actualCostDetail.map((f, j)=>{
        detail.push([
          j +2,
          f.name,
          f.unitPrice,
          f.count,
          f.price,
        ]);
      })
      const d = {
        sheetname: userDetail.name,
        pname: userDetail.pname + ' 様',
        hno_user: userDetail.hno + ' ' + userDetail.name + ' 様',
        detail,
      };
      sheets.push(d);
    });
  }
  // 月間予定
  if (dataLayout.indexOf('monthriyou') > -1){
    const lineHight = 4;  // 予定表上で位置ユーザーあたり何行で表示するか
    content.title = '利用予定表';
    const cnfOR = com.etc.configOccupancyRate;  // 稼働率計算設定値
    // 稼働日をカウントするための配列 
    content.dateCounter = dateList.map(e=>{
      if (e.holiday === 0)  return 1; // 平日はカウントするので常に1
      if (!cnfOR && e.holiday !== 2) return 0; // 設定の初期値 休業日
      if (!cnfOR && e.holiday !== 1)  return 1; // 設定の初期値 休校日
      if (cnfOR === '休業日を含めて稼働率計算' && e.holiday !== 2) return 1;
      if (cnfOR === '休業日を含めて稼働率計算' && e.holiday !== 1) return 1;
      if (cnfOR === '休業・休校を含めず稼働率計算' && e.holiday !== 2)
        return 0;
      if (cnfOR === '休業・休校を含めず稼働率計算' && e.holiday !== 1)
        return 0;
    });
    // 単純に休日平日を設定
    content.dateattr = dateList.map(e=>e.holiday);
    // 曜日を作成
    content.weekdays = dateList.map(e=>(
      ['日', '月', '火', '水', '木', '金', '土'][e.date.getDay()]
    ));
    // 必要なユーザー一覧を設定
    // const userinfo = billingDt.map((e)=>{
    //   let i = 1;
    //   const thisUser = comMod.getUser(e.UID, users);
    //   // undefinedが返ることあり
    //   if (thisUser){
    //     return([
    //       i++, thisUser.name, thisUser.belongs1, thisUser.belongs2, 
    //       thisUser.ageStr, parseInt(thisUser.volume), 
    //     ]);
    //   }
    //   else{
    //     return false;
    //   }
    // });
    const userinfo = users.map((e, i)=>{
      // const thisUser = comMod.getUser(e.UID, users);
      // return([
      //   i + 1, thisUser.name, thisUser.belongs1, thisUser.belongs2, 
      //   thisUser.ageStr, parseInt(thisUser.volume), 
      // ]);
      return([
        i + 1, e.name, e.belongs1, e.belongs2, 
        e.ageStr, parseInt(e.volume), 
      ]);
    });
    // falseを排除してuserinfo作成
    content.userinfo = userinfo;
    // 定員
    content.teiin = parseInt(com.addiction[service].定員);
    // 上限
    content.upperlimit = comMod.upperLimitOfUseByDay(content.teiin)
    // 単純に日付
    content.days = dateList.map(e=>e.date.getDate());
    // 余分な行削除の基準になるエリア名
    content.hideEmpRows = 'detail';
    
    // 各業の配列を定義
    const detail = [];
    billingDt.map(e=>{
      // usersに定義されていないデータはスキップ
      if (!users.find(f=>e.UID === 'UID' + f.uid))  return false;
      const useResult = Array(dateList.length).fill('');
      const transfer = Array(dateList.length).fill('');
      const start = Array(dateList.length).fill('');
      const pickup = Array(dateList.length).fill('');
      Object.keys(e).map(f=>{
        // 日付オブジェクトを扱う。キーの先頭がD2以外はスキップ
        if (f.indexOf('D2') !== 0)  return false;
        const thisDay = parseInt(f.slice(-2)) - 1; // DXXXXXXXXの末尾二桁が日付
        // 利用実績
        if (e[f].absence)              useResult[thisDay] = '✕';
        else if (e[f].offSchool === 0) useResult[thisDay] = '○';
        else if (e[f].offSchool === 1) useResult[thisDay] = '◎';
        // 送迎
        transfer[thisDay] = e[f].transfer.filter(g=>g).length;
        // 開始
        start[thisDay] = e[f].start;
        // 迎え
        pickup[thisDay] = e[f].transfer[0];
      });
      // detilに追加
      detail.push(useResult);
      detail.push(transfer);
      detail.push(start);
      detail.push(pickup);
    });
    sheets.push({detail, sheetname:month});
  }
  content.data = data;
  content.sheets = sheets;
  content.intoOne = intoOne;
  console.log('content', content)
  return content;
}

// ドキュメント出力用のデータをdbに投げて
// ドキュメントサーバに作成のリクエストする
async function requestReport(prms, name, setres){
  // prms = { hid, bid, stamp, template, dst, content, }
  let res;
  try{
    prms.a = 'sendDocument';
    res = await axios.post(endPoint, comMod.uPrms(prms));
    if (!res.data.result){
      throw new Error(res);
    }
    prms.a = 'excelgen';
    res = await axios.post(endPoint, comMod.uPrms(prms));
    if (!res.data.result) {
      throw new Error(res);
    }
    res.name = name;
    res.result = true;
    setres(res);
  }
  catch{
    res.name = name;
    setres(res);
  }
}

// このコンポーネントでは代理受領通知の日付の利用者負担額一覧の日付を
// 設定する
// ->変更 ボタン押下でDispatchも行う。
const SetReportDate = (props) => {
  // itemは今のところ代理受領通知日、利用者負担額一覧発行日も追加予定
  const dispatch = useDispatch();
  const classes = useStyles();
  const path = useLocation().pathname;
  const stdDate = useSelector(state=>state.stdDate);
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const {formChange, setFormChange, item} = props;
  const tDate = comMod.parseDate(stdDate).date.dt;
  let nDate;
  if (item === '代理受領通知日'){
    // 代理受領通知日未設定時の初期値。基準日翌々月の１２日
    nDate = new Date(tDate.getFullYear(), tDate.getMonth() + 2, 12);
  }
  else if (item === '利用者負担額一覧'){
    // 利用者負担額一覧未設定時の初期値。基準日翌月の１日
    nDate = new Date(tDate.getFullYear(), tDate.getMonth() + 1, 1);
  }
  const jtInit = comMod.formatDate(nDate, 'YYYY-MM-DD');
  console.log(comMod.findDeepPath(schedule, [service, item]))
  const jtDef =
    (comMod.findDeepPath(schedule, [service, item])) ? 
    schedule[service][item] : jtInit;

  // 代理受領通知へのDispatchは別途ボタンイベントを設定する
  // setPreviewと一緒には出来ない！
  const handleClick = () => {
    const sch = {...schedule};
    if (!sch[service]) sch[service] = {};
    const repotDate = document.querySelector(`#${item} [name=repotDate]`).value;
    sch[service][item] = repotDate;
    dispatch(Actions.setStore({schedule: sch}));
    // 送信を行ったらフォーム変更フラグはリセットする
    setFormChange({...formChange, [item]: true});
    comMod.setSchedleLastUpdate(dispatch, path); // 自動セーブ予約
  }
  // フォームの更新検出用
  const handleChange = () => {
    setFormChange({...formChange, [item]: true});
  };
  const wrapperStyle = {paddingLeft: 0, paddingRight: 0}
  const inputLabels = {
    代理受領通知日:'代理受領通知日付',
    利用者負担額一覧:'負担額一覧日付'
  };
  return (<>
    <form id={item} className={classes.setDateForm}
      onChange={handleChange}
    >
      <DateInput 
        name='repotDate' 
        label={inputLabels[item]}
        required
        def = {jtDef}
        wrapperStyle={wrapperStyle}
      />
      <Button 
        variant='contained'
        onClick={()=>{handleClick()}}
      >
        日付登録
      </Button>

    </form>
  </>)
}

const ReportsMain = () => {
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const classes = useStyles();
  const stdDate = useSelector(state => state.stdDate);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com);
  const serviceItems = useSelector(state => state.serviceItems);
  const classroom = useSelector(state => state.classroom);
  const service = useSelector(state => state.service);
  const account = useSelector(state => state.account);
  const dateList = useSelector(state=>state.dateList);
  // スナックバー用
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');
  // フォーム変更検出用
  const [formChange, setFormChange] = useState(
    {代理受領通知日: false, 利用者負担額一覧: false}
  );
  if (!service){
    dispatch(Actions.changeService(serviceItems[0]));
  }
  // ユーザー選択用のstateと初期値を設定
  const tmpUserList 
    = users
    .filter(e=>classroom === '' || classroom === e.classroom)
    .filter(e=>e.service === service)
    .map(e=>{
      return {uid: e.uid, checked: true}
    });
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const [userList, setUserList] = useState(tmpUserList)

  const prms = { stdDate, schedule, users, com, service };
  const { billingDt: bdTmp, masterRec } = setBillInfoToSch(prms);
  const billingDt = [];
  // billingDtをusersの順番に並び替え
  users.map(e=>{
    if (!bdTmp.length) return false;
    const UID = 'UID' + e.uid;
    const thisDt = bdTmp.find(f=>f.UID === UID);
    if (thisDt) billingDt.push(thisDt);
  })
  console.log('billingDt',billingDt, 'masterRec', masterRec)

  // ノードの描写を管理
  const [nodes, setnodes] = useState(nodesDef);
  // リクエストの結果を格納
  const [res, setres] = useState({result: true});
  // リンクの消去を管理
  const [erase, seterase] = useState('');
  // プレビュー表示 空白でoff 格納されている値で表示するプレビューを指定する
  const [preview, setPreview] = useState('');
  const [displaySw, setDisplaySw] = useState({
    navigetion: {display: 'block'},
    preview: {display: 'none'},
  });
  // previewとナビゲーションの切り替え
  // ノードに渡すスタイルを書き換える  
  useEffect(()=>{
    if (!preview){
      setDisplaySw({
        navigetion: { display: 'block' },
        preview: { display: 'none' },
      })
    }
    else{
      setDisplaySw({
        navigetion: { display: 'none' },
        preview: { display: 'block' },
      })
    }
  },[preview]);

  // ドキュメント作成用のapi結果を監視
  useEffect(()=>{
    // 配列になっているstateからデータ取得した要素のリンクを更新する
    console.log('res:', res);
    const tmp = [...nodes];
    const ndx = tmp.findIndex(e=>e.name === res.name);
    if (ndx > -1 && res.data.dstPath){
      const f = DOCSURL + res.data.dstPath
      tmp[ndx].link = f;
      tmp[ndx].created = new Date().getTime();
      // const blob = new Blob([f], { type: f.mimeType});
      // const anker = document.createElement('a');
      // anker.href = window.URL.createObjectURL(blob);
      // anker.click();
    }
    // エラーのフック
    if (!res.result){
      setseverity('error');
      setmsg('帳票の作成中にエラーが発生しました。');
    }
    else{
      setseverity('');
      setmsg('');
    }
    setnodes(tmp);
  }, [res]);

  // リンク消去確認用のインターバル
  useInterval(()=>{
    nodes.map(e=>{
      if (!e.created) return false;
      // 経過時間が設定値を超えていたら消去するノードリストの名前を格納
      if (new Date().getTime() - e.created > ERASE_ERT){
        seterase(e.name);
      }    
    });
  }, ERASE_INTVL);
  // リンク消去の実行
  useEffect(()=>{
    const ndx = nodes.findIndex(e=>e.name === erase);
    const tmp = [...nodes];
    if (ndx > -1){
      tmp[ndx].link = '';
      tmp[ndx].created = 0;
      setnodes(tmp);
      seterase('');
    };
  }, [erase]);
  const [userListlength, SetUserListlength] = useState(userList.length);
  const [scheduleCount, SetScheduleCount] = useState(0);
  // ユーザーリストのカウント
  useEffect(()=>{
    SetUserListlength(userList.filter(e=>e.checked === true).length);
    let cnt = 0;
    // チェックされたユーザーのスケジュール件数をカウント
    userList.filter(e => e.checked === true).map(f=>{
      const thisSch = schedule['UID' + f.uid];
      if (!thisSch)  return false;
      cnt += Object.keys(thisSch).filter(g=>g.indexOf('D2') === 0).length
    });
    SetScheduleCount(cnt);
  }, [userList])

  const handleClick = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    // クリック元のnameに対応したテンプレート名のリスト
    const templateList = {
      uribyuser1: 'URI01.xlsx',
      invoice_seikyuu: 'inv01.xlsx',
      invoice_juryou: 'inv01.xlsx',
      invoice_seikyuu_hikae: 'inv01.xlsx',
      invoice_juryou_hikae: 'inv01.xlsx',
      monthriyou: 'sch01.xlsx',
      uriagedaityou: 'uri02.xlsx',
      errtest: 'errtest.xlsx',
    }
    const dstNameList = {
      uribyuser1: 'uriage-riyousya',
      invoice_seikyuu: 'seikyuu',
      invoice_juryou: 'juryou',
      invoice_seikyuu_hikae: 'seikyuu-hikae',
      invoice_juryou_hikae: 'juryou-hikae',
      monthriyou: 'month-riyou',
      uriagedaityou: 'uriagedaityou',
      errtest: 'errtest',
    }
    // 対象データ件数のチェック
    const thisNode = nodes.find(e=>e.name === name);
    if (thisNode.countCheck && !scheduleCount){
      setmsg('帳票対象データがありません');
      setseverity('warning');
      return false;
    }
    const template = templateList[name];
    const month = stdDate.replace(/\-/g, '').substr(0, 6);
    const dstFile = 
      com.fprefix + '-' + month + '-' + dstNameList[name] + '.xlsx';
    console.log('clicked name : ', name);
    // フィルタ済みのユーザーリストを作成
    const tUsers = userList.filter(e=>e.checked === true);
    const fUsers = tUsers.map(e=>(
      users.filter(f=>parseInt(e.uid)=== parseInt(f.uid))[0]
    ));
    // クリック元のnameに対応したドキュメントのコンテントを作成する
    const content = makeReportDt(
      billingDt, masterRec, dateList, com, service, 
      account, fUsers, stdDate, name
    );
    console.log('name', name, 'content', content);
    // 作成以外のボタンが押された場合
    if (!template) return false;
    const stamp = parseInt(new Date().getTime());
    const tmpDir = comMod.randomStr(20);
    const dst = '/' + tmpDir + '/' + dstFile;
    const prms = {
      hid, bid, stamp, template, dst, 
      content: JSON.stringify(content)
    };
    requestReport(prms, name, setres);
  }
  // 表示切り替えハンドラ
  const handleDispChangeClick = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    setPreview(name);
  }
  // 印刷ボタン
  const handlePrintClick = () =>{
    window.print();
  }
  const nodesRender = nodes.map((e, i)=>{
    return(
      <div className='reportCntRow' key={i}>
        <div className='reportDisc'>
          {e.discription}
        </div>
        <div className='genButton'>
          {e.link === '' &&
            <Button
              className={classes.reportButton}
              color='primary'
              variant='contained'
              name={e.name}
              // addictionclass='buttonGp'
              onClick={e=>handleClick(e)}
            >
              作成
            </Button>
          }
          {e.link !== '' &&
            <a href={e.link}>
              <Button
                className={classes.reportButton}
                label='ダウンロード'
                variant='contained'
                // addictionclass='buttonGp'
                color='secondary'
                name={e.name + '-dl'}
              >
                ダウンロード
              </Button>
            </a>
          }
        </div>
      </div>
    )
  });

  const [selects, setSelects] = useState({ '請求書・受領書選択': '請求書'})
  const handleChange = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    const value = ev.currentTarget.value;
    setSelects({...selects, [name]: value})
  }
  // 納品請求書のプレビュークリック用
  const handlePreviewClick = (ev) =>{
    const name = ev.currentTarget.getAttribute('name');
    setPreview(selects[name]);
  }

  return(
    <div className='AppPage reports'>
      <div className={'navigation '} style={displaySw.navigetion}>
        <CheckProgress inline />
        {nodesRender}
        <div className='reportCntRow' >
          <div className='reportDisc' style={{ paddingLeft: 0 }}>
            <SelectGp
              nameJp={'請求書・受領書選択'}
              value={selects['請求書・受領書選択']}
              size='large'
              opts={['請求書', '請求書控え', '受領書', '受領書控え',]}
              onChange={(ev) => handleChange(ev)}
              hidenull
            />

          </div>
          <Button
            className={classes.reportButtonSelected}
            name={'請求書・受領書選択'}
            variant='contained'
            color='primary'
            onClick={handlePreviewClick}
          >
            印刷用ページへ
          </Button>
        </div>
        <div className='reportCntRow'>
          <div className='reportDisc'>通所給付費明細</div>
          <div className='genButton'>
            <Button
              className={classes.reportButton}
              variant='contained'
              color='primary'
              onClick={()=>{
                setPreview('通所給付費明細');
              }}
            >
              印刷用ページへ
            </Button>
          </div>
        </div>


        <div className='reportCntRow'>
          <div className='reportDisc'>
            <SetReportDate 
              formChange={formChange} setFormChange={setFormChange}
              item='代理受領通知日'
            />

          </div>
          <div className='genButton'>
            <Button className={classes.reportButtonSelected}
              variant='contained'
              color='primary'
              onClick={()=>{
                setPreview('代理受領通知')
                if (formChange.代理受領通知日){
                  setmsg('入力済み受領日付が設定されていません。');
                  setseverity('warning');            
                }
              }}
            >
              印刷用ページへ
            </Button>
          </div>
        </div>

        <div className='reportCntRow'>
          <div className='reportDisc'>上限管理結果票</div>
          <div className='genButton'>
            <Button
              className={classes.reportButton}
              variant='contained'
              color='primary'
              onClick={()=>{
                setPreview('上限管理結果票');
              }}
            >
              印刷用ページへ
            </Button>
          </div>
        </div>

        <div className='reportCntRow'>
          <div className='reportDisc'>
            <SetReportDate 
              formChange={formChange} setFormChange={setFormChange}
              item='利用者負担額一覧'
            />

          </div>
          <div className='genButton'>
            <Button className={classes.reportButtonSelected}
              variant='contained'
              color='primary'
              onClick={()=>{
                setPreview('利用者負担額一覧')
                if (formChange.利用者負担額一覧){
                  setmsg('入力済み受領日付が設定されていません。');
                  setseverity('warning');            
                }
              }}
            >
              印刷用ページへ
            </Button>
          </div>
        </div>

        <div className='reportCntRow'>
          <div className='reportDisc'>提供実績管理票</div>
          <div className='genButton'>
            <Button
              className={classes.reportButton}
              variant='contained'
              color='primary'
              onClick={()=>{
                setPreview('提供実績管理票');
              }}
            >
              印刷用ページへ
            </Button>
          </div>
        </div>



        {/* <div className={classes.testDiv}>
          <a onClick={handleDispChangeClick} name='請求書'>
            請求書の印刷ページを表示する
          </a>
        </div>
        <div className={classes.testDiv}>
          <a onClick={handleDispChangeClick} name='請求書（控）'>
            請求書控えの印刷ページを表示する
          </a>
        </div>
        <div className={classes.testDiv}>
          <a onClick={handleDispChangeClick} name='受領書'>
            受領書の印刷ページを表示する
          </a>
        </div>
        <div className={classes.testDiv}>
          <a onClick={handleDispChangeClick} name='受領書（控）'>
            受領書控えの印刷ページを表示する
          </a>
        </div> */}
        <div className={classes.dialogOpenButtonRoot}>
          <Button
            onClick={() => setUserSelectOpen(true)}
            color='secondary'
            variant='contained'
          >
            <GroupIcon fontSize='large' />
            <div className='buttonText'>
              <span>設定済み</span>
              <span>{userListlength}</span>
              <span>人</span>
            </div>

          </Button>
          <div className='scheduleCount'>
            対象件数 <span>{scheduleCount}</span> 件
        </div>
        </div>
        <UserSelectDialog
          open={userSelectOpen}
          setOpen={setUserSelectOpen}
          userList={userList}
          setUserList={setUserList}
        />
      </div>
      <div className={'printPreview '} style={displaySw.preview}>
        <div className={classes.printCntRoot + ' noprint'}>
          <Button
            color='primary'
            onClick={()=>handlePrintClick()}
            variant='contained'
          >
            <PrintIcon/>
            印刷する
          </Button>
          <Button
            color='secondary'
            onClick={handleDispChangeClick}
            name=''
            variant='contained'
          >
            <CloseIcon/>
            印刷画面を終了する
          </Button>
          <div className='subButtons'>
            <Button
              variant='text'
            >
              <HelpOutlineIcon/>
              <a href="https://www.awesomescreenshot.com/video/2827026?key=315eb090f2b1afe19d4ee3a5eab79298" target="_blank">
                綺麗に印刷するには？
              </a>            
            </Button>
          </div>

        </div>
        <Invoice userList={userList} preview={preview}/>
        <TuusyokyuuhuMeisai userList={userList} preview={preview}/>
        <JougenKanri userList={userList} preview={preview}/>
        <TeikyouJisseki userList={userList} preview={preview}/>
      </div>
      <SnackMsg msg={msg} severity={severity} setmsg={setmsg} />
    </div>
  )
}


const Reports = () => {
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  if (loadingStatus.loaded){
    return (<ReportsMain />)
  }
  else if (loadingStatus.error){
    return (<div>error occured.</div>)
  }
  else{
    return <LoadingSpinner/>
  }
}

export default Reports;