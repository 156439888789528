// Scheduleとカレンダーを変更したときにタイムスタンプを一秒単位で
// ストアのコントロールモードに置いておく
// 同時にストアにsave済みかどうかのフラグを立てる
// 一定時間、更新がない場合、スケジュールの更新を行う
// スケジュールの更新はダイアログ等が開いているときはキャンセル
// 送信キャンセルの判断や送信自体は
// comMod.callDisptchForSendSchedule で行う
// このコンポーネントは送信ボタンのみ提供する

import React, { useEffect, useState, useRef } from 'react';
import * as Actions from '../../Actions';
import {
  useDispatch, useSelector,
} from 'react-redux';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi'
import useInterval from 'use-interval';
import { useLocation } from 'react-router-dom';


// 編集完了後、何秒で保存するか
const SAVE_DELAY = 10;

export const SchIntervalSave = ()=>{
  const dispatch = useDispatch();
  const dateList = useSelector(state => state.dateList);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const cntMd = useSelector(state => state.controleMode);
  const [sendFlug, setsendFlug] = useState(false);  // 送信フラグ
  const path = useLocation().pathname;
  const lastUpdatePath = cntMd.scheduleLastUpdatePath;

  // 送信フラグの監視を行い送信実行
  useEffect(() => {
    if (sendFlug) {
      setsendFlug(false);
      const prms = { dateList, stdDate, schedule, hid, bid, dispatch };
      dispatch(Actions.setControleMode({ scheduleLastUpdatePath: path}))
      comMod.callDisptchForSendSchedule(prms);
    }
  }, [sendFlug]);
  
  useInterval(() => {
    const elementOpen = comMod.isEditElementOpen();
    // 最終更新が未定義なら0
    const lu = (cntMd.scheduleLastUpdate) ? cntMd.scheduleLastUpdate : 0;
    const saved = (cntMd.saved !== undefined) ? cntMd.saved : true;
    const thisTime = Math.floor(new Date().getTime() / 1000);
    if (thisTime - lu > SAVE_DELAY && !saved && !elementOpen){
      setsendFlug(true);
    }
    // パスが変わったらsaveフラグ
    if (path !== lastUpdatePath && lastUpdatePath && !saved && !elementOpen){
      setsendFlug(true);
    }
  }, 1000);

  return (
    <div>
      <div>
        <mui.SaveButtonWithSate/>
      </div>

    </div>
  )
}
export default SchIntervalSave;