import React, { useEffect, useState } from 'react';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ExposureIcon from '@material-ui/icons/Exposure';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as comMod from '../../commonModule';

import { createMuiTheme } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import lime from '@material-ui/core/colors/lime';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#009688',
      main: '#00695c',
      dark: '#004d40',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

// import classes from '*.module.css';

// マテリアルUIを使ったcomponentはreactのclassではなく
// 単純関数で作成するのが正解らしい（今の所の知見
// これらのcomponentはここでまとめて
// import {xxx,xxx} from 'materialUi'
// ってして使う

const useStyle = makeStyles((theme) =>({
  snackErr: {
    backgroundColor: '#433',
    color: 'rgb(250, 179, 174)'
  },
  snackWarning: {
    backgroundColor: '#443',
    color: 'rgb(255, 213, 153)'
  },
  snackInfo: {
    backgroundColor: '#334',
    color: 'rgb(166, 213, 250)'
  },
  snackSuccess: {
    backgroundColor: '#343',
    color: 'rgb(183, 223, 185)'
  },
  fab1:{
    backgroundColor:'#333',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonOk: {
    paddingRight: 55,
    paddingLeft: 55,
  },
  buttonCancel: {
    background: 'linear-gradient(45deg, #433 30%, #333 90%)',
    color: 'rgb(255 225 222)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(100, 100, 100, .3)',
    height: 30,
    padding: '0 30px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlChkBox:{
    margin: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fabAdd :{
    backgroundColor: 'rgb(29, 102, 28)', 
    color: '#fff'
  },
  fabEditOff: {
    transition:'.6s',
    backgroundColor: 'rgb(212, 56, 85)',
    opacity:.6,
    color: '#fff'
  },
  fabEditOn: {
    transition: '.6s',
    backgroundColor: 'rgb(212, 56, 85)',
    opacity: 1,
    color: '#fff'
  },
  extendedIcon: {
    marginRight: 8,
  },

  intervalSaveBtn: {
    padding: '4px 6px',
    borderRadius: '2px',
    minWidth: 'auto',
    '& .MuiButton-label': {
      fontSize: '8px',
      padding: '2px 3px 0px 4px'
    }
  },

  saveButtonRoot: {
    '& > *': {
      margin: '0 4px 0 4px',
    },
    '& .MuiButton-label ': {
      fontSize: '80%',
    }
  },
  headButtonRoot: {
    '& > *': {
      margin: '1px 4px 0 4px',
    },
    '& .MuiButton-label ': {
      fontSize: '80%',
    }
  },
  ButtonNotSaved: {
    '& > *': {
      margin: '0 4px 0 4px',
    },
    '& .MuiButton-label ': {
      fontSize: '80%',
    },
    '& .MuiButton-contained ':{
      backgroundColor:lime[900],
    },
    '& .MuiButton-contained:hover ': {
      backgroundColor: lime[800],
    }

  },
  monthNavBtn : {
    minWidth: 32,
    color: '#eee',
  },
  formCntSelectClass : {
    margin: 0,
    width: 160, 
    marginTop: 1.5,
    '& .MuiInputBase-root':{
      fontSize: '.7rem',
      color: '#333',
      backgroundColor: '#e0e0e0',
      paddingLeft: 6,
      // borderRadius: 4,
    },
    '& .MuiInputBase-input': {
      padding: '9.5px 0'
    }
  },
  textSelectClass:{
    fontSize: '.7rem',
    paddingTop: 13,
  }

}));

export const RadioStd = (props)=>{
  // const classes = useStyle();
  console.log('RadioStd', props);
  const RadioItems = ()=>{
    const rtn = props.items.map((e,i)=>{
      return(
        <FormControlLabel
          key={i}
          value={e.value}
          control={<Radio color="primary" />}
          label={e.label}
          labelPlacement={props.labelPlacement}
        />
      )
    });
    return (rtn);
  }
  return(
    <RadioGroup
      value={props.value}
      name={props.name}
      onChange={(e) => props.onChange(e)}
      row aria-label="position"
    >
      <RadioItems/>
    </RadioGroup>
  )
}


export const SelectTime = (props)=>{
  // 開始時間、終了時間の選択肢を表示する
  const OptionOfTimes = (props) => {
    const timelst = comMod.timePickerList(
      props.listStart, props.listEnd, props.step
    );
    const options = timelst.map((e, i) => {
      return (
        <option key={i} value={e.str}>{e.str}</option>
      )
    });
    return options;
  }
  const classes = useStyle();
  return(
    <>
      <FormControl className={classes.formControl}>
      <InputLabel >{props.label}</InputLabel>
        <Select
          // labelId="etui87-label"
          // id="etui87"
          native
          value={props.time}
          name={props.name}
          onChange={(e)=>props.onChange(e)}
        >
          <OptionOfTimes {...props}/>
        </Select>
      </FormControl>
    </>
  )
}

export const SelectStd = (props)=>{
  const classes = useStyle();
  const Options = (props) => {
    const options = props.options.map((e, i) => {
      return (
        <option key={i} value={e.value}>{e.label}</option>
      )
    });
    return options;
  }
  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel >{props.label}</InputLabel>
        <Select
          native
          value={props.value}
          name={props.name}
          onChange={(e) => props.onChange(e)}
        >
          <Options {...props} />
        </Select>
      </FormControl>
    </>
  )

}

export const SnapberAlert = ()=>{
  const snackBar = useSelector(state=>state.snackBar);
  const classes = useStyle();
  const dispatch = useDispatch();
  const closeThis = ()=>{
    dispatch(Actions.closeSnackbar())
  };
  // seventyによる色の書き換えがわからないためIF文で切り替える
  const SnackInside = () => {
    if (snackBar.severity === 'error') {
      return (
        <Alert severity="error" className={classes.snackErr}>
          {snackBar.text}
        </Alert>
      )
    }
    else if (snackBar.severity === 'warning') {
      return (
        <Alert severity="warning" className={classes.snackWarning}>
          {snackBar.text}
        </Alert>
      )
    }
    else if (snackBar.severity === 'info') {
      return (
        <Alert severity="info" className={classes.snackInfo}>
          {snackBar.text}
        </Alert>
      )
    }
    else {
      return (
        <Alert severity="success" className={classes.snackSuccess}>
          {snackBar.text}
        </Alert>
      )

    }
  }
  return (
    <div className={classes.root}>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000} onClose={closeThis}
        key={snackBar.key}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackInside/>
      </Snackbar>
    </div>
  )
}

// フローティングアクションボタン
// まずは追加と修正
// イベントはpropsからもらってくるようにする
export const FabAddEdit = (props)=>{
  const classes = useStyle();
  const { 
    clickHandler, editOn, swapOn, hideSwap, hideAdd, ...other
  } = props;
  // const editClass = (editOn) ? classes.fabEditOn : classes.fabEditOff;
  // 上位モジュールのstateの値によってスタイルを変更
  const editBtnStyle = (editOn)?
    { backgroundColor: '#00695c', color: '#fff'}:
    { backgroundColor: '#888', color: '#fff' };
  const addBtnStyle = { backgroundColor: '#c62828', color: '#fff' };
  const swapBtnStyle = (swapOn)?
    { backgroundColor: '#827717', color: '#fff' , padding:'24px 16px'} :
    { backgroundColor: '#888', color: '#fff', padding: '24px 16px'};

  return (
    <div className="floatingActionButtons">
      {!hideAdd &&
        <Fab 
          onClick={e => clickHandler(e)}
          style={addBtnStyle}
          variant="extended" 
          aria-label="add" name="add"
          // このuseStyleを使うとhoverがおかしくなる
          // className={classes.fabAdd}
        >
          <AddIcon className={classes.extendedIcon} />
          追加
        </Fab>
      }
      {!hideSwap &&
        <Fab 
          onClick={e => clickHandler(e)}
          variant="extended" style={swapBtnStyle}
          name="swap"
        >
          <SwapVertIcon className={classes.extendedIcon} />
          並び替え
        </Fab>      
      }
      <Fab 
        onClick={e => clickHandler(e)}
        style={editBtnStyle} 
        variant="extended" 
        aria-label="edit" name="edit"
        // className={editClass}
      >
        <EditIcon className={classes.extendedIcon} />
        修正
      </Fab>
    </div>
  )
}

// Schedule用fab
// 追加削除と追加修正モードを提供する
// buttonmode = 0 両方オフ、1=追加削除、2=追加修正
// 自分でuseSelectorして自分でdispatchする方向で
// イベントはそれだけ。あとは利用するモジュールがステイトを読み取って判断
export const FabSchedule = (props)=>{
  const classes = useStyle();
  const dispatch = useDispatch();
  const def = useSelector(state=>state.controleMode.fabSchedule);
  const [thisMode, setthisMode] = useState((!def)? 0: def);
  const addRemove = (thisMode === 1) ? true : false;
  const addEdit = (thisMode === 2) ? true : false;
  const addRemoveStyle = (addRemove) ?
    { backgroundColor: '#C62828', color: '#fff' } :
    { backgroundColor: '#888', color: '#fff' };
  const addEditStyle = (addEdit) ?
    { backgroundColor: '#00695c', color: '#fff' } :
    { backgroundColor: '#888', color: '#fff' };
  // 値を設定したらディスパッチする
  const clickHandler = (v) =>{
    v = (v === thisMode) ? 0: v;
    setthisMode(v);
    const p = { fabSchedule: v };
    dispatch(Actions.setControleMode(p));
  }
  return (
    <div className="floatingActionButtons">
      <Fab variant="extended" style={addRemoveStyle}
        onClick={()=>clickHandler(1)}
      >
        <ExposureIcon className={classes.extendedIcon} />
        追加・削除
      </Fab>
      <Fab variant="extended" style={addEditStyle}
        onClick={() => clickHandler(2)}
      >
        <EditIcon className={classes.extendedIcon} />
        追加・修正
      </Fab>
    </div>
  );
}

// --------------------------使ってない。削除予定。
// Scheduleで追加削除モードと修正モードを切り替える用
// イベントはフックを使う
// export const RadioScheduleEdit = ()=>{
//   const dispatch = useDispatch();
//   const dispatchThis = (v) =>{
//     dispatch(Actions.schChangeMode(v));
//   }
//   const handleChange = (e) =>{
//     seteditmode(e.currentTarget.value);
//     dispatchThis(e.currentTarget.value);
//   }
//   let def = useSelector(state => state.controleMode.schEditMode);
//   // let def = props.controleMode.schEditMode;
//   def = (def === undefined) ? "0" : def;
//   const [editmode, seteditmode] = React.useState(def);
//   const buttonSaveStyle = {
//     padding:'19px 8px',
//     borderRadius: '50%',
//   }
//   return (
//     <div className="floatingRadioButtons">
//       <FormControl component="fieldset">
//         {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
//         <RadioGroup 
//           onChange={handleChange}
//           row aria-label="position" 
//           name="sheduleMode" 
//           value={editmode}
//           style={{
//             backgroundColor:'#333', 
//             color:'#eee',
//             padding:'6px',
//             borderRadius:'2px',
//             boxShadow:'0 2px 2px',
//             fontSize:'.5rem',
//           }}
//         >
//           <FormControlLabel
//             value="0"
//             control={<Radio style={{padding:'4px'}} color="primary" />}
//             label="追加・削除"
//             labelPlacement="bottom"
//           />
//           <FormControlLabel
//             value="1"
//             control={<Radio style={{padding:'4px'}} color="primary" />}
//             label="修正"
//             labelPlacement="bottom"
//           />
//         </RadioGroup>
//       </FormControl>
//       <div className='buttonWrapper floating'>
//         <ButtonSave color='primary' style={buttonSaveStyle}/>
//       </div>
//     </div>
//   );
// }

export const TimePickers = (props) =>{
  const classes = useStyle();
  return (
    <form className={classes.container} nodisabledate>
      <TextField
        id="time"
        label="Alarm clock"
        type="time"
        defaultValue={props.default}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          // step: 300, // 5 min
          step: '15min',
        }}
      />
    </form>
  );
}

// チェックボックス
// props内の配列より作成する
// 想定している配列
// checkBoxFromArray = [
//   {neme:'actualCost', label:'おやつ', amount:100, detail:'hogehoge'}
// ]
// detailは後から追加するつもり。チップヘルプ的に使う
export const checkBoxFromArray = (props)=>{
  const chkbox = props.lst.map((e, i) => {
    const classes = useStyle();
    return (
      <FormControlLabel key={i} className={classes.formControlChkBox}
        control={
          <Checkbox 
            style={{ width: '20px', height: '20px' }}
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '20px' }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: '20px' }} />}

            onChange={(e) => props.onChange(e)}
            name={props.item}
            value={e[0]}
            amount={e[1]}
            checked={e[0] in props.already}
            color={props.color}
          />
        }
        label={e[0]}
      />
    )
  });
  return (chkbox);
}
// 上記のcheckBoxFromArrayと同じ機能
// [
//   {name: actualCost,checked:true,value:'おやつ',amount:100},
//   ...
// ]
// amountは数字以外のものが入ることもあり
// イベントは持たないようにする。formイベントで一括管理
export const checkBoxGroupe = (props) =>{
  // propsはオブジェクトになっているので配列に治す。
  // mapメソッドが必要なため
  const chkBoxArray = [];
  Object.keys(props.array).forEach(e=>{
    chkBoxArray.push(props.array[e]);
  });
  const chkbox = chkBoxArray.map((e, i)=> {
    const classes = useStyle();
    return (
      <FormControlLabel key={i} className={classes.formControlChkBox}
        control={
          <Checkbox
            style={{ width: '20px', height: '20px' }}
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '20px' }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: '20px' }} />}

            onChange={(e) => props.onChange(e)}
            name={e.name}
            value={e.value}
            amount={e.amount}
            checked={e.checked}
            color={props.color}
          />
        }
        label={e.value}
      />
    )
  });
  return (chkbox);

}

// 汎用ボタン
export const ButtonGP = (props)=>{
  const cn = (props.addictionclass === undefined) 
    ? '' : props.addictionclass;
  const st = (props.addictionStyle === undefined)
    ?'':props.addictionStyle;  
  const key = new Date().getTime();
  return (
    <Button
      key={key}
      variant="contained"
      className={cn}
      style={{st}}
      name={props.name}
      color={(props.color===undefined)?'default':props.color}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );

}

/* okボタン */
export const ButtonOK = (props)=>{
  const classes = useStyle();
  const type = (props.type === undefined)?'button':'submit';
  return (
    <Button 
      variant="contained" 
      className={classes.buttonOk} 
      color="primary"
      type={type}
      onClick={props.onClick}
    >
      <CheckIcon/>
      OK
    </Button>
  );
}

export const ButtonCancel = (props) => {
  const classes = useStyle();
  const size = (props.size) ? props.size :'medium';
  return (
    <Button 
      variant="contained"
      // className={classes.buttonCancel}
      color="secondary"
      size={size}
      onClick={props.onClick}
    >
      <ClearIcon />
      キャンセル
    </Button>
  );
}

export const ButtonWeeklyCopy = (props)=>{
  return(
    <Button variant="contained" color="default" onClick={()=>props.onClick()}>
      <ArrowForwardIcon/>
    </Button>
  )
}

// --------------------------使ってない。削除予定。
// いまのところカレンダーのサーバ送信を行っている
// フックを使っている。
// dateListの書き込みなど。
// スケジュールの書き込みを対応する予定
// export const ButtonSave = (props)=>{
//   const classes = useStyle();
//   const dispatch = useDispatch();
//   const dateList = useSelector(state => state.dateList);
//   const hid = useSelector(state => state.hid);
//   const bid = useSelector(state => state.bid);
//   const stdDate = useSelector(state => state.stdDate);
//   const schedule = useSelector(state => state.schedule);
//   const btnCalss = (props.className)?classes[props.className]:'';
//   const variant = props.variant ? props.variant : 'contained';
//   const size = props.size ? props.size : 'medium';
//   const label = props.label ? props.label : '';
//   // // 日付オブジェクトをjsonにすると時差で日付がずれる！
//   // // ので一旦文字列に変換
//   // const newList = dateList.map(e=>{
//   //   return({
//   //     date:comMod.formatDate(e.date, 'YYYY-MM-DD'),
//   //     holiday: e.holiday,
//   //   });
//   // });
//   const clickHandler =()=>{
//     const prms = { dateList, stdDate, schedule, hid, bid, dispatch };
//     comMod.callDisptchForSendSchedule(prms);
//   }

//   return(
//     <Button 
//       variant={variant} color={props.color}
//       size={size}
//       className={btnCalss}
//       onClick={clickHandler}
//     >
//       <CloudUploadIcon/>
//       {label}
//     </Button>
//   )
// }
// 状態によって表示を変えるsaveボタン
// save済みの場合は何もしない
export const SaveButtonWithSate = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const dateList = useSelector(state => state.dateList);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const cntMd = useSelector(state => state.controleMode);
  const saved = (cntMd.saved !== undefined) ? cntMd.saved : true;

  const clickHandler = () => {
    const prms = { dateList, stdDate, schedule, hid, bid, dispatch };
    comMod.callDisptchForSendSchedule(prms);
  }
  return (<>
      {saved &&
        <div className={classes.saveButtonRoot} >
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            保存済み
          </Button>
        </div>
      }
      {!saved &&
        <div className={classes.ButtonNotSaved} >
          <Button
            variant="contained"
            // color={lime[900]}
            startIcon={<SaveIcon />}
            onClick={clickHandler}
          >
            保存待ち
          </Button>
        </div>
      }
  </>)
}


export const ButtonLoad = (props) => {
  return (
    <Button variant="contained" onClick={props.onClick}>
      <CloudDownloadIcon />
    </Button>
  )
}


// stateのstdDateを更新するため、次月、前月などを示す文字列を返す
// setの分、月をオフセットする
const getNewMonth = (stdDate, set)=>{
  const m = new Date(
    stdDate.split('-')[0], stdDate.split('-')[1] - 1,1
  );
  m.setMonth(m.getMonth() + set);
  return (comMod.formatDate(m ,'YYYY-MM-DD'));
}

// export const ButtonNextMonth = (props) => {
//   const stdDate = useSelector(state=>state.stdDate);
//   return (
//     <Button 
//       variant="contained" 
//       onClick={(e) => props.onClick(e)}
//     >
//       <NavigateNextIcon />
//     </Button>
//   )
// }

export const ButtonBeforeMonth = (props) => {
  return (
    <Button
      variant="contained"
      onClick={(e) => props.onClick(e)}
    >
      <NavigateBeforeIcon /> 
    </Button>
  )
}
export const MonthButtons = () => {
  const classes = useStyle();
  const stdDate = useSelector(state => state.stdDate);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  // const service = useSelector(state => state.service);
  // const dateList = useSelector(state => state.dateList);
  // const schedule = useSelector(state => state.schedule);
  const weekDayDefaultSet = 
    useSelector(state => state.config.weekDayDefaultSet);
  const dispatch = useDispatch();
  const setMonth = (set) => {
    console.log('set month running.');
    let prms = {};
    const newStdDate = getNewMonth(stdDate, set);
    // 基準日の変更
    dispatch(Actions.setStdDate(newStdDate));
    // 伝送状態の更新
    prms = {
      hid: hid,
      bid: bid,
      date: newStdDate,
      reg: 1,
      a: 'listSent',
    }
    dispatch(Actions.fetchTransfer(prms));
    // csv db登録のリセット
    dispatch(Actions.resetTransfer());

    // ユーザーリストの取得
    prms = {
      a: 'lu',  // リストユーザー指定
      hid,
      bid,
      date: newStdDate,
      // service,
    };
    // if (prms.service === '') delete prms.service;
    // this.props.listUsers(prms);
    dispatch(Actions.listUsers(prms));
    // カレンダーの取得
    prms = {
      date: newStdDate,
      hid,
      bid,
      weekDayDefaultSet,
      a: 'fetchCalender',
    }
    // this.props.fetchCalender(prms);
    dispatch(Actions.fetchCalender(prms));
    // スケジュールの取得
    prms = {
      date: newStdDate,
      hid,
      bid,
      a: 'fetchSchedule',
    }
    dispatch(Actions.fetchSchedule(prms));
    // 売上情報のリセット
    dispatch(Actions.setStore({billingDt: "", masterRec: ""}));
    // クッキーをセット
    comMod.setCookeis('stdDate', newStdDate);
  }
  const NavBtn = (props) => {
    return (
      <Button className={classes.monthNavBtn}
        // variant="contained"
        onClick={props.onClick}
      >
        {props.icon}
      </Button>
    )
  }
  return (
    <div className='monthNav'>
      <NavBtn 
        onClick={()=>setMonth(-1)}
        icon={<NavigateBeforeIcon />}
      />
      <span className='monthDisplay'>
        <div className='small'>{stdDate.split('-')[0]}年</div>
        <div className='medium'>{stdDate.split('-')[1]}</div>
        <div className='small'>月</div>
      </span>
      <NavBtn
        onClick={()=>setMonth(1)}
        icon={<NavigateNextIcon />}
      />
    </div>
  )
}

// 表示月の切り替え
// ボタンと手続きをひとまとめにする
// props.setで次月と前月を切り替える
// state内の基準日であるstdDateを変更
// ユーザーリストの読み込み
// カレンダーの読み込み
// スケジュールの読み込み <= まだ出来ていない
// スケジュールの書き込み <= まだ出来ていない
// 等のapi経由dbアクセスを行う
// MonthButtonに移行
// export const ButtonMonthNav = (props)=>{
//   // stateの取得
//   const stdDate = useSelector(state => state.stdDate);
//   const hid = useSelector(state=>state.hid);
//   const bid = useSelector(state=>state.bid);
//   const service = useSelector(state => state.service);
//   const dateList = useSelector(state => state.dateList);
//   const schedule = useSelector(state => state.schedule);
//   const weekDayDefaultSet = useSelector(state=>state.config.weekDayDefaultSet);
//   const dispatch = useDispatch();
//   const setMonth =(set)=>{
//     let prms = {};
//     const newStdDate = getNewMonth(stdDate, set);
//     // 基準日の変更
//     dispatch(Actions.setStdDate(newStdDate));
//     // 伝送状態の更新
//     prms = {
//       hid: hid,
//       bid: bid,
//       date: newStdDate,
//       reg: 1,
//       a: 'listSent',
//     }
//     dispatch(Actions.fetchTransfer(prms));
//     // csv db登録のリセット
//     dispatch(Actions.resetTransfer());

//     // ユーザーリストの取得
//     prms = {
//       a: 'lu',  // リストユーザー指定
//       hid,
//       bid,
//       date: newStdDate,
//       // service,
//     };
//     // if (prms.service === '') delete prms.service;
//     // this.props.listUsers(prms);
//     dispatch(Actions.listUsers(prms));
//     // カレンダーの取得
//     prms = {
//       date: newStdDate,
//       hid,
//       bid,
//       weekDayDefaultSet,
//       a: 'fetchCalender',
//     }
//     // this.props.fetchCalender(prms);
//     dispatch(Actions.fetchCalender(prms));
//     // スケジュールの取得
//     prms = {
//       date: newStdDate,
//       hid,
//       bid,
//       a: 'fetchSchedule',
//     }
//     dispatch(Actions.fetchSchedule(prms));

//   }

//   if (props.set === 1){
//     return(
//       <Button variant="contained" onClick={()=>setMonth(1)}>
//         次月
//         <NavigateNextIcon />
//       </Button>
//     )
//   }
//   else if (props.set === -1){
//     return(
//       <Button variant="contained" onClick={()=>setMonth(-1)}>
//         <NavigateBeforeIcon />
//         前月
//       </Button>
//     )
//   }
//   else{
//     return null;
//   }
// }
// ServiceChangeButtonから変更中
export const ChangeService = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {dispAll, ...rest} = props;
  const service = useSelector(state => state.service);
  const serviceItems = useSelector(state => state.serviceItems);
  const serviceShortHand = useSelector(state => state.serviceShortHand);
  // dispAllがfalseでservice指定がない場合、強制的に指定される。
  const selectList = serviceItems.map(_=>{
    return {label: serviceShortHand[_], service: _};
  });
  // 全てを表示するかどうか
  if (dispAll)  selectList.unshift({label:'全て', service: ''});
  // 全表示を許可されていないのにサービス指定がない場合。サービスを強制的に指定する
  if (!dispAll && service === ''){
    const target = serviceItems[0];
    dispatch(Actions.setStore({service: target}));
  }
  // 現在のカレントインデックス
  let currentNdx = selectList.findIndex(_ => _.service === service);
  currentNdx = (currentNdx === -1) ? 0 : currentNdx;
  // const [currentNdx, setCurrentNdx] = useState(preCurrent);
  // カレントサービスを示す selectListのインデックス
  const handleClick = () => {
    const nextCurrent = 
      (selectList.length - 1 === currentNdx) ? 0 : currentNdx + 1;
    // setCurrentNdx(nextCurrent);
    dispatch(Actions.changeService(selectList[nextCurrent].service));
  }
  // サービスが一つしか存在しない場合は何も表示しない
  if (serviceItems.length === 1) return null;
  return (
    <div className={classes.headButtonRoot}>
      <Button
        variant='contained'
        onClick={handleClick}
      >
        {selectList[currentNdx].label}
      </Button>
    </div>
  )
}

// サービスの切り替えを行う
// 無指定が可能かどうかを引数で指定する
// サービスが一つしか設定されていない場合はnullを返す
// Buttonの中身を別のファンクションにするとレンダリングの内容が変わるんで
// このような回りくどい記述になってる
// 変更中 0120 -> ChangeServeice
export const ServiceChangeButton=(props)=>{
  const dispatch = useDispatch();
  const service = useSelector(state => state.service);
  const serviceItems = useSelector(state => state.serviceItems);
  const serviceShortHand = useSelector(state => state.serviceShortHand);
  const changeService = (e) => {
    e.preventDefault();
    const serviceName = e.currentTarget.getAttribute('service');
    dispatch(Actions.changeService(serviceName));
  }
 
  if (serviceItems.length === 1)  return null;
  if (props.allowUnSepcified){
    return (<>
      <ButtonGroup
        color="default" 
        // size = "small"
        aria-label="outlined default button group"
      >
        <Button 
          variant={(service === serviceItems[0]) ? 'contained' : 'outlined'}
          service={serviceItems[0]}
          onClick={changeService}
        >
          {serviceShortHand[serviceItems[0]]}
        </Button>
        <Button 
          variant={(service === serviceItems[1]) ? 'contained' : 'outlined'}
          service={serviceItems[1]}
          onClick={changeService}
        >
          {serviceShortHand[serviceItems[1]]}
        </Button>
        <Button 
          variant={(service === '') ? 'contained' : 'outlined'}
          service=''
          onClick={changeService}
        >
          全て
        </Button>
      </ButtonGroup>
    </>)
  }
  else{
    return(<>
      <ButtonGroup
        color="default" 
        // size = "small"
        aria-label="outlined default button group"
      >
        <Button 
          variant={(service===serviceItems[0])?'contained':'outlined'}
          service={serviceItems[0]}
          onClick={changeService}
        >
          {serviceShortHand[serviceItems[0]]}
        </Button>
        <Button 
          variant={(service === serviceItems[1]) ? 'contained' : 'outlined'}
          service={serviceItems[1]}
          onClick={changeService}
        >
          {serviceShortHand[serviceItems[1]]}
        </Button>
      </ButtonGroup>
    </>)
  }
}

// 普通の通知
// エラーとかじゃないやつ
export const NoticeDialog = (props) => {
  // props = {title, content, initOpen}
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // useEffect(()=>{
  //   setOpen(props.initOpen);
  // })
  const handleClose = () => {
    props.setnoticeopen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.noticeopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <props.Content/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} color="primary" autoFocus
            variant='contained'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// appバーに配置する教室選択のセレクト
// 値の取得からディスパッチまでこのコンポーネントで完結させる
export const SelectClassRoom = () =>{
  const classes = useStyle();
  const dispatch = useDispatch();
  const classroom = useSelector(state=>state.classroom);
  const users = useSelector(state=>state.users);
  const optSet = new Set();
  // ユーザーstateから教室のセット取得
  users.map(e=>{
    if (e.classroom) optSet.add(e.classroom);
  });
  // セットを配列化ソートしてオプションリストに入れる
  const options = Array.from(optSet).sort((a, b) => (a > b)? 1 :(-1)).map(e=>{
    return(
      <MenuItem value={e}>{e}</MenuItem>
    )
  })
  const [val, setVal] = useState(classroom);
  const handleChange = (ev) =>{
    setVal(ev.target.value);
    dispatch(Actions.setStore({ classroom: ev.target.value}));
  }
  return (<>
    <span className={classes.textSelectClass}>教室</span>
    <FormControl className={classes.formCntSelectClass}>
      <Select
        value={val}
        onChange={handleChange}
        displayEmpty
        // className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="">全て</MenuItem>
        {options}
      </Select>
      {/* <FormHelperText>Without label</FormHelperText> */}
    </FormControl>
  </>)
}

// 教室選択のボタン版
// セレクトだとでざいんがががが
export const ChangeClassRoom = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const classroom = useSelector(state => state.classroom);
  const users = useSelector(state => state.users);
  const optSet = new Set();
  // ユーザーstateから教室のセット取得
  users.map(e => {
    if (e.classroom) optSet.add(e.classroom);
  });
  // ボタンの表示がトグルするリストを作成
  const lst = [];
  Array.from(optSet).sort((a, b)=>(a > b)? 1: -1).map(e=>{
    lst.push({label:e, value:e});
  });
  lst.unshift({label:'全教室', value:''});
  // 現在のstore stateが示す教室の設定インデックス
  let curNdx = lst.findIndex(e=>e.value === classroom);
  // 該当するクラス名が存在しない場合全教室表示に強制変更
  if (curNdx === -1){
    curNdx = 0;
    dispatch(Actions.setStore({classroom: ''}));
  }
  const handleClick = () => {
    const nextCurrent =
      (lst.length - 1 === curNdx) ? 0 : curNdx + 1;
    // setCurrentNdx(nextCurrent);
    dispatch(Actions.setStore({classroom: lst[nextCurrent].value}));
  }
  // 教室が未設定の場合は表示されない
  if (lst.length === 1) return null;
  return (
    <div className={classes.headButtonRoot}>
      <Button
        variant='contained'
        onClick={handleClick}
      >
        {lst[curNdx].label}
      </Button>
    </div>
  )
}
