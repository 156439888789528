import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import { setUseResult } from '../../Actions';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import { useLocation } from 'react-router-dom';

const useStyle = makeStyles((theme)=>({
  root :{
    ' & .MuiDialog-paperWidthSm':{
      minWidth:100
    }
  },
  links: {
    '& > a > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
      color: '#fff'
    },
  },
}));


const closeThis = () => {
  console.log('close this clicked.');
}

const FormInner = (props)=>{

  const { uid, template, ...other } = props;
  const dispatch = useDispatch();
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const dateList = useSelector(state => state.dateList);
  const service = useSelector(state => state.service);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const path = useLocation().pathname;

  const actualCostList = useSelector(state => state.config.actualCostList);
  const addic = [
    '児童指導員等加配加算（Ⅰ）', '児童指導員等加配加算（Ⅱ）',
    '看護職員加配加算', '延長支援加算', '特別支援加算', '家庭連携加算',
    '訪問支援特別加算', '欠席時対応加算', '医療連携体制加算',
    '事業所内相談支援加算', '強度行動障害児支援加算','保育・教育等移行支援加算',
  ];
  const handleSubmit = () => {
    // 休日と平日でフォームが別れているのでそれぞれ値を取得
    const wdInpt = document.querySelectorAll('#er34weekday input');
    const wdSlct = document.querySelectorAll('#er34weekday select');
    const wdFval = comMod.getFormDatas([wdInpt, wdSlct]);
    const hlInpt = document.querySelectorAll('#er34holiday input');
    const hlSlct = document.querySelectorAll('#er34holiday select');
    const hlFval = comMod.getFormDatas([hlInpt, hlSlct]);
    console.log('wdFval', wdFval);
    console.log('hlFval', hlFval);
    [wdFval, hlFval].map(e=>{
      Object.keys(e.actualCost).map(f=>{
        // 実費項目をテンプレートと比較しながら処理
        if (e.actualCost[f]){
          e.actualCost[f] = actualCostList[f];
        }
        else{
          delete e.actualCost[f];
        }
      });
      // 加算項目を処理 指定されたキーを持つ値をdAddictionとしてまとめる
      e.dAddiction = {};
      Object.keys(e).map(f=>{
        if (addic.indexOf(f) > -1) {
          e.dAddiction[f] = e[f];
          delete e[f];
        }
      });
      // 送迎の配列化処理
      e.transfer = [];
      e.transfer[0] = (e.pickup !== undefined) ? e.pickup : '';
      e.transfer[1] = (e.send !== undefined) ? e.send : '';
      delete e.pickup; delete e.send;
    });
    // 平日休日設定を追加
    wdFval.offSchool = 0; hlFval.offSchool = 1;
    // サービスを追加。これが漏れていたと思われる 12/21
    wdFval.service = service;
    hlFval.service = service;

    // 曜日指定を単純配列に変更
    const daysWd = Object.keys(wdFval.weekday).map(e=>{
      return wdFval.weekday[e];
    })
    const daysHl = Object.keys(hlFval.weekday).map(e => {
      return hlFval.weekday[e];
    })
    dispatch(Actions.scheduleMonthlySet(
      'UID' + uid, stdDate, wdFval, daysWd, dateList, 0
    ));
    dispatch(Actions.scheduleMonthlySet(
      'UID' + uid, stdDate, hlFval, daysHl, dateList, 1
    ));
    comMod.setSchedleLastUpdate(dispatch, path);
  }

  return(<>
    <div className='cntRow'>
      <sfp.TimeInput
        name='start' label='開始'
        value={template} uid={uid} 
        required size='middle'
      />
      <sfp.TimeInput
        name='end' label='終了'
        value={template} uid={uid} 
        required size='middle'
      />
    </div>
    <div className='cntRow'>
      <sfp.Transfer
        name='pickup' label='迎え'
        value={template} uid={uid} 
        required size='middle'
      />
      <sfp.Transfer
        name='send' label='送り'
        value={template} uid={uid} 
        required size='middle'
      />
    </div>
    <div className='cntRow'>
      <sfp.ActualCostCheckBox
        value={template} uid={uid} 
        required size='middle'
      />
    </div>
    {!props.editTemplate && <>
      <div className='cntRow'>
        <sfp.WeeksChkBox name={"weekday"} />
      </div>
      <div className='cntRow'>
        <afp.JiShidouKaHai1 uid={uid} size='middle' dLayer={3} />
        <afp.JiShidouKaHai2 uid={uid} size='middle' dLayer={3} />
        <afp.KangoKahai uid={uid} size='middle' dLayer={3} />
        <afp.EnchouShien uid={uid} size='middle' dLayer={3} />
        <afp.TokubetsuShien uid={uid} size='middle' dLayer={3} />
        <afp.KateiRenkei uid={uid} size='middle' dLayer={3} />
        <afp.HoumonShien uid={uid} size='middle' dLayer={3} />
        <afp.KessekiTaiou uid={uid} size='middle' dLayer={3} />
        <afp.IryouRenkei uid={uid} size='middle' dLayer={3} />
        <afp.JigyousyoSoudan uid={uid} size='middle' dLayer={3} />
        <afp.KyoudoKoudou uid={uid} size='middle' dLayer={3} />
        <afp.HoikuKyouiku uid={uid} size='middle' dLayer={3} />
      </div>
    </>}
    <div className="buttonWrapper">
      <mui.ButtonCancel onClick={() => props.closeEvent()} />
      <mui.ButtonOK onClick={(e) => handleSubmit(e)} />
    </div>
  </>)
}

const WeekDay = (props)=>{
  const {display, ...others} = props;
  return(
    <form id="er34weekday" className="dialogForm " style={display}>
      <FormInner {...props} />
    </form>
  )
}
const Holiday = (props) => {
  const { display, ...others } = props;
  return (
    <form id="er34holiday" className="dialogForm " style={display}>
      <FormInner {...props} />
    </form>
  )
}

const Links = (props) => {
  const { tab, settab } = props;
  const classes = useStyle();
  return (<>
    <div className={'linksTab ' + classes.links} >
      <a onClick={() => settab(0)} className={(tab === 0) ? 'current' : ''}>
        <Button >平日設定</Button>
      </a>
      <a onClick={() => settab(1)} className={(tab === 1) ? 'current' : ''}>
        <Button >休日設定</Button>
      </a>
    </div>
  </>)
}

export const SchByUserMonthly = (props)=>{
  // <SchByUserMonthly uid={props.uid} />
  const [tab, settab] = useState(0);
  const service = useSelector(state=>state.service);
  const template = useSelector(
    state => state.scheduleTemplate[service]
  );
  // 子Componentから親Componentが書き換わる場合は
  // useEffectでちゃんと監視する必要があるらしい
  useEffect(()=>{
    settab(tab)
  }, [tab, settab]);
  const disp = (tab === 0) ? 
    [{ display: 'block' }, { display: 'none' }]:
    [{ display: 'none' }, { display: 'block' }];
  return(<>
    <Links tab={tab} settab={settab} />
    <WeekDay {...props} display={disp[0]} template={template.weekday}/>
    <Holiday {...props} display={disp[1]} template={template.schoolOff}/>
  
  </>)
}

export default SchByUserMonthly;