import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import { setUseResult } from '../../Actions';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import { useStyles } from '../common/FormPartsCommon';


export const UserDialog = (props) =>{
  // stateのopenで開く、uidsはuidを持つ
  // editOnで修正モード、uidに従って修正を行う
  const {open, setopen, editOn, uids, ...other} = props;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const stdDate = useSelector(state => state.stdDate);
  const dispatch = useDispatch();
  const titleStr = (editOn) ? '利用者修正削除' : '利用者追加';
  const classes = useStyles();
  // 所属のユニークなリストを作成する
  const users = useSelector(state=>state.users);
  const belongs1Set = new Set();
  const belongs2Set = new Set();
  users.map(e=>{
    belongs1Set.add(e.belongs1);
    belongs2Set.add(e.belongs2);
  });
  const belongs1List = Array.from(belongs1Set);
  const belongs2List = Array.from(belongs2Set);
  // sindexの最大値を作成する 新規追加のときはこれを使う
  const aryMax = (a, b) => {return Math.max(a, b)};
  const sindexMax = (users.length) ?
    users.map(e=>e.sindex).reduce(aryMax) + 10 : 0;
  
    // uidに従ったuserの情報 後からメンバーを見に行くので空のobjで初期化
  // uidsが設定されていないこともあるのでそちらも確認
  // ここでなぜeditonを見に行こうとしたか不明
  // const thisUser = (editOn && uids)? users.find(e=>e.uid === uids) : {};
  const thisUser = (uids)? users.find(e=>e.uid === uids) : {};
  // // 送迎先の初期値を設定
  // const destListInit = (comMod.findDeepPath(thisUser, 'etc.destList') === null)?
  //   ['自宅', '学校',] : thisUser.etc.destList;
  // 行き先指定するためのstate
  const [destList, setDestList] = useState([]);

  const handleSubmit = (e)=>{
    e.preventDefault();
    const formId = '#fgr649hg ';
    // 値が必要なエレメント
    const inputs = document.querySelectorAll(formId + 'input');
    const selects = document.querySelectorAll(formId + 'select');
    // エラーメッセージ用のノード
    const errMsgDisp = document.querySelector(formId + '.errMsg');
    errMsgDisp.textContent = '';
    // 必須項目が入力されているか
    const notFilled = comMod.checkRequireFilled([inputs, selects]);
    if (notFilled.length){
      console.log(notFilled);
      errMsgDisp.textContent = '必要な項目が入力されていません。';
      // スナックバー表示でダイアログが閉じる！
      // dispatch(Actions.setSnackMsg(
      //   '必要な項目で入力されていないところがあります。', 'error'
      // ));
      return false;
    }
    // エラーがないか helperテキストエラーのセレクタを定義
    const errOccured = document.querySelectorAll(
      formId + '.MuiFormHelperText-root.Mui-error'
    );
    if (errOccured.length){
      console.log(errOccured);
      errMsgDisp.textContent = 'エラーのところがあります。';
      // スナックバー表示でダイアログが閉じる！
      // dispatch(Actions.setSnackMsg(
      //   'エラーが発生している入力項目があります。', 'error'
      // ));
      return false;
    }
    // フォームの値を取得 disabledも取得 空白入力も取得
    const userDatas = comMod.getFormDatas([inputs, selects], true, true);
    // 必要なデータ変更を行う 名字と名前の連結 未入力の場合を想定する
    const cn = (a, b) =>{
      a = (a) ? a : '';   b = (b) ? b : '';
      return ((a && b) ? a + ' ' + b : a + b)
    }
    userDatas.name = cn(userDatas.lname, userDatas.fname);
    userDatas.pname = cn(userDatas.plname, userDatas.pfname);
    userDatas.kana = cn(userDatas.klname, userDatas.kfname);
    userDatas.pkana = cn(userDatas.pklname, userDatas.pkfname);
    const ages = comMod.getAge(userDatas.birthday);
    userDatas.age = ages.age;
    userDatas.ageNdx = ages.ageNdx;
    userDatas.ageStr = ages.flx;

    // これだけ半角変換が効かないので
    userDatas.scity_no = comMod.convHankaku(userDatas.scity_no);
    // 法人事業所idの付加
    userDatas.hid = hid;
    userDatas.bid = bid;
    userDatas.stdDate = stdDate;
    // 日付の空白は1989年とかに解釈されるので
    userDatas.endDate = (!userDatas.endDate)?'0000-00-00':userDatas.endDate;
    // 管理タイプ未入力だとエラーになる
    userDatas.kanri_type = (userDatas.kanri_type) ? userDatas.kanri_type:'';
    
    // storeの更新
    // フォームの項目で不足しているパラメータがあるので従来のパラメータに
    // 上書きする
    userDatas.users = users;
    const newUserData = {...thisUser, ...userDatas};
    // 学齢の計算 追加のときは学齢がないので
    if (newUserData.age === undefined){
      newUserData.age = comMod.getAge(newUserData.birthday).age;
      newUserData.ageNdx = comMod.getAge(newUserData.birthday).ageNdx;
      newUserData.ageStr = comMod.getAge(newUserData.birthday).flx;
    }
    // 新規追加はインデックス最大値を使う
    if (!newUserData.sindex)  newUserData.sindex = sindexMax;

    dispatch(Actions.editUser({...newUserData}));
    // ここで書き込み送信を行う
    // ここではaパラメータ必須。削除と兼用しているため
    dispatch(Actions.updateUser({...newUserData, a:'sendUser'}));
    // 送迎先の処理
    // ----- 入力しかかりの値を拾う
    // const destFld = document.querySelector(formId + ' input[name=dest]').value;
    // ----- 現在のリストに無かったら追加
    // let uEtc = {}
    // if (destFld){
    //   if (destList.indexOf(destFld) === -1){
    //     uEtc = { ...thisUser.etc, destList: [...destList, destFld] };
    //   }
    // }
    // else{
    //   uEtc = { ...thisUser.etc, destList:destList};
    // }
    // const uidn = uids.replace(/[^0-9]/g, '');
    // dispatch(Actions.setUsersEtc(uidn, uEtc));
    // const prms = {
    //   hid, bid, uid: uidn, etc: JSON.stringify(uEtc)
    // };
    // dispatch(Actions.sendUsersEtc(prms));
  }
  const cancelSubmit = ()=>{
    setDestList([]);
    setopen(false);
  }
  const deleteUser = ()=>{
    const uid = comMod.convUID(uids).num;
    // storeから削除実行
    let prms = {
      uid, users, delete:true,
    }
    dispatch(Actions.editUser(prms));
    // ここではaパラメータ必須。削除と兼用しているため
    prms = {
      hid, bid, uid: uids, a: 'removeUser'
    }
    dispatch(Actions.updateUser(prms));
  }

  return(<>
    <Dialog className={classes.usersDialog}
      open={open}
      onClose={()=>setopen(false)}
    >
      <DialogTitle>
        <div className='formTitle'>
          {titleStr}
        </div>
      </DialogTitle>
      <DialogContent className={classes.userDialogContentRoot}>
        <form className="dialogForm users" id="fgr649hg" autoComplete="off">
          <div className='cntRow'>
            <sfp.NameInput
              nameLname={'lname'} nameFname={'fname'}
              labelLname={'名字'} labelFname={'名'}
              required
              def={thisUser.name}
            />
            <sfp.NameInput
              nameLname={'klname'} nameFname={'kfname'}
              labelLname={'みょうじ'} labelFname={'なまえ'}
              kana
              def={thisUser.kana}
            />
            <sfp.DateInput
              name={'birthday'} label={'生年月日'} required
              def={thisUser.birthday}
            />
          </div>

          <div className='cntRow'>
            <sfp.UserType def={thisUser.type} />
            <sfp.ServiceType def={thisUser.service} />
            <sfp.HihokenNo def={thisUser.hno} uid={uids}/>
            <sfp.Volume def={thisUser.volume} />
            <sfp.PriceLimit def={thisUser.priceLimit} />
            <sfp.Scity
              def={[thisUser.scity, thisUser.scity_no]}
              label={'支給市区町村'} name={'scity'}
              labelNo={'番号'} nameNo={'scity_no'}
            />
            <sfp.KanriType def={thisUser.kanri_type} />
          </div>

          <div className='cntRow'>
            <sfp.DateInput
              name={'startDate'} label={'利用開始日'} required
              def={thisUser.startDate}
            />
            <sfp.DateInput
              name={'contractDate'} label={'契約日'} required
              def={thisUser.contractDate}
            />
            <sfp.DateInput
              name={'endDate'} label={'契約終了日'}
              def={thisUser.endDate}
            />
            <sfp.ContractLineNo
              name={'endDate'} label={'契約終了日'}
              def={thisUser.lineNo}
            />
          </div>
          <div className='cntRow'>
            <sfp.NameInput
              nameLname={'plname'} nameFname={'pfname'}
              labelLname={'保護者名字'} labelFname={'保護者名'}
              required
              def={thisUser.pname}

            />
            <sfp.NameInput
              nameLname={'pklname'} nameFname={'pkfname'}
              labelLname={'保護者みょうじ'} labelFname={'保護者なまえ'}
              kana
              def={thisUser.pkana}
            />
          </div>
          <div className='cntRow'>
            <sfp.MailInput
              name={'pmail'} label={'保護者メール'}
              def={thisUser.pkana}
              def={thisUser.pmail}
            />
            <sfp.PhoneInput
              name={'pphone'} label={'保護者電話1'} required
              def={thisUser.pphone}
            />
            <sfp.PhoneInput
              name={'pphone1'} label={'保護者電話2'}
              def={thisUser.pphone1}
            />
          </div>

          <div className='cntRow'>
            <sfp.Belongs
              name={'belongs1'} label={'所属1'} options={belongs1List}
              def={thisUser.belongs1}
            />
            <sfp.Belongs
              name={'belongs2'} label={'所属2'} options={belongs2List}
              def={thisUser.belongs2}
            />
            <sfp.ClassRoom def={thisUser.classroom} />
          </div>
          <div className='errMsg'></div>
          {/* <sfp.TransferList 
            destList={destList} setDestList={setDestList} 
            uid={uids}
          /> */}
        </form>
       
      </DialogContent>
      <DialogActions>
        <div className='buttonWrapper'>
          {editOn &&
            <mui.ButtonGP
              // color='Error'
              label='削除'
              onClick={deleteUser}
            />
          }
          <mui.ButtonGP
            color='secondary'
            label='キャンセル'
            onClick={cancelSubmit}
          />
          <mui.ButtonGP
            color='primary'
            label='書き込み'
            type="submit"
            onClick={handleSubmit}
          />

        </div>
      </DialogActions>
      
    </Dialog>
    
  </>)
}
export default UserDialog;