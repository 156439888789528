import React, {useState} from 'react';
import { HashRouter, Route, Switch, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import * as Actions  from './Actions'
import Users from './component/Users/Users';
import Schedule from './component/schedule/Schedule';
import SchCalWrapp from './component/schedule/SchCalWrapp';
import Billing from './component/Billing/Billing';
import Proseed, {ProseedUpperLimit} from './component/Billing/Proseed';
import Reports from './component/reports/Reports';
import 
  Setting, { 
    StandardSettings, AddictionSettings, AddictionSettingsWs,
    ScheduleSettings, ViewSettings, OthesSettings, RegParamsSettings,
  } 
  from './component/Setting/Setting';
import Account from './component/account/Account';
import Hoge from './Hoge';
import store from './store';
import * as comMod from './commonModule';
import './index.css';
import { connect } from 'react-redux';
import { useDispatch, /*useSelector,*/ } from 'react-redux';
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
// import SchFooterNav from './component/schedule/SchFooterNav';
import SchByUsers from './component/schedule/SchByUser';
import SchWeekly from './component/schedule/SchWeekly';
import Login from './component/common/Login';
import { LoadingSpinner} from './component/common/commonParts';

import * as mui from './component/common/materialUi';
import Rev from './Rev';
import SchIntervalSave from './component/schedule/SchIntervalSave';
import SnackMsg from './component/common/SnackMsg';
// import TemporaryDrawer from './DrowerMenu'
import DrowerMenu, {SideToolBar, } from './DrowerMenu';
import Invoice from './component/reports/Invoice';
import {BilUpperLimit} from './component/Billing/Billing';
import {ResetPassWd} from './component/account/Account'
// import GridTest from './component/reports/gridtest';

export const theme = createMuiTheme({
  palette: {
    // default:{
    //   light: '#eceff1',
    //   main: '#cfd8dc',
    //   dark: '#90a4ae',
    //   contrastText: '#000000',
    // },
    cancel: {
      light: '#eceff1',
      main: '#cfd8dc',
      dark: '#90a4ae',
      contrastText: '#000000',
    },
    primary: {
      light: '#26a69a',
      main: '#00695c',
      dark: '#004d40',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#039be5',
      main: '#0277bd',
      dark: '#01579b',
      contrastText: '#ffffff',
    },
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log('ErrorBoundary componentDidCatch', error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}


const Header = (props) => {
  const ref = useLocation().pathname;
  // サービス未指定を可能とするパスリストを列挙する
  const dispAllPath = ['/'];
  const dispAll = dispAllPath.findIndex(_=>_ === ref) > -1;

  // 教室指定不可とするパスを列挙
  const notDispClassRoomPath = ['/proseed'];
  const notDispClassRoom = notDispClassRoomPath.findIndex(_ => _ === ref) > -1
  let hname = '', bname = '';
  if (props.props.comFtc.done && !props.props.comFtc.err){
    hname = props.props.com.hname;
    bname = props.props.com.bname;
  }
  let dispName = '';
  if (props.props.fetchAccountStatus.done 
    && !props.props.fetchAccountStatus.err) 
  {
    dispName = props.props.account.sbname + ' ' + props.props.account.lname;
  }
  return (
    <div className='headBar'>
      <div className='leftSide'>
        <DrowerMenu />
        <div className='titleWrapper'>
          <div className='title'>
            <img src={`${window.location.origin}/img/logoa_eee.svg`} width="120px" alt="logo" />
          </div>
        </div>
        <SchIntervalSave />
        <mui.ChangeService dispAll={dispAll}/>
        {notDispClassRoom === false &&
          <mui.ChangeClassRoom />
        }
        <mui.MonthButtons />
      </div>
      <div>
        {/* <mui.ButtonSave color="primary" /> */}
      </div>

      <div className='comWrapper'>
        {/* <div className='com'>{hname}</div>
        <div className='branch'>{bname}</div> */}
        <div>{dispName} さん</div>
      </div>
    </div>
  )
}

class Main extends React.Component{
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Header props={this.props} />
          <SideToolBar/>
          <Switch>
            <Route exact path='/' props={this.props} component={Users} />
            <Route path='/schedule/weekly/:prms' component={SchWeekly} />
            <Route path='/schedule/weekly/' component={SchWeekly} />
            <Route path='/schedule/calender/' component={SchCalWrapp} />
            <Route path='/schedule/users/:p' component={SchByUsers} />
            <Route path='/schedule/users/' component={SchByUsers} />
            <Route path='/schedule' component={Schedule} />
            {/* <Route path='/reports/gridtest' component={GridTest} /> */}
            <Route path='/reports/invoice' component={Invoice} />
            <Route path='/reports' component={Reports} />
            <Route path='/billing/upperlimit' component={BilUpperLimit} />
            <Route path='/billing' component={Billing} />
            <Route path='/proseed/upperlimit' component={ProseedUpperLimit} />
            <Route path='/proseed' component={Proseed} />
            <Route path='/setting/standard' component={StandardSettings} />
            <Route path='/setting/addiction' component={AddictionSettings} />
            <Route path='/setting/view' component={ViewSettings} />
            <Route path='/setting/schedule' component={ScheduleSettings} />
            <Route path='/setting/others' component={OthesSettings} />
            <Route path='/setting/reg' component={RegParamsSettings} />
            <Route path='/setting' component={Setting} />
            <Route path='/Account' component={Account} />
            <Route path='/restpassword' component={ResetPassWd} />
            <Route path='/test' component={Hoge} />
          </Switch>
        </HashRouter>
        <SnackMsg />
      </Provider>
    )
  }
}

// セッションのチェックを行う
// ローディングと認証を確認する
const sessionCheck = (props)=>{
  const st = props.sessionStatus;
  const ss = props.session;
  if (st.loading)  return({done:false,loading:true,err:false})
  if (!st.loading && st.done && Object.keys(ss).length)
    return({done:true,loading:false,err:false})
  if (!st.loading && !st.done && !Object.keys(ss).length)
    return({done:false,loading:false,err:true})
}

const loadStatus = (props) => {
  const sessionDone = props.sessionStatus.done;
  const scheduleDone = props.fetchSchedule.done;
  const clenderDone = props.fetchCalenderStatus.done;
  const userDone = props.userFtc.done;
  const comDone = props.comFtc.done;
  const sessionErr = props.sessionStatus.err;
  const scheduleErr = props.fetchSchedule.err;
  const clenderErr = props.fetchCalenderStatus.err;
  const userErr = props.userFtc.err;
  const comErr = props.comFtc.erre;
  const done = (
    sessionDone && scheduleDone && clenderDone && userDone && comDone
  );
  const error = (
    sessionErr || scheduleErr || clenderErr || userErr || comErr
  );
  return { allLoad: done, someError: error };
}

const ErroeOccured = ()=>{
  return(<div>なんかダメみたいです。</div>)
}

const FirstLoading = () => {
  return (<div>最初のローディング中だよ</div>)
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad :true
    };
  }
  setfirstLoad = (v)=>{
    this.setState({firstLoad: v});
  }

  render(){
    const ls = loadStatus(this.props);
    const Rtn = ()=>{
      // アカウント存在確認
      if (Object.keys(this.props.account).length){
        // 読み込めたらメイン firstLoadを外す
        if (ls.done){
          this.setfirstLoad(false);
          return(<Main {...this.props}/>)
        }
        // 一旦は読めたのでmainを表示
        else if (!this.firstLoad){
          return (<Main {...this.props} />)
        }
        // エラーだったらしょうがないね
        else if (ls.err){
          return <ErroeOccured/>
        }
        else if (ls.firstLoad){
          return <FirstLoading/>
        }
      }
      else{
        return <Login/>
      }
    }
    return (
      <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <Rtn/>
          <mui.SnapberAlert/>
        </MuiThemeProvider>
      </ErrorBoundary>
    )
  }
  
}
function mapStateToProps(state){
  return(state);
}
export default connect(mapStateToProps, Actions)(App);
