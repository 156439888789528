import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import * as sfp from '../common/StdFormParts';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

// 上限管理の情報を入力する
// 自社が上限管理事業所の場合の他事業所の情報と利用学、負担額を入力する
// 自社が協力事業所の場合もこのコンポーネントを使うことにした
// 指定タイプ specifyType=1の場合、協力事業所として管理事業所を登録、
// 上限額を入力する
// 負担方法 自社優先 自社ができるだけ多く請求する
//          最多額優先 一番多く利用があったところができるだけ多く請求する
//          等分 利用額にあわせて按分する
export const UpperLimitKanri = (props)=>{
  const { uid, specifyType, } = props;
  const dispatch = useDispatch();
  const UID = comMod.convUID(uid).str;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const users = useSelector(state=>state.users);
  const thisUser = comMod.getUser(uid, users);
  const schedule = useSelector(state => state.schedule);

  // specifyType=1の場合、協力事業所として操作を行うが
  // その場合の操作対象は管理事業所となる
  const objKey = (specifyType) ? '管理事業所':'協力事業所';

  // user.etc.協力事業所として事業所名、事業所番号を記載する
  // schedule.uid.協力事業所として事業所名、事業所番号、金額を記載する
  // 事業所名が記載されていないものは無視する
  const submitHandler=()=>{
    // 値が必要なエレメント
    const inputs = document.querySelectorAll('#ttydo55 input');
    const selects = document.querySelectorAll('#ttydo55 select');
    // フォームの値を取得
    const fvals = comMod.getFormDatas([inputs, selects], true);
    // それぞれバラバラのnameタグをグループ化するための配列
    const namesTags = [
      [
        'office1', 'no1', 'amount1', 'kettei', 
        '上限管理配分種別', 'kanriKekka',
      ], 
      ['office2', 'no2', 'amount2'], 
      ['office3', 'no3', 'amount3'], 
      ['office4', 'no4', 'amount4'], 
    ];
    // dispatch用の配列
    const outPuts = namesTags.map(e=>{
      if (fvals[e[0]]){ // 事業所名に記述がある場合
        return{
          name: fvals[e[0]],
          no: fvals[e[1]],
          amount: fvals[e[2]], // amountというキーをstoreに書き込めない！
          kettei: fvals[e[3]], // 協力事業所のときのみ有効
          haibun: fvals[e[4]], // 協力事業所のときのみ有効
          kanriKekka: fvals[e[5]], // 協力事業所のときのみ有効
        }
      }
    }).filter(e=>e !== undefined);
    // 同一の事業所番号が配列に含まれていないかチェック
    const tmp = outPuts.map(e=>e.no);
    const dup = tmp.filter((e, i, a)=>a.indexOf(e) !== a.lastIndexOf(e));
    if (dup.length){
      dispatch(
        Actions.setSnackMsg('事業所が重複しているため登録できません。','error')
      );
      return false;
    };
    // scheduleにdispatch
    dispatch(Actions.addSchedule(UID, objKey, outPuts));
    // users.etcには金額は不要なので削除 -> これがあるから値が消える？
    // ここで削除するとstoreまで影響する。削除はせず
    // outPuts.map(e=>{
    //   delete e.amount_;
    // });
    const nuid = comMod.convUID(UID).num;
    const userEtc = { ...thisUser.etc, [objKey]: outPuts};
    if (objKey === '協力事業所') { // 分かりづらいけど管理事業所の場合！
      Object.assign(userEtc, {上限管理配分種別: fvals.上限管理配分種別})
    }
    // users.etcにdispatch
    dispatch(Actions.setUsersEtc(nuid, userEtc));
    // usersはdbに送信
    const prms = {
      hid, bid, uid: nuid, etc: JSON.stringify(userEtc)
    };
    dispatch(Actions.sendUsersEtc(prms));
    comMod.setSchedleLastUpdate(dispatch, true);
  }
  const canselHandler = ()=>{
    console.log('cancel clicked.')
    props.close();
  }
  // Scheduleに記載されている値を優先して読み込む。
  // 管理事業者の場合、複数の協力事業所を読み込むので何番目の事業所を見るかは
  // iで指定する
  const getFormDef = (i)=>{
    const ts = comMod.findDeepPath(schedule, [UID, objKey]);
    let rts;
    if (!ts) rts = ['', '', '', ''];
    else{
      const vs = ts[i];
      rts = (vs !== undefined) ? 
        [vs.name, vs.no, vs.amount, vs.kettei] : ['', '', '', ''];
    }
    const tu = comMod.findDeepPath(thisUser, ['etc', objKey]);
    let rtu;
    if (!tu) rtu = (['', '', '', '']);
    else{
      const vu = tu[i];
      // ユーザーから値を返すときは金額情報は返さない
      rtu = (vu !== undefined) ?
        [vu.name, vu.no, '', ''] : ['', '', '', ''];
    }
    // rtsに値が入っていればそちらを返す。なければuserから取得した値を
    const rt = (rts[0])? rts : rtu;
    return rt;
  }
  // OtherOfficeは子コンポーネント側でstate管理されているがこちらの
  // コンポーネントでも使う必要が出てきたので改めてstateとして定義
  const [officeNames, setOfficeNames] = useState([
    getFormDef(0)[0],getFormDef(1)[0],getFormDef(2)[0],getFormDef(3)[0],
  ]);

  const haibunDef = comMod.findDeepPath(thisUser, 'etc.上限管理配分種別');
  const kanriKekkaDef = () =>{
    const v = comMod.findDeepPath(schedule, [UID, '管理事業所']);
    console.log('v', v);
    const w = (v && v.length)? v[0].kanriKekka : '';
    return (w) ? w : '';
  }
  
  const KanriFrormParts = () => (<>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名1' name='office1'
        labelNo='番号1' nameNo='no1'
        def={getFormDef(0)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {0}
      />
      <sfp.NumInputGP 
        name='amount1' label='利用総額1' def={getFormDef(0)[2]}
        required={officeNames[0] !== ''}
      />
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名2' name='office2'
        labelNo='番号2' nameNo='no2'
        def={getFormDef(1)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {1}
      />
      <sfp.NumInputGP 
        name='amount2' label='利用総額2' def={getFormDef(1)[2]} 
        required={officeNames[1] !== ''}
      />
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名3' name='office3'
        labelNo='番号3' nameNo='no3'
        def={getFormDef(2)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {2}
      />
      <sfp.NumInputGP 
        name='amount3' label='利用総額3' def={getFormDef(2)[2]} 
        required={officeNames[2] !== ''}
      />
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名4' name='office4'
        labelNo='番号4' nameNo='no4'
        def={getFormDef(3)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {3}
      />
      <sfp.NumInputGP 
        name='amount4' label='利用総額4' def={getFormDef(3)[2]} 
        required={officeNames[3] !== ''}
      />
    </div>
    <div className="cntRow">
      <sfp.HaibunSyubetu def={haibunDef }/>
    </div>
  </>)

  const KyouryokuFrormParts = () => (<>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名' name='office1'
        labelNo='番号' nameNo='no1'
        def={getFormDef(0)}
      />
      {/* <sfp.NumInputGP name='amount1' label='利用総額' def={getFormDef(1)[2]} /> */}
      <sfp.NumInputGP 
        name='kettei' label='決定請求額' 
        def={getFormDef(1)[3]} 
      />
      <sfp.KanriKekka def={kanriKekkaDef}/>
    </div>
  </>)
  return(<>
    <form id = "ttydo55" className="dialogForm">
      {!specifyType && <KanriFrormParts />}
      {specifyType && <KyouryokuFrormParts />}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonCancel onClick={() => canselHandler()} />
      <mui.ButtonOK onClick={(e) => submitHandler(e)} />
    </div>

  </>)
}