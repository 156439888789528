import React, { useEffect, useState } from 'react';
import * as mui from '../common/materialUi'
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { common } from '@material-ui/core/colors';
import { LoadingSpinner } from '../common/commonParts';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { 
  houdySirvice, houdayKasan, chiikiKubun, unitPrice,
  serviceSyubetu, ketteiScode,
} from './BlCalcData';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { FormatAlignJustifyOutlined } from '@material-ui/icons';
import { 
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from './blMakeData';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';
import CheckProgress from '../common/CheckProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
}));

const Links = () => {
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  const prms = [
    { link: "/billing", label: "請求送信" },
    { link: "/billing/upperlimit", label: "上限管理" },
  ];
  const linkList = prms.map((e, i) => {
    let cls = (ref === e.link) ? 'current' : '';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return (<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}

export const BilUpperLimit = (props) =>{
  return(<>
    <Links/>
    <div className="AppPage account">hoge</div>
  </>)
}


// 請求処理を行うモジュール。
// 処理はmakeCsvで一括して行う。
// makeCsvからは
// 明細情報（請求）k121(122)
// サービス提供実績 k611
// 上限管理(k411)
// を作成するモジュールそれぞれコールする予定。
// 個別の作成モジュールはcsvに該当する配列を返す。
// 配列はjsonにしてdbに投げるようにする
// 親モジュールAccountからはuseStateで作成したステイトとset関数を
// 明細作成用モジュールに渡してそれぞれ状態管理を行うようにする。
// サーバーサイドの状態管理を行うためにfetch関連のディスパッチを作成して
// そちらもstateで状態管理を行うようにする。

// 送信の状態を監視するタイマーインターバル
const fetchTransferInterval = 1000 * 8;
const dummyProgressInterval = 800;
// 配列置換用文字列定義
const REC_CNT = 'REC_CNT';
const TOTAL_RECS = 'TOTAL_RECS';
const JINO = 'JINO';
const MONTH_USE = 'MONTH_USE';
const MONTH_APPLY = 'MONTH_APPLY';
const CITY = 'CITY';
const HNO = 'HNO';
// カラム位置
const CP_TR_SOUGEI_CNT = 36; // 送迎回数 35 カラム
// 以下はトータルレコードに挿入するべき値とカラム位置 今はまだ対応していない
// const KATEI_CNT = 'KATEI_CNT'; // 家庭連携加算 37
// const KATEI_CNT1 = 'KATEI_CNT1'; // 家庭連携加算算定回数 38
// const JIKATSU_CNT = 'JIKATSU_CNT';// 自活支援回数 53
// const HOUMON_CNT = 'HOUMON_CNT';// 訪問回数 54
// const KOUDOU_CNT = 'KOUDOU_CNT';// 行動障害回数 119
// const JUUDO_CNT = 'JUUDO_CNT';// 重度回数 123

// 配列から特定の文字列を検索して置換する
const elmRep = (a, str, value) =>{
  const ndx = a.indexOf(str);
  if (ndx === -1) return false;
  a[ndx] = value;
  return true;
}

// // scheduleから該当サービスのあったuidを取得する
// const getUIDS = (schedule, users, service)=>{
//   const t = Object.keys(schedule).map(e=>{
//     if ((e.indexOf('UID') === 0) && comMod.getUser(e.users).service === service){
//       return e;
//     };
//   });
//   // undefindが帰ってくるのでフィルタする
//   const uids = t.filter(e=>e);

//   return uids;
// }


// 利用明細レコードを作成する トータルレコードに記載するための値も返す
// const getDetailUseResult = (detailRec, schedule, uid, cityNo, jino, hno) =>{
//   // detailRecの形式
//   // detailRec = [2, REC_CNT, 'K611', 2, MONTH_USE, CITY, JINO, HNO, '0501'];
//   // カラムポジションの定義
//   const CP_DATE = 11;
//   const CP_START = 16;
//   const CP_END = 17;
//   const CP_PICKUP = 23;
//   const CP_SEND = 24;
//   const CP_BIKOU = 36;  // 備考だがサンプルデータは平日=1 休日=2が入力されている

//   const detail = []; // リターン用の配列
//   const thisSch = schedule[uid];  // 処理を行うユーザーのスケジュール
//   // did (d20200816)キーで格納されている値でmap
//   let transferCount = 0; // 送迎カウント
//   Object.keys(thisSch).forEach(did=>{
//     const e = thisSch[did];
//     // did形式以外のキーは弾く
//     if (!did.match(/^D[0-9]+/)) return true;
//     // 利用実績フラグのチェック
//     if (!e.useResult) return true;
//     const ary = detailRec.concat();
//     elmRep(ary, MONTH_USE, did.substr(1, 4));// 提供月
//     elmRep(ary, CITY, cityNo);// 市区町村番号
//     elmRep(ary, JINO, jino);// 事業所番号
//     elmRep(ary, HNO, hno);// 被保険者番号
//     ary[CP_DATE] = parseInt(did.substr(7, 2));// 日付
//     ary[CP_START] = e.start.replace(':', ''); //開始時間
//     ary[CP_END] = e.end.replace(':', ''); //終了時間
//     ary[CP_PICKUP] = (e.transfer[0]) ? 1 : 0; // 迎え
//     ary[CP_SEND] = (e.transfer[1]) ? 1 : 0; // 送り
//     transferCount += (e.transfer[0]) ? 1 : 0;
//     transferCount += (e.transfer[1]) ? 1 : 0;
//     ary[CP_BIKOU] = parseInt(e.offSchool) + 1; // 平日0休日1を+1して変換
//     detail.push(ary);

//   });
//   return {detail, transferCount};
// }
// 配列がlengthになるまでfillerを挿入する
// const addFill = (array, filler, length) =>{
// 　while(array.length < length)  array.push(filler);
//   return true;
// }

// 利用実績ファイルに対応した配列を作成
// const makeUseResult = (prms)=>{
//   const {stdDate, schedule, users, com, service} = prms;

//   const monthUse = stdDate.replace(/\-/g, '').substr(0, 6);
//   // stdDateを日付型に変換
//   const date = new Date(stdDate.split('-')[0], stdDate.split('-')[1],1);
//   date.setMonth(date.getMonth() + 1);
//   const monthApply = comMod.formatDate(date, 'YYYYMM');
//   // コントロールレコード。後から置換するフィールドに文字列を入れておく
//   const cntRec = [1,REC_CNT,0,TOTAL_RECS,'K61',0,JINO,0,1,MONTH_APPLY,''];
//   // 基本レコード 合計情報などが記載されている
//   const totalRec = [2, REC_CNT, 'K611', 1, MONTH_USE, CITY, JINO, HNO, '0501'];
//   addFill(totalRec, '', 148);
//   // 先頭の自明な項目を設定する monthは提供月 hnoは受給者番号
//   // 0501は要識別種別番号 固定にしてあるがあとで変更するかも
//   // 明細レコード
//   const detailRec = [2,REC_CNT,'K611',2,MONTH_USE,CITY,JINO, HNO,'0501'];
//   addFill(detailRec, '', 92);
//   const endRec = [3, REC_CNT];
  
//   // リターンするためのレコード
//   const rt = [];
//   // コントロールレコードの作成
//   elmRep(cntRec, JINO, com.jino); // 事業所番号
//   elmRep(cntRec, MONTH_APPLY, monthApply); // 申請月
//   rt.push(cntRec);
//   // 対象のUIDを取得
//   const uids = getUIDS(schedule, users, service);
//   // ユーザーごとの処理 totalレコードと明細レコードの作成
//   const jino = com.jino;
//   uids.forEach(uid=>{
//     const hno = comMod.getUser(uid, users).hno;
//     const cityNo = comMod.getUser(uid, users).scity_no;
//     const tr = totalRec.concat(); //rtに挿入用のレコード
//     elmRep(tr, MONTH_USE, monthUse); // 利用月
//     elmRep(tr, CITY, cityNo); // 請求市町村
//     elmRep(tr, JINO, jino); // 事業所番号
//     elmRep(tr, HNO, hno); // 被保険者番号
//     // detailRecordを取得する トータルレコードに取得する値と合わせて
//     // オブジェクトを返す
//     const detail = getDetailUseResult(
//       detailRec, schedule, uid, cityNo, jino, hno
//     );
//     tr[CP_TR_SOUGEI_CNT] = detail.transferCount; // 送迎回数をトータルレコードに
//     rt.push(tr);
//     // getDetailUseResultからの戻り配列を全て追加
//     detail.detail.forEach(e=>{
//       rt.push(e);
//     });
//   });
//   rt.push(endRec);  // 終端レコード
//   rt.forEach((e, i)=>{
//     elmRep(e, REC_CNT, i +1);
//   });
//   elmRep(rt[0], TOTAL_RECS, rt.length - 2); //総レコードカウントの書き込み
//   return rt;
// }

// const makeDummy = (num)=>{
//   const rt = [];
//   for (let i = 0; i < num; i++){
//     rt.push(['this is dummy', i + 1]);
//   }
//   return rt;
// };

// それぞれのcsv作成モジュールのローダー
const makeAndSend = (prms) =>{
  const {stdDate, schedule, users, com, service, dispatch} = prms;
  // const useResult = makeUseResult(prms);
  const { billingDt, masterRec } = setBillInfoToSch(prms);
  console.log('billingDt', billingDt);
  console.log('masterRec', masterRec);
  const billing = makeBiling(billingDt, masterRec);
  const upperLimit = makeJugenkanri(billingDt, masterRec);
  const useResult = makeTeikyouJisseki(billingDt, masterRec);
  console.log('upperLimit', upperLimit);
  console.log('useResult', useResult);
  const jino = com.jino;
  const bid = com.bid;
  const hid = com.hid;
  const transferData = { useResult, upperLimit, billing, jino, bid, hid};
  console.log('transferData', transferData);

  const smpHno = ['0200383255', '0200324515', '0200259745', '0122584063'];
  const sampleBilingCsvImg = billing.filter(e=>(
    smpHno.indexOf(e[7]) > -1
  ));
  console.log('sampleBilingCsvImg', sampleBilingCsvImg)
  const sampleBiling = billingDt.filter(e => (
    smpHno.indexOf(e.hno) > -1
  ));
  console.log('sampleBiling', sampleBiling)

  dispatch(Actions.sendTransfer({
    hid: com.hid,
    bid: com.bid,
    date: stdDate,
    dt: JSON.stringify(transferData),
    a: 'sendTransferData',
  }));
}

// フックを使う必要があるのでトップレベルファンクションにする
const TransferDone = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector(state => state.fetchTransferStatus);

  const [count, setCount] = React.useState(0);
  const [sent, setSent] = React.useState(false);
  useEffect(()=> {
    const tid = setInterval(()=>{
      setCount(count+1);
      // 国保連送信を監視する
      if (fetchStatus.data.dt.length) {
        const transferd = fetchStatus.data.dt[0].sent; // 国保連伝送日付
        // 日付が入っていたら送信済み
        if (transferd.indexOf('0000-00') !== 0) { 
          setSent(true);
        }
      }
      // 一定時間過ぎたら経過表示を停止する
      if (count > 6){
        dispatch(Actions.resetTransfer());
      }
    }, dummyProgressInterval);
    return () => clearInterval(tid);
  });
  const checkIconStyle = {
    verticalAlign: 'sub',
  }

  return (
    <div className="AppPage account">
      <div className="billingPanel">
        <div className="sendProgress">
          <span className='icon'>
            <CheckIcon style={checkIconStyle} color='primary'/>
          </span>
          <span className='text'>送信準備完了しました。</span>
        </div>
        {(count > 0) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>請求データ送信完了</span>
          </div>
        }
        {(count > 1) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>利用実績データ送信完了</span>
          </div>
        }
        {(count > 2) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>上限管理データ送信完了</span>
          </div>
        }
        {(count > 2 && !sent) &&
          <div className="sendProgress">
            送信完了までしばらくお待ち下さい。
          </div>
        }
        {(count > 2 && sent) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckCircleIcon style={checkIconStyle} color='secondary' />
            </span>
            <span className='text'>国保連伝送完了</span>
          </div>
        }
      </div>
    </div>
  )

}



const Billing = () => {
  const dispatch = useDispatch();
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com); // 会社と事業所の情報
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const sendStatus = useSelector(state => state.sendTransferStatus);
  const fetchStatus = useSelector(state => state.fetchTransferStatus);

  // 送信データの状態取得 useEffectを使うよ！！
  useEffect(()=>{
    const params = {
      hid, bid, 
      date: stdDate,
      reg: 1,
      displayLoading : false,
      a: 'listSent',
    }
    if (!fetchStatus.done && !fetchStatus.err){
      dispatch(Actions.fetchTransfer(params));
    }

    const tid = setInterval(()=>{
      // 送信状態のポーリング
      dispatch(Actions.fetchTransfer(params));
    }, fetchTransferInterval);
    return () => clearInterval(tid);
  }, [fetchStatus]);

  const thisMonth = stdDate.split('-')[0] + '年' + stdDate.split('-')[1] + '月';
  
  const str = thisMonth + 'のデータ伝送をリクエストします。よろしいですか？'
  const prms = {
    stdDate, schedule, users, com, service: '放課後等デイサービス', dispatch
  }
  
  const clickHandler = ()=>{
    console.log('clicked');
    makeAndSend(prms);
  }
  // 送付処理を行うかどうか。送付済みのときはボタン自体が表示されないはず。
  const TransferButtons = () => {
    let str1;
    let transferToKKH = false;  // 国保連送付済み
    // db登録済みデータが存在するとき
    if (fetchStatus.data.dt.length) {
      const gen = fetchStatus.data.dt[0].gen; //送信済み日付
      const transferd = fetchStatus.data.dt[0].sent; // 国保連伝送日付
      // 日付が入っていない場合は未伝送
      if (transferd.indexOf('0000-00') === 0) { 
        str1 = thisMonth + 'のデータは' + gen + 'に伝送リクエスト済みです。' + '再度リクエストを行いますか？'
      }
      else {
        str1 = thisMonth + 'のデータは' + 
          transferd + 'に伝送が完了しました。'
        transferToKKH = true;
      }
    }
    const msg = (str1) ? str1 : str;
    return(<>
      <Links />
      <CheckProgress inline />
      <div className="AppPage account">
        <div className='billingPanel'>
          <div className="massage">{msg}</div>
          {!transferToKKH &&
            <>
              <mui.ButtonGP
                color='primary'
                label='はい'
                addictionclass='accountButtonGP'
                onClick={clickHandler}
              />
              <mui.ButtonGP
                color='secondary'
                label='いいえ'
                addictionclass='accountButtonGP'
              />
            </>
          }

        </div>
        {/* <SalesAccordion /> */}
      </div>

    </>)
  }
  const FetchLoading = () =>(
    <LoadingSpinner/>
  )
  const TransferLoading = () =>(
    <div className="AppPage account">
      <div className="billingPanel">送信中です。</div>
    </div>
  )
  const TransferErr = () => (
    <div className="AppPage account">
      <div className="billingPanel">送信エラーが発生しています。</div>
    </div>
  )
  if (!fetchStatus.done) return <FetchLoading/>
  else if (!sendStatus.done) return <TransferButtons/>
  else if (sendStatus.loading) return <TransferLoading />
  else if (sendStatus.done && !sendStatus.err) return <TransferDone />
  else if (sendStatus.done && sendStatus.err) return <TransferErr />
}

export default Billing;