export const unitPrice = {
  放課後等デイサービス:{
    一級地: 11.20, 二級地: 10.96, 三級地: 10.90, 四級地: 10.72,
    五級地: 10.60, 六級地: 10.36, 七級地: 10.18, その他: 10.00,
  },
  児童発達支援:{
    一級地: 11.20, 二級地: 10.96, 三級地: 10.90, 四級地: 10.72,
    五級地: 10.60, 六級地: 10.36, 七級地: 10.18, その他: 10.00,
  },
}

export const chiikiKubun = {
  一級地: 11, 二級地: 12, 三級地: 13, 四級地: 14,
  五級地: 15, 六級地: 16, 七級地: 17, その他: 18,
}

export const serviceSyubetu = {
  放課後等デイサービス: 63, 児童発達支援: 61,
  '63': '放課後等デイサービス', '61': '児童発達支援',
}

// 決定サービスコード
export const ketteiScode = {
  放課後等デイサービス: 631000, 児童発達支援: 611000,
}

// 送迎をカウントするために送迎のサービスコードを定義
export const SOUGEY_SVC_CODE = ['636240', '636243', '636241', '636244',];
// 同じく家族連携
export const KATEI_SVC_CODE = ['635350', '635351',]
// 同じく訪問支援
export const HOUMON_SVC_CODE = ['635360', '635361',]
// 同じく医療連携
export const IREN_SVC_CODE = [
  '636230', '636231', '639992', '636233', '636234', '636235',
];


export const houdySirvice = [
  { s: '631111', v: 660, c: '放デイ１・' },
  { s: '631135', v: 561, c: '放デイ１・評減・' },
  { s: '631112', v: 462, c: '放デイ１・未計画・' },
  { s: '631136', v: 393, c: '放デイ１・未計画・評減・' },
  { s: '631137', v: 330, c: '放デイ１・未計画２・' },
  { s: '631138', v: 281, c: '放デイ１・未計画２・評減・' },
  { s: '631139', v: 655, c: '放デイ１・拘減・' },
  { s: '631140', v: 556, c: '放デイ１・評減・拘減・' },
  { s: '631141', v: 457, c: '放デイ１・未計画・拘減・' },
  { s: '631142', v: 388, c: '放デイ１・未計画・評減・拘減・' },
  { s: '631143', v: 325, c: '放デイ１・未計画２・拘減・' },
  { s: '631144', v: 276, c: '放デイ１・未計画２・評減・拘減・' },
  { s: '631113', v: 669, c: '放デイ１・有資格１・' },
  { s: '631145', v: 569, c: '放デイ１・有資格１・評減・' },
  { s: '631114', v: 468, c: '放デイ１・有資格１・未計画・' },
  { s: '631146', v: 398, c: '放デイ１・有資格１・未計画・評減・' },
  { s: '631147', v: 335, c: '放デイ１・有資格１・未計画２・' },
  { s: '631148', v: 285, c: '放デイ１・有資格１・未計画２・評減・' },
  { s: '631149', v: 664, c: '放デイ１・有資格１・拘減・' },
  { s: '631150', v: 564, c: '放デイ１・有資格１・評減・拘減・' },
  { s: '631151', v: 463, c: '放デイ１・有資格１・未計画・拘減・' },
  { s: '631152', v: 393, c: '放デイ１・有資格１・未計画・評減・拘減・' },
  { s: '631153', v: 330, c: '放デイ１・有資格１・未計画２・拘減・' },
  { s: '631154', v: 280, c: '放デイ１・有資格１・未計画２・評減・拘減・' },
  { s: '631121', v: 443, c: '放デイ２・' },
  { s: '631155', v: 377, c: '放デイ２・評減・' },
  { s: '631122', v: 310, c: '放デイ２・未計画・' },
  { s: '631156', v: 264, c: '放デイ２・未計画・評減・' },
  { s: '631157', v: 222, c: '放デイ２・未計画２・' },
  { s: '631158', v: 189, c: '放デイ２・未計画２・評減・' },
  { s: '631159', v: 438, c: '放デイ２・拘減・' },
  { s: '631160', v: 372, c: '放デイ２・評減・拘減・' },
  { s: '631161', v: 305, c: '放デイ２・未計画・拘減・' },
  { s: '631162', v: 259, c: '放デイ２・未計画・評減・拘減・' },
  { s: '631163', v: 217, c: '放デイ２・未計画２・拘減・' },
  { s: '631164', v: 184, c: '放デイ２・未計画２・評減・拘減・' },
  { s: '631123', v: 449, c: '放デイ２・有資格２・' },
  { s: '631165', v: 382, c: '放デイ２・有資格２・評減・' },
  { s: '631124', v: 314, c: '放デイ２・有資格２・未計画・' },
  { s: '631166', v: 267, c: '放デイ２・有資格２・未計画・評減・' },
  { s: '631167', v: 225, c: '放デイ２・有資格２・未計画２・' },
  { s: '631168', v: 191, c: '放デイ２・有資格２・未計画２・評減・' },
  { s: '631169', v: 444, c: '放デイ２・有資格２・拘減・' },
  { s: '631170', v: 377, c: '放デイ２・有資格２・評減・拘減・' },
  { s: '631171', v: 309, c: '放デイ２・有資格２・未計画・拘減・' },
  { s: '631172', v: 262, c: '放デイ２・有資格２・未計画・評減・拘減・' },
  { s: '631173', v: 220, c: '放デイ２・有資格２・未計画２・拘減・' },
  { s: '631174', v: 186, c: '放デイ２・有資格２・未計画２・評減・拘減・' },
  { s: '631131', v: 333, c: '放デイ３・' },
  { s: '631175', v: 283, c: '放デイ３・評減・' },
  { s: '631132', v: 233, c: '放デイ３・未計画・' },
  { s: '631176', v: 198, c: '放デイ３・未計画・評減・' },
  { s: '631177', v: 167, c: '放デイ３・未計画２・' },
  { s: '631178', v: 142, c: '放デイ３・未計画２・評減・' },
  { s: '631179', v: 328, c: '放デイ３・拘減・' },
  { s: '631180', v: 278, c: '放デイ３・評減・拘減・' },
  { s: '631181', v: 228, c: '放デイ３・未計画・拘減・' },
  { s: '631182', v: 193, c: '放デイ３・未計画・評減・拘減・' },
  { s: '631183', v: 162, c: '放デイ３・未計画２・拘減・' },
  { s: '631184', v: 137, c: '放デイ３・未計画２・評減・拘減・' },
  { s: '631133', v: 337, c: '放デイ３・有資格３・' },
  { s: '631185', v: 286, c: '放デイ３・有資格３・評減・' },
  { s: '631134', v: 236, c: '放デイ３・有資格３・未計画・' },
  { s: '631186', v: 201, c: '放デイ３・有資格３・未計画・評減・' },
  { s: '631187', v: 169, c: '放デイ３・有資格３・未計画２・' },
  { s: '631188', v: 144, c: '放デイ３・有資格３・未計画２・評減・' },
  { s: '631189', v: 332, c: '放デイ３・有資格３・拘減・' },
  { s: '631190', v: 281, c: '放デイ３・有資格３・評減・拘減・' },
  { s: '631191', v: 231, c: '放デイ３・有資格３・未計画・拘減・' },
  { s: '631192', v: 196, c: '放デイ３・有資格３・未計画・評減・拘減・' },
  { s: '631193', v: 164, c: '放デイ３・有資格３・未計画２・拘減・' },
  { s: '631194', v: 139, c: '放デイ３・有資格３・未計画２・評減・拘減・' },
  { s: '631271', v: 649, c: '放デイ２１・' },
  { s: '631272', v: 552, c: '放デイ２１・評減・' },
  { s: '631273', v: 454, c: '放デイ２１・未計画・' },
  { s: '631274', v: 386, c: '放デイ２１・未計画・評減・' },
  { s: '631275', v: 325, c: '放デイ２１・未計画２・' },
  { s: '631276', v: 276, c: '放デイ２１・未計画２・評減・' },
  { s: '631277', v: 644, c: '放デイ２１・拘減・' },
  { s: '631278', v: 547, c: '放デイ２１・評減・拘減・' },
  { s: '631279', v: 449, c: '放デイ２１・未計画・拘減・' },
  { s: '631280', v: 381, c: '放デイ２１・未計画・評減・拘減・' },
  { s: '631281', v: 320, c: '放デイ２１・未計画２・拘減・' },
  { s: '631282', v: 271, c: '放デイ２１・未計画２・評減・拘減・' },
  { s: '631283', v: 658, c: '放デイ２１・有資格７・' },
  { s: '631284', v: 559, c: '放デイ２１・有資格７・評減・' },
  { s: '631285', v: 461, c: '放デイ２１・有資格７・未計画・' },
  { s: '631286', v: 392, c: '放デイ２１・有資格７・未計画・評減・' },
  { s: '631287', v: 329, c: '放デイ２１・有資格７・未計画２・' },
  { s: '631288', v: 280, c: '放デイ２１・有資格７・未計画２・評減・' },
  { s: '631289', v: 653, c: '放デイ２１・有資格７・拘減・' },
  { s: '631290', v: 554, c: '放デイ２１・有資格７・評減・拘減・' },
  { s: '631291', v: 456, c: '放デイ２１・有資格７・未計画・拘減・' },
  { s: '631292', v: 387, c: '放デイ２１・有資格７・未計画・評減・拘減・' },
  { s: '631293', v: 324, c: '放デイ２１・有資格７・未計画２・拘減・' },
  { s: '631294', v: 275, c: '放デイ２１・有資格７・未計画２・評減・拘減・' },
  { s: '631381', v: 433, c: '放デイ２２・' },
  { s: '631382', v: 368, c: '放デイ２２・評減・' },
  { s: '631383', v: 303, c: '放デイ２２・未計画・' },
  { s: '631384', v: 258, c: '放デイ２２・未計画・評減・' },
  { s: '631385', v: 217, c: '放デイ２２・未計画２・' },
  { s: '631386', v: 184, c: '放デイ２２・未計画２・評減・' },
  { s: '631387', v: 428, c: '放デイ２２・拘減・' },
  { s: '631388', v: 363, c: '放デイ２２・評減・拘減・' },
  { s: '631389', v: 298, c: '放デイ２２・未計画・拘減・' },
  { s: '631390', v: 253, c: '放デイ２２・未計画・評減・拘減・' },
  { s: '631391', v: 212, c: '放デイ２２・未計画２・拘減・' },
  { s: '631392', v: 179, c: '放デイ２２・未計画２・評減・拘減・' },
  { s: '631393', v: 439, c: '放デイ２２・有資格８・' },
  { s: '631394', v: 373, c: '放デイ２２・有資格８・評減・' },
  { s: '631395', v: 307, c: '放デイ２２・有資格８・未計画・' },
  { s: '631396', v: 261, c: '放デイ２２・有資格８・未計画・評減・' },
  { s: '631397', v: 220, c: '放デイ２２・有資格８・未計画２・' },
  { s: '631398', v: 187, c: '放デイ２２・有資格８・未計画２・評減・' },
  { s: '631399', v: 434, c: '放デイ２２・有資格８・拘減・' },
  { s: '631400', v: 368, c: '放デイ２２・有資格８・評減・拘減・' },
  { s: '631401', v: 302, c: '放デイ２２・有資格８・未計画・拘減・' },
  { s: '631402', v: 256, c: '放デイ２２・有資格８・未計画・評減・拘減・' },
  { s: '631403', v: 215, c: '放デイ２２・有資格８・未計画２・拘減・' },
  { s: '631404', v: 182, c: '放デイ２２・有資格８・未計画２・評減・拘減・' },
  { s: '631481', v: 326, c: '放デイ２３・' },
  { s: '631482', v: 277, c: '放デイ２３・評減・' },
  { s: '631483', v: 228, c: '放デイ２３・未計画・' },
  { s: '631484', v: 194, c: '放デイ２３・未計画・評減・' },
  { s: '631485', v: 163, c: '放デイ２３・未計画２・' },
  { s: '631486', v: 139, c: '放デイ２３・未計画２・評減・' },
  { s: '631487', v: 321, c: '放デイ２３・拘減・' },
  { s: '631488', v: 272, c: '放デイ２３・評減・拘減・' },
  { s: '631489', v: 223, c: '放デイ２３・未計画・拘減・' },
  { s: '631490', v: 189, c: '放デイ２３・未計画・評減・拘減・' },
  { s: '631491', v: 158, c: '放デイ２３・未計画２・拘減・' },
  { s: '631492', v: 134, c: '放デイ２３・未計画２・評減・拘減・' },
  { s: '631493', v: 330, c: '放デイ２３・有資格９・' },
  { s: '631494', v: 281, c: '放デイ２３・有資格９・評減・' },
  { s: '631495', v: 231, c: '放デイ２３・有資格９・未計画・' },
  { s: '631496', v: 196, c: '放デイ２３・有資格９・未計画・評減・' },
  { s: '631497', v: 165, c: '放デイ２３・有資格９・未計画２・' },
  { s: '631498', v: 140, c: '放デイ２３・有資格９・未計画２・評減・' },
  { s: '631499', v: 325, c: '放デイ２３・有資格９・拘減・' },
  { s: '631500', v: 276, c: '放デイ２３・有資格９・評減・拘減・' },
  { s: '631501', v: 226, c: '放デイ２３・有資格９・未計画・拘減・' },
  { s: '631502', v: 191, c: '放デイ２３・有資格９・未計画・評減・拘減・' },
  { s: '631503', v: 160, c: '放デイ２３・有資格９・未計画２・拘減・' },
  { s: '631504', v: 135, c: '放デイ２３・有資格９・未計画２・評減・拘減・' },
  { s: '631511', v: 612, c: '放デイ２４・' },
  { s: '631512', v: 520, c: '放デイ２４・評減・' },
  { s: '631513', v: 428, c: '放デイ２４・未計画・' },
  { s: '631514', v: 364, c: '放デイ２４・未計画・評減・' },
  { s: '631515', v: 306, c: '放デイ２４・未計画２・' },
  { s: '631516', v: 260, c: '放デイ２４・未計画２・評減・' },
  { s: '631517', v: 607, c: '放デイ２４・拘減・' },
  { s: '631518', v: 515, c: '放デイ２４・評減・拘減・' },
  { s: '631519', v: 423, c: '放デイ２４・未計画・拘減・' },
  { s: '631520', v: 359, c: '放デイ２４・未計画・評減・拘減・' },
  { s: '631521', v: 301, c: '放デイ２４・未計画２・拘減・' },
  { s: '631522', v: 255, c: '放デイ２４・未計画２・評減・拘減・' },
  { s: '631523', v: 621, c: '放デイ２４・有資格１０・' },
  { s: '631524', v: 528, c: '放デイ２４・有資格１０・評減・' },
  { s: '631525', v: 435, c: '放デイ２４・有資格１０・未計画・' },
  { s: '631526', v: 370, c: '放デイ２４・有資格１０・未計画・評減・' },
  { s: '631527', v: 311, c: '放デイ２４・有資格１０・未計画２・' },
  { s: '631528', v: 264, c: '放デイ２４・有資格１０・未計画２・評減・' },
  { s: '631529', v: 616, c: '放デイ２４・有資格１０・拘減・' },
  { s: '631530', v: 523, c: '放デイ２４・有資格１０・評減・拘減・' },
  { s: '631531', v: 430, c: '放デイ２４・有資格１０・未計画・拘減・' },
  { s: '631532', v: 365, c: '放デイ２４・有資格１０・未計画・評減・拘減・' },
  { s: '631533', v: 306, c: '放デイ２４・有資格１０・未計画２・拘減・' },
  { s: '631534', v: 259, c: '放デイ２４・有資格１０・未計画２・評減・拘減・' },
  { s: '631541', v: 407, c: '放デイ２５・' },
  { s: '631542', v: 346, c: '放デイ２５・評減・' },
  { s: '631543', v: 285, c: '放デイ２５・未計画・' },
  { s: '631544', v: 242, c: '放デイ２５・未計画・評減・' },
  { s: '631545', v: 204, c: '放デイ２５・未計画２・' },
  { s: '631546', v: 173, c: '放デイ２５・未計画２・評減・' },
  { s: '631547', v: 402, c: '放デイ２５・拘減・' },
  { s: '631548', v: 341, c: '放デイ２５・評減・拘減・' },
  { s: '631549', v: 280, c: '放デイ２５・未計画・拘減・' },
  { s: '631550', v: 237, c: '放デイ２５・未計画・評減・拘減・' },
  { s: '631551', v: 199, c: '放デイ２５・未計画２・拘減・' },
  { s: '631552', v: 168, c: '放デイ２５・未計画２・評減・拘減・' },
  { s: '631553', v: 413, c: '放デイ２５・有資格１１・' },
  { s: '631554', v: 351, c: '放デイ２５・有資格１１・評減・' },
  { s: '631555', v: 289, c: '放デイ２５・有資格１１・未計画・' },
  { s: '631556', v: 246, c: '放デイ２５・有資格１１・未計画・評減・' },
  { s: '631557', v: 207, c: '放デイ２５・有資格１１・未計画２・' },
  { s: '631558', v: 176, c: '放デイ２５・有資格１１・未計画２・評減・' },
  { s: '631559', v: 408, c: '放デイ２５・有資格１１・拘減・' },
  { s: '631560', v: 346, c: '放デイ２５・有資格１１・評減・拘減・' },
  { s: '631561', v: 284, c: '放デイ２５・有資格１１・未計画・拘減・' },
  { s: '631562', v: 241, c: '放デイ２５・有資格１１・未計画・評減・拘減・' },
  { s: '631563', v: 202, c: '放デイ２５・有資格１１・未計画２・拘減・' },
  { s: '631564', v: 171, c: '放デイ２５・有資格１１・未計画２・評減・拘減・' },
  { s: '631571', v: 306, c: '放デイ２６・' },
  { s: '631572', v: 260, c: '放デイ２６・評減・' },
  { s: '631573', v: 214, c: '放デイ２６・未計画・' },
  { s: '631574', v: 182, c: '放デイ２６・未計画・評減・' },
  { s: '631575', v: 153, c: '放デイ２６・未計画２・' },
  { s: '631576', v: 130, c: '放デイ２６・未計画２・評減・' },
  { s: '631577', v: 301, c: '放デイ２６・拘減・' },
  { s: '631578', v: 255, c: '放デイ２６・評減・拘減・' },
  { s: '631579', v: 209, c: '放デイ２６・未計画・拘減・' },
  { s: '631580', v: 177, c: '放デイ２６・未計画・評減・拘減・' },
  { s: '631581', v: 148, c: '放デイ２６・未計画２・拘減・' },
  { s: '631582', v: 125, c: '放デイ２６・未計画２・評減・拘減・' },
  { s: '631583', v: 310, c: '放デイ２６・有資格１２・' },
  { s: '631584', v: 264, c: '放デイ２６・有資格１２・評減・' },
  { s: '631585', v: 217, c: '放デイ２６・有資格１２・未計画・' },
  { s: '631586', v: 184, c: '放デイ２６・有資格１２・未計画・評減・' },
  { s: '631587', v: 155, c: '放デイ２６・有資格１２・未計画２・' },
  { s: '631588', v: 132, c: '放デイ２６・有資格１２・未計画２・評減・' },
  { s: '631589', v: 305, c: '放デイ２６・有資格１２・拘減・' },
  { s: '631590', v: 259, c: '放デイ２６・有資格１２・評減・拘減・' },
  { s: '631591', v: 212, c: '放デイ２６・有資格１２・未計画・拘減・' },
  { s: '631592', v: 179, c: '放デイ２６・有資格１２・未計画・評減・拘減・' },
  { s: '631593', v: 150, c: '放デイ２６・有資格１２・未計画２・拘減・' },
  { s: '631594', v: 127, c: '放デイ２６・有資格１２・未計画２・評減・拘減・' },
  { s: '631601', v: 599, c: '放デイ２７・' },
  { s: '631602', v: 509, c: '放デイ２７・評減・' },
  { s: '631603', v: 419, c: '放デイ２７・未計画・' },
  { s: '631604', v: 356, c: '放デイ２７・未計画・評減・' },
  { s: '631605', v: 300, c: '放デイ２７・未計画２・' },
  { s: '631606', v: 255, c: '放デイ２７・未計画２・評減・' },
  { s: '631607', v: 594, c: '放デイ２７・拘減・' },
  { s: '631608', v: 504, c: '放デイ２７・評減・拘減・' },
  { s: '631609', v: 414, c: '放デイ２７・未計画・拘減・' },
  { s: '631610', v: 351, c: '放デイ２７・未計画・評減・拘減・' },
  { s: '631611', v: 295, c: '放デイ２７・未計画２・拘減・' },
  { s: '631612', v: 250, c: '放デイ２７・未計画２・評減・拘減・' },
  { s: '631613', v: 608, c: '放デイ２７・有資格１３・' },
  { s: '631614', v: 517, c: '放デイ２７・有資格１３・評減・' },
  { s: '631615', v: 426, c: '放デイ２７・有資格１３・未計画・' },
  { s: '631616', v: 362, c: '放デイ２７・有資格１３・未計画・評減・' },
  { s: '631617', v: 304, c: '放デイ２７・有資格１３・未計画２・' },
  { s: '631618', v: 258, c: '放デイ２７・有資格１３・未計画２・評減・' },
  { s: '631619', v: 603, c: '放デイ２７・有資格１３・拘減・' },
  { s: '631620', v: 512, c: '放デイ２７・有資格１３・評減・拘減・' },
  { s: '631621', v: 421, c: '放デイ２７・有資格１３・未計画・拘減・' },
  { s: '631622', v: 357, c: '放デイ２７・有資格１３・未計画・評減・拘減・' },
  { s: '631623', v: 299, c: '放デイ２７・有資格１３・未計画２・拘減・' },
  { s: '631624', v: 253, c: '放デイ２７・有資格１３・未計画２・評減・拘減・' },
  { s: '631631', v: 398, c: '放デイ２８・' },
  { s: '631632', v: 338, c: '放デイ２８・評減・' },
  { s: '631633', v: 279, c: '放デイ２８・未計画・' },
  { s: '631634', v: 237, c: '放デイ２８・未計画・評減・' },
  { s: '631635', v: 199, c: '放デイ２８・未計画２・' },
  { s: '631636', v: 169, c: '放デイ２８・未計画２・評減・' },
  { s: '631637', v: 393, c: '放デイ２８・拘減・' },
  { s: '631638', v: 333, c: '放デイ２８・評減・拘減・' },
  { s: '631639', v: 274, c: '放デイ２８・未計画・拘減・' },
  { s: '631640', v: 232, c: '放デイ２８・未計画・評減・拘減・' },
  { s: '631641', v: 194, c: '放デイ２８・未計画２・拘減・' },
  { s: '631642', v: 164, c: '放デイ２８・未計画２・評減・拘減・' },
  { s: '631643', v: 404, c: '放デイ２８・有資格１４・' },
  { s: '631644', v: 343, c: '放デイ２８・有資格１４・評減・' },
  { s: '631645', v: 283, c: '放デイ２８・有資格１４・未計画・' },
  { s: '631646', v: 241, c: '放デイ２８・有資格１４・未計画・評減・' },
  { s: '631647', v: 202, c: '放デイ２８・有資格１４・未計画２・' },
  { s: '631648', v: 172, c: '放デイ２８・有資格１４・未計画２・評減・' },
  { s: '631649', v: 399, c: '放デイ２８・有資格１４・拘減・' },
  { s: '631650', v: 338, c: '放デイ２８・有資格１４・評減・拘減・' },
  { s: '631651', v: 278, c: '放デイ２８・有資格１４・未計画・拘減・' },
  { s: '631652', v: 236, c: '放デイ２８・有資格１４・未計画・評減・拘減・' },
  { s: '631653', v: 197, c: '放デイ２８・有資格１４・未計画２・拘減・' },
  { s: '631654', v: 167, c: '放デイ２８・有資格１４・未計画２・評減・拘減・' },
  { s: '631661', v: 299, c: '放デイ２９・' },
  { s: '631662', v: 254, c: '放デイ２９・評減・' },
  { s: '631663', v: 209, c: '放デイ２９・未計画・' },
  { s: '631664', v: 178, c: '放デイ２９・未計画・評減・' },
  { s: '631665', v: 150, c: '放デイ２９・未計画２・' },
  { s: '631666', v: 128, c: '放デイ２９・未計画２・評減・' },
  { s: '631667', v: 294, c: '放デイ２９・拘減・' },
  { s: '631668', v: 249, c: '放デイ２９・評減・拘減・' },
  { s: '631669', v: 204, c: '放デイ２９・未計画・拘減・' },
  { s: '631670', v: 173, c: '放デイ２９・未計画・評減・拘減・' },
  { s: '631671', v: 145, c: '放デイ２９・未計画２・拘減・' },
  { s: '631672', v: 123, c: '放デイ２９・未計画２・評減・拘減・' },
  { s: '631673', v: 303, c: '放デイ２９・有資格１５・' },
  { s: '631674', v: 258, c: '放デイ２９・有資格１５・評減・' },
  { s: '631675', v: 212, c: '放デイ２９・有資格１５・未計画・' },
  { s: '631676', v: 180, c: '放デイ２９・有資格１５・未計画・評減・' },
  { s: '631677', v: 152, c: '放デイ２９・有資格１５・未計画２・' },
  { s: '631678', v: 129, c: '放デイ２９・有資格１５・未計画２・評減・' },
  { s: '631679', v: 298, c: '放デイ２９・有資格１５・拘減・' },
  { s: '631680', v: 253, c: '放デイ２９・有資格１５・評減・拘減・' },
  { s: '631681', v: 207, c: '放デイ２９・有資格１５・未計画・拘減・' },
  { s: '631682', v: 175, c: '放デイ２９・有資格１５・未計画・評減・拘減・' },
  { s: '631683', v: 147, c: '放デイ２９・有資格１５・未計画２・拘減・' },
  { s: '631684', v: 124, c: '放デイ２９・有資格１５・未計画２・評減・拘減・' },
  { s: '631211', v: 792, c: '放デイ４・' },
  { s: '631701', v: 673, c: '放デイ４・評減・' },
  { s: '631212', v: 554, c: '放デイ４・開減１・' },
  { s: '631702', v: 471, c: '放デイ４・開減１・評減・' },
  { s: '631215', v: 673, c: '放デイ４・開減２・' },
  { s: '631703', v: 572, c: '放デイ４・開減２・評減・' },
  { s: '631704', v: 787, c: '放デイ４・拘減・' },
  { s: '631705', v: 668, c: '放デイ４・評減・拘減・' },
  { s: '631706', v: 549, c: '放デイ４・開減１・拘減・' },
  { s: '631707', v: 466, c: '放デイ４・開減１・評減・拘減・' },
  { s: '631708', v: 668, c: '放デイ４・開減２・拘減・' },
  { s: '631709', v: 567, c: '放デイ４・開減２・評減・拘減・' },
  { s: '631213', v: 554, c: '放デイ４・未計画・' },
  { s: '631710', v: 471, c: '放デイ４・未計画・評減・' },
  { s: '631214', v: 388, c: '放デイ４・未計画・開減１・' },
  { s: '631711', v: 330, c: '放デイ４・未計画・開減１・評減・' },
  { s: '631216', v: 471, c: '放デイ４・未計画・開減２・' },
  { s: '631712', v: 400, c: '放デイ４・未計画・開減２・評減・' },
  { s: '631713', v: 549, c: '放デイ４・未計画・拘減・' },
  { s: '631714', v: 466, c: '放デイ４・未計画・評減・拘減・' },
  { s: '631715', v: 383, c: '放デイ４・未計画・開減１・拘減・' },
  { s: '631716', v: 325, c: '放デイ４・未計画・開減１・評減・拘減・' },
  { s: '631717', v: 466, c: '放デイ４・未計画・開減２・拘減・' },
  { s: '631718', v: 395, c: '放デイ４・未計画・開減２・評減・拘減・' },
  { s: '631719', v: 396, c: '放デイ４・未計画２・' },
  { s: '631720', v: 337, c: '放デイ４・未計画２・評減・' },
  { s: '631721', v: 277, c: '放デイ４・未計画２・開減１・' },
  { s: '631722', v: 235, c: '放デイ４・未計画２・開減１・評減・' },
  { s: '631723', v: 337, c: '放デイ４・未計画２・開減２・' },
  { s: '631724', v: 286, c: '放デイ４・未計画２・開減２・評減・' },
  { s: '631725', v: 391, c: '放デイ４・未計画２・拘減・' },
  { s: '631726', v: 332, c: '放デイ４・未計画２・評減・拘減・' },
  { s: '631727', v: 272, c: '放デイ４・未計画２・開減１・拘減・' },
  { s: '631728', v: 230, c: '放デイ４・未計画２・開減１・評減・拘減・' },
  { s: '631729', v: 332, c: '放デイ４・未計画２・開減２・拘減・' },
  { s: '631730', v: 281, c: '放デイ４・未計画２・開減２・評減・拘減・' },
  { s: '631217', v: 804, c: '放デイ４・有資格４・' },
  { s: '631731', v: 683, c: '放デイ４・有資格４・評減・' },
  { s: '631218', v: 563, c: '放デイ４・有資格４・開減１・' },
  { s: '631732', v: 479, c: '放デイ４・有資格４・開減１・評減・' },
  { s: '631219', v: 683, c: '放デイ４・有資格４・開減２・' },
  { s: '631733', v: 581, c: '放デイ４・有資格４・開減２・評減・' },
  { s: '631734', v: 799, c: '放デイ４・有資格４・拘減・' },
  { s: '631735', v: 678, c: '放デイ４・有資格４・評減・拘減・' },
  { s: '631736', v: 558, c: '放デイ４・有資格４・開減１・拘減・' },
  { s: '631737', v: 474, c: '放デイ４・有資格４・開減１・評減・拘減・' },
  { s: '631738', v: 678, c: '放デイ４・有資格４・開減２・拘減・' },
  { s: '631739', v: 576, c: '放デイ４・有資格４・開減２・評減・拘減・' },
  { s: '631220', v: 563, c: '放デイ４・有資格４・未計画・' },
  { s: '631740', v: 479, c: '放デイ４・有資格４・未計画・評減・' },
  { s: '631241', v: 394, c: '放デイ４・有資格４・未計画・開減１・' },
  { s: '631741', v: 335, c: '放デイ４・有資格４・未計画・開減１・評減・' },
  { s: '631242', v: 479, c: '放デイ４・有資格４・未計画・開減２・' },
  { s: '631742', v: 407, c: '放デイ４・有資格４・未計画・開減２・評減・' },
  { s: '631743', v: 558, c: '放デイ４・有資格４・未計画・拘減・' },
  { s: '631744', v: 474, c: '放デイ４・有資格４・未計画・評減・拘減・' },
  { s: '631745', v: 389, c: '放デイ４・有資格４・未計画・開減１・拘減・' },
  { s: '631746', v: 330, c: '放デイ４・有資格４・未計画・開減１・評減・拘減・' },
  { s: '631747', v: 474, c: '放デイ４・有資格４・未計画・開減２・拘減・' },
  { s: '631748', v: 402, c: '放デイ４・有資格４・未計画・開減２・評減・拘減・' },
  { s: '631749', v: 402, c: '放デイ４・有資格４・未計画２・' },
  { s: '631750', v: 342, c: '放デイ４・有資格４・未計画２・評減・' },
  { s: '631751', v: 281, c: '放デイ４・有資格４・未計画２・開減１・' },
  { s: '631752', v: 239, c: '放デイ４・有資格４・未計画２・開減１・評減・' },
  { s: '631753', v: 342, c: '放デイ４・有資格４・未計画２・開減２・' },
  { s: '631754', v: 291, c: '放デイ４・有資格４・未計画２・開減２・評減・' },
  { s: '631755', v: 397, c: '放デイ４・有資格４・未計画２・拘減・' },
  { s: '631756', v: 337, c: '放デイ４・有資格４・未計画２・評減・拘減・' },
  { s: '631757', v: 276, c: '放デイ４・有資格４・未計画２・開減１・拘減・' },
  { s: '631758', v: 234, c: '放デイ４・有資格４・未計画２・開減１・評減・拘減・' },
  { s: '631759', v: 337, c: '放デイ４・有資格４・未計画２・開減２・拘減・' },
  { s: '631760', v: 286, c: '放デイ４・有資格４・未計画２・開減２・評減・拘減・' },
  { s: '631221', v: 532, c: '放デイ５・' },
  { s: '631771', v: 452, c: '放デイ５・評減・' },
  { s: '631222', v: 372, c: '放デイ５・開減１・' },
  { s: '631772', v: 316, c: '放デイ５・開減１・評減・' },
  { s: '631225', v: 452, c: '放デイ５・開減２・' },
  { s: '631773', v: 384, c: '放デイ５・開減２・評減・' },
  { s: '631774', v: 527, c: '放デイ５・拘減・' },
  { s: '631775', v: 447, c: '放デイ５・評減・拘減・' },
  { s: '631776', v: 367, c: '放デイ５・開減１・拘減・' },
  { s: '631777', v: 311, c: '放デイ５・開減１・評減・拘減・' },
  { s: '631778', v: 447, c: '放デイ５・開減２・拘減・' },
  { s: '631779', v: 379, c: '放デイ５・開減２・評減・拘減・' },
  { s: '631223', v: 372, c: '放デイ５・未計画・' },
  { s: '631780', v: 316, c: '放デイ５・未計画・評減・' },
  { s: '631224', v: 260, c: '放デイ５・未計画・開減１・' },
  { s: '631781', v: 221, c: '放デイ５・未計画・開減１・評減・' },
  { s: '631226', v: 316, c: '放デイ５・未計画・開減２・' },
  { s: '631782', v: 269, c: '放デイ５・未計画・開減２・評減・' },
  { s: '631783', v: 367, c: '放デイ５・未計画・拘減・' },
  { s: '631784', v: 311, c: '放デイ５・未計画・評減・拘減・' },
  { s: '631785', v: 255, c: '放デイ５・未計画・開減１・拘減・' },
  { s: '631786', v: 216, c: '放デイ５・未計画・開減１・評減・拘減・' },
  { s: '631787', v: 311, c: '放デイ５・未計画・開減２・拘減・' },
  { s: '631788', v: 264, c: '放デイ５・未計画・開減２・評減・拘減・' },
  { s: '631789', v: 266, c: '放デイ５・未計画２・' },
  { s: '631790', v: 226, c: '放デイ５・未計画２・評減・' },
  { s: '631791', v: 186, c: '放デイ５・未計画２・開減１・' },
  { s: '631792', v: 158, c: '放デイ５・未計画２・開減１・評減・' },
  { s: '631793', v: 226, c: '放デイ５・未計画２・開減２・' },
  { s: '631794', v: 192, c: '放デイ５・未計画２・開減２・評減・' },
  { s: '631795', v: 261, c: '放デイ５・未計画２・拘減・' },
  { s: '631796', v: 221, c: '放デイ５・未計画２・評減・拘減・' },
  { s: '631797', v: 181, c: '放デイ５・未計画２・開減１・拘減・' },
  { s: '631798', v: 153, c: '放デイ５・未計画２・開減１・評減・拘減・' },
  { s: '631799', v: 221, c: '放デイ５・未計画２・開減２・拘減・' },
  { s: '631800', v: 187, c: '放デイ５・未計画２・開減２・評減・拘減・' },
  { s: '631227', v: 540, c: '放デイ５・有資格５・' },
  { s: '631801', v: 459, c: '放デイ５・有資格５・評減・' },
  { s: '631228', v: 378, c: '放デイ５・有資格５・開減１・' },
  { s: '631802', v: 321, c: '放デイ５・有資格５・開減１・評減・' },
  { s: '631229', v: 459, c: '放デイ５・有資格５・開減２・' },
  { s: '631803', v: 390, c: '放デイ５・有資格５・開減２・評減・' },
  { s: '631804', v: 535, c: '放デイ５・有資格５・拘減・' },
  { s: '631805', v: 454, c: '放デイ５・有資格５・評減・拘減・' },
  { s: '631806', v: 373, c: '放デイ５・有資格５・開減１・拘減・' },
  { s: '631807', v: 316, c: '放デイ５・有資格５・開減１・評減・拘減・' },
  { s: '631808', v: 454, c: '放デイ５・有資格５・開減２・拘減・' },
  { s: '631809', v: 385, c: '放デイ５・有資格５・開減２・評減・拘減・' },
  { s: '631230', v: 378, c: '放デイ５・有資格５・未計画・' },
  { s: '631810', v: 321, c: '放デイ５・有資格５・未計画・評減・' },
  { s: '631251', v: 265, c: '放デイ５・有資格５・未計画・開減１・' },
  { s: '631811', v: 225, c: '放デイ５・有資格５・未計画・開減１・評減・' },
  { s: '631252', v: 321, c: '放デイ５・有資格５・未計画・開減２・' },
  { s: '631812', v: 273, c: '放デイ５・有資格５・未計画・開減２・評減・' },
  { s: '631813', v: 373, c: '放デイ５・有資格５・未計画・拘減・' },
  { s: '631814', v: 316, c: '放デイ５・有資格５・未計画・評減・拘減・' },
  { s: '631815', v: 260, c: '放デイ５・有資格５・未計画・開減１・拘減・' },
  { s: '631816', v: 220, c: '放デイ５・有資格５・未計画・開減１・評減・拘減・' },
  { s: '631817', v: 316, c: '放デイ５・有資格５・未計画・開減２・拘減・' },
  { s: '631818', v: 268, c: '放デイ５・有資格５・未計画・開減２・評減・拘減・' },
  { s: '631819', v: 270, c: '放デイ５・有資格５・未計画２・' },
  { s: '631820', v: 230, c: '放デイ５・有資格５・未計画２・評減・' },
  { s: '631821', v: 189, c: '放デイ５・有資格５・未計画２・開減１・' },
  { s: '631822', v: 161, c: '放デイ５・有資格５・未計画２・開減１・評減・' },
  { s: '631823', v: 230, c: '放デイ５・有資格５・未計画２・開減２・' },
  { s: '631824', v: 196, c: '放デイ５・有資格５・未計画２・開減２・評減・' },
  { s: '631825', v: 265, c: '放デイ５・有資格５・未計画２・拘減・' },
  { s: '631826', v: 225, c: '放デイ５・有資格５・未計画２・評減・拘減・' },
  { s: '631827', v: 184, c: '放デイ５・有資格５・未計画２・開減１・拘減・' },
  { s: '631828', v: 156, c: '放デイ５・有資格５・未計画２・開減１・評減・拘減・' },
  { s: '631829', v: 225, c: '放デイ５・有資格５・未計画２・開減２・拘減・' },
  { s: '631830', v: 191, c: '放デイ５・有資格５・未計画２・開減２・評減・拘減・' },
  { s: '631231', v: 412, c: '放デイ６・' },
  { s: '631841', v: 350, c: '放デイ６・評減・' },
  { s: '631232', v: 288, c: '放デイ６・開減１・' },
  { s: '631842', v: 245, c: '放デイ６・開減１・評減・' },
  { s: '631235', v: 350, c: '放デイ６・開減２・' },
  { s: '631843', v: 298, c: '放デイ６・開減２・評減・' },
  { s: '631844', v: 407, c: '放デイ６・拘減・' },
  { s: '631845', v: 345, c: '放デイ６・評減・拘減・' },
  { s: '631846', v: 283, c: '放デイ６・開減１・拘減・' },
  { s: '631847', v: 240, c: '放デイ６・開減１・評減・拘減・' },
  { s: '631848', v: 345, c: '放デイ６・開減２・拘減・' },
  { s: '631849', v: 293, c: '放デイ６・開減２・評減・拘減・' },
  { s: '631233', v: 288, c: '放デイ６・未計画・' },
  { s: '631850', v: 245, c: '放デイ６・未計画・評減・' },
  { s: '631234', v: 202, c: '放デイ６・未計画・開減１・' },
  { s: '631851', v: 172, c: '放デイ６・未計画・開減１・評減・' },
  { s: '631236', v: 245, c: '放デイ６・未計画・開減２・' },
  { s: '631852', v: 208, c: '放デイ６・未計画・開減２・評減・' },
  { s: '631853', v: 283, c: '放デイ６・未計画・拘減・' },
  { s: '631854', v: 240, c: '放デイ６・未計画・評減・拘減・' },
  { s: '631855', v: 197, c: '放デイ６・未計画・開減１・拘減・' },
  { s: '631856', v: 167, c: '放デイ６・未計画・開減１・評減・拘減・' },
  { s: '631857', v: 240, c: '放デイ６・未計画・開減２・拘減・' },
  { s: '631858', v: 203, c: '放デイ６・未計画・開減２・評減・拘減・' },
  { s: '631859', v: 206, c: '放デイ６・未計画２・' },
  { s: '631860', v: 175, c: '放デイ６・未計画２・評減・' },
  { s: '631861', v: 144, c: '放デイ６・未計画２・開減１・' },
  { s: '631862', v: 122, c: '放デイ６・未計画２・開減１・評減・' },
  { s: '631863', v: 175, c: '放デイ６・未計画２・開減２・' },
  { s: '631864', v: 149, c: '放デイ６・未計画２・開減２・評減・' },
  { s: '631865', v: 201, c: '放デイ６・未計画２・拘減・' },
  { s: '631866', v: 170, c: '放デイ６・未計画２・評減・拘減・' },
  { s: '631867', v: 139, c: '放デイ６・未計画２・開減１・拘減・' },
  { s: '631868', v: 117, c: '放デイ６・未計画２・開減１・評減・拘減・' },
  { s: '631869', v: 170, c: '放デイ６・未計画２・開減２・拘減・' },
  { s: '631870', v: 144, c: '放デイ６・未計画２・開減２・評減・拘減・' },
  { s: '631237', v: 418, c: '放デイ６・有資格６・' },
  { s: '631871', v: 355, c: '放デイ６・有資格６・評減・' },
  { s: '631238', v: 293, c: '放デイ６・有資格６・開減１・' },
  { s: '631872', v: 249, c: '放デイ６・有資格６・開減１・評減・' },
  { s: '631239', v: 355, c: '放デイ６・有資格６・開減２・' },
  { s: '631873', v: 302, c: '放デイ６・有資格６・開減２・評減・' },
  { s: '631874', v: 413, c: '放デイ６・有資格６・拘減・' },
  { s: '631875', v: 350, c: '放デイ６・有資格６・評減・拘減・' },
  { s: '631876', v: 288, c: '放デイ６・有資格６・開減１・拘減・' },
  { s: '631877', v: 244, c: '放デイ６・有資格６・開減１・評減・拘減・' },
  { s: '631878', v: 350, c: '放デイ６・有資格６・開減２・拘減・' },
  { s: '631879', v: 297, c: '放デイ６・有資格６・開減２・評減・拘減・' },
  { s: '631240', v: 293, c: '放デイ６・有資格６・未計画・' },
  { s: '631880', v: 249, c: '放デイ６・有資格６・未計画・評減・' },
  { s: '631261', v: 205, c: '放デイ６・有資格６・未計画・開減１・' },
  { s: '631881', v: 174, c: '放デイ６・有資格６・未計画・開減１・評減・' },
  { s: '631262', v: 249, c: '放デイ６・有資格６・未計画・開減２・' },
  { s: '631882', v: 212, c: '放デイ６・有資格６・未計画・開減２・評減・' },
  { s: '631883', v: 288, c: '放デイ６・有資格６・未計画・拘減・' },
  { s: '631884', v: 244, c: '放デイ６・有資格６・未計画・評減・拘減・' },
  { s: '631885', v: 200, c: '放デイ６・有資格６・未計画・開減１・拘減・' },
  { s: '631886', v: 169, c: '放デイ６・有資格６・未計画・開減１・評減・拘減・' },
  { s: '631887', v: 244, c: '放デイ６・有資格６・未計画・開減２・拘減・' },
  { s: '631888', v: 207, c: '放デイ６・有資格６・未計画・開減２・評減・拘減・' },
  { s: '631889', v: 209, c: '放デイ６・有資格６・未計画２・' },
  { s: '631890', v: 178, c: '放デイ６・有資格６・未計画２・評減・' },
  { s: '631891', v: 146, c: '放デイ６・有資格６・未計画２・開減１・' },
  { s: '631892', v: 124, c: '放デイ６・有資格６・未計画２・開減１・評減・' },
  { s: '631893', v: 178, c: '放デイ６・有資格６・未計画２・開減２・' },
  { s: '631894', v: 151, c: '放デイ６・有資格６・未計画２・開減２・評減・' },
  { s: '631895', v: 204, c: '放デイ６・有資格６・未計画２・拘減・' },
  { s: '631896', v: 173, c: '放デイ６・有資格６・未計画２・評減・拘減・' },
  { s: '631897', v: 141, c: '放デイ６・有資格６・未計画２・開減１・拘減・' },
  { s: '631898', v: 119, c: '放デイ６・有資格６・未計画２・開減１・評減・拘減・' },
  { s: '631899', v: 173, c: '放デイ６・有資格６・未計画２・開減２・拘減・' },
  { s: '631900', v: 146, c: '放デイ６・有資格６・未計画２・開減２・評減・拘減・' },
  { s: '631901', v: 730, c: '放デイ３０・' },
  { s: '631902', v: 621, c: '放デイ３０・評減・' },
  { s: '631903', v: 511, c: '放デイ３０・開減１・' },
  { s: '631904', v: 434, c: '放デイ３０・開減１・評減・' },
  { s: '631905', v: 621, c: '放デイ３０・開減２・' },
  { s: '631906', v: 528, c: '放デイ３０・開減２・評減・' },
  { s: '631907', v: 725, c: '放デイ３０・拘減・' },
  { s: '631908', v: 616, c: '放デイ３０・評減・拘減・' },
  { s: '631909', v: 506, c: '放デイ３０・開減１・拘減・' },
  { s: '631910', v: 429, c: '放デイ３０・開減１・評減・拘減・' },
  { s: '631911', v: 616, c: '放デイ３０・開減２・拘減・' },
  { s: '631912', v: 523, c: '放デイ３０・開減２・評減・拘減・' },
  { s: '631913', v: 511, c: '放デイ３０・未計画・' },
  { s: '631914', v: 434, c: '放デイ３０・未計画・評減・' },
  { s: '631915', v: 358, c: '放デイ３０・未計画・開減１・' },
  { s: '631916', v: 304, c: '放デイ３０・未計画・開減１・評減・' },
  { s: '631917', v: 434, c: '放デイ３０・未計画・開減２・' },
  { s: '631918', v: 369, c: '放デイ３０・未計画・開減２・評減・' },
  { s: '631919', v: 506, c: '放デイ３０・未計画・拘減・' },
  { s: '631920', v: 429, c: '放デイ３０・未計画・評減・拘減・' },
  { s: '631921', v: 353, c: '放デイ３０・未計画・開減１・拘減・' },
  { s: '631922', v: 299, c: '放デイ３０・未計画・開減１・評減・拘減・' },
  { s: '631923', v: 429, c: '放デイ３０・未計画・開減２・拘減・' },
  { s: '631924', v: 364, c: '放デイ３０・未計画・開減２・評減・拘減・' },
  { s: '631925', v: 365, c: '放デイ３０・未計画２・' },
  { s: '631926', v: 310, c: '放デイ３０・未計画２・評減・' },
  { s: '631927', v: 256, c: '放デイ３０・未計画２・開減１・' },
  { s: '631928', v: 218, c: '放デイ３０・未計画２・開減１・評減・' },
  { s: '631929', v: 310, c: '放デイ３０・未計画２・開減２・' },
  { s: '631930', v: 264, c: '放デイ３０・未計画２・開減２・評減・' },
  { s: '631931', v: 360, c: '放デイ３０・未計画２・拘減・' },
  { s: '631932', v: 305, c: '放デイ３０・未計画２・評減・拘減・' },
  { s: '631933', v: 251, c: '放デイ３０・未計画２・開減１・拘減・' },
  { s: '631934', v: 213, c: '放デイ３０・未計画２・開減１・評減・拘減・' },
  { s: '631935', v: 305, c: '放デイ３０・未計画２・開減２・拘減・' },
  { s: '631936', v: 259, c: '放デイ３０・未計画２・開減２・評減・拘減・' },
  { s: '631937', v: 742, c: '放デイ３０・有資格１６・' },
  { s: '631938', v: 631, c: '放デイ３０・有資格１６・評減・' },
  { s: '631939', v: 519, c: '放デイ３０・有資格１６・開減１・' },
  { s: '631940', v: 441, c: '放デイ３０・有資格１６・開減１・評減・' },
  { s: '631941', v: 631, c: '放デイ３０・有資格１６・開減２・' },
  { s: '631942', v: 536, c: '放デイ３０・有資格１６・開減２・評減・' },
  { s: '631943', v: 737, c: '放デイ３０・有資格１６・拘減・' },
  { s: '631944', v: 626, c: '放デイ３０・有資格１６・評減・拘減・' },
  { s: '631945', v: 514, c: '放デイ３０・有資格１６・開減１・拘減・' },
  { s: '631946', v: 436, c: '放デイ３０・有資格１６・開減１・評減・拘減・' },
  { s: '631947', v: 626, c: '放デイ３０・有資格１６・開減２・拘減・' },
  { s: '631948', v: 531, c: '放デイ３０・有資格１６・開減２・評減・拘減・' },
  { s: '631949', v: 519, c: '放デイ３０・有資格１６・未計画・' },
  { s: '631950', v: 441, c: '放デイ３０・有資格１６・未計画・評減・' },
  { s: '631951', v: 363, c: '放デイ３０・有資格１６・未計画・開減１・' },
  { s: '631952', v: 309, c: '放デイ３０・有資格１６・未計画・開減１・評減・' },
  { s: '631953', v: 441, c: '放デイ３０・有資格１６・未計画・開減２・' },
  { s: '631954', v: 375, c: '放デイ３０・有資格１６・未計画・開減２・評減・' },
  { s: '631955', v: 514, c: '放デイ３０・有資格１６・未計画・拘減・' },
  { s: '631956', v: 436, c: '放デイ３０・有資格１６・未計画・評減・拘減・' },
  { s: '631957', v: 358, c: '放デイ３０・有資格１６・未計画・開減１・拘減・' },
  { s: '631958', v: 304, c: '放デイ３０・有資格１６・未計画・開減１・評減・拘減・' },
  { s: '631959', v: 436, c: '放デイ３０・有資格１６・未計画・開減２・拘減・' },
  { s: '631960', v: 370, c: '放デイ３０・有資格１６・未計画・開減２・評減・拘減・' },
  { s: '631961', v: 371, c: '放デイ３０・有資格１６・未計画２・' },
  { s: '631962', v: 315, c: '放デイ３０・有資格１６・未計画２・評減・' },
  { s: '631963', v: 260, c: '放デイ３０・有資格１６・未計画２・開減１・' },
  { s: '631964', v: 221, c: '放デイ３０・有資格１６・未計画２・開減１・評減・' },
  { s: '631965', v: 315, c: '放デイ３０・有資格１６・未計画２・開減２・' },
  { s: '631966', v: 268, c: '放デイ３０・有資格１６・未計画２・開減２・評減・' },
  { s: '631967', v: 366, c: '放デイ３０・有資格１６・未計画２・拘減・' },
  { s: '631968', v: 310, c: '放デイ３０・有資格１６・未計画２・評減・拘減・' },
  { s: '631969', v: 255, c: '放デイ３０・有資格１６・未計画２・開減１・拘減・' },
  { s: '631970', v: 216, c: '放デイ３０・有資格１６・未計画２・開減１・評減・拘減・' },
  { s: '631971', v: 310, c: '放デイ３０・有資格１６・未計画２・開減２・拘減・' },
  { s: '631972', v: 263, c: '放デイ３０・有資格１６・未計画２・開減２・評減・拘減・' },
  { s: '631981', v: 486, c: '放デイ３１・' },
  { s: '631982', v: 413, c: '放デイ３１・評減・' },
  { s: '631983', v: 340, c: '放デイ３１・開減１・' },
  { s: '631984', v: 289, c: '放デイ３１・開減１・評減・' },
  { s: '631985', v: 413, c: '放デイ３１・開減２・' },
  { s: '631986', v: 351, c: '放デイ３１・開減２・評減・' },
  { s: '631987', v: 481, c: '放デイ３１・拘減・' },
  { s: '631988', v: 408, c: '放デイ３１・評減・拘減・' },
  { s: '631989', v: 335, c: '放デイ３１・開減１・拘減・' },
  { s: '631990', v: 284, c: '放デイ３１・開減１・評減・拘減・' },
  { s: '631991', v: 408, c: '放デイ３１・開減２・拘減・' },
  { s: '631992', v: 346, c: '放デイ３１・開減２・評減・拘減・' },
  { s: '631993', v: 340, c: '放デイ３１・未計画・' },
  { s: '631994', v: 289, c: '放デイ３１・未計画・評減・' },
  { s: '631995', v: 238, c: '放デイ３１・未計画・開減１・' },
  { s: '631996', v: 202, c: '放デイ３１・未計画・開減１・評減・' },
  { s: '631997', v: 289, c: '放デイ３１・未計画・開減２・' },
  { s: '631998', v: 246, c: '放デイ３１・未計画・開減２・評減・' },
  { s: '631999', v: 335, c: '放デイ３１・未計画・拘減・' },
  { s: '632000', v: 284, c: '放デイ３１・未計画・評減・拘減・' },
  { s: '632001', v: 233, c: '放デイ３１・未計画・開減１・拘減・' },
  { s: '632002', v: 197, c: '放デイ３１・未計画・開減１・評減・拘減・' },
  { s: '632003', v: 284, c: '放デイ３１・未計画・開減２・拘減・' },
  { s: '632004', v: 241, c: '放デイ３１・未計画・開減２・評減・拘減・' },
  { s: '632005', v: 243, c: '放デイ３１・未計画２・' },
  { s: '632006', v: 207, c: '放デイ３１・未計画２・評減・' },
  { s: '632007', v: 170, c: '放デイ３１・未計画２・開減１・' },
  { s: '632008', v: 145, c: '放デイ３１・未計画２・開減１・評減・' },
  { s: '632009', v: 207, c: '放デイ３１・未計画２・開減２・' },
  { s: '632010', v: 176, c: '放デイ３１・未計画２・開減２・評減・' },
  { s: '632011', v: 238, c: '放デイ３１・未計画２・拘減・' },
  { s: '632012', v: 202, c: '放デイ３１・未計画２・評減・拘減・' },
  { s: '632013', v: 165, c: '放デイ３１・未計画２・開減１・拘減・' },
  { s: '632014', v: 140, c: '放デイ３１・未計画２・開減１・評減・拘減・' },
  { s: '632015', v: 202, c: '放デイ３１・未計画２・開減２・拘減・' },
  { s: '632016', v: 171, c: '放デイ３１・未計画２・開減２・評減・拘減・' },
  { s: '632017', v: 494, c: '放デイ３１・有資格１７・' },
  { s: '632018', v: 420, c: '放デイ３１・有資格１７・評減・' },
  { s: '632019', v: 346, c: '放デイ３１・有資格１７・開減１・' },
  { s: '632020', v: 294, c: '放デイ３１・有資格１７・開減１・評減・' },
  { s: '632021', v: 420, c: '放デイ３１・有資格１７・開減２・' },
  { s: '632022', v: 357, c: '放デイ３１・有資格１７・開減２・評減・' },
  { s: '632023', v: 489, c: '放デイ３１・有資格１７・拘減・' },
  { s: '632024', v: 415, c: '放デイ３１・有資格１７・評減・拘減・' },
  { s: '632025', v: 341, c: '放デイ３１・有資格１７・開減１・拘減・' },
  { s: '632026', v: 289, c: '放デイ３１・有資格１７・開減１・評減・拘減・' },
  { s: '632027', v: 415, c: '放デイ３１・有資格１７・開減２・拘減・' },
  { s: '632028', v: 352, c: '放デイ３１・有資格１７・開減２・評減・拘減・' },
  { s: '632029', v: 346, c: '放デイ３１・有資格１７・未計画・' },
  { s: '632030', v: 294, c: '放デイ３１・有資格１７・未計画・評減・' },
  { s: '632031', v: 242, c: '放デイ３１・有資格１７・未計画・開減１・' },
  { s: '632032', v: 206, c: '放デイ３１・有資格１７・未計画・開減１・評減・' },
  { s: '632033', v: 294, c: '放デイ３１・有資格１７・未計画・開減２・' },
  { s: '632034', v: 250, c: '放デイ３１・有資格１７・未計画・開減２・評減・' },
  { s: '632035', v: 341, c: '放デイ３１・有資格１７・未計画・拘減・' },
  { s: '632036', v: 289, c: '放デイ３１・有資格１７・未計画・評減・拘減・' },
  { s: '632037', v: 237, c: '放デイ３１・有資格１７・未計画・開減１・拘減・' },
  { s: '632038', v: 201, c: '放デイ３１・有資格１７・未計画・開減１・評減・拘減・' },
  { s: '632039', v: 289, c: '放デイ３１・有資格１７・未計画・開減２・拘減・' },
  { s: '632040', v: 245, c: '放デイ３１・有資格１７・未計画・開減２・評減・拘減・' },
  { s: '632041', v: 247, c: '放デイ３１・有資格１７・未計画２・' },
  { s: '632042', v: 210, c: '放デイ３１・有資格１７・未計画２・評減・' },
  { s: '632043', v: 173, c: '放デイ３１・有資格１７・未計画２・開減１・' },
  { s: '632044', v: 147, c: '放デイ３１・有資格１７・未計画２・開減１・評減・' },
  { s: '632045', v: 210, c: '放デイ３１・有資格１７・未計画２・開減２・' },
  { s: '632046', v: 179, c: '放デイ３１・有資格１７・未計画２・開減２・評減・' },
  { s: '632047', v: 242, c: '放デイ３１・有資格１７・未計画２・拘減・' },
  { s: '632048', v: 205, c: '放デイ３１・有資格１７・未計画２・評減・拘減・' },
  { s: '632049', v: 168, c: '放デイ３１・有資格１７・未計画２・開減１・拘減・' },
  { s: '632050', v: 142, c: '放デイ３１・有資格１７・未計画２・開減１・評減・拘減・' },
  { s: '632051', v: 205, c: '放デイ３１・有資格１７・未計画２・開減２・拘減・' },
  { s: '632052', v: 174, c: '放デイ３１・有資格１７・未計画２・開減２・評減・拘減・' },
  { s: '632061', v: 376, c: '放デイ３２・' },
  { s: '632062', v: 320, c: '放デイ３２・評減・' },
  { s: '632063', v: 263, c: '放デイ３２・開減１・' },
  { s: '632064', v: 224, c: '放デイ３２・開減１・評減・' },
  { s: '632065', v: 320, c: '放デイ３２・開減２・' },
  { s: '632066', v: 272, c: '放デイ３２・開減２・評減・' },
  { s: '632067', v: 371, c: '放デイ３２・拘減・' },
  { s: '632068', v: 315, c: '放デイ３２・評減・拘減・' },
  { s: '632069', v: 258, c: '放デイ３２・開減１・拘減・' },
  { s: '632070', v: 219, c: '放デイ３２・開減１・評減・拘減・' },
  { s: '632071', v: 315, c: '放デイ３２・開減２・拘減・' },
  { s: '632072', v: 267, c: '放デイ３２・開減２・評減・拘減・' },
  { s: '632073', v: 263, c: '放デイ３２・未計画・' },
  { s: '632074', v: 224, c: '放デイ３２・未計画・評減・' },
  { s: '632075', v: 184, c: '放デイ３２・未計画・開減１・' },
  { s: '632076', v: 156, c: '放デイ３２・未計画・開減１・評減・' },
  { s: '632077', v: 224, c: '放デイ３２・未計画・開減２・' },
  { s: '632078', v: 190, c: '放デイ３２・未計画・開減２・評減・' },
  { s: '632079', v: 258, c: '放デイ３２・未計画・拘減・' },
  { s: '632080', v: 219, c: '放デイ３２・未計画・評減・拘減・' },
  { s: '632081', v: 179, c: '放デイ３２・未計画・開減１・拘減・' },
  { s: '632082', v: 151, c: '放デイ３２・未計画・開減１・評減・拘減・' },
  { s: '632083', v: 219, c: '放デイ３２・未計画・開減２・拘減・' },
  { s: '632084', v: 185, c: '放デイ３２・未計画・開減２・評減・拘減・' },
  { s: '632085', v: 188, c: '放デイ３２・未計画２・' },
  { s: '632086', v: 160, c: '放デイ３２・未計画２・評減・' },
  { s: '632087', v: 132, c: '放デイ３２・未計画２・開減１・' },
  { s: '632088', v: 112, c: '放デイ３２・未計画２・開減１・評減・' },
  { s: '632089', v: 160, c: '放デイ３２・未計画２・開減２・' },
  { s: '632090', v: 136, c: '放デイ３２・未計画２・開減２・評減・' },
  { s: '632091', v: 183, c: '放デイ３２・未計画２・拘減・' },
  { s: '632092', v: 155, c: '放デイ３２・未計画２・評減・拘減・' },
  { s: '632093', v: 127, c: '放デイ３２・未計画２・開減１・拘減・' },
  { s: '632094', v: 107, c: '放デイ３２・未計画２・開減１・評減・拘減・' },
  { s: '632095', v: 155, c: '放デイ３２・未計画２・開減２・拘減・' },
  { s: '632096', v: 131, c: '放デイ３２・未計画２・開減２・評減・拘減・' },
  { s: '632097', v: 382, c: '放デイ３２・有資格１８・' },
  { s: '632098', v: 325, c: '放デイ３２・有資格１８・評減・' },
  { s: '632099', v: 267, c: '放デイ３２・有資格１８・開減１・' },
  { s: '632100', v: 227, c: '放デイ３２・有資格１８・開減１・評減・' },
  { s: '632101', v: 325, c: '放デイ３２・有資格１８・開減２・' },
  { s: '632102', v: 276, c: '放デイ３２・有資格１８・開減２・評減・' },
  { s: '632103', v: 377, c: '放デイ３２・有資格１８・拘減・' },
  { s: '632104', v: 320, c: '放デイ３２・有資格１８・評減・拘減・' },
  { s: '632105', v: 262, c: '放デイ３２・有資格１８・開減１・拘減・' },
  { s: '632106', v: 222, c: '放デイ３２・有資格１８・開減１・評減・拘減・' },
  { s: '632107', v: 320, c: '放デイ３２・有資格１８・開減２・拘減・' },
  { s: '632108', v: 271, c: '放デイ３２・有資格１８・開減２・評減・拘減・' },
  { s: '632109', v: 267, c: '放デイ３２・有資格１８・未計画・' },
  { s: '632110', v: 227, c: '放デイ３２・有資格１８・未計画・評減・' },
  { s: '632111', v: 187, c: '放デイ３２・有資格１８・未計画・開減１・' },
  { s: '632112', v: 159, c: '放デイ３２・有資格１８・未計画・開減１・評減・' },
  { s: '632113', v: 227, c: '放デイ３２・有資格１８・未計画・開減２・' },
  { s: '632114', v: 193, c: '放デイ３２・有資格１８・未計画・開減２・評減・' },
  { s: '632115', v: 262, c: '放デイ３２・有資格１８・未計画・拘減・' },
  { s: '632116', v: 222, c: '放デイ３２・有資格１８・未計画・評減・拘減・' },
  { s: '632117', v: 182, c: '放デイ３２・有資格１８・未計画・開減１・拘減・' },
  { s: '632118', v: 154, c: '放デイ３２・有資格１８・未計画・開減１・評減・拘減・' },
  { s: '632119', v: 222, c: '放デイ３２・有資格１８・未計画・開減２・拘減・' },
  { s: '632120', v: 188, c: '放デイ３２・有資格１８・未計画・開減２・評減・拘減・' },
  { s: '632121', v: 191, c: '放デイ３２・有資格１８・未計画２・' },
  { s: '632122', v: 162, c: '放デイ３２・有資格１８・未計画２・評減・' },
  { s: '632123', v: 134, c: '放デイ３２・有資格１８・未計画２・開減１・' },
  { s: '632124', v: 114, c: '放デイ３２・有資格１８・未計画２・開減１・評減・' },
  { s: '632125', v: 162, c: '放デイ３２・有資格１８・未計画２・開減２・' },
  { s: '632126', v: 138, c: '放デイ３２・有資格１８・未計画２・開減２・評減・' },
  { s: '632127', v: 186, c: '放デイ３２・有資格１８・未計画２・拘減・' },
  { s: '632128', v: 157, c: '放デイ３２・有資格１８・未計画２・評減・拘減・' },
  { s: '632129', v: 129, c: '放デイ３２・有資格１８・未計画２・開減１・拘減・' },
  { s: '632130', v: 109, c: '放デイ３２・有資格１８・未計画２・開減１・評減・拘減・' },
  { s: '632131', v: 157, c: '放デイ３２・有資格１８・未計画２・開減２・拘減・' },
  { s: '632132', v: 133, c: '放デイ３２・有資格１８・未計画２・開減２・評減・拘減・' },
  { s: '631311', v: 1754, c: '放デイ７・' },
  { s: '632141', v: 1491, c: '放デイ７・評減・' },
  { s: '631312', v: 1228, c: '放デイ７・未計画・' },
  { s: '632142', v: 1044, c: '放デイ７・未計画・評減・' },
  { s: '632143', v: 877, c: '放デイ７・未計画２・' },
  { s: '632144', v: 745, c: '放デイ７・未計画２・評減・' },
  { s: '632145', v: 1749, c: '放デイ７・拘減・' },
  { s: '632146', v: 1486, c: '放デイ７・評減・拘減・' },
  { s: '632147', v: 1223, c: '放デイ７・未計画・拘減・' },
  { s: '632148', v: 1039, c: '放デイ７・未計画・評減・拘減・' },
  { s: '632149', v: 872, c: '放デイ７・未計画２・拘減・' },
  { s: '632150', v: 740, c: '放デイ７・未計画２・評減・拘減・' },
  { s: '631341', v: 1466, c: '放デイ８・' },
  { s: '632151', v: 1246, c: '放デイ８・評減・' },
  { s: '631342', v: 1026, c: '放デイ８・未計画・' },
  { s: '632152', v: 872, c: '放デイ８・未計画・評減・' },
  { s: '632153', v: 733, c: '放デイ８・未計画２・' },
  { s: '632154', v: 623, c: '放デイ８・未計画２・評減・' },
  { s: '632155', v: 1461, c: '放デイ８・拘減・' },
  { s: '632156', v: 1241, c: '放デイ８・評減・拘減・' },
  { s: '632157', v: 1021, c: '放デイ８・未計画・拘減・' },
  { s: '632158', v: 867, c: '放デイ８・未計画・評減・拘減・' },
  { s: '632159', v: 728, c: '放デイ８・未計画２・拘減・' },
  { s: '632160', v: 618, c: '放デイ８・未計画２・評減・拘減・' },
  { s: '631351', v: 1262, c: '放デイ９・' },
  { s: '632161', v: 1073, c: '放デイ９・評減・' },
  { s: '631352', v: 883, c: '放デイ９・未計画・' },
  { s: '632162', v: 751, c: '放デイ９・未計画・評減・' },
  { s: '632163', v: 631, c: '放デイ９・未計画２・' },
  { s: '632164', v: 536, c: '放デイ９・未計画２・評減・' },
  { s: '632165', v: 1257, c: '放デイ９・拘減・' },
  { s: '632166', v: 1068, c: '放デイ９・評減・拘減・' },
  { s: '632167', v: 878, c: '放デイ９・未計画・拘減・' },
  { s: '632168', v: 746, c: '放デイ９・未計画・評減・拘減・' },
  { s: '632169', v: 626, c: '放デイ９・未計画２・拘減・' },
  { s: '632170', v: 531, c: '放デイ９・未計画２・評減・拘減・' },
  { s: '631361', v: 1107, c: '放デイ１０・' },
  { s: '632171', v: 941, c: '放デイ１０・評減・' },
  { s: '631362', v: 775, c: '放デイ１０・未計画・' },
  { s: '632172', v: 659, c: '放デイ１０・未計画・評減・' },
  { s: '632173', v: 554, c: '放デイ１０・未計画２・' },
  { s: '632174', v: 471, c: '放デイ１０・未計画２・評減・' },
  { s: '632175', v: 1102, c: '放デイ１０・拘減・' },
  { s: '632176', v: 936, c: '放デイ１０・評減・拘減・' },
  { s: '632177', v: 770, c: '放デイ１０・未計画・拘減・' },
  { s: '632178', v: 654, c: '放デイ１０・未計画・評減・拘減・' },
  { s: '632179', v: 549, c: '放デイ１０・未計画２・拘減・' },
  { s: '632180', v: 466, c: '放デイ１０・未計画２・評減・拘減・' },
  { s: '631371', v: 988, c: '放デイ１１・' },
  { s: '632181', v: 840, c: '放デイ１１・評減・' },
  { s: '631372', v: 692, c: '放デイ１１・未計画・' },
  { s: '632182', v: 588, c: '放デイ１１・未計画・評減・' },
  { s: '632183', v: 494, c: '放デイ１１・未計画２・' },
  { s: '632184', v: 420, c: '放デイ１１・未計画２・評減・' },
  { s: '632185', v: 983, c: '放デイ１１・拘減・' },
  { s: '632186', v: 835, c: '放デイ１１・評減・拘減・' },
  { s: '632187', v: 687, c: '放デイ１１・未計画・拘減・' },
  { s: '632188', v: 583, c: '放デイ１１・未計画・評減・拘減・' },
  { s: '632189', v: 489, c: '放デイ１１・未計画２・拘減・' },
  { s: '632190', v: 415, c: '放デイ１１・未計画２・評減・拘減・' },
  { s: '631321', v: 892, c: '放デイ１２・' },
  { s: '632191', v: 758, c: '放デイ１２・評減・' },
  { s: '631322', v: 624, c: '放デイ１２・未計画・' },
  { s: '632192', v: 530, c: '放デイ１２・未計画・評減・' },
  { s: '632193', v: 446, c: '放デイ１２・未計画２・' },
  { s: '632194', v: 379, c: '放デイ１２・未計画２・評減・' },
  { s: '632195', v: 887, c: '放デイ１２・拘減・' },
  { s: '632196', v: 753, c: '放デイ１２・評減・拘減・' },
  { s: '632197', v: 619, c: '放デイ１２・未計画・拘減・' },
  { s: '632198', v: 525, c: '放デイ１２・未計画・評減・拘減・' },
  { s: '632199', v: 441, c: '放デイ１２・未計画２・拘減・' },
  { s: '632200', v: 374, c: '放デイ１２・未計画２・評減・拘減・' },
  { s: '631331', v: 685, c: '放デイ１３・' },
  { s: '632201', v: 582, c: '放デイ１３・評減・' },
  { s: '631332', v: 480, c: '放デイ１３・未計画・' },
  { s: '632202', v: 408, c: '放デイ１３・未計画・評減・' },
  { s: '632203', v: 343, c: '放デイ１３・未計画２・' },
  { s: '632204', v: 292, c: '放デイ１３・未計画２・評減・' },
  { s: '632205', v: 680, c: '放デイ１３・拘減・' },
  { s: '632206', v: 577, c: '放デイ１３・評減・拘減・' },
  { s: '632207', v: 475, c: '放デイ１３・未計画・拘減・' },
  { s: '632208', v: 403, c: '放デイ１３・未計画・評減・拘減・' },
  { s: '632209', v: 338, c: '放デイ１３・未計画２・拘減・' },
  { s: '632210', v: 287, c: '放デイ１３・未計画２・評減・拘減・' },
  { s: '631411', v: 2036, c: '放デイ１４・' },
  { s: '632211', v: 1731, c: '放デイ１４・評減・' },
  { s: '631412', v: 1425, c: '放デイ１４・開減１・' },
  { s: '632212', v: 1211, c: '放デイ１４・開減１・評減・' },
  { s: '631415', v: 1731, c: '放デイ１４・開減２・' },
  { s: '632213', v: 1471, c: '放デイ１４・開減２・評減・' },
  { s: '632214', v: 2031, c: '放デイ１４・拘減・' },
  { s: '632215', v: 1726, c: '放デイ１４・評減・拘減・' },
  { s: '632216', v: 1420, c: '放デイ１４・開減１・拘減・' },
  { s: '632217', v: 1206, c: '放デイ１４・開減１・評減・拘減・' },
  { s: '632218', v: 1726, c: '放デイ１４・開減２・拘減・' },
  { s: '632219', v: 1466, c: '放デイ１４・開減２・評減・拘減・' },
  { s: '631413', v: 1425, c: '放デイ１４・未計画・' },
  { s: '632220', v: 1211, c: '放デイ１４・未計画・評減・' },
  { s: '631414', v: 998, c: '放デイ１４・未計画・開減１・' },
  { s: '632221', v: 848, c: '放デイ１４・未計画・開減１・評減・' },
  { s: '631416', v: 1211, c: '放デイ１４・未計画・開減２・' },
  { s: '632222', v: 1029, c: '放デイ１４・未計画・開減２・評減・' },
  { s: '632223', v: 1420, c: '放デイ１４・未計画・拘減・' },
  { s: '632224', v: 1206, c: '放デイ１４・未計画・評減・拘減・' },
  { s: '632225', v: 993, c: '放デイ１４・未計画・開減１・拘減・' },
  { s: '632226', v: 843, c: '放デイ１４・未計画・開減１・評減・拘減・' },
  { s: '632227', v: 1206, c: '放デイ１４・未計画・開減２・拘減・' },
  { s: '632228', v: 1024, c: '放デイ１４・未計画・開減２・評減・拘減・' },
  { s: '632229', v: 1018, c: '放デイ１４・未計画２・' },
  { s: '632230', v: 865, c: '放デイ１４・未計画２・評減・' },
  { s: '632231', v: 713, c: '放デイ１４・未計画２・開減１・' },
  { s: '632232', v: 606, c: '放デイ１４・未計画２・開減１・評減・' },
  { s: '632233', v: 865, c: '放デイ１４・未計画２・開減２・' },
  { s: '632234', v: 735, c: '放デイ１４・未計画２・開減２・評減・' },
  { s: '632235', v: 1013, c: '放デイ１４・未計画２・拘減・' },
  { s: '632236', v: 860, c: '放デイ１４・未計画２・評減・拘減・' },
  { s: '632237', v: 708, c: '放デイ１４・未計画２・開減１・拘減・' },
  { s: '632238', v: 601, c: '放デイ１４・未計画２・開減１・評減・拘減・' },
  { s: '632239', v: 860, c: '放デイ１４・未計画２・開減２・拘減・' },
  { s: '632240', v: 730, c: '放デイ１４・未計画２・開減２・評減・拘減・' },
  { s: '631441', v: 1704, c: '放デイ１５・' },
  { s: '632241', v: 1448, c: '放デイ１５・評減・' },
  { s: '631442', v: 1193, c: '放デイ１５・開減１・' },
  { s: '632242', v: 1014, c: '放デイ１５・開減１・評減・' },
  { s: '631443', v: 1448, c: '放デイ１５・開減２・' },
  { s: '632243', v: 1231, c: '放デイ１５・開減２・評減・' },
  { s: '632244', v: 1699, c: '放デイ１５・拘減・' },
  { s: '632245', v: 1443, c: '放デイ１５・評減・拘減・' },
  { s: '632246', v: 1188, c: '放デイ１５・開減１・拘減・' },
  { s: '632247', v: 1009, c: '放デイ１５・開減１・評減・拘減・' },
  { s: '632248', v: 1443, c: '放デイ１５・開減２・拘減・' },
  { s: '632249', v: 1226, c: '放デイ１５・開減２・評減・拘減・' },
  { s: '631444', v: 1193, c: '放デイ１５・未計画・' },
  { s: '632250', v: 1014, c: '放デイ１５・未計画・評減・' },
  { s: '631445', v: 835, c: '放デイ１５・未計画・開減１・' },
  { s: '632251', v: 710, c: '放デイ１５・未計画・開減１・評減・' },
  { s: '631446', v: 1014, c: '放デイ１５・未計画・開減２・' },
  { s: '632252', v: 862, c: '放デイ１５・未計画・開減２・評減・' },
  { s: '632253', v: 1188, c: '放デイ１５・未計画・拘減・' },
  { s: '632254', v: 1009, c: '放デイ１５・未計画・評減・拘減・' },
  { s: '632255', v: 830, c: '放デイ１５・未計画・開減１・拘減・' },
  { s: '632256', v: 705, c: '放デイ１５・未計画・開減１・評減・拘減・' },
  { s: '632257', v: 1009, c: '放デイ１５・未計画・開減２・拘減・' },
  { s: '632258', v: 857, c: '放デイ１５・未計画・開減２・評減・拘減・' },
  { s: '632259', v: 852, c: '放デイ１５・未計画２・' },
  { s: '632260', v: 724, c: '放デイ１５・未計画２・評減・' },
  { s: '632261', v: 596, c: '放デイ１５・未計画２・開減１・' },
  { s: '632262', v: 507, c: '放デイ１５・未計画２・開減１・評減・' },
  { s: '632263', v: 724, c: '放デイ１５・未計画２・開減２・' },
  { s: '632264', v: 615, c: '放デイ１５・未計画２・開減２・評減・' },
  { s: '632265', v: 847, c: '放デイ１５・未計画２・拘減・' },
  { s: '632266', v: 719, c: '放デイ１５・未計画２・評減・拘減・' },
  { s: '632267', v: 591, c: '放デイ１５・未計画２・開減１・拘減・' },
  { s: '632268', v: 502, c: '放デイ１５・未計画２・開減１・評減・拘減・' },
  { s: '632269', v: 719, c: '放デイ１５・未計画２・開減２・拘減・' },
  { s: '632270', v: 610, c: '放デイ１５・未計画２・開減２・評減・拘減・' },
  { s: '631451', v: 1465, c: '放デイ１６・' },
  { s: '632271', v: 1245, c: '放デイ１６・評減・' },
  { s: '631452', v: 1026, c: '放デイ１６・開減１・' },
  { s: '632272', v: 872, c: '放デイ１６・開減１・評減・' },
  { s: '631453', v: 1245, c: '放デイ１６・開減２・' },
  { s: '632273', v: 1058, c: '放デイ１６・開減２・評減・' },
  { s: '632274', v: 1460, c: '放デイ１６・拘減・' },
  { s: '632275', v: 1240, c: '放デイ１６・評減・拘減・' },
  { s: '632276', v: 1021, c: '放デイ１６・開減１・拘減・' },
  { s: '632277', v: 867, c: '放デイ１６・開減１・評減・拘減・' },
  { s: '632278', v: 1240, c: '放デイ１６・開減２・拘減・' },
  { s: '632279', v: 1053, c: '放デイ１６・開減２・評減・拘減・' },
  { s: '631454', v: 1026, c: '放デイ１６・未計画・' },
  { s: '632280', v: 872, c: '放デイ１６・未計画・評減・' },
  { s: '631455', v: 718, c: '放デイ１６・未計画・開減１・' },
  { s: '632281', v: 610, c: '放デイ１６・未計画・開減１・評減・' },
  { s: '631456', v: 872, c: '放デイ１６・未計画・開減２・' },
  { s: '632282', v: 741, c: '放デイ１６・未計画・開減２・評減・' },
  { s: '632283', v: 1021, c: '放デイ１６・未計画・拘減・' },
  { s: '632284', v: 867, c: '放デイ１６・未計画・評減・拘減・' },
  { s: '632285', v: 713, c: '放デイ１６・未計画・開減１・拘減・' },
  { s: '632286', v: 605, c: '放デイ１６・未計画・開減１・評減・拘減・' },
  { s: '632287', v: 867, c: '放デイ１６・未計画・開減２・拘減・' },
  { s: '632288', v: 736, c: '放デイ１６・未計画・開減２・評減・拘減・' },
  { s: '632289', v: 733, c: '放デイ１６・未計画２・' },
  { s: '632290', v: 623, c: '放デイ１６・未計画２・評減・' },
  { s: '632291', v: 513, c: '放デイ１６・未計画２・開減１・' },
  { s: '632292', v: 436, c: '放デイ１６・未計画２・開減１・評減・' },
  { s: '632293', v: 623, c: '放デイ１６・未計画２・開減２・' },
  { s: '632294', v: 530, c: '放デイ１６・未計画２・開減２・評減・' },
  { s: '632295', v: 728, c: '放デイ１６・未計画２・拘減・' },
  { s: '632296', v: 618, c: '放デイ１６・未計画２・評減・拘減・' },
  { s: '632297', v: 508, c: '放デイ１６・未計画２・開減１・拘減・' },
  { s: '632298', v: 431, c: '放デイ１６・未計画２・開減１・評減・拘減・' },
  { s: '632299', v: 618, c: '放デイ１６・未計画２・開減２・拘減・' },
  { s: '632300', v: 525, c: '放デイ１６・未計画２・開減２・評減・拘減・' },
  { s: '631461', v: 1287, c: '放デイ１７・' },
  { s: '632301', v: 1094, c: '放デイ１７・評減・' },
  { s: '631462', v: 901, c: '放デイ１７・開減１・' },
  { s: '632302', v: 766, c: '放デイ１７・開減１・評減・' },
  { s: '631463', v: 1094, c: '放デイ１７・開減２・' },
  { s: '632303', v: 930, c: '放デイ１７・開減２・評減・' },
  { s: '632304', v: 1282, c: '放デイ１７・拘減・' },
  { s: '632305', v: 1089, c: '放デイ１７・評減・拘減・' },
  { s: '632306', v: 896, c: '放デイ１７・開減１・拘減・' },
  { s: '632307', v: 761, c: '放デイ１７・開減１・評減・拘減・' },
  { s: '632308', v: 1089, c: '放デイ１７・開減２・拘減・' },
  { s: '632309', v: 925, c: '放デイ１７・開減２・評減・拘減・' },
  { s: '631464', v: 901, c: '放デイ１７・未計画・' },
  { s: '632310', v: 766, c: '放デイ１７・未計画・評減・' },
  { s: '631465', v: 631, c: '放デイ１７・未計画・開減１・' },
  { s: '632311', v: 536, c: '放デイ１７・未計画・開減１・評減・' },
  { s: '631466', v: 766, c: '放デイ１７・未計画・開減２・' },
  { s: '632312', v: 651, c: '放デイ１７・未計画・開減２・評減・' },
  { s: '632313', v: 896, c: '放デイ１７・未計画・拘減・' },
  { s: '632314', v: 761, c: '放デイ１７・未計画・評減・拘減・' },
  { s: '632315', v: 626, c: '放デイ１７・未計画・開減１・拘減・' },
  { s: '632316', v: 531, c: '放デイ１７・未計画・開減１・評減・拘減・' },
  { s: '632317', v: 761, c: '放デイ１７・未計画・開減２・拘減・' },
  { s: '632318', v: 646, c: '放デイ１７・未計画・開減２・評減・拘減・' },
  { s: '632319', v: 644, c: '放デイ１７・未計画２・' },
  { s: '632320', v: 547, c: '放デイ１７・未計画２・評減・' },
  { s: '632321', v: 451, c: '放デイ１７・未計画２・開減１・' },
  { s: '632322', v: 383, c: '放デイ１７・未計画２・開減１・評減・' },
  { s: '632323', v: 547, c: '放デイ１７・未計画２・開減２・' },
  { s: '632324', v: 465, c: '放デイ１７・未計画２・開減２・評減・' },
  { s: '632325', v: 639, c: '放デイ１７・未計画２・拘減・' },
  { s: '632326', v: 542, c: '放デイ１７・未計画２・評減・拘減・' },
  { s: '632327', v: 446, c: '放デイ１７・未計画２・開減１・拘減・' },
  { s: '632328', v: 378, c: '放デイ１７・未計画２・開減１・評減・拘減・' },
  { s: '632329', v: 542, c: '放デイ１７・未計画２・開減２・拘減・' },
  { s: '632330', v: 460, c: '放デイ１７・未計画２・開減２・評減・拘減・' },
  { s: '631471', v: 1149, c: '放デイ１８・' },
  { s: '632331', v: 977, c: '放デイ１８・評減・' },
  { s: '631472', v: 804, c: '放デイ１８・開減１・' },
  { s: '632332', v: 683, c: '放デイ１８・開減１・評減・' },
  { s: '631473', v: 977, c: '放デイ１８・開減２・' },
  { s: '632333', v: 830, c: '放デイ１８・開減２・評減・' },
  { s: '632334', v: 1144, c: '放デイ１８・拘減・' },
  { s: '632335', v: 972, c: '放デイ１８・評減・拘減・' },
  { s: '632336', v: 799, c: '放デイ１８・開減１・拘減・' },
  { s: '632337', v: 678, c: '放デイ１８・開減１・評減・拘減・' },
  { s: '632338', v: 972, c: '放デイ１８・開減２・拘減・' },
  { s: '632339', v: 825, c: '放デイ１８・開減２・評減・拘減・' },
  { s: '631474', v: 804, c: '放デイ１８・未計画・' },
  { s: '632340', v: 683, c: '放デイ１８・未計画・評減・' },
  { s: '631475', v: 563, c: '放デイ１８・未計画・開減１・' },
  { s: '632341', v: 479, c: '放デイ１８・未計画・開減１・評減・' },
  { s: '631476', v: 683, c: '放デイ１８・未計画・開減２・' },
  { s: '632342', v: 581, c: '放デイ１８・未計画・開減２・評減・' },
  { s: '632343', v: 799, c: '放デイ１８・未計画・拘減・' },
  { s: '632344', v: 678, c: '放デイ１８・未計画・評減・拘減・' },
  { s: '632345', v: 558, c: '放デイ１８・未計画・開減１・拘減・' },
  { s: '632346', v: 474, c: '放デイ１８・未計画・開減１・評減・拘減・' },
  { s: '632347', v: 678, c: '放デイ１８・未計画・開減２・拘減・' },
  { s: '632348', v: 576, c: '放デイ１８・未計画・開減２・評減・拘減・' },
  { s: '632349', v: 575, c: '放デイ１８・未計画２・' },
  { s: '632350', v: 489, c: '放デイ１８・未計画２・評減・' },
  { s: '632351', v: 403, c: '放デイ１８・未計画２・開減１・' },
  { s: '632352', v: 343, c: '放デイ１８・未計画２・開減１・評減・' },
  { s: '632353', v: 489, c: '放デイ１８・未計画２・開減２・' },
  { s: '632354', v: 416, c: '放デイ１８・未計画２・開減２・評減・' },
  { s: '632355', v: 570, c: '放デイ１８・未計画２・拘減・' },
  { s: '632356', v: 484, c: '放デイ１８・未計画２・評減・拘減・' },
  { s: '632357', v: 398, c: '放デイ１８・未計画２・開減１・拘減・' },
  { s: '632358', v: 338, c: '放デイ１８・未計画２・開減１・評減・拘減・' },
  { s: '632359', v: 484, c: '放デイ１８・未計画２・開減２・拘減・' },
  { s: '632360', v: 411, c: '放デイ１８・未計画２・開減２・評減・拘減・' },
  { s: '631421', v: 1038, c: '放デイ１９・' },
  { s: '632361', v: 882, c: '放デイ１９・評減・' },
  { s: '631422', v: 727, c: '放デイ１９・開減１・' },
  { s: '632362', v: 618, c: '放デイ１９・開減１・評減・' },
  { s: '631425', v: 882, c: '放デイ１９・開減２・' },
  { s: '632363', v: 750, c: '放デイ１９・開減２・評減・' },
  { s: '632364', v: 1033, c: '放デイ１９・拘減・' },
  { s: '632365', v: 877, c: '放デイ１９・評減・拘減・' },
  { s: '632366', v: 722, c: '放デイ１９・開減１・拘減・' },
  { s: '632367', v: 613, c: '放デイ１９・開減１・評減・拘減・' },
  { s: '632368', v: 877, c: '放デイ１９・開減２・拘減・' },
  { s: '632369', v: 745, c: '放デイ１９・開減２・評減・拘減・' },
  { s: '631423', v: 727, c: '放デイ１９・未計画・' },
  { s: '632370', v: 618, c: '放デイ１９・未計画・評減・' },
  { s: '631424', v: 509, c: '放デイ１９・未計画・開減１・' },
  { s: '632371', v: 433, c: '放デイ１９・未計画・開減１・評減・' },
  { s: '631426', v: 618, c: '放デイ１９・未計画・開減２・' },
  { s: '632372', v: 525, c: '放デイ１９・未計画・開減２・評減・' },
  { s: '632373', v: 722, c: '放デイ１９・未計画・拘減・' },
  { s: '632374', v: 613, c: '放デイ１９・未計画・評減・拘減・' },
  { s: '632375', v: 504, c: '放デイ１９・未計画・開減１・拘減・' },
  { s: '632376', v: 428, c: '放デイ１９・未計画・開減１・評減・拘減・' },
  { s: '632377', v: 613, c: '放デイ１９・未計画・開減２・拘減・' },
  { s: '632378', v: 520, c: '放デイ１９・未計画・開減２・評減・拘減・' },
  { s: '632379', v: 519, c: '放デイ１９・未計画２・' },
  { s: '632380', v: 441, c: '放デイ１９・未計画２・評減・' },
  { s: '632381', v: 363, c: '放デイ１９・未計画２・開減１・' },
  { s: '632382', v: 309, c: '放デイ１９・未計画２・開減１・評減・' },
  { s: '632383', v: 441, c: '放デイ１９・未計画２・開減２・' },
  { s: '632384', v: 375, c: '放デイ１９・未計画２・開減２・評減・' },
  { s: '632385', v: 514, c: '放デイ１９・未計画２・拘減・' },
  { s: '632386', v: 436, c: '放デイ１９・未計画２・評減・拘減・' },
  { s: '632387', v: 358, c: '放デイ１９・未計画２・開減１・拘減・' },
  { s: '632388', v: 304, c: '放デイ１９・未計画２・開減１・評減・拘減・' },
  { s: '632389', v: 436, c: '放デイ１９・未計画２・開減２・拘減・' },
  { s: '632390', v: 370, c: '放デイ１９・未計画２・開減２・評減・拘減・' },
  { s: '631431', v: 809, c: '放デイ２０・' },
  { s: '632391', v: 688, c: '放デイ２０・評減・' },
  { s: '631432', v: 566, c: '放デイ２０・開減１・' },
  { s: '632392', v: 481, c: '放デイ２０・開減１・評減・' },
  { s: '631435', v: 688, c: '放デイ２０・開減２・' },
  { s: '632393', v: 585, c: '放デイ２０・開減２・評減・' },
  { s: '632394', v: 804, c: '放デイ２０・拘減・' },
  { s: '632395', v: 683, c: '放デイ２０・評減・拘減・' },
  { s: '632396', v: 561, c: '放デイ２０・開減１・拘減・' },
  { s: '632397', v: 476, c: '放デイ２０・開減１・評減・拘減・' },
  { s: '632398', v: 683, c: '放デイ２０・開減２・拘減・' },
  { s: '632399', v: 580, c: '放デイ２０・開減２・評減・拘減・' },
  { s: '631433', v: 566, c: '放デイ２０・未計画・' },
  { s: '632400', v: 481, c: '放デイ２０・未計画・評減・' },
  { s: '631434', v: 396, c: '放デイ２０・未計画・開減１・' },
  { s: '632401', v: 337, c: '放デイ２０・未計画・開減１・評減・' },
  { s: '631436', v: 481, c: '放デイ２０・未計画・開減２・' },
  { s: '632402', v: 409, c: '放デイ２０・未計画・開減２・評減・' },
  { s: '632403', v: 561, c: '放デイ２０・未計画・拘減・' },
  { s: '632404', v: 476, c: '放デイ２０・未計画・評減・拘減・' },
  { s: '632405', v: 391, c: '放デイ２０・未計画・開減１・拘減・' },
  { s: '632406', v: 332, c: '放デイ２０・未計画・開減１・評減・拘減・' },
  { s: '632407', v: 476, c: '放デイ２０・未計画・開減２・拘減・' },
  { s: '632408', v: 404, c: '放デイ２０・未計画・開減２・評減・拘減・' },
  { s: '632409', v: 405, c: '放デイ２０・未計画２・' },
  { s: '632410', v: 344, c: '放デイ２０・未計画２・評減・' },
  { s: '632411', v: 284, c: '放デイ２０・未計画２・開減１・' },
  { s: '632412', v: 241, c: '放デイ２０・未計画２・開減１・評減・' },
  { s: '632413', v: 344, c: '放デイ２０・未計画２・開減２・' },
  { s: '632414', v: 292, c: '放デイ２０・未計画２・開減２・評減・' },
  { s: '632415', v: 400, c: '放デイ２０・未計画２・拘減・' },
  { s: '632416', v: 339, c: '放デイ２０・未計画２・評減・拘減・' },
  { s: '632417', v: 279, c: '放デイ２０・未計画２・開減１・拘減・' },
  { s: '632418', v: 236, c: '放デイ２０・未計画２・開減１・評減・拘減・' },
  { s: '632419', v: 339, c: '放デイ２０・未計画２・開減２・拘減・' },
  { s: '632420', v: 287, c: '放デイ２０・未計画２・開減２・評減・拘減・' },
  { s: '632431', v: 429, c: '共生型放デイ１・' },
  { s: '632432', v: 365, c: '共生型放デイ１・評減・' },
  { s: '632433', v: 424, c: '共生型放デイ１・拘減・' },
  { s: '632434', v: 360, c: '共生型放デイ１・評減・拘減・' },
  { s: '632441', v: 554, c: '共生型放デイ２・' },
  { s: '632442', v: 471, c: '共生型放デイ２・評減・' },
  { s: '632443', v: 388, c: '共生型放デイ２・開減１・' },
  { s: '632444', v: 330, c: '共生型放デイ２・開減１・評減・' },
  { s: '632445', v: 471, c: '共生型放デイ２・開減２・' },
  { s: '632446', v: 400, c: '共生型放デイ２・開減２・評減・' },
  { s: '632447', v: 549, c: '共生型放デイ２・拘減・' },
  { s: '632448', v: 466, c: '共生型放デイ２・評減・拘減・' },
  { s: '632449', v: 383, c: '共生型放デイ２・開減１・拘減・' },
  { s: '632450', v: 325, c: '共生型放デイ２・開減１・評減・拘減・' },
  { s: '632451', v: 466, c: '共生型放デイ２・開減２・拘減・' },
  { s: '632452', v: 395, c: '共生型放デイ２・開減２・評減・拘減・' },
  { s: '632461', v: 533, c: '基準該当放デイⅠ１・' },
  { s: '632462', v: 453, c: '基準該当放デイⅠ１・評減・' },
  { s: '632463', v: 373, c: '基準該当放デイⅠ１・未計画・' },
  { s: '632464', v: 317, c: '基準該当放デイⅠ１・未計画・評減・' },
  { s: '632465', v: 267, c: '基準該当放デイⅠ１・未計画２・' },
  { s: '632466', v: 227, c: '基準該当放デイⅠ１・未計画２・評減・' },
  { s: '632467', v: 658, c: '基準該当放デイⅠ２・' },
  { s: '632468', v: 559, c: '基準該当放デイⅠ２・評減・' },
  { s: '632469', v: 461, c: '基準該当放デイⅠ２・開減１・' },
  { s: '632470', v: 392, c: '基準該当放デイⅠ２・開減１・評減・' },
  { s: '632471', v: 559, c: '基準該当放デイⅠ２・開減２・' },
  { s: '632472', v: 475, c: '基準該当放デイⅠ２・開減２・評減・' },
  { s: '632473', v: 461, c: '基準該当放デイⅠ２・未計画・' },
  { s: '632474', v: 392, c: '基準該当放デイⅠ２・未計画・評減・' },
  { s: '632475', v: 323, c: '基準該当放デイⅠ２・未計画・開減１・' },
  { s: '632476', v: 275, c: '基準該当放デイⅠ２・未計画・開減１・評減・' },
  { s: '632477', v: 392, c: '基準該当放デイⅠ２・未計画・開減２・' },
  { s: '632478', v: 333, c: '基準該当放デイⅠ２・未計画・開減２・評減・' },
  { s: '632479', v: 329, c: '基準該当放デイⅠ２・未計画２・' },
  { s: '632480', v: 280, c: '基準該当放デイⅠ２・未計画２・評減・' },
  { s: '632481', v: 230, c: '基準該当放デイⅠ２・未計画２・開減１・' },
  { s: '632482', v: 196, c: '基準該当放デイⅠ２・未計画２・開減１・評減・' },
  { s: '632483', v: 280, c: '基準該当放デイⅠ２・未計画２・開減２・' },
  { s: '632484', v: 238, c: '基準該当放デイⅠ２・未計画２・開減２・評減・' },
  { s: '632491', v: 429, c: '基準該当放デイⅡ１・' },
  { s: '632492', v: 365, c: '基準該当放デイⅡ１・評減・' },
  { s: '632493', v: 554, c: '基準該当放デイⅡ２・' },
  { s: '632494', v: 471, c: '基準該当放デイⅡ２・評減・' },
  { s: '632495', v: 388, c: '基準該当放デイⅡ２・開減１・' },
  { s: '632496', v: 330, c: '基準該当放デイⅡ２・開減１・評減・' },
  { s: '632497', v: 471, c: '基準該当放デイⅡ２・開減２・' },
  { s: '632498', v: 400, c: '基準該当放デイⅡ２・開減２・評減・' },
  { s: '638111', v: 462, c: '放デイ１・定超・' },
  { s: '638135', v: 393, c: '放デイ１・定超・評減・' },
  { s: '638112', v: 323, c: '放デイ１・定超・未計画・' },
  { s: '638136', v: 275, c: '放デイ１・定超・未計画・評減・' },
  { s: '638137', v: 231, c: '放デイ１・定超・未計画２・' },
  { s: '638138', v: 196, c: '放デイ１・定超・未計画２・評減・' },
  { s: '638139', v: 457, c: '放デイ１・定超・拘減・' },
  { s: '638140', v: 388, c: '放デイ１・定超・評減・拘減・' },
  { s: '638141', v: 318, c: '放デイ１・定超・未計画・拘減・' },
  { s: '638142', v: 270, c: '放デイ１・定超・未計画・評減・拘減・' },
  { s: '638143', v: 226, c: '放デイ１・定超・未計画２・拘減・' },
  { s: '638144', v: 191, c: '放デイ１・定超・未計画２・評減・拘減・' },
  { s: '638113', v: 468, c: '放デイ１・有資格１・定超・' },
  { s: '638145', v: 398, c: '放デイ１・有資格１・定超・評減・' },
  { s: '638114', v: 328, c: '放デイ１・有資格１・定超・未計画・' },
  { s: '638146', v: 279, c: '放デイ１・有資格１・定超・未計画・評減・' },
  { s: '638147', v: 234, c: '放デイ１・有資格１・定超・未計画２・' },
  { s: '638148', v: 199, c: '放デイ１・有資格１・定超・未計画２・評減・' },
  { s: '638149', v: 463, c: '放デイ１・有資格１・定超・拘減・' },
  { s: '638150', v: 393, c: '放デイ１・有資格１・定超・評減・拘減・' },
  { s: '638151', v: 323, c: '放デイ１・有資格１・定超・未計画・拘減・' },
  { s: '638152', v: 274, c: '放デイ１・有資格１・定超・未計画・評減・拘減・' },
  { s: '638153', v: 229, c: '放デイ１・有資格１・定超・未計画２・拘減・' },
  { s: '638154', v: 194, c: '放デイ１・有資格１・定超・未計画２・評減・拘減・' },
  { s: '638121', v: 310, c: '放デイ２・定超・' },
  { s: '638155', v: 264, c: '放デイ２・定超・評減・' },
  { s: '638122', v: 217, c: '放デイ２・定超・未計画・' },
  { s: '638156', v: 184, c: '放デイ２・定超・未計画・評減・' },
  { s: '638157', v: 155, c: '放デイ２・定超・未計画２・' },
  { s: '638158', v: 132, c: '放デイ２・定超・未計画２・評減・' },
  { s: '638159', v: 305, c: '放デイ２・定超・拘減・' },
  { s: '638160', v: 259, c: '放デイ２・定超・評減・拘減・' },
  { s: '638161', v: 212, c: '放デイ２・定超・未計画・拘減・' },
  { s: '638162', v: 179, c: '放デイ２・定超・未計画・評減・拘減・' },
  { s: '638163', v: 150, c: '放デイ２・定超・未計画２・拘減・' },
  { s: '638164', v: 127, c: '放デイ２・定超・未計画２・評減・拘減・' },
  { s: '638123', v: 314, c: '放デイ２・有資格２・定超・' },
  { s: '638165', v: 267, c: '放デイ２・有資格２・定超・評減・' },
  { s: '638124', v: 220, c: '放デイ２・有資格２・定超・未計画・' },
  { s: '638166', v: 187, c: '放デイ２・有資格２・定超・未計画・評減・' },
  { s: '638167', v: 157, c: '放デイ２・有資格２・定超・未計画２・' },
  { s: '638168', v: 133, c: '放デイ２・有資格２・定超・未計画２・評減・' },
  { s: '638169', v: 309, c: '放デイ２・有資格２・定超・拘減・' },
  { s: '638170', v: 262, c: '放デイ２・有資格２・定超・評減・拘減・' },
  { s: '638171', v: 215, c: '放デイ２・有資格２・定超・未計画・拘減・' },
  { s: '638172', v: 182, c: '放デイ２・有資格２・定超・未計画・評減・拘減・' },
  { s: '638173', v: 152, c: '放デイ２・有資格２・定超・未計画２・拘減・' },
  { s: '638174', v: 128, c: '放デイ２・有資格２・定超・未計画２・評減・拘減・' },
  { s: '638131', v: 233, c: '放デイ３・定超・' },
  { s: '638175', v: 198, c: '放デイ３・定超・評減・' },
  { s: '638132', v: 163, c: '放デイ３・定超・未計画・' },
  { s: '638176', v: 139, c: '放デイ３・定超・未計画・評減・' },
  { s: '638177', v: 117, c: '放デイ３・定超・未計画２・' },
  { s: '638178', v: 99, c: '放デイ３・定超・未計画２・評減・' },
  { s: '638179', v: 228, c: '放デイ３・定超・拘減・' },
  { s: '638180', v: 193, c: '放デイ３・定超・評減・拘減・' },
  { s: '638181', v: 158, c: '放デイ３・定超・未計画・拘減・' },
  { s: '638182', v: 134, c: '放デイ３・定超・未計画・評減・拘減・' },
  { s: '638183', v: 112, c: '放デイ３・定超・未計画２・拘減・' },
  { s: '638184', v: 94, c: '放デイ３・定超・未計画２・評減・拘減・' },
  { s: '638133', v: 236, c: '放デイ３・有資格３・定超・' },
  { s: '638185', v: 201, c: '放デイ３・有資格３・定超・評減・' },
  { s: '638134', v: 165, c: '放デイ３・有資格３・定超・未計画・' },
  { s: '638186', v: 140, c: '放デイ３・有資格３・定超・未計画・評減・' },
  { s: '638187', v: 118, c: '放デイ３・有資格３・定超・未計画２・' },
  { s: '638188', v: 100, c: '放デイ３・有資格３・定超・未計画２・評減・' },
  { s: '638189', v: 231, c: '放デイ３・有資格３・定超・拘減・' },
  { s: '638190', v: 196, c: '放デイ３・有資格３・定超・評減・拘減・' },
  { s: '638191', v: 160, c: '放デイ３・有資格３・定超・未計画・拘減・' },
  { s: '638192', v: 135, c: '放デイ３・有資格３・定超・未計画・評減・拘減・' },
  { s: '638193', v: 113, c: '放デイ３・有資格３・定超・未計画２・拘減・' },
  { s: '638194', v: 95, c: '放デイ３・有資格３・定超・未計画２・評減・拘減・' },
  { s: '638271', v: 454, c: '放デイ２１・定超・' },
  { s: '638272', v: 386, c: '放デイ２１・定超・評減・' },
  { s: '638273', v: 318, c: '放デイ２１・定超・未計画・' },
  { s: '638274', v: 270, c: '放デイ２１・定超・未計画・評減・' },
  { s: '638275', v: 227, c: '放デイ２１・定超・未計画２・' },
  { s: '638276', v: 193, c: '放デイ２１・定超・未計画２・評減・' },
  { s: '638277', v: 449, c: '放デイ２１・定超・拘減・' },
  { s: '638278', v: 381, c: '放デイ２１・定超・評減・拘減・' },
  { s: '638279', v: 313, c: '放デイ２１・定超・未計画・拘減・' },
  { s: '638280', v: 265, c: '放デイ２１・定超・未計画・評減・拘減・' },
  { s: '638281', v: 222, c: '放デイ２１・定超・未計画２・拘減・' },
  { s: '638282', v: 188, c: '放デイ２１・定超・未計画２・評減・拘減・' },
  { s: '638283', v: 461, c: '放デイ２１・有資格７・定超・' },
  { s: '638284', v: 392, c: '放デイ２１・有資格７・定超・評減・' },
  { s: '638285', v: 323, c: '放デイ２１・有資格７・定超・未計画・' },
  { s: '638286', v: 275, c: '放デイ２１・有資格７・定超・未計画・評減・' },
  { s: '638287', v: 231, c: '放デイ２１・有資格７・定超・未計画２・' },
  { s: '638288', v: 196, c: '放デイ２１・有資格７・定超・未計画２・評減・' },
  { s: '638289', v: 456, c: '放デイ２１・有資格７・定超・拘減・' },
  { s: '638290', v: 387, c: '放デイ２１・有資格７・定超・評減・拘減・' },
  { s: '638291', v: 318, c: '放デイ２１・有資格７・定超・未計画・拘減・' },
  { s: '638292', v: 270, c: '放デイ２１・有資格７・定超・未計画・評減・拘減・' },
  { s: '638293', v: 226, c: '放デイ２１・有資格７・定超・未計画２・拘減・' },
  { s: '638294', v: 191, c: '放デイ２１・有資格７・定超・未計画２・評減・拘減・' },
  { s: '638381', v: 303, c: '放デイ２２・定超・' },
  { s: '638382', v: 258, c: '放デイ２２・定超・評減・' },
  { s: '638383', v: 212, c: '放デイ２２・定超・未計画・' },
  { s: '638384', v: 180, c: '放デイ２２・定超・未計画・評減・' },
  { s: '638385', v: 152, c: '放デイ２２・定超・未計画２・' },
  { s: '638386', v: 129, c: '放デイ２２・定超・未計画２・評減・' },
  { s: '638387', v: 298, c: '放デイ２２・定超・拘減・' },
  { s: '638388', v: 253, c: '放デイ２２・定超・評減・拘減・' },
  { s: '638389', v: 207, c: '放デイ２２・定超・未計画・拘減・' },
  { s: '638390', v: 175, c: '放デイ２２・定超・未計画・評減・拘減・' },
  { s: '638391', v: 147, c: '放デイ２２・定超・未計画２・拘減・' },
  { s: '638392', v: 124, c: '放デイ２２・定超・未計画２・評減・拘減・' },
  { s: '638393', v: 307, c: '放デイ２２・有資格８・定超・' },
  { s: '638394', v: 261, c: '放デイ２２・有資格８・定超・評減・' },
  { s: '638395', v: 215, c: '放デイ２２・有資格８・定超・未計画・' },
  { s: '638396', v: 183, c: '放デイ２２・有資格８・定超・未計画・評減・' },
  { s: '638397', v: 154, c: '放デイ２２・有資格８・定超・未計画２・' },
  { s: '638398', v: 131, c: '放デイ２２・有資格８・定超・未計画２・評減・' },
  { s: '638399', v: 302, c: '放デイ２２・有資格８・定超・拘減・' },
  { s: '638400', v: 256, c: '放デイ２２・有資格８・定超・評減・拘減・' },
  { s: '638401', v: 210, c: '放デイ２２・有資格８・定超・未計画・拘減・' },
  { s: '638402', v: 178, c: '放デイ２２・有資格８・定超・未計画・評減・拘減・' },
  { s: '638403', v: 149, c: '放デイ２２・有資格８・定超・未計画２・拘減・' },
  { s: '638404', v: 126, c: '放デイ２２・有資格８・定超・未計画２・評減・拘減・' },
  { s: '638481', v: 228, c: '放デイ２３・定超・' },
  { s: '638482', v: 194, c: '放デイ２３・定超・評減・' },
  { s: '638483', v: 160, c: '放デイ２３・定超・未計画・' },
  { s: '638484', v: 136, c: '放デイ２３・定超・未計画・評減・' },
  { s: '638485', v: 114, c: '放デイ２３・定超・未計画２・' },
  { s: '638486', v: 97, c: '放デイ２３・定超・未計画２・評減・' },
  { s: '638487', v: 223, c: '放デイ２３・定超・拘減・' },
  { s: '638488', v: 189, c: '放デイ２３・定超・評減・拘減・' },
  { s: '638489', v: 155, c: '放デイ２３・定超・未計画・拘減・' },
  { s: '638490', v: 131, c: '放デイ２３・定超・未計画・評減・拘減・' },
  { s: '638491', v: 109, c: '放デイ２３・定超・未計画２・拘減・' },
  { s: '638492', v: 92, c: '放デイ２３・定超・未計画２・評減・拘減・' },
  { s: '638493', v: 231, c: '放デイ２３・有資格９・定超・' },
  { s: '638494', v: 196, c: '放デイ２３・有資格９・定超・評減・' },
  { s: '638495', v: 162, c: '放デイ２３・有資格９・定超・未計画・' },
  { s: '638496', v: 138, c: '放デイ２３・有資格９・定超・未計画・評減・' },
  { s: '638497', v: 116, c: '放デイ２３・有資格９・定超・未計画２・' },
  { s: '638498', v: 99, c: '放デイ２３・有資格９・定超・未計画２・評減・' },
  { s: '638499', v: 226, c: '放デイ２３・有資格９・定超・拘減・' },
  { s: '638500', v: 191, c: '放デイ２３・有資格９・定超・評減・拘減・' },
  { s: '638501', v: 157, c: '放デイ２３・有資格９・定超・未計画・拘減・' },
  { s: '638502', v: 133, c: '放デイ２３・有資格９・定超・未計画・評減・拘減・' },
  { s: '638503', v: 111, c: '放デイ２３・有資格９・定超・未計画２・拘減・' },
  { s: '638504', v: 94, c: '放デイ２３・有資格９・定超・未計画２・評減・拘減・' },
  { s: '638511', v: 428, c: '放デイ２４・定超・' },
  { s: '638512', v: 364, c: '放デイ２４・定超・評減・' },
  { s: '638513', v: 300, c: '放デイ２４・定超・未計画・' },
  { s: '638514', v: 255, c: '放デイ２４・定超・未計画・評減・' },
  { s: '638515', v: 214, c: '放デイ２４・定超・未計画２・' },
  { s: '638516', v: 182, c: '放デイ２４・定超・未計画２・評減・' },
  { s: '638517', v: 423, c: '放デイ２４・定超・拘減・' },
  { s: '638518', v: 359, c: '放デイ２４・定超・評減・拘減・' },
  { s: '638519', v: 295, c: '放デイ２４・定超・未計画・拘減・' },
  { s: '638520', v: 250, c: '放デイ２４・定超・未計画・評減・拘減・' },
  { s: '638521', v: 209, c: '放デイ２４・定超・未計画２・拘減・' },
  { s: '638522', v: 177, c: '放デイ２４・定超・未計画２・評減・拘減・' },
  { s: '638523', v: 435, c: '放デイ２４・有資格１０・定超・' },
  { s: '638524', v: 370, c: '放デイ２４・有資格１０・定超・評減・' },
  { s: '638525', v: 305, c: '放デイ２４・有資格１０・定超・未計画・' },
  { s: '638526', v: 259, c: '放デイ２４・有資格１０・定超・未計画・評減・' },
  { s: '638527', v: 218, c: '放デイ２４・有資格１０・定超・未計画２・' },
  { s: '638528', v: 185, c: '放デイ２４・有資格１０・定超・未計画２・評減・' },
  { s: '638529', v: 430, c: '放デイ２４・有資格１０・定超・拘減・' },
  { s: '638530', v: 365, c: '放デイ２４・有資格１０・定超・評減・拘減・' },
  { s: '638531', v: 300, c: '放デイ２４・有資格１０・定超・未計画・拘減・' },
  { s: '638532', v: 254, c: '放デイ２４・有資格１０・定超・未計画・評減・拘減・' },
  { s: '638533', v: 213, c: '放デイ２４・有資格１０・定超・未計画２・拘減・' },
  { s: '638534', v: 180, c: '放デイ２４・有資格１０・定超・未計画２・評減・拘減・' },
  { s: '638541', v: 285, c: '放デイ２５・定超・' },
  { s: '638542', v: 242, c: '放デイ２５・定超・評減・' },
  { s: '638543', v: 200, c: '放デイ２５・定超・未計画・' },
  { s: '638544', v: 170, c: '放デイ２５・定超・未計画・評減・' },
  { s: '638545', v: 143, c: '放デイ２５・定超・未計画２・' },
  { s: '638546', v: 122, c: '放デイ２５・定超・未計画２・評減・' },
  { s: '638547', v: 280, c: '放デイ２５・定超・拘減・' },
  { s: '638548', v: 237, c: '放デイ２５・定超・評減・拘減・' },
  { s: '638549', v: 195, c: '放デイ２５・定超・未計画・拘減・' },
  { s: '638550', v: 165, c: '放デイ２５・定超・未計画・評減・拘減・' },
  { s: '638551', v: 138, c: '放デイ２５・定超・未計画２・拘減・' },
  { s: '638552', v: 117, c: '放デイ２５・定超・未計画２・評減・拘減・' },
  { s: '638553', v: 289, c: '放デイ２５・有資格１１・定超・' },
  { s: '638554', v: 246, c: '放デイ２５・有資格１１・定超・評減・' },
  { s: '638555', v: 202, c: '放デイ２５・有資格１１・定超・未計画・' },
  { s: '638556', v: 172, c: '放デイ２５・有資格１１・定超・未計画・評減・' },
  { s: '638557', v: 145, c: '放デイ２５・有資格１１・定超・未計画２・' },
  { s: '638558', v: 123, c: '放デイ２５・有資格１１・定超・未計画２・評減・' },
  { s: '638559', v: 284, c: '放デイ２５・有資格１１・定超・拘減・' },
  { s: '638560', v: 241, c: '放デイ２５・有資格１１・定超・評減・拘減・' },
  { s: '638561', v: 197, c: '放デイ２５・有資格１１・定超・未計画・拘減・' },
  { s: '638562', v: 167, c: '放デイ２５・有資格１１・定超・未計画・評減・拘減・' },
  { s: '638563', v: 140, c: '放デイ２５・有資格１１・定超・未計画２・拘減・' },
  { s: '638564', v: 118, c: '放デイ２５・有資格１１・定超・未計画２・評減・拘減・' },
  { s: '638571', v: 214, c: '放デイ２６・定超・' },
  { s: '638572', v: 182, c: '放デイ２６・定超・評減・' },
  { s: '638573', v: 150, c: '放デイ２６・定超・未計画・' },
  { s: '638574', v: 128, c: '放デイ２６・定超・未計画・評減・' },
  { s: '638575', v: 107, c: '放デイ２６・定超・未計画２・' },
  { s: '638576', v: 91, c: '放デイ２６・定超・未計画２・評減・' },
  { s: '638577', v: 209, c: '放デイ２６・定超・拘減・' },
  { s: '638578', v: 177, c: '放デイ２６・定超・評減・拘減・' },
  { s: '638579', v: 145, c: '放デイ２６・定超・未計画・拘減・' },
  { s: '638580', v: 123, c: '放デイ２６・定超・未計画・評減・拘減・' },
  { s: '638581', v: 102, c: '放デイ２６・定超・未計画２・拘減・' },
  { s: '638582', v: 86, c: '放デイ２６・定超・未計画２・評減・拘減・' },
  { s: '638583', v: 217, c: '放デイ２６・有資格１２・定超・' },
  { s: '638584', v: 184, c: '放デイ２６・有資格１２・定超・評減・' },
  { s: '638585', v: 152, c: '放デイ２６・有資格１２・定超・未計画・' },
  { s: '638586', v: 129, c: '放デイ２６・有資格１２・定超・未計画・評減・' },
  { s: '638587', v: 109, c: '放デイ２６・有資格１２・定超・未計画２・' },
  { s: '638588', v: 93, c: '放デイ２６・有資格１２・定超・未計画２・評減・' },
  { s: '638589', v: 212, c: '放デイ２６・有資格１２・定超・拘減・' },
  { s: '638590', v: 179, c: '放デイ２６・有資格１２・定超・評減・拘減・' },
  { s: '638591', v: 147, c: '放デイ２６・有資格１２・定超・未計画・拘減・' },
  { s: '638592', v: 124, c: '放デイ２６・有資格１２・定超・未計画・評減・拘減・' },
  { s: '638593', v: 104, c: '放デイ２６・有資格１２・定超・未計画２・拘減・' },
  { s: '638594', v: 88, c: '放デイ２６・有資格１２・定超・未計画２・評減・拘減・' },
  { s: '638601', v: 419, c: '放デイ２７・定超・' },
  { s: '638602', v: 356, c: '放デイ２７・定超・評減・' },
  { s: '638603', v: 293, c: '放デイ２７・定超・未計画・' },
  { s: '638604', v: 249, c: '放デイ２７・定超・未計画・評減・' },
  { s: '638605', v: 210, c: '放デイ２７・定超・未計画２・' },
  { s: '638606', v: 179, c: '放デイ２７・定超・未計画２・評減・' },
  { s: '638607', v: 414, c: '放デイ２７・定超・拘減・' },
  { s: '638608', v: 351, c: '放デイ２７・定超・評減・拘減・' },
  { s: '638609', v: 288, c: '放デイ２７・定超・未計画・拘減・' },
  { s: '638610', v: 244, c: '放デイ２７・定超・未計画・評減・拘減・' },
  { s: '638611', v: 205, c: '放デイ２７・定超・未計画２・拘減・' },
  { s: '638612', v: 174, c: '放デイ２７・定超・未計画２・評減・拘減・' },
  { s: '638613', v: 426, c: '放デイ２７・有資格１３・定超・' },
  { s: '638614', v: 362, c: '放デイ２７・有資格１３・定超・評減・' },
  { s: '638615', v: 298, c: '放デイ２７・有資格１３・定超・未計画・' },
  { s: '638616', v: 253, c: '放デイ２７・有資格１３・定超・未計画・評減・' },
  { s: '638617', v: 213, c: '放デイ２７・有資格１３・定超・未計画２・' },
  { s: '638618', v: 181, c: '放デイ２７・有資格１３・定超・未計画２・評減・' },
  { s: '638619', v: 421, c: '放デイ２７・有資格１３・定超・拘減・' },
  { s: '638620', v: 357, c: '放デイ２７・有資格１３・定超・評減・拘減・' },
  { s: '638621', v: 293, c: '放デイ２７・有資格１３・定超・未計画・拘減・' },
  { s: '638622', v: 248, c: '放デイ２７・有資格１３・定超・未計画・評減・拘減・' },
  { s: '638623', v: 208, c: '放デイ２７・有資格１３・定超・未計画２・拘減・' },
  { s: '638624', v: 176, c: '放デイ２７・有資格１３・定超・未計画２・評減・拘減・' },
  { s: '638631', v: 279, c: '放デイ２８・定超・' },
  { s: '638632', v: 237, c: '放デイ２８・定超・評減・' },
  { s: '638633', v: 195, c: '放デイ２８・定超・未計画・' },
  { s: '638634', v: 166, c: '放デイ２８・定超・未計画・評減・' },
  { s: '638635', v: 140, c: '放デイ２８・定超・未計画２・' },
  { s: '638636', v: 119, c: '放デイ２８・定超・未計画２・評減・' },
  { s: '638637', v: 274, c: '放デイ２８・定超・拘減・' },
  { s: '638638', v: 232, c: '放デイ２８・定超・評減・拘減・' },
  { s: '638639', v: 190, c: '放デイ２８・定超・未計画・拘減・' },
  { s: '638640', v: 161, c: '放デイ２８・定超・未計画・評減・拘減・' },
  { s: '638641', v: 135, c: '放デイ２８・定超・未計画２・拘減・' },
  { s: '638642', v: 114, c: '放デイ２８・定超・未計画２・評減・拘減・' },
  { s: '638643', v: 283, c: '放デイ２８・有資格１４・定超・' },
  { s: '638644', v: 241, c: '放デイ２８・有資格１４・定超・評減・' },
  { s: '638645', v: 198, c: '放デイ２８・有資格１４・定超・未計画・' },
  { s: '638646', v: 168, c: '放デイ２８・有資格１４・定超・未計画・評減・' },
  { s: '638647', v: 142, c: '放デイ２８・有資格１４・定超・未計画２・' },
  { s: '638648', v: 121, c: '放デイ２８・有資格１４・定超・未計画２・評減・' },
  { s: '638649', v: 278, c: '放デイ２８・有資格１４・定超・拘減・' },
  { s: '638650', v: 236, c: '放デイ２８・有資格１４・定超・評減・拘減・' },
  { s: '638651', v: 193, c: '放デイ２８・有資格１４・定超・未計画・拘減・' },
  { s: '638652', v: 163, c: '放デイ２８・有資格１４・定超・未計画・評減・拘減・' },
  { s: '638653', v: 137, c: '放デイ２８・有資格１４・定超・未計画２・拘減・' },
  { s: '638654', v: 116, c: '放デイ２８・有資格１４・定超・未計画２・評減・拘減・' },
  { s: '638661', v: 209, c: '放デイ２９・定超・' },
  { s: '638662', v: 178, c: '放デイ２９・定超・評減・' },
  { s: '638663', v: 146, c: '放デイ２９・定超・未計画・' },
  { s: '638664', v: 124, c: '放デイ２９・定超・未計画・評減・' },
  { s: '638665', v: 105, c: '放デイ２９・定超・未計画２・' },
  { s: '638666', v: 89, c: '放デイ２９・定超・未計画２・評減・' },
  { s: '638667', v: 204, c: '放デイ２９・定超・拘減・' },
  { s: '638668', v: 173, c: '放デイ２９・定超・評減・拘減・' },
  { s: '638669', v: 141, c: '放デイ２９・定超・未計画・拘減・' },
  { s: '638670', v: 119, c: '放デイ２９・定超・未計画・評減・拘減・' },
  { s: '638671', v: 100, c: '放デイ２９・定超・未計画２・拘減・' },
  { s: '638672', v: 84, c: '放デイ２９・定超・未計画２・評減・拘減・' },
  { s: '638673', v: 212, c: '放デイ２９・有資格１５・定超・' },
  { s: '638674', v: 180, c: '放デイ２９・有資格１５・定超・評減・' },
  { s: '638675', v: 148, c: '放デイ２９・有資格１５・定超・未計画・' },
  { s: '638676', v: 126, c: '放デイ２９・有資格１５・定超・未計画・評減・' },
  { s: '638677', v: 106, c: '放デイ２９・有資格１５・定超・未計画２・' },
  { s: '638678', v: 90, c: '放デイ２９・有資格１５・定超・未計画２・評減・' },
  { s: '638679', v: 207, c: '放デイ２９・有資格１５・定超・拘減・' },
  { s: '638680', v: 175, c: '放デイ２９・有資格１５・定超・評減・拘減・' },
  { s: '638681', v: 143, c: '放デイ２９・有資格１５・定超・未計画・拘減・' },
  { s: '638682', v: 121, c: '放デイ２９・有資格１５・定超・未計画・評減・拘減・' },
  { s: '638683', v: 101, c: '放デイ２９・有資格１５・定超・未計画２・拘減・' },
  { s: '638684', v: 85, c: '放デイ２９・有資格１５・定超・未計画２・評減・拘減・' },
  { s: '638211', v: 554, c: '放デイ４・定超・' },
  { s: '638701', v: 471, c: '放デイ４・定超・評減・' },
  { s: '638212', v: 388, c: '放デイ４・定超・開減１・' },
  { s: '638702', v: 330, c: '放デイ４・定超・開減１・評減・' },
  { s: '638215', v: 471, c: '放デイ４・定超・開減２・' },
  { s: '638703', v: 400, c: '放デイ４・定超・開減２・評減・' },
  { s: '638704', v: 549, c: '放デイ４・定超・拘減・' },
  { s: '638705', v: 466, c: '放デイ４・定超・評減・拘減・' },
  { s: '638706', v: 383, c: '放デイ４・定超・開減１・拘減・' },
  { s: '638707', v: 325, c: '放デイ４・定超・開減１・評減・拘減・' },
  { s: '638708', v: 466, c: '放デイ４・定超・開減２・拘減・' },
  { s: '638709', v: 395, c: '放デイ４・定超・開減２・評減・拘減・' },
  { s: '638213', v: 388, c: '放デイ４・定超・未計画・' },
  { s: '638710', v: 330, c: '放デイ４・定超・未計画・評減・' },
  { s: '638214', v: 272, c: '放デイ４・定超・未計画・開減１・' },
  { s: '638711', v: 231, c: '放デイ４・定超・未計画・開減１・評減・' },
  { s: '638216', v: 330, c: '放デイ４・定超・未計画・開減２・' },
  { s: '638712', v: 281, c: '放デイ４・定超・未計画・開減２・評減・' },
  { s: '638713', v: 383, c: '放デイ４・定超・未計画・拘減・' },
  { s: '638714', v: 325, c: '放デイ４・定超・未計画・評減・拘減・' },
  { s: '638715', v: 267, c: '放デイ４・定超・未計画・開減１・拘減・' },
  { s: '638716', v: 226, c: '放デイ４・定超・未計画・開減１・評減・拘減・' },
  { s: '638717', v: 325, c: '放デイ４・定超・未計画・開減２・拘減・' },
  { s: '638718', v: 276, c: '放デイ４・定超・未計画・開減２・評減・拘減・' },
  { s: '638719', v: 277, c: '放デイ４・定超・未計画２・' },
  { s: '638720', v: 235, c: '放デイ４・定超・未計画２・評減・' },
  { s: '638721', v: 194, c: '放デイ４・定超・未計画２・開減１・' },
  { s: '638722', v: 165, c: '放デイ４・定超・未計画２・開減１・評減・' },
  { s: '638723', v: 235, c: '放デイ４・定超・未計画２・開減２・' },
  { s: '638724', v: 200, c: '放デイ４・定超・未計画２・開減２・評減・' },
  { s: '638725', v: 272, c: '放デイ４・定超・未計画２・拘減・' },
  { s: '638726', v: 230, c: '放デイ４・定超・未計画２・評減・拘減・' },
  { s: '638727', v: 189, c: '放デイ４・定超・未計画２・開減１・拘減・' },
  { s: '638728', v: 160, c: '放デイ４・定超・未計画２・開減１・評減・拘減・' },
  { s: '638729', v: 230, c: '放デイ４・定超・未計画２・開減２・拘減・' },
  { s: '638730', v: 195, c: '放デイ４・定超・未計画２・開減２・評減・拘減・' },
  { s: '638217', v: 563, c: '放デイ４・有資格４・定超・' },
  { s: '638731', v: 479, c: '放デイ４・有資格４・定超・評減・' },
  { s: '638218', v: 394, c: '放デイ４・有資格４・定超・開減１・' },
  { s: '638732', v: 335, c: '放デイ４・有資格４・定超・開減１・評減・' },
  { s: '638219', v: 479, c: '放デイ４・有資格４・定超・開減２・' },
  { s: '638733', v: 407, c: '放デイ４・有資格４・定超・開減２・評減・' },
  { s: '638734', v: 558, c: '放デイ４・有資格４・定超・拘減・' },
  { s: '638735', v: 474, c: '放デイ４・有資格４・定超・評減・拘減・' },
  { s: '638736', v: 389, c: '放デイ４・有資格４・定超・開減１・拘減・' },
  { s: '638737', v: 330, c: '放デイ４・有資格４・定超・開減１・評減・拘減・' },
  { s: '638738', v: 474, c: '放デイ４・有資格４・定超・開減２・拘減・' },
  { s: '638739', v: 402, c: '放デイ４・有資格４・定超・開減２・評減・拘減・' },
  { s: '638220', v: 394, c: '放デイ４・有資格４・定超・未計画・' },
  { s: '638740', v: 335, c: '放デイ４・有資格４・定超・未計画・評減・' },
  { s: '638241', v: 276, c: '放デイ４・有資格４・定超・未計画・開減１・' },
  { s: '638741', v: 235, c: '放デイ４・有資格４・定超・未計画・開減１・評減・' },
  { s: '638242', v: 335, c: '放デイ４・有資格４・定超・未計画・開減２・' },
  { s: '638742', v: 285, c: '放デイ４・有資格４・定超・未計画・開減２・評減・' },
  { s: '638743', v: 389, c: '放デイ４・有資格４・定超・未計画・拘減・' },
  { s: '638744', v: 330, c: '放デイ４・有資格４・定超・未計画・評減・拘減・' },
  { s: '638745', v: 271, c: '放デイ４・有資格４・定超・未計画・開減１・拘減・' },
  { s: '638746', v: 230, c: '放デイ４・有資格４・定超・未計画・開減１・評減・拘減・' },
  { s: '638747', v: 330, c: '放デイ４・有資格４・定超・未計画・開減２・拘減・' },
  { s: '638748', v: 280, c: '放デイ４・有資格４・定超・未計画・開減２・評減・拘減・' },
  { s: '638749', v: 282, c: '放デイ４・有資格４・定超・未計画２・' },
  { s: '638750', v: 240, c: '放デイ４・有資格４・定超・未計画２・評減・' },
  { s: '638751', v: 197, c: '放デイ４・有資格４・定超・未計画２・開減１・' },
  { s: '638752', v: 167, c: '放デイ４・有資格４・定超・未計画２・開減１・評減・' },
  { s: '638753', v: 240, c: '放デイ４・有資格４・定超・未計画２・開減２・' },
  { s: '638754', v: 204, c: '放デイ４・有資格４・定超・未計画２・開減２・評減・' },
  { s: '638755', v: 277, c: '放デイ４・有資格４・定超・未計画２・拘減・' },
  { s: '638756', v: 235, c: '放デイ４・有資格４・定超・未計画２・評減・拘減・' },
  { s: '638757', v: 192, c: '放デイ４・有資格４・定超・未計画２・開減１・拘減・' },
  { s: '638758', v: 162, c: '放デイ４・有資格４・定超・未計画２・開減１・評減・拘減・' },
  { s: '638759', v: 235, c: '放デイ４・有資格４・定超・未計画２・開減２・拘減・' },
  { s: '638760', v: 199, c: '放デイ４・有資格４・定超・未計画２・開減２・評減・拘減・' },
  { s: '638221', v: 372, c: '放デイ５・定超・' },
  { s: '638771', v: 316, c: '放デイ５・定超・評減・' },
  { s: '638222', v: 260, c: '放デイ５・定超・開減１・' },
  { s: '638772', v: 221, c: '放デイ５・定超・開減１・評減・' },
  { s: '638225', v: 316, c: '放デイ５・定超・開減２・' },
  { s: '638773', v: 269, c: '放デイ５・定超・開減２・評減・' },
  { s: '638774', v: 367, c: '放デイ５・定超・拘減・' },
  { s: '638775', v: 311, c: '放デイ５・定超・評減・拘減・' },
  { s: '638776', v: 255, c: '放デイ５・定超・開減１・拘減・' },
  { s: '638777', v: 216, c: '放デイ５・定超・開減１・評減・拘減・' },
  { s: '638778', v: 311, c: '放デイ５・定超・開減２・拘減・' },
  { s: '638779', v: 264, c: '放デイ５・定超・開減２・評減・拘減・' },
  { s: '638223', v: 260, c: '放デイ５・定超・未計画・' },
  { s: '638780', v: 221, c: '放デイ５・定超・未計画・評減・' },
  { s: '638224', v: 182, c: '放デイ５・定超・未計画・開減１・' },
  { s: '638781', v: 155, c: '放デイ５・定超・未計画・開減１・評減・' },
  { s: '638226', v: 221, c: '放デイ５・定超・未計画・開減２・' },
  { s: '638782', v: 188, c: '放デイ５・定超・未計画・開減２・評減・' },
  { s: '638783', v: 255, c: '放デイ５・定超・未計画・拘減・' },
  { s: '638784', v: 216, c: '放デイ５・定超・未計画・評減・拘減・' },
  { s: '638785', v: 177, c: '放デイ５・定超・未計画・開減１・拘減・' },
  { s: '638786', v: 150, c: '放デイ５・定超・未計画・開減１・評減・拘減・' },
  { s: '638787', v: 216, c: '放デイ５・定超・未計画・開減２・拘減・' },
  { s: '638788', v: 183, c: '放デイ５・定超・未計画・開減２・評減・拘減・' },
  { s: '638789', v: 186, c: '放デイ５・定超・未計画２・' },
  { s: '638790', v: 158, c: '放デイ５・定超・未計画２・評減・' },
  { s: '638791', v: 130, c: '放デイ５・定超・未計画２・開減１・' },
  { s: '638792', v: 111, c: '放デイ５・定超・未計画２・開減１・評減・' },
  { s: '638793', v: 158, c: '放デイ５・定超・未計画２・開減２・' },
  { s: '638794', v: 134, c: '放デイ５・定超・未計画２・開減２・評減・' },
  { s: '638795', v: 181, c: '放デイ５・定超・未計画２・拘減・' },
  { s: '638796', v: 153, c: '放デイ５・定超・未計画２・評減・拘減・' },
  { s: '638797', v: 125, c: '放デイ５・定超・未計画２・開減１・拘減・' },
  { s: '638798', v: 106, c: '放デイ５・定超・未計画２・開減１・評減・拘減・' },
  { s: '638799', v: 153, c: '放デイ５・定超・未計画２・開減２・拘減・' },
  { s: '638800', v: 129, c: '放デイ５・定超・未計画２・開減２・評減・拘減・' },
  { s: '638227', v: 378, c: '放デイ５・有資格５・定超・' },
  { s: '638801', v: 321, c: '放デイ５・有資格５・定超・評減・' },
  { s: '638228', v: 265, c: '放デイ５・有資格５・定超・開減１・' },
  { s: '638802', v: 225, c: '放デイ５・有資格５・定超・開減１・評減・' },
  { s: '638229', v: 321, c: '放デイ５・有資格５・定超・開減２・' },
  { s: '638803', v: 273, c: '放デイ５・有資格５・定超・開減２・評減・' },
  { s: '638804', v: 373, c: '放デイ５・有資格５・定超・拘減・' },
  { s: '638805', v: 316, c: '放デイ５・有資格５・定超・評減・拘減・' },
  { s: '638806', v: 260, c: '放デイ５・有資格５・定超・開減１・拘減・' },
  { s: '638807', v: 220, c: '放デイ５・有資格５・定超・開減１・評減・拘減・' },
  { s: '638808', v: 316, c: '放デイ５・有資格５・定超・開減２・拘減・' },
  { s: '638809', v: 268, c: '放デイ５・有資格５・定超・開減２・評減・拘減・' },
  { s: '638230', v: 265, c: '放デイ５・有資格５・定超・未計画・' },
  { s: '638810', v: 225, c: '放デイ５・有資格５・定超・未計画・評減・' },
  { s: '638251', v: 186, c: '放デイ５・有資格５・定超・未計画・開減１・' },
  { s: '638811', v: 158, c: '放デイ５・有資格５・定超・未計画・開減１・評減・' },
  { s: '638252', v: 225, c: '放デイ５・有資格５・定超・未計画・開減２・' },
  { s: '638812', v: 191, c: '放デイ５・有資格５・定超・未計画・開減２・評減・' },
  { s: '638813', v: 260, c: '放デイ５・有資格５・定超・未計画・拘減・' },
  { s: '638814', v: 220, c: '放デイ５・有資格５・定超・未計画・評減・拘減・' },
  { s: '638815', v: 181, c: '放デイ５・有資格５・定超・未計画・開減１・拘減・' },
  { s: '638816', v: 153, c: '放デイ５・有資格５・定超・未計画・開減１・評減・拘減・' },
  { s: '638817', v: 220, c: '放デイ５・有資格５・定超・未計画・開減２・拘減・' },
  { s: '638818', v: 186, c: '放デイ５・有資格５・定超・未計画・開減２・評減・拘減・' },
  { s: '638819', v: 189, c: '放デイ５・有資格５・定超・未計画２・' },
  { s: '638820', v: 161, c: '放デイ５・有資格５・定超・未計画２・評減・' },
  { s: '638821', v: 132, c: '放デイ５・有資格５・定超・未計画２・開減１・' },
  { s: '638822', v: 112, c: '放デイ５・有資格５・定超・未計画２・開減１・評減・' },
  { s: '638823', v: 161, c: '放デイ５・有資格５・定超・未計画２・開減２・' },
  { s: '638824', v: 137, c: '放デイ５・有資格５・定超・未計画２・開減２・評減・' },
  { s: '638825', v: 184, c: '放デイ５・有資格５・定超・未計画２・拘減・' },
  { s: '638826', v: 156, c: '放デイ５・有資格５・定超・未計画２・評減・拘減・' },
  { s: '638827', v: 127, c: '放デイ５・有資格５・定超・未計画２・開減１・拘減・' },
  { s: '638828', v: 107, c: '放デイ５・有資格５・定超・未計画２・開減１・評減・拘減・' },
  { s: '638829', v: 156, c: '放デイ５・有資格５・定超・未計画２・開減２・拘減・' },
  { s: '638830', v: 132, c: '放デイ５・有資格５・定超・未計画２・開減２・評減・拘減・' },
  { s: '638231', v: 288, c: '放デイ６・定超・' },
  { s: '638841', v: 245, c: '放デイ６・定超・評減・' },
  { s: '638232', v: 202, c: '放デイ６・定超・開減１・' },
  { s: '638842', v: 172, c: '放デイ６・定超・開減１・評減・' },
  { s: '638235', v: 245, c: '放デイ６・定超・開減２・' },
  { s: '638843', v: 208, c: '放デイ６・定超・開減２・評減・' },
  { s: '638844', v: 283, c: '放デイ６・定超・拘減・' },
  { s: '638845', v: 240, c: '放デイ６・定超・評減・拘減・' },
  { s: '638846', v: 197, c: '放デイ６・定超・開減１・拘減・' },
  { s: '638847', v: 167, c: '放デイ６・定超・開減１・評減・拘減・' },
  { s: '638848', v: 240, c: '放デイ６・定超・開減２・拘減・' },
  { s: '638849', v: 203, c: '放デイ６・定超・開減２・評減・拘減・' },
  { s: '638233', v: 202, c: '放デイ６・定超・未計画・' },
  { s: '638850', v: 172, c: '放デイ６・定超・未計画・評減・' },
  { s: '638234', v: 141, c: '放デイ６・定超・未計画・開減１・' },
  { s: '638851', v: 120, c: '放デイ６・定超・未計画・開減１・評減・' },
  { s: '638236', v: 172, c: '放デイ６・定超・未計画・開減２・' },
  { s: '638852', v: 146, c: '放デイ６・定超・未計画・開減２・評減・' },
  { s: '638853', v: 197, c: '放デイ６・定超・未計画・拘減・' },
  { s: '638854', v: 167, c: '放デイ６・定超・未計画・評減・拘減・' },
  { s: '638855', v: 136, c: '放デイ６・定超・未計画・開減１・拘減・' },
  { s: '638856', v: 115, c: '放デイ６・定超・未計画・開減１・評減・拘減・' },
  { s: '638857', v: 167, c: '放デイ６・定超・未計画・開減２・拘減・' },
  { s: '638858', v: 141, c: '放デイ６・定超・未計画・開減２・評減・拘減・' },
  { s: '638859', v: 144, c: '放デイ６・定超・未計画２・' },
  { s: '638860', v: 122, c: '放デイ６・定超・未計画２・評減・' },
  { s: '638861', v: 101, c: '放デイ６・定超・未計画２・開減１・' },
  { s: '638862', v: 86, c: '放デイ６・定超・未計画２・開減１・評減・' },
  { s: '638863', v: 122, c: '放デイ６・定超・未計画２・開減２・' },
  { s: '638864', v: 104, c: '放デイ６・定超・未計画２・開減２・評減・' },
  { s: '638865', v: 139, c: '放デイ６・定超・未計画２・拘減・' },
  { s: '638866', v: 117, c: '放デイ６・定超・未計画２・評減・拘減・' },
  { s: '638867', v: 96, c: '放デイ６・定超・未計画２・開減１・拘減・' },
  { s: '638868', v: 81, c: '放デイ６・定超・未計画２・開減１・評減・拘減・' },
  { s: '638869', v: 117, c: '放デイ６・定超・未計画２・開減２・拘減・' },
  { s: '638870', v: 99, c: '放デイ６・定超・未計画２・開減２・評減・拘減・' },
  { s: '638237', v: 293, c: '放デイ６・有資格６・定超・' },
  { s: '638871', v: 249, c: '放デイ６・有資格６・定超・評減・' },
  { s: '638238', v: 205, c: '放デイ６・有資格６・定超・開減１・' },
  { s: '638872', v: 174, c: '放デイ６・有資格６・定超・開減１・評減・' },
  { s: '638239', v: 249, c: '放デイ６・有資格６・定超・開減２・' },
  { s: '638873', v: 212, c: '放デイ６・有資格６・定超・開減２・評減・' },
  { s: '638874', v: 288, c: '放デイ６・有資格６・定超・拘減・' },
  { s: '638875', v: 244, c: '放デイ６・有資格６・定超・評減・拘減・' },
  { s: '638876', v: 200, c: '放デイ６・有資格６・定超・開減１・拘減・' },
  { s: '638877', v: 169, c: '放デイ６・有資格６・定超・開減１・評減・拘減・' },
  { s: '638878', v: 244, c: '放デイ６・有資格６・定超・開減２・拘減・' },
  { s: '638879', v: 207, c: '放デイ６・有資格６・定超・開減２・評減・拘減・' },
  { s: '638240', v: 205, c: '放デイ６・有資格６・定超・未計画・' },
  { s: '638880', v: 174, c: '放デイ６・有資格６・定超・未計画・評減・' },
  { s: '638261', v: 144, c: '放デイ６・有資格６・定超・未計画・開減１・' },
  { s: '638881', v: 122, c: '放デイ６・有資格６・定超・未計画・開減１・評減・' },
  { s: '638262', v: 174, c: '放デイ６・有資格６・定超・未計画・開減２・' },
  { s: '638882', v: 148, c: '放デイ６・有資格６・定超・未計画・開減２・評減・' },
  { s: '638883', v: 200, c: '放デイ６・有資格６・定超・未計画・拘減・' },
  { s: '638884', v: 169, c: '放デイ６・有資格６・定超・未計画・評減・拘減・' },
  { s: '638885', v: 139, c: '放デイ６・有資格６・定超・未計画・開減１・拘減・' },
  { s: '638886', v: 117, c: '放デイ６・有資格６・定超・未計画・開減１・評減・拘減・' },
  { s: '638887', v: 169, c: '放デイ６・有資格６・定超・未計画・開減２・拘減・' },
  { s: '638888', v: 143, c: '放デイ６・有資格６・定超・未計画・開減２・評減・拘減・' },
  { s: '638889', v: 147, c: '放デイ６・有資格６・定超・未計画２・' },
  { s: '638890', v: 125, c: '放デイ６・有資格６・定超・未計画２・評減・' },
  { s: '638891', v: 103, c: '放デイ６・有資格６・定超・未計画２・開減１・' },
  { s: '638892', v: 88, c: '放デイ６・有資格６・定超・未計画２・開減１・評減・' },
  { s: '638893', v: 125, c: '放デイ６・有資格６・定超・未計画２・開減２・' },
  { s: '638894', v: 106, c: '放デイ６・有資格６・定超・未計画２・開減２・評減・' },
  { s: '638895', v: 142, c: '放デイ６・有資格６・定超・未計画２・拘減・' },
  { s: '638896', v: 120, c: '放デイ６・有資格６・定超・未計画２・評減・拘減・' },
  { s: '638897', v: 98, c: '放デイ６・有資格６・定超・未計画２・開減１・拘減・' },
  { s: '638898', v: 83, c: '放デイ６・有資格６・定超・未計画２・開減１・評減・拘減・' },
  { s: '638899', v: 120, c: '放デイ６・有資格６・定超・未計画２・開減２・拘減・' },
  { s: '638900', v: 101, c: '放デイ６・有資格６・定超・未計画２・開減２・評減・拘減・' },
  { s: '638901', v: 511, c: '放デイ３０・定超・' },
  { s: '638902', v: 434, c: '放デイ３０・定超・評減・' },
  { s: '638903', v: 358, c: '放デイ３０・定超・開減１・' },
  { s: '638904', v: 304, c: '放デイ３０・定超・開減１・評減・' },
  { s: '638905', v: 434, c: '放デイ３０・定超・開減２・' },
  { s: '638906', v: 369, c: '放デイ３０・定超・開減２・評減・' },
  { s: '638907', v: 506, c: '放デイ３０・定超・拘減・' },
  { s: '638908', v: 429, c: '放デイ３０・定超・評減・拘減・' },
  { s: '638909', v: 353, c: '放デイ３０・定超・開減１・拘減・' },
  { s: '638910', v: 299, c: '放デイ３０・定超・開減１・評減・拘減・' },
  { s: '638911', v: 429, c: '放デイ３０・定超・開減２・拘減・' },
  { s: '638912', v: 364, c: '放デイ３０・定超・開減２・評減・拘減・' },
  { s: '638913', v: 358, c: '放デイ３０・定超・未計画・' },
  { s: '638914', v: 304, c: '放デイ３０・定超・未計画・評減・' },
  { s: '638915', v: 251, c: '放デイ３０・定超・未計画・開減１・' },
  { s: '638916', v: 213, c: '放デイ３０・定超・未計画・開減１・評減・' },
  { s: '638917', v: 304, c: '放デイ３０・定超・未計画・開減２・' },
  { s: '638918', v: 258, c: '放デイ３０・定超・未計画・開減２・評減・' },
  { s: '638919', v: 353, c: '放デイ３０・定超・未計画・拘減・' },
  { s: '638920', v: 299, c: '放デイ３０・定超・未計画・評減・拘減・' },
  { s: '638921', v: 246, c: '放デイ３０・定超・未計画・開減１・拘減・' },
  { s: '638922', v: 208, c: '放デイ３０・定超・未計画・開減１・評減・拘減・' },
  { s: '638923', v: 299, c: '放デイ３０・定超・未計画・開減２・拘減・' },
  { s: '638924', v: 253, c: '放デイ３０・定超・未計画・開減２・評減・拘減・' },
  { s: '638925', v: 256, c: '放デイ３０・定超・未計画２・' },
  { s: '638926', v: 218, c: '放デイ３０・定超・未計画２・評減・' },
  { s: '638927', v: 179, c: '放デイ３０・定超・未計画２・開減１・' },
  { s: '638928', v: 152, c: '放デイ３０・定超・未計画２・開減１・評減・' },
  { s: '638929', v: 218, c: '放デイ３０・定超・未計画２・開減２・' },
  { s: '638930', v: 185, c: '放デイ３０・定超・未計画２・開減２・評減・' },
  { s: '638931', v: 251, c: '放デイ３０・定超・未計画２・拘減・' },
  { s: '638932', v: 213, c: '放デイ３０・定超・未計画２・評減・拘減・' },
  { s: '638933', v: 174, c: '放デイ３０・定超・未計画２・開減１・拘減・' },
  { s: '638934', v: 147, c: '放デイ３０・定超・未計画２・開減１・評減・拘減・' },
  { s: '638935', v: 213, c: '放デイ３０・定超・未計画２・開減２・拘減・' },
  { s: '638936', v: 180, c: '放デイ３０・定超・未計画２・開減２・評減・拘減・' },
  { s: '638937', v: 519, c: '放デイ３０・有資格１６・定超・' },
  { s: '638938', v: 441, c: '放デイ３０・有資格１６・定超・評減・' },
  { s: '638939', v: 363, c: '放デイ３０・有資格１６・定超・開減１・' },
  { s: '638940', v: 309, c: '放デイ３０・有資格１６・定超・開減１・評減・' },
  { s: '638941', v: 441, c: '放デイ３０・有資格１６・定超・開減２・' },
  { s: '638942', v: 375, c: '放デイ３０・有資格１６・定超・開減２・評減・' },
  { s: '638943', v: 514, c: '放デイ３０・有資格１６・定超・拘減・' },
  { s: '638944', v: 436, c: '放デイ３０・有資格１６・定超・評減・拘減・' },
  { s: '638945', v: 358, c: '放デイ３０・有資格１６・定超・開減１・拘減・' },
  { s: '638946', v: 304, c: '放デイ３０・有資格１６・定超・開減１・評減・拘減・' },
  { s: '638947', v: 436, c: '放デイ３０・有資格１６・定超・開減２・拘減・' },
  { s: '638948', v: 370, c: '放デイ３０・有資格１６・定超・開減２・評減・拘減・' },
  { s: '638949', v: 363, c: '放デイ３０・有資格１６・定超・未計画・' },
  { s: '638950', v: 309, c: '放デイ３０・有資格１６・定超・未計画・評減・' },
  { s: '638951', v: 254, c: '放デイ３０・有資格１６・定超・未計画・開減１・' },
  { s: '638952', v: 216, c: '放デイ３０・有資格１６・定超・未計画・開減１・評減・' },
  { s: '638953', v: 309, c: '放デイ３０・有資格１６・定超・未計画・開減２・' },
  { s: '638954', v: 263, c: '放デイ３０・有資格１６・定超・未計画・開減２・評減・' },
  { s: '638955', v: 358, c: '放デイ３０・有資格１６・定超・未計画・拘減・' },
  { s: '638956', v: 304, c: '放デイ３０・有資格１６・定超・未計画・評減・拘減・' },
  { s: '638957', v: 249, c: '放デイ３０・有資格１６・定超・未計画・開減１・拘減・' },
  { s: '638958', v: 211, c: '放デイ３０・有資格１６・定超・未計画・開減１・評減・拘減・' },
  { s: '638959', v: 304, c: '放デイ３０・有資格１６・定超・未計画・開減２・拘減・' },
  { s: '638960', v: 258, c: '放デイ３０・有資格１６・定超・未計画・開減２・評減・拘減・' },
  { s: '638961', v: 260, c: '放デイ３０・有資格１６・定超・未計画２・' },
  { s: '638962', v: 221, c: '放デイ３０・有資格１６・定超・未計画２・評減・' },
  { s: '638963', v: 182, c: '放デイ３０・有資格１６・定超・未計画２・開減１・' },
  { s: '638964', v: 155, c: '放デイ３０・有資格１６・定超・未計画２・開減１・評減・' },
  { s: '638965', v: 221, c: '放デイ３０・有資格１６・定超・未計画２・開減２・' },
  { s: '638966', v: 188, c: '放デイ３０・有資格１６・定超・未計画２・開減２・評減・' },
  { s: '638967', v: 255, c: '放デイ３０・有資格１６・定超・未計画２・拘減・' },
  { s: '638968', v: 216, c: '放デイ３０・有資格１６・定超・未計画２・評減・拘減・' },
  { s: '638969', v: 177, c: '放デイ３０・有資格１６・定超・未計画２・開減１・拘減・' },
  { s: '638970', v: 150, c: '放デイ３０・有資格１６・定超・未計画２・開減１・評減・拘減・' },
  { s: '638971', v: 216, c: '放デイ３０・有資格１６・定超・未計画２・開減２・拘減・' },
  { s: '638972', v: 183, c: '放デイ３０・有資格１６・定超・未計画２・開減２・評減・拘減・' },
  { s: '638981', v: 340, c: '放デイ３１・定超・' },
  { s: '638982', v: 289, c: '放デイ３１・定超・評減・' },
  { s: '638983', v: 238, c: '放デイ３１・定超・開減１・' },
  { s: '638984', v: 202, c: '放デイ３１・定超・開減１・評減・' },
  { s: '638985', v: 289, c: '放デイ３１・定超・開減２・' },
  { s: '638986', v: 246, c: '放デイ３１・定超・開減２・評減・' },
  { s: '638987', v: 335, c: '放デイ３１・定超・拘減・' },
  { s: '638988', v: 284, c: '放デイ３１・定超・評減・拘減・' },
  { s: '638989', v: 233, c: '放デイ３１・定超・開減１・拘減・' },
  { s: '638990', v: 197, c: '放デイ３１・定超・開減１・評減・拘減・' },
  { s: '638991', v: 284, c: '放デイ３１・定超・開減２・拘減・' },
  { s: '638992', v: 241, c: '放デイ３１・定超・開減２・評減・拘減・' },
  { s: '638993', v: 238, c: '放デイ３１・定超・未計画・' },
  { s: '638994', v: 202, c: '放デイ３１・定超・未計画・評減・' },
  { s: '638995', v: 167, c: '放デイ３１・定超・未計画・開減１・' },
  { s: '638996', v: 142, c: '放デイ３１・定超・未計画・開減１・評減・' },
  { s: '638997', v: 202, c: '放デイ３１・定超・未計画・開減２・' },
  { s: '638998', v: 172, c: '放デイ３１・定超・未計画・開減２・評減・' },
  { s: '638999', v: 233, c: '放デイ３１・定超・未計画・拘減・' },
  { s: '637000', v: 197, c: '放デイ３１・定超・未計画・評減・拘減・' },
  { s: '637001', v: 162, c: '放デイ３１・定超・未計画・開減１・拘減・' },
  { s: '637002', v: 137, c: '放デイ３１・定超・未計画・開減１・評減・拘減・' },
  { s: '637003', v: 197, c: '放デイ３１・定超・未計画・開減２・拘減・' },
  { s: '637004', v: 167, c: '放デイ３１・定超・未計画・開減２・評減・拘減・' },
  { s: '637005', v: 170, c: '放デイ３１・定超・未計画２・' },
  { s: '637006', v: 145, c: '放デイ３１・定超・未計画２・評減・' },
  { s: '637007', v: 119, c: '放デイ３１・定超・未計画２・開減１・' },
  { s: '637008', v: 101, c: '放デイ３１・定超・未計画２・開減１・評減・' },
  { s: '637009', v: 145, c: '放デイ３１・定超・未計画２・開減２・' },
  { s: '637010', v: 123, c: '放デイ３１・定超・未計画２・開減２・評減・' },
  { s: '637011', v: 165, c: '放デイ３１・定超・未計画２・拘減・' },
  { s: '637012', v: 140, c: '放デイ３１・定超・未計画２・評減・拘減・' },
  { s: '637013', v: 114, c: '放デイ３１・定超・未計画２・開減１・拘減・' },
  { s: '637014', v: 96, c: '放デイ３１・定超・未計画２・開減１・評減・拘減・' },
  { s: '637015', v: 140, c: '放デイ３１・定超・未計画２・開減２・拘減・' },
  { s: '637016', v: 118, c: '放デイ３１・定超・未計画２・開減２・評減・拘減・' },
  { s: '637017', v: 346, c: '放デイ３１・有資格１７・定超・' },
  { s: '637018', v: 294, c: '放デイ３１・有資格１７・定超・評減・' },
  { s: '637019', v: 242, c: '放デイ３１・有資格１７・定超・開減１・' },
  { s: '637020', v: 206, c: '放デイ３１・有資格１７・定超・開減１・評減・' },
  { s: '637021', v: 294, c: '放デイ３１・有資格１７・定超・開減２・' },
  { s: '637022', v: 250, c: '放デイ３１・有資格１７・定超・開減２・評減・' },
  { s: '637023', v: 341, c: '放デイ３１・有資格１７・定超・拘減・' },
  { s: '637024', v: 289, c: '放デイ３１・有資格１７・定超・評減・拘減・' },
  { s: '637025', v: 237, c: '放デイ３１・有資格１７・定超・開減１・拘減・' },
  { s: '637026', v: 201, c: '放デイ３１・有資格１７・定超・開減１・評減・拘減・' },
  { s: '637027', v: 289, c: '放デイ３１・有資格１７・定超・開減２・拘減・' },
  { s: '637028', v: 245, c: '放デイ３１・有資格１７・定超・開減２・評減・拘減・' },
  { s: '637029', v: 242, c: '放デイ３１・有資格１７・定超・未計画・' },
  { s: '637030', v: 206, c: '放デイ３１・有資格１７・定超・未計画・評減・' },
  { s: '637031', v: 169, c: '放デイ３１・有資格１７・定超・未計画・開減１・' },
  { s: '637032', v: 144, c: '放デイ３１・有資格１７・定超・未計画・開減１・評減・' },
  { s: '637033', v: 206, c: '放デイ３１・有資格１７・定超・未計画・開減２・' },
  { s: '637034', v: 175, c: '放デイ３１・有資格１７・定超・未計画・開減２・評減・' },
  { s: '637035', v: 237, c: '放デイ３１・有資格１７・定超・未計画・拘減・' },
  { s: '637036', v: 201, c: '放デイ３１・有資格１７・定超・未計画・評減・拘減・' },
  { s: '637037', v: 164, c: '放デイ３１・有資格１７・定超・未計画・開減１・拘減・' },
  { s: '637038', v: 139, c: '放デイ３１・有資格１７・定超・未計画・開減１・評減・拘減・' },
  { s: '637039', v: 201, c: '放デイ３１・有資格１７・定超・未計画・開減２・拘減・' },
  { s: '637040', v: 170, c: '放デイ３１・有資格１７・定超・未計画・開減２・評減・拘減・' },
  { s: '637041', v: 173, c: '放デイ３１・有資格１７・定超・未計画２・' },
  { s: '637042', v: 147, c: '放デイ３１・有資格１７・定超・未計画２・評減・' },
  { s: '637043', v: 121, c: '放デイ３１・有資格１７・定超・未計画２・開減１・' },
  { s: '637044', v: 103, c: '放デイ３１・有資格１７・定超・未計画２・開減１・評減・' },
  { s: '637045', v: 147, c: '放デイ３１・有資格１７・定超・未計画２・開減２・' },
  { s: '637046', v: 125, c: '放デイ３１・有資格１７・定超・未計画２・開減２・評減・' },
  { s: '637047', v: 168, c: '放デイ３１・有資格１７・定超・未計画２・拘減・' },
  { s: '637048', v: 142, c: '放デイ３１・有資格１７・定超・未計画２・評減・拘減・' },
  { s: '637049', v: 116, c: '放デイ３１・有資格１７・定超・未計画２・開減１・拘減・' },
  { s: '637050', v: 98, c: '放デイ３１・有資格１７・定超・未計画２・開減１・評減・拘減・' },
  { s: '637051', v: 142, c: '放デイ３１・有資格１７・定超・未計画２・開減２・拘減・' },
  { s: '637052', v: 120, c: '放デイ３１・有資格１７・定超・未計画２・開減２・評減・拘減・' },
  { s: '637061', v: 263, c: '放デイ３２・定超・' },
  { s: '637062', v: 224, c: '放デイ３２・定超・評減・' },
  { s: '637063', v: 184, c: '放デイ３２・定超・開減１・' },
  { s: '637064', v: 156, c: '放デイ３２・定超・開減１・評減・' },
  { s: '637065', v: 224, c: '放デイ３２・定超・開減２・' },
  { s: '637066', v: 190, c: '放デイ３２・定超・開減２・評減・' },
  { s: '637067', v: 258, c: '放デイ３２・定超・拘減・' },
  { s: '637068', v: 219, c: '放デイ３２・定超・評減・拘減・' },
  { s: '637069', v: 179, c: '放デイ３２・定超・開減１・拘減・' },
  { s: '637070', v: 151, c: '放デイ３２・定超・開減１・評減・拘減・' },
  { s: '637071', v: 219, c: '放デイ３２・定超・開減２・拘減・' },
  { s: '637072', v: 185, c: '放デイ３２・定超・開減２・評減・拘減・' },
  { s: '637073', v: 184, c: '放デイ３２・定超・未計画・' },
  { s: '637074', v: 156, c: '放デイ３２・定超・未計画・評減・' },
  { s: '637075', v: 129, c: '放デイ３２・定超・未計画・開減１・' },
  { s: '637076', v: 110, c: '放デイ３２・定超・未計画・開減１・評減・' },
  { s: '637077', v: 156, c: '放デイ３２・定超・未計画・開減２・' },
  { s: '637078', v: 133, c: '放デイ３２・定超・未計画・開減２・評減・' },
  { s: '637079', v: 179, c: '放デイ３２・定超・未計画・拘減・' },
  { s: '637080', v: 151, c: '放デイ３２・定超・未計画・評減・拘減・' },
  { s: '637081', v: 124, c: '放デイ３２・定超・未計画・開減１・拘減・' },
  { s: '637082', v: 105, c: '放デイ３２・定超・未計画・開減１・評減・拘減・' },
  { s: '637083', v: 151, c: '放デイ３２・定超・未計画・開減２・拘減・' },
  { s: '637084', v: 128, c: '放デイ３２・定超・未計画・開減２・評減・拘減・' },
  { s: '637085', v: 132, c: '放デイ３２・定超・未計画２・' },
  { s: '637086', v: 112, c: '放デイ３２・定超・未計画２・評減・' },
  { s: '637087', v: 92, c: '放デイ３２・定超・未計画２・開減１・' },
  { s: '637088', v: 78, c: '放デイ３２・定超・未計画２・開減１・評減・' },
  { s: '637089', v: 112, c: '放デイ３２・定超・未計画２・開減２・' },
  { s: '637090', v: 95, c: '放デイ３２・定超・未計画２・開減２・評減・' },
  { s: '637091', v: 127, c: '放デイ３２・定超・未計画２・拘減・' },
  { s: '637092', v: 107, c: '放デイ３２・定超・未計画２・評減・拘減・' },
  { s: '637093', v: 87, c: '放デイ３２・定超・未計画２・開減１・拘減・' },
  { s: '637094', v: 73, c: '放デイ３２・定超・未計画２・開減１・評減・拘減・' },
  { s: '637095', v: 107, c: '放デイ３２・定超・未計画２・開減２・拘減・' },
  { s: '637096', v: 90, c: '放デイ３２・定超・未計画２・開減２・評減・拘減・' },
  { s: '637097', v: 267, c: '放デイ３２・有資格１８・定超・' },
  { s: '637098', v: 227, c: '放デイ３２・有資格１８・定超・評減・' },
  { s: '637099', v: 187, c: '放デイ３２・有資格１８・定超・開減１・' },
  { s: '637100', v: 159, c: '放デイ３２・有資格１８・定超・開減１・評減・' },
  { s: '637101', v: 227, c: '放デイ３２・有資格１８・定超・開減２・' },
  { s: '637102', v: 193, c: '放デイ３２・有資格１８・定超・開減２・評減・' },
  { s: '637103', v: 262, c: '放デイ３２・有資格１８・定超・拘減・' },
  { s: '637104', v: 222, c: '放デイ３２・有資格１８・定超・評減・拘減・' },
  { s: '637105', v: 182, c: '放デイ３２・有資格１８・定超・開減１・拘減・' },
  { s: '637106', v: 154, c: '放デイ３２・有資格１８・定超・開減１・評減・拘減・' },
  { s: '637107', v: 222, c: '放デイ３２・有資格１８・定超・開減２・拘減・' },
  { s: '637108', v: 188, c: '放デイ３２・有資格１８・定超・開減２・評減・拘減・' },
  { s: '637109', v: 187, c: '放デイ３２・有資格１８・定超・未計画・' },
  { s: '637110', v: 159, c: '放デイ３２・有資格１８・定超・未計画・評減・' },
  { s: '637111', v: 131, c: '放デイ３２・有資格１８・定超・未計画・開減１・' },
  { s: '637112', v: 111, c: '放デイ３２・有資格１８・定超・未計画・開減１・評減・' },
  { s: '637113', v: 159, c: '放デイ３２・有資格１８・定超・未計画・開減２・' },
  { s: '637114', v: 135, c: '放デイ３２・有資格１８・定超・未計画・開減２・評減・' },
  { s: '637115', v: 182, c: '放デイ３２・有資格１８・定超・未計画・拘減・' },
  { s: '637116', v: 154, c: '放デイ３２・有資格１８・定超・未計画・評減・拘減・' },
  { s: '637117', v: 126, c: '放デイ３２・有資格１８・定超・未計画・開減１・拘減・' },
  { s: '637118', v: 106, c: '放デイ３２・有資格１８・定超・未計画・開減１・評減・拘減・' },
  { s: '637119', v: 154, c: '放デイ３２・有資格１８・定超・未計画・開減２・拘減・' },
  { s: '637120', v: 130, c: '放デイ３２・有資格１８・定超・未計画・開減２・評減・拘減・' },
  { s: '637121', v: 134, c: '放デイ３２・有資格１８・定超・未計画２・' },
  { s: '637122', v: 114, c: '放デイ３２・有資格１８・定超・未計画２・評減・' },
  { s: '637123', v: 94, c: '放デイ３２・有資格１８・定超・未計画２・開減１・' },
  { s: '637124', v: 80, c: '放デイ３２・有資格１８・定超・未計画２・開減１・評減・' },
  { s: '637125', v: 114, c: '放デイ３２・有資格１８・定超・未計画２・開減２・' },
  { s: '637126', v: 97, c: '放デイ３２・有資格１８・定超・未計画２・開減２・評減・' },
  { s: '637127', v: 129, c: '放デイ３２・有資格１８・定超・未計画２・拘減・' },
  { s: '637128', v: 109, c: '放デイ３２・有資格１８・定超・未計画２・評減・拘減・' },
  { s: '637129', v: 89, c: '放デイ３２・有資格１８・定超・未計画２・開減１・拘減・' },
  { s: '637130', v: 75, c: '放デイ３２・有資格１８・定超・未計画２・開減１・評減・拘減・' },
  { s: '637131', v: 109, c: '放デイ３２・有資格１８・定超・未計画２・開減２・拘減・' },
  { s: '637132', v: 92, c: '放デイ３２・有資格１８・定超・未計画２・開減２・評減・拘減・' },
  { s: '638311', v: 1228, c: '放デイ７・定超・' },
  { s: '637141', v: 1044, c: '放デイ７・定超・評減・' },
  { s: '638312', v: 860, c: '放デイ７・定超・未計画・' },
  { s: '637142', v: 731, c: '放デイ７・定超・未計画・評減・' },
  { s: '637143', v: 614, c: '放デイ７・定超・未計画２・' },
  { s: '637144', v: 522, c: '放デイ７・定超・未計画２・評減・' },
  { s: '637145', v: 1223, c: '放デイ７・定超・拘減・' },
  { s: '637146', v: 1039, c: '放デイ７・定超・評減・拘減・' },
  { s: '637147', v: 855, c: '放デイ７・定超・未計画・拘減・' },
  { s: '637148', v: 726, c: '放デイ７・定超・未計画・評減・拘減・' },
  { s: '637149', v: 609, c: '放デイ７・定超・未計画２・拘減・' },
  { s: '637150', v: 517, c: '放デイ７・定超・未計画２・評減・拘減・' },
  { s: '638341', v: 1026, c: '放デイ８・定超・' },
  { s: '637151', v: 872, c: '放デイ８・定超・評減・' },
  { s: '638342', v: 718, c: '放デイ８・定超・未計画・' },
  { s: '637152', v: 610, c: '放デイ８・定超・未計画・評減・' },
  { s: '637153', v: 513, c: '放デイ８・定超・未計画２・' },
  { s: '637154', v: 436, c: '放デイ８・定超・未計画２・評減・' },
  { s: '637155', v: 1021, c: '放デイ８・定超・拘減・' },
  { s: '637156', v: 867, c: '放デイ８・定超・評減・拘減・' },
  { s: '637157', v: 713, c: '放デイ８・定超・未計画・拘減・' },
  { s: '637158', v: 605, c: '放デイ８・定超・未計画・評減・拘減・' },
  { s: '637159', v: 508, c: '放デイ８・定超・未計画２・拘減・' },
  { s: '637160', v: 431, c: '放デイ８・定超・未計画２・評減・拘減・' },
  { s: '638351', v: 883, c: '放デイ９・定超・' },
  { s: '637161', v: 751, c: '放デイ９・定超・評減・' },
  { s: '638352', v: 618, c: '放デイ９・定超・未計画・' },
  { s: '637162', v: 525, c: '放デイ９・定超・未計画・評減・' },
  { s: '637163', v: 442, c: '放デイ９・定超・未計画２・' },
  { s: '637164', v: 376, c: '放デイ９・定超・未計画２・評減・' },
  { s: '637165', v: 878, c: '放デイ９・定超・拘減・' },
  { s: '637166', v: 746, c: '放デイ９・定超・評減・拘減・' },
  { s: '637167', v: 613, c: '放デイ９・定超・未計画・拘減・' },
  { s: '637168', v: 520, c: '放デイ９・定超・未計画・評減・拘減・' },
  { s: '637169', v: 437, c: '放デイ９・定超・未計画２・拘減・' },
  { s: '637170', v: 371, c: '放デイ９・定超・未計画２・評減・拘減・' },
  { s: '638361', v: 775, c: '放デイ１０・定超・' },
  { s: '637171', v: 659, c: '放デイ１０・定超・評減・' },
  { s: '638362', v: 543, c: '放デイ１０・定超・未計画・' },
  { s: '637172', v: 462, c: '放デイ１０・定超・未計画・評減・' },
  { s: '637173', v: 388, c: '放デイ１０・定超・未計画２・' },
  { s: '637174', v: 330, c: '放デイ１０・定超・未計画２・評減・' },
  { s: '637175', v: 770, c: '放デイ１０・定超・拘減・' },
  { s: '637176', v: 654, c: '放デイ１０・定超・評減・拘減・' },
  { s: '637177', v: 538, c: '放デイ１０・定超・未計画・拘減・' },
  { s: '637178', v: 457, c: '放デイ１０・定超・未計画・評減・拘減・' },
  { s: '637179', v: 383, c: '放デイ１０・定超・未計画２・拘減・' },
  { s: '637180', v: 325, c: '放デイ１０・定超・未計画２・評減・拘減・' },
  { s: '638371', v: 692, c: '放デイ１１・定超・' },
  { s: '637181', v: 588, c: '放デイ１１・定超・評減・' },
  { s: '638372', v: 484, c: '放デイ１１・定超・未計画・' },
  { s: '637182', v: 411, c: '放デイ１１・定超・未計画・評減・' },
  { s: '637183', v: 346, c: '放デイ１１・定超・未計画２・' },
  { s: '637184', v: 294, c: '放デイ１１・定超・未計画２・評減・' },
  { s: '637185', v: 687, c: '放デイ１１・定超・拘減・' },
  { s: '637186', v: 583, c: '放デイ１１・定超・評減・拘減・' },
  { s: '637187', v: 479, c: '放デイ１１・定超・未計画・拘減・' },
  { s: '637188', v: 406, c: '放デイ１１・定超・未計画・評減・拘減・' },
  { s: '637189', v: 341, c: '放デイ１１・定超・未計画２・拘減・' },
  { s: '637190', v: 289, c: '放デイ１１・定超・未計画２・評減・拘減・' },
  { s: '638321', v: 624, c: '放デイ１２・定超・' },
  { s: '637191', v: 530, c: '放デイ１２・定超・評減・' },
  { s: '638322', v: 437, c: '放デイ１２・定超・未計画・' },
  { s: '637192', v: 371, c: '放デイ１２・定超・未計画・評減・' },
  { s: '637193', v: 312, c: '放デイ１２・定超・未計画２・' },
  { s: '637194', v: 265, c: '放デイ１２・定超・未計画２・評減・' },
  { s: '637195', v: 619, c: '放デイ１２・定超・拘減・' },
  { s: '637196', v: 525, c: '放デイ１２・定超・評減・拘減・' },
  { s: '637197', v: 432, c: '放デイ１２・定超・未計画・拘減・' },
  { s: '637198', v: 366, c: '放デイ１２・定超・未計画・評減・拘減・' },
  { s: '637199', v: 307, c: '放デイ１２・定超・未計画２・拘減・' },
  { s: '637200', v: 260, c: '放デイ１２・定超・未計画２・評減・拘減・' },
  { s: '638331', v: 480, c: '放デイ１３・定超・' },
  { s: '637201', v: 408, c: '放デイ１３・定超・評減・' },
  { s: '638332', v: 336, c: '放デイ１３・定超・未計画・' },
  { s: '637202', v: 286, c: '放デイ１３・定超・未計画・評減・' },
  { s: '637203', v: 240, c: '放デイ１３・定超・未計画２・' },
  { s: '637204', v: 204, c: '放デイ１３・定超・未計画２・評減・' },
  { s: '637205', v: 475, c: '放デイ１３・定超・拘減・' },
  { s: '637206', v: 403, c: '放デイ１３・定超・評減・拘減・' },
  { s: '637207', v: 331, c: '放デイ１３・定超・未計画・拘減・' },
  { s: '637208', v: 281, c: '放デイ１３・定超・未計画・評減・拘減・' },
  { s: '637209', v: 235, c: '放デイ１３・定超・未計画２・拘減・' },
  { s: '637210', v: 199, c: '放デイ１３・定超・未計画２・評減・拘減・' },
  { s: '638411', v: 1425, c: '放デイ１４・定超・' },
  { s: '637211', v: 1211, c: '放デイ１４・定超・評減・' },
  { s: '638412', v: 998, c: '放デイ１４・定超・開減１・' },
  { s: '637212', v: 848, c: '放デイ１４・定超・開減１・評減・' },
  { s: '638415', v: 1211, c: '放デイ１４・定超・開減２・' },
  { s: '637213', v: 1029, c: '放デイ１４・定超・開減２・評減・' },
  { s: '637214', v: 1420, c: '放デイ１４・定超・拘減・' },
  { s: '637215', v: 1206, c: '放デイ１４・定超・評減・拘減・' },
  { s: '637216', v: 993, c: '放デイ１４・定超・開減１・拘減・' },
  { s: '637217', v: 843, c: '放デイ１４・定超・開減１・評減・拘減・' },
  { s: '637218', v: 1206, c: '放デイ１４・定超・開減２・拘減・' },
  { s: '637219', v: 1024, c: '放デイ１４・定超・開減２・評減・拘減・' },
  { s: '638413', v: 998, c: '放デイ１４・定超・未計画・' },
  { s: '637220', v: 848, c: '放デイ１４・定超・未計画・評減・' },
  { s: '638414', v: 699, c: '放デイ１４・定超・未計画・開減１・' },
  { s: '637221', v: 594, c: '放デイ１４・定超・未計画・開減１・評減・' },
  { s: '638416', v: 848, c: '放デイ１４・定超・未計画・開減２・' },
  { s: '637222', v: 721, c: '放デイ１４・定超・未計画・開減２・評減・' },
  { s: '637223', v: 993, c: '放デイ１４・定超・未計画・拘減・' },
  { s: '637224', v: 843, c: '放デイ１４・定超・未計画・評減・拘減・' },
  { s: '637225', v: 694, c: '放デイ１４・定超・未計画・開減１・拘減・' },
  { s: '637226', v: 589, c: '放デイ１４・定超・未計画・開減１・評減・拘減・' },
  { s: '637227', v: 843, c: '放デイ１４・定超・未計画・開減２・拘減・' },
  { s: '637228', v: 716, c: '放デイ１４・定超・未計画・開減２・評減・拘減・' },
  { s: '637229', v: 713, c: '放デイ１４・定超・未計画２・' },
  { s: '637230', v: 606, c: '放デイ１４・定超・未計画２・評減・' },
  { s: '637231', v: 499, c: '放デイ１４・定超・未計画２・開減１・' },
  { s: '637232', v: 424, c: '放デイ１４・定超・未計画２・開減１・評減・' },
  { s: '637233', v: 606, c: '放デイ１４・定超・未計画２・開減２・' },
  { s: '637234', v: 515, c: '放デイ１４・定超・未計画２・開減２・評減・' },
  { s: '637235', v: 708, c: '放デイ１４・定超・未計画２・拘減・' },
  { s: '637236', v: 601, c: '放デイ１４・定超・未計画２・評減・拘減・' },
  { s: '637237', v: 494, c: '放デイ１４・定超・未計画２・開減１・拘減・' },
  { s: '637238', v: 419, c: '放デイ１４・定超・未計画２・開減１・評減・拘減・' },
  { s: '637239', v: 601, c: '放デイ１４・定超・未計画２・開減２・拘減・' },
  { s: '637240', v: 510, c: '放デイ１４・定超・未計画２・開減２・評減・拘減・' },
  { s: '638441', v: 1193, c: '放デイ１５・定超・' },
  { s: '637241', v: 1014, c: '放デイ１５・定超・評減・' },
  { s: '638442', v: 835, c: '放デイ１５・定超・開減１・' },
  { s: '637242', v: 710, c: '放デイ１５・定超・開減１・評減・' },
  { s: '638443', v: 1014, c: '放デイ１５・定超・開減２・' },
  { s: '637243', v: 862, c: '放デイ１５・定超・開減２・評減・' },
  { s: '637244', v: 1188, c: '放デイ１５・定超・拘減・' },
  { s: '637245', v: 1009, c: '放デイ１５・定超・評減・拘減・' },
  { s: '637246', v: 830, c: '放デイ１５・定超・開減１・拘減・' },
  { s: '637247', v: 705, c: '放デイ１５・定超・開減１・評減・拘減・' },
  { s: '637248', v: 1009, c: '放デイ１５・定超・開減２・拘減・' },
  { s: '637249', v: 857, c: '放デイ１５・定超・開減２・評減・拘減・' },
  { s: '638444', v: 835, c: '放デイ１５・定超・未計画・' },
  { s: '637250', v: 710, c: '放デイ１５・定超・未計画・評減・' },
  { s: '638445', v: 585, c: '放デイ１５・定超・未計画・開減１・' },
  { s: '637251', v: 497, c: '放デイ１５・定超・未計画・開減１・評減・' },
  { s: '638446', v: 710, c: '放デイ１５・定超・未計画・開減２・' },
  { s: '637252', v: 604, c: '放デイ１５・定超・未計画・開減２・評減・' },
  { s: '637253', v: 830, c: '放デイ１５・定超・未計画・拘減・' },
  { s: '637254', v: 705, c: '放デイ１５・定超・未計画・評減・拘減・' },
  { s: '637255', v: 580, c: '放デイ１５・定超・未計画・開減１・拘減・' },
  { s: '637256', v: 492, c: '放デイ１５・定超・未計画・開減１・評減・拘減・' },
  { s: '637257', v: 705, c: '放デイ１５・定超・未計画・開減２・拘減・' },
  { s: '637258', v: 599, c: '放デイ１５・定超・未計画・開減２・評減・拘減・' },
  { s: '637259', v: 597, c: '放デイ１５・定超・未計画２・' },
  { s: '637260', v: 507, c: '放デイ１５・定超・未計画２・評減・' },
  { s: '637261', v: 418, c: '放デイ１５・定超・未計画２・開減１・' },
  { s: '637262', v: 355, c: '放デイ１５・定超・未計画２・開減１・評減・' },
  { s: '637263', v: 507, c: '放デイ１５・定超・未計画２・開減２・' },
  { s: '637264', v: 431, c: '放デイ１５・定超・未計画２・開減２・評減・' },
  { s: '637265', v: 592, c: '放デイ１５・定超・未計画２・拘減・' },
  { s: '637266', v: 502, c: '放デイ１５・定超・未計画２・評減・拘減・' },
  { s: '637267', v: 413, c: '放デイ１５・定超・未計画２・開減１・拘減・' },
  { s: '637268', v: 350, c: '放デイ１５・定超・未計画２・開減１・評減・拘減・' },
  { s: '637269', v: 502, c: '放デイ１５・定超・未計画２・開減２・拘減・' },
  { s: '637270', v: 426, c: '放デイ１５・定超・未計画２・開減２・評減・拘減・' },
  { s: '638451', v: 1026, c: '放デイ１６・定超・' },
  { s: '637271', v: 872, c: '放デイ１６・定超・評減・' },
  { s: '638452', v: 718, c: '放デイ１６・定超・開減１・' },
  { s: '637272', v: 610, c: '放デイ１６・定超・開減１・評減・' },
  { s: '638453', v: 872, c: '放デイ１６・定超・開減２・' },
  { s: '637273', v: 741, c: '放デイ１６・定超・開減２・評減・' },
  { s: '637274', v: 1021, c: '放デイ１６・定超・拘減・' },
  { s: '637275', v: 867, c: '放デイ１６・定超・評減・拘減・' },
  { s: '637276', v: 713, c: '放デイ１６・定超・開減１・拘減・' },
  { s: '637277', v: 605, c: '放デイ１６・定超・開減１・評減・拘減・' },
  { s: '637278', v: 867, c: '放デイ１６・定超・開減２・拘減・' },
  { s: '637279', v: 736, c: '放デイ１６・定超・開減２・評減・拘減・' },
  { s: '638454', v: 718, c: '放デイ１６・定超・未計画・' },
  { s: '637280', v: 610, c: '放デイ１６・定超・未計画・評減・' },
  { s: '638455', v: 503, c: '放デイ１６・定超・未計画・開減１・' },
  { s: '637281', v: 428, c: '放デイ１６・定超・未計画・開減１・評減・' },
  { s: '638456', v: 610, c: '放デイ１６・定超・未計画・開減２・' },
  { s: '637282', v: 519, c: '放デイ１６・定超・未計画・開減２・評減・' },
  { s: '637283', v: 713, c: '放デイ１６・定超・未計画・拘減・' },
  { s: '637284', v: 605, c: '放デイ１６・定超・未計画・評減・拘減・' },
  { s: '637285', v: 498, c: '放デイ１６・定超・未計画・開減１・拘減・' },
  { s: '637286', v: 423, c: '放デイ１６・定超・未計画・開減１・評減・拘減・' },
  { s: '637287', v: 605, c: '放デイ１６・定超・未計画・開減２・拘減・' },
  { s: '637288', v: 514, c: '放デイ１６・定超・未計画・開減２・評減・拘減・' },
  { s: '637289', v: 513, c: '放デイ１６・定超・未計画２・' },
  { s: '637290', v: 436, c: '放デイ１６・定超・未計画２・評減・' },
  { s: '637291', v: 359, c: '放デイ１６・定超・未計画２・開減１・' },
  { s: '637292', v: 305, c: '放デイ１６・定超・未計画２・開減１・評減・' },
  { s: '637293', v: 436, c: '放デイ１６・定超・未計画２・開減２・' },
  { s: '637294', v: 371, c: '放デイ１６・定超・未計画２・開減２・評減・' },
  { s: '637295', v: 508, c: '放デイ１６・定超・未計画２・拘減・' },
  { s: '637296', v: 431, c: '放デイ１６・定超・未計画２・評減・拘減・' },
  { s: '637297', v: 354, c: '放デイ１６・定超・未計画２・開減１・拘減・' },
  { s: '637298', v: 300, c: '放デイ１６・定超・未計画２・開減１・評減・拘減・' },
  { s: '637299', v: 431, c: '放デイ１６・定超・未計画２・開減２・拘減・' },
  { s: '637300', v: 366, c: '放デイ１６・定超・未計画２・開減２・評減・拘減・' },
  { s: '638461', v: 901, c: '放デイ１７・定超・' },
  { s: '637301', v: 766, c: '放デイ１７・定超・評減・' },
  { s: '638462', v: 631, c: '放デイ１７・定超・開減１・' },
  { s: '637302', v: 536, c: '放デイ１７・定超・開減１・評減・' },
  { s: '638463', v: 766, c: '放デイ１７・定超・開減２・' },
  { s: '637303', v: 651, c: '放デイ１７・定超・開減２・評減・' },
  { s: '637304', v: 896, c: '放デイ１７・定超・拘減・' },
  { s: '637305', v: 761, c: '放デイ１７・定超・評減・拘減・' },
  { s: '637306', v: 626, c: '放デイ１７・定超・開減１・拘減・' },
  { s: '637307', v: 531, c: '放デイ１７・定超・開減１・評減・拘減・' },
  { s: '637308', v: 761, c: '放デイ１７・定超・開減２・拘減・' },
  { s: '637309', v: 646, c: '放デイ１７・定超・開減２・評減・拘減・' },
  { s: '638464', v: 631, c: '放デイ１７・定超・未計画・' },
  { s: '637310', v: 536, c: '放デイ１７・定超・未計画・評減・' },
  { s: '638465', v: 442, c: '放デイ１７・定超・未計画・開減１・' },
  { s: '637311', v: 376, c: '放デイ１７・定超・未計画・開減１・評減・' },
  { s: '638466', v: 536, c: '放デイ１７・定超・未計画・開減２・' },
  { s: '637312', v: 456, c: '放デイ１７・定超・未計画・開減２・評減・' },
  { s: '637313', v: 626, c: '放デイ１７・定超・未計画・拘減・' },
  { s: '637314', v: 531, c: '放デイ１７・定超・未計画・評減・拘減・' },
  { s: '637315', v: 437, c: '放デイ１７・定超・未計画・開減１・拘減・' },
  { s: '637316', v: 371, c: '放デイ１７・定超・未計画・開減１・評減・拘減・' },
  { s: '637317', v: 531, c: '放デイ１７・定超・未計画・開減２・拘減・' },
  { s: '637318', v: 451, c: '放デイ１７・定超・未計画・開減２・評減・拘減・' },
  { s: '637319', v: 451, c: '放デイ１７・定超・未計画２・' },
  { s: '637320', v: 383, c: '放デイ１７・定超・未計画２・評減・' },
  { s: '637321', v: 316, c: '放デイ１７・定超・未計画２・開減１・' },
  { s: '637322', v: 269, c: '放デイ１７・定超・未計画２・開減１・評減・' },
  { s: '637323', v: 383, c: '放デイ１７・定超・未計画２・開減２・' },
  { s: '637324', v: 326, c: '放デイ１７・定超・未計画２・開減２・評減・' },
  { s: '637325', v: 446, c: '放デイ１７・定超・未計画２・拘減・' },
  { s: '637326', v: 378, c: '放デイ１７・定超・未計画２・評減・拘減・' },
  { s: '637327', v: 311, c: '放デイ１７・定超・未計画２・開減１・拘減・' },
  { s: '637328', v: 264, c: '放デイ１７・定超・未計画２・開減１・評減・拘減・' },
  { s: '637329', v: 378, c: '放デイ１７・定超・未計画２・開減２・拘減・' },
  { s: '637330', v: 321, c: '放デイ１７・定超・未計画２・開減２・評減・拘減・' },
  { s: '638471', v: 804, c: '放デイ１８・定超・' },
  { s: '637331', v: 683, c: '放デイ１８・定超・評減・' },
  { s: '638472', v: 563, c: '放デイ１８・定超・開減１・' },
  { s: '637332', v: 479, c: '放デイ１８・定超・開減１・評減・' },
  { s: '638473', v: 683, c: '放デイ１８・定超・開減２・' },
  { s: '637333', v: 581, c: '放デイ１８・定超・開減２・評減・' },
  { s: '637334', v: 799, c: '放デイ１８・定超・拘減・' },
  { s: '637335', v: 678, c: '放デイ１８・定超・評減・拘減・' },
  { s: '637336', v: 558, c: '放デイ１８・定超・開減１・拘減・' },
  { s: '637337', v: 474, c: '放デイ１８・定超・開減１・評減・拘減・' },
  { s: '637338', v: 678, c: '放デイ１８・定超・開減２・拘減・' },
  { s: '637339', v: 576, c: '放デイ１８・定超・開減２・評減・拘減・' },
  { s: '638474', v: 563, c: '放デイ１８・定超・未計画・' },
  { s: '637340', v: 479, c: '放デイ１８・定超・未計画・評減・' },
  { s: '638475', v: 394, c: '放デイ１８・定超・未計画・開減１・' },
  { s: '637341', v: 335, c: '放デイ１８・定超・未計画・開減１・評減・' },
  { s: '638476', v: 479, c: '放デイ１８・定超・未計画・開減２・' },
  { s: '637342', v: 407, c: '放デイ１８・定超・未計画・開減２・評減・' },
  { s: '637343', v: 558, c: '放デイ１８・定超・未計画・拘減・' },
  { s: '637344', v: 474, c: '放デイ１８・定超・未計画・評減・拘減・' },
  { s: '637345', v: 389, c: '放デイ１８・定超・未計画・開減１・拘減・' },
  { s: '637346', v: 330, c: '放デイ１８・定超・未計画・開減１・評減・拘減・' },
  { s: '637347', v: 474, c: '放デイ１８・定超・未計画・開減２・拘減・' },
  { s: '637348', v: 402, c: '放デイ１８・定超・未計画・開減２・評減・拘減・' },
  { s: '637349', v: 402, c: '放デイ１８・定超・未計画２・' },
  { s: '637350', v: 342, c: '放デイ１８・定超・未計画２・評減・' },
  { s: '637351', v: 281, c: '放デイ１８・定超・未計画２・開減１・' },
  { s: '637352', v: 239, c: '放デイ１８・定超・未計画２・開減１・評減・' },
  { s: '637353', v: 342, c: '放デイ１８・定超・未計画２・開減２・' },
  { s: '637354', v: 291, c: '放デイ１８・定超・未計画２・開減２・評減・' },
  { s: '637355', v: 397, c: '放デイ１８・定超・未計画２・拘減・' },
  { s: '637356', v: 337, c: '放デイ１８・定超・未計画２・評減・拘減・' },
  { s: '637357', v: 276, c: '放デイ１８・定超・未計画２・開減１・拘減・' },
  { s: '637358', v: 234, c: '放デイ１８・定超・未計画２・開減１・評減・拘減・' },
  { s: '637359', v: 337, c: '放デイ１８・定超・未計画２・開減２・拘減・' },
  { s: '637360', v: 286, c: '放デイ１８・定超・未計画２・開減２・評減・拘減・' },
  { s: '638421', v: 727, c: '放デイ１９・定超・' },
  { s: '637361', v: 618, c: '放デイ１９・定超・評減・' },
  { s: '638422', v: 509, c: '放デイ１９・定超・開減１・' },
  { s: '637362', v: 433, c: '放デイ１９・定超・開減１・評減・' },
  { s: '638425', v: 618, c: '放デイ１９・定超・開減２・' },
  { s: '637363', v: 525, c: '放デイ１９・定超・開減２・評減・' },
  { s: '637364', v: 722, c: '放デイ１９・定超・拘減・' },
  { s: '637365', v: 613, c: '放デイ１９・定超・評減・拘減・' },
  { s: '637366', v: 504, c: '放デイ１９・定超・開減１・拘減・' },
  { s: '637367', v: 428, c: '放デイ１９・定超・開減１・評減・拘減・' },
  { s: '637368', v: 613, c: '放デイ１９・定超・開減２・拘減・' },
  { s: '637369', v: 520, c: '放デイ１９・定超・開減２・評減・拘減・' },
  { s: '638423', v: 509, c: '放デイ１９・定超・未計画・' },
  { s: '637370', v: 433, c: '放デイ１９・定超・未計画・評減・' },
  { s: '638424', v: 356, c: '放デイ１９・定超・未計画・開減１・' },
  { s: '637371', v: 303, c: '放デイ１９・定超・未計画・開減１・評減・' },
  { s: '638426', v: 433, c: '放デイ１９・定超・未計画・開減２・' },
  { s: '637372', v: 368, c: '放デイ１９・定超・未計画・開減２・評減・' },
  { s: '637373', v: 504, c: '放デイ１９・定超・未計画・拘減・' },
  { s: '637374', v: 428, c: '放デイ１９・定超・未計画・評減・拘減・' },
  { s: '637375', v: 351, c: '放デイ１９・定超・未計画・開減１・拘減・' },
  { s: '637376', v: 298, c: '放デイ１９・定超・未計画・開減１・評減・拘減・' },
  { s: '637377', v: 428, c: '放デイ１９・定超・未計画・開減２・拘減・' },
  { s: '637378', v: 363, c: '放デイ１９・定超・未計画・開減２・評減・拘減・' },
  { s: '637379', v: 364, c: '放デイ１９・定超・未計画２・' },
  { s: '637380', v: 309, c: '放デイ１９・定超・未計画２・評減・' },
  { s: '637381', v: 255, c: '放デイ１９・定超・未計画２・開減１・' },
  { s: '637382', v: 217, c: '放デイ１９・定超・未計画２・開減１・評減・' },
  { s: '637383', v: 309, c: '放デイ１９・定超・未計画２・開減２・' },
  { s: '637384', v: 263, c: '放デイ１９・定超・未計画２・開減２・評減・' },
  { s: '637385', v: 359, c: '放デイ１９・定超・未計画２・拘減・' },
  { s: '637386', v: 304, c: '放デイ１９・定超・未計画２・評減・拘減・' },
  { s: '637387', v: 250, c: '放デイ１９・定超・未計画２・開減１・拘減・' },
  { s: '637388', v: 212, c: '放デイ１９・定超・未計画２・開減１・評減・拘減・' },
  { s: '637389', v: 304, c: '放デイ１９・定超・未計画２・開減２・拘減・' },
  { s: '637390', v: 258, c: '放デイ１９・定超・未計画２・開減２・評減・拘減・' },
  { s: '638431', v: 566, c: '放デイ２０・定超・' },
  { s: '637391', v: 481, c: '放デイ２０・定超・評減・' },
  { s: '638432', v: 396, c: '放デイ２０・定超・開減１・' },
  { s: '637392', v: 337, c: '放デイ２０・定超・開減１・評減・' },
  { s: '638435', v: 481, c: '放デイ２０・定超・開減２・' },
  { s: '637393', v: 409, c: '放デイ２０・定超・開減２・評減・' },
  { s: '637394', v: 561, c: '放デイ２０・定超・拘減・' },
  { s: '637395', v: 476, c: '放デイ２０・定超・評減・拘減・' },
  { s: '637396', v: 391, c: '放デイ２０・定超・開減１・拘減・' },
  { s: '637397', v: 332, c: '放デイ２０・定超・開減１・評減・拘減・' },
  { s: '637398', v: 476, c: '放デイ２０・定超・開減２・拘減・' },
  { s: '637399', v: 404, c: '放デイ２０・定超・開減２・評減・拘減・' },
  { s: '638433', v: 396, c: '放デイ２０・定超・未計画・' },
  { s: '637400', v: 337, c: '放デイ２０・定超・未計画・評減・' },
  { s: '638434', v: 277, c: '放デイ２０・定超・未計画・開減１・' },
  { s: '637401', v: 235, c: '放デイ２０・定超・未計画・開減１・評減・' },
  { s: '638436', v: 337, c: '放デイ２０・定超・未計画・開減２・' },
  { s: '637402', v: 286, c: '放デイ２０・定超・未計画・開減２・評減・' },
  { s: '637403', v: 391, c: '放デイ２０・定超・未計画・拘減・' },
  { s: '637404', v: 332, c: '放デイ２０・定超・未計画・評減・拘減・' },
  { s: '637405', v: 272, c: '放デイ２０・定超・未計画・開減１・拘減・' },
  { s: '637406', v: 230, c: '放デイ２０・定超・未計画・開減１・評減・拘減・' },
  { s: '637407', v: 332, c: '放デイ２０・定超・未計画・開減２・拘減・' },
  { s: '637408', v: 281, c: '放デイ２０・定超・未計画・開減２・評減・拘減・' },
  { s: '637409', v: 283, c: '放デイ２０・定超・未計画２・' },
  { s: '637410', v: 241, c: '放デイ２０・定超・未計画２・評減・' },
  { s: '637411', v: 198, c: '放デイ２０・定超・未計画２・開減１・' },
  { s: '637412', v: 168, c: '放デイ２０・定超・未計画２・開減１・評減・' },
  { s: '637413', v: 241, c: '放デイ２０・定超・未計画２・開減２・' },
  { s: '637414', v: 205, c: '放デイ２０・定超・未計画２・開減２・評減・' },
  { s: '637415', v: 278, c: '放デイ２０・定超・未計画２・拘減・' },
  { s: '637416', v: 236, c: '放デイ２０・定超・未計画２・評減・拘減・' },
  { s: '637417', v: 193, c: '放デイ２０・定超・未計画２・開減１・拘減・' },
  { s: '637418', v: 163, c: '放デイ２０・定超・未計画２・開減１・評減・拘減・' },
  { s: '637419', v: 236, c: '放デイ２０・定超・未計画２・開減２・拘減・' },
  { s: '637420', v: 200, c: '放デイ２０・定超・未計画２・開減２・評減・拘減・' },
  { s: '637431', v: 300, c: '共生型放デイ１・定超・' },
  { s: '637432', v: 255, c: '共生型放デイ１・定超・評減・' },
  { s: '637433', v: 295, c: '共生型放デイ１・定超・拘減・' },
  { s: '637434', v: 250, c: '共生型放デイ１・定超・評減・拘減・' },
  { s: '637441', v: 388, c: '共生型放デイ２・定超・' },
  { s: '637442', v: 330, c: '共生型放デイ２・定超・評減・' },
  { s: '637443', v: 272, c: '共生型放デイ２・定超・開減１・' },
  { s: '637444', v: 231, c: '共生型放デイ２・定超・開減１・評減・' },
  { s: '637445', v: 330, c: '共生型放デイ２・定超・開減２・' },
  { s: '637446', v: 281, c: '共生型放デイ２・定超・開減２・評減・' },
  { s: '637447', v: 383, c: '共生型放デイ２・定超・拘減・' },
  { s: '637448', v: 325, c: '共生型放デイ２・定超・評減・拘減・' },
  { s: '637449', v: 267, c: '共生型放デイ２・定超・開減１・拘減・' },
  { s: '637450', v: 226, c: '共生型放デイ２・定超・開減１・評減・拘減・' },
  { s: '637451', v: 325, c: '共生型放デイ２・定超・開減２・拘減・' },
  { s: '637452', v: 276, c: '共生型放デイ２・定超・開減２・評減・拘減・' },
  { s: '637461', v: 373, c: '基準該当放デイⅠ１・定超・' },
  { s: '637462', v: 317, c: '基準該当放デイⅠ１・定超・評減・' },
  { s: '637463', v: 261, c: '基準該当放デイⅠ１・定超・未計画・' },
  { s: '637464', v: 222, c: '基準該当放デイⅠ１・定超・未計画・評減・' },
  { s: '637465', v: 187, c: '基準該当放デイⅠ１・定超・未計画２・' },
  { s: '637466', v: 159, c: '基準該当放デイⅠ１・定超・未計画２・評減・' },
  { s: '637467', v: 461, c: '基準該当放デイⅠ２・定超・' },
  { s: '637468', v: 392, c: '基準該当放デイⅠ２・定超・評減・' },
  { s: '637469', v: 323, c: '基準該当放デイⅠ２・定超・開減１・' },
  { s: '637470', v: 275, c: '基準該当放デイⅠ２・定超・開減１・評減・' },
  { s: '637471', v: 392, c: '基準該当放デイⅠ２・定超・開減２・' },
  { s: '637472', v: 333, c: '基準該当放デイⅠ２・定超・開減２・評減・' },
  { s: '637473', v: 323, c: '基準該当放デイⅠ２・定超・未計画・' },
  { s: '637474', v: 275, c: '基準該当放デイⅠ２・定超・未計画・評減・' },
  { s: '637475', v: 226, c: '基準該当放デイⅠ２・定超・未計画・開減１・' },
  { s: '637476', v: 192, c: '基準該当放デイⅠ２・定超・未計画・開減１・評減・' },
  { s: '637477', v: 275, c: '基準該当放デイⅠ２・定超・未計画・開減２・' },
  { s: '637478', v: 234, c: '基準該当放デイⅠ２・定超・未計画・開減２・評減・' },
  { s: '637479', v: 231, c: '基準該当放デイⅠ２・定超・未計画２・' },
  { s: '637480', v: 196, c: '基準該当放デイⅠ２・定超・未計画２・評減・' },
  { s: '637481', v: 162, c: '基準該当放デイⅠ２・定超・未計画２・開減１・' },
  { s: '637482', v: 138, c: '基準該当放デイⅠ２・定超・未計画２・開減１・評減・' },
  { s: '637483', v: 196, c: '基準該当放デイⅠ２・定超・未計画２・開減２・' },
  { s: '637484', v: 167, c: '基準該当放デイⅠ２・定超・未計画２・開減２・評減・' },
  { s: '637491', v: 300, c: '基準該当放デイⅡ１・定超・' },
  { s: '637492', v: 255, c: '基準該当放デイⅡ１・定超・評減・' },
  { s: '637493', v: 388, c: '基準該当放デイⅡ２・定超・' },
  { s: '637494', v: 330, c: '基準該当放デイⅡ２・定超・評減・' },
  { s: '637495', v: 272, c: '基準該当放デイⅡ２・定超・開減１・' },
  { s: '637496', v: 231, c: '基準該当放デイⅡ２・定超・開減１・評減・' },
  { s: '637497', v: 330, c: '基準該当放デイⅡ２・定超・開減２・' },
  { s: '637498', v: 281, c: '基準該当放デイⅡ２・定超・開減２・評減・' },
  { s: '639111', v: 462, c: '放デイ１・人欠・' },
  { s: '639301', v: 393, c: '放デイ１・人欠・評減・' },
  { s: '639112', v: 323, c: '放デイ１・人欠・未計画・' },
  { s: '639302', v: 275, c: '放デイ１・人欠・未計画・評減・' },
  { s: '639303', v: 231, c: '放デイ１・人欠・未計画２・' },
  { s: '639304', v: 196, c: '放デイ１・人欠・未計画２・評減・' },
  { s: '639305', v: 457, c: '放デイ１・人欠・拘減・' },
  { s: '639306', v: 388, c: '放デイ１・人欠・評減・拘減・' },
  { s: '639307', v: 318, c: '放デイ１・人欠・未計画・拘減・' },
  { s: '639308', v: 270, c: '放デイ１・人欠・未計画・評減・拘減・' },
  { s: '639309', v: 226, c: '放デイ１・人欠・未計画２・拘減・' },
  { s: '639310', v: 191, c: '放デイ１・人欠・未計画２・評減・拘減・' },
  { s: '639113', v: 468, c: '放デイ１・有資格１・人欠・' },
  { s: '639311', v: 398, c: '放デイ１・有資格１・人欠・評減・' },
  { s: '639114', v: 328, c: '放デイ１・有資格１・人欠・未計画・' },
  { s: '639312', v: 279, c: '放デイ１・有資格１・人欠・未計画・評減・' },
  { s: '639313', v: 234, c: '放デイ１・有資格１・人欠・未計画２・' },
  { s: '639314', v: 199, c: '放デイ１・有資格１・人欠・未計画２・評減・' },
  { s: '639315', v: 463, c: '放デイ１・有資格１・人欠・拘減・' },
  { s: '639316', v: 393, c: '放デイ１・有資格１・人欠・評減・拘減・' },
  { s: '639317', v: 323, c: '放デイ１・有資格１・人欠・未計画・拘減・' },
  { s: '639318', v: 274, c: '放デイ１・有資格１・人欠・未計画・評減・拘減・' },
  { s: '639319', v: 229, c: '放デイ１・有資格１・人欠・未計画２・拘減・' },
  { s: '639320', v: 194, c: '放デイ１・有資格１・人欠・未計画２・評減・拘減・' },
  { s: '639321', v: 330, c: '放デイ１・人欠２・' },
  { s: '639322', v: 281, c: '放デイ１・人欠２・評減・' },
  { s: '639323', v: 231, c: '放デイ１・人欠２・未計画・' },
  { s: '639324', v: 196, c: '放デイ１・人欠２・未計画・評減・' },
  { s: '639325', v: 165, c: '放デイ１・人欠２・未計画２・' },
  { s: '639326', v: 140, c: '放デイ１・人欠２・未計画２・評減・' },
  { s: '639327', v: 325, c: '放デイ１・人欠２・拘減・' },
  { s: '639328', v: 276, c: '放デイ１・人欠２・評減・拘減・' },
  { s: '639329', v: 226, c: '放デイ１・人欠２・未計画・拘減・' },
  { s: '639330', v: 191, c: '放デイ１・人欠２・未計画・評減・拘減・' },
  { s: '639331', v: 160, c: '放デイ１・人欠２・未計画２・拘減・' },
  { s: '639332', v: 135, c: '放デイ１・人欠２・未計画２・評減・拘減・' },
  { s: '639333', v: 335, c: '放デイ１・有資格１・人欠２・' },
  { s: '639334', v: 285, c: '放デイ１・有資格１・人欠２・評減・' },
  { s: '639335', v: 235, c: '放デイ１・有資格１・人欠２・未計画・' },
  { s: '639336', v: 200, c: '放デイ１・有資格１・人欠２・未計画・評減・' },
  { s: '639337', v: 168, c: '放デイ１・有資格１・人欠２・未計画２・' },
  { s: '639338', v: 143, c: '放デイ１・有資格１・人欠２・未計画２・評減・' },
  { s: '639339', v: 330, c: '放デイ１・有資格１・人欠２・拘減・' },
  { s: '639340', v: 280, c: '放デイ１・有資格１・人欠２・評減・拘減・' },
  { s: '639341', v: 230, c: '放デイ１・有資格１・人欠２・未計画・拘減・' },
  { s: '639342', v: 195, c: '放デイ１・有資格１・人欠２・未計画・評減・拘減・' },
  { s: '639343', v: 163, c: '放デイ１・有資格１・人欠２・未計画２・拘減・' },
  { s: '639344', v: 138, c: '放デイ１・有資格１・人欠２・未計画２・評減・拘減・' },
  { s: '639121', v: 310, c: '放デイ２・人欠・' },
  { s: '639351', v: 264, c: '放デイ２・人欠・評減・' },
  { s: '639122', v: 217, c: '放デイ２・人欠・未計画・' },
  { s: '639352', v: 184, c: '放デイ２・人欠・未計画・評減・' },
  { s: '639353', v: 155, c: '放デイ２・人欠・未計画２・' },
  { s: '639354', v: 132, c: '放デイ２・人欠・未計画２・評減・' },
  { s: '639355', v: 305, c: '放デイ２・人欠・拘減・' },
  { s: '639356', v: 259, c: '放デイ２・人欠・評減・拘減・' },
  { s: '639357', v: 212, c: '放デイ２・人欠・未計画・拘減・' },
  { s: '639358', v: 179, c: '放デイ２・人欠・未計画・評減・拘減・' },
  { s: '639359', v: 150, c: '放デイ２・人欠・未計画２・拘減・' },
  { s: '639360', v: 127, c: '放デイ２・人欠・未計画２・評減・拘減・' },
  { s: '639123', v: 314, c: '放デイ２・有資格２・人欠・' },
  { s: '639361', v: 267, c: '放デイ２・有資格２・人欠・評減・' },
  { s: '639124', v: 220, c: '放デイ２・有資格２・人欠・未計画・' },
  { s: '639362', v: 187, c: '放デイ２・有資格２・人欠・未計画・評減・' },
  { s: '639363', v: 157, c: '放デイ２・有資格２・人欠・未計画２・' },
  { s: '639364', v: 133, c: '放デイ２・有資格２・人欠・未計画２・評減・' },
  { s: '639365', v: 309, c: '放デイ２・有資格２・人欠・拘減・' },
  { s: '639366', v: 262, c: '放デイ２・有資格２・人欠・評減・拘減・' },
  { s: '639367', v: 215, c: '放デイ２・有資格２・人欠・未計画・拘減・' },
  { s: '639368', v: 182, c: '放デイ２・有資格２・人欠・未計画・評減・拘減・' },
  { s: '639369', v: 152, c: '放デイ２・有資格２・人欠・未計画２・拘減・' },
  { s: '639370', v: 128, c: '放デイ２・有資格２・人欠・未計画２・評減・拘減・' },
  { s: '639371', v: 222, c: '放デイ２・人欠２・' },
  { s: '639372', v: 189, c: '放デイ２・人欠２・評減・' },
  { s: '639373', v: 155, c: '放デイ２・人欠２・未計画・' },
  { s: '639374', v: 132, c: '放デイ２・人欠２・未計画・評減・' },
  { s: '639375', v: 111, c: '放デイ２・人欠２・未計画２・' },
  { s: '639376', v: 94, c: '放デイ２・人欠２・未計画２・評減・' },
  { s: '639377', v: 217, c: '放デイ２・人欠２・拘減・' },
  { s: '639378', v: 184, c: '放デイ２・人欠２・評減・拘減・' },
  { s: '639379', v: 150, c: '放デイ２・人欠２・未計画・拘減・' },
  { s: '639380', v: 127, c: '放デイ２・人欠２・未計画・評減・拘減・' },
  { s: '639381', v: 106, c: '放デイ２・人欠２・未計画２・拘減・' },
  { s: '639382', v: 89, c: '放デイ２・人欠２・未計画２・評減・拘減・' },
  { s: '639383', v: 225, c: '放デイ２・有資格２・人欠２・' },
  { s: '639384', v: 191, c: '放デイ２・有資格２・人欠２・評減・' },
  { s: '639385', v: 158, c: '放デイ２・有資格２・人欠２・未計画・' },
  { s: '639386', v: 134, c: '放デイ２・有資格２・人欠２・未計画・評減・' },
  { s: '639387', v: 113, c: '放デイ２・有資格２・人欠２・未計画２・' },
  { s: '639388', v: 96, c: '放デイ２・有資格２・人欠２・未計画２・評減・' },
  { s: '639389', v: 220, c: '放デイ２・有資格２・人欠２・拘減・' },
  { s: '639390', v: 186, c: '放デイ２・有資格２・人欠２・評減・拘減・' },
  { s: '639391', v: 153, c: '放デイ２・有資格２・人欠２・未計画・拘減・' },
  { s: '639392', v: 129, c: '放デイ２・有資格２・人欠２・未計画・評減・拘減・' },
  { s: '639393', v: 108, c: '放デイ２・有資格２・人欠２・未計画２・拘減・' },
  { s: '639394', v: 91, c: '放デイ２・有資格２・人欠２・未計画２・評減・拘減・' },
  { s: '639131', v: 233, c: '放デイ３・人欠・' },
  { s: '639401', v: 198, c: '放デイ３・人欠・評減・' },
  { s: '639132', v: 163, c: '放デイ３・人欠・未計画・' },
  { s: '639402', v: 139, c: '放デイ３・人欠・未計画・評減・' },
  { s: '639403', v: 117, c: '放デイ３・人欠・未計画２・' },
  { s: '639404', v: 99, c: '放デイ３・人欠・未計画２・評減・' },
  { s: '639405', v: 228, c: '放デイ３・人欠・拘減・' },
  { s: '639406', v: 193, c: '放デイ３・人欠・評減・拘減・' },
  { s: '639407', v: 158, c: '放デイ３・人欠・未計画・拘減・' },
  { s: '639408', v: 134, c: '放デイ３・人欠・未計画・評減・拘減・' },
  { s: '639409', v: 112, c: '放デイ３・人欠・未計画２・拘減・' },
  { s: '639410', v: 94, c: '放デイ３・人欠・未計画２・評減・拘減・' },
  { s: '639133', v: 236, c: '放デイ３・有資格３・人欠・' },
  { s: '639411', v: 201, c: '放デイ３・有資格３・人欠・評減・' },
  { s: '639134', v: 165, c: '放デイ３・有資格３・人欠・未計画・' },
  { s: '639412', v: 140, c: '放デイ３・有資格３・人欠・未計画・評減・' },
  { s: '639413', v: 118, c: '放デイ３・有資格３・人欠・未計画２・' },
  { s: '639414', v: 100, c: '放デイ３・有資格３・人欠・未計画２・評減・' },
  { s: '639415', v: 231, c: '放デイ３・有資格３・人欠・拘減・' },
  { s: '639416', v: 196, c: '放デイ３・有資格３・人欠・評減・拘減・' },
  { s: '639417', v: 160, c: '放デイ３・有資格３・人欠・未計画・拘減・' },
  { s: '639418', v: 135, c: '放デイ３・有資格３・人欠・未計画・評減・拘減・' },
  { s: '639419', v: 113, c: '放デイ３・有資格３・人欠・未計画２・拘減・' },
  { s: '639420', v: 95, c: '放デイ３・有資格３・人欠・未計画２・評減・拘減・' },
  { s: '639421', v: 167, c: '放デイ３・人欠２・' },
  { s: '639422', v: 142, c: '放デイ３・人欠２・評減・' },
  { s: '639423', v: 117, c: '放デイ３・人欠２・未計画・' },
  { s: '639424', v: 99, c: '放デイ３・人欠２・未計画・評減・' },
  { s: '639425', v: 84, c: '放デイ３・人欠２・未計画２・' },
  { s: '639426', v: 71, c: '放デイ３・人欠２・未計画２・評減・' },
  { s: '639427', v: 162, c: '放デイ３・人欠２・拘減・' },
  { s: '639428', v: 137, c: '放デイ３・人欠２・評減・拘減・' },
  { s: '639429', v: 112, c: '放デイ３・人欠２・未計画・拘減・' },
  { s: '639430', v: 94, c: '放デイ３・人欠２・未計画・評減・拘減・' },
  { s: '639431', v: 79, c: '放デイ３・人欠２・未計画２・拘減・' },
  { s: '639432', v: 66, c: '放デイ３・人欠２・未計画２・評減・拘減・' },
  { s: '639433', v: 169, c: '放デイ３・有資格３・人欠２・' },
  { s: '639434', v: 144, c: '放デイ３・有資格３・人欠２・評減・' },
  { s: '639435', v: 118, c: '放デイ３・有資格３・人欠２・未計画・' },
  { s: '639436', v: 100, c: '放デイ３・有資格３・人欠２・未計画・評減・' },
  { s: '639437', v: 85, c: '放デイ３・有資格３・人欠２・未計画２・' },
  { s: '639438', v: 72, c: '放デイ３・有資格３・人欠２・未計画２・評減・' },
  { s: '639439', v: 164, c: '放デイ３・有資格３・人欠２・拘減・' },
  { s: '639440', v: 139, c: '放デイ３・有資格３・人欠２・評減・拘減・' },
  { s: '639441', v: 113, c: '放デイ３・有資格３・人欠２・未計画・拘減・' },
  { s: '639442', v: 95, c: '放デイ３・有資格３・人欠２・未計画・評減・拘減・' },
  { s: '639443', v: 80, c: '放デイ３・有資格３・人欠２・未計画２・拘減・' },
  { s: '639444', v: 67, c: '放デイ３・有資格３・人欠２・未計画２・評減・拘減・' },
  { s: '639451', v: 454, c: '放デイ２１・人欠・' },
  { s: '639452', v: 386, c: '放デイ２１・人欠・評減・' },
  { s: '639453', v: 318, c: '放デイ２１・人欠・未計画・' },
  { s: '639454', v: 270, c: '放デイ２１・人欠・未計画・評減・' },
  { s: '639455', v: 227, c: '放デイ２１・人欠・未計画２・' },
  { s: '639456', v: 193, c: '放デイ２１・人欠・未計画２・評減・' },
  { s: '639457', v: 449, c: '放デイ２１・人欠・拘減・' },
  { s: '639458', v: 381, c: '放デイ２１・人欠・評減・拘減・' },
  { s: '639459', v: 313, c: '放デイ２１・人欠・未計画・拘減・' },
  { s: '639460', v: 265, c: '放デイ２１・人欠・未計画・評減・拘減・' },
  { s: '639461', v: 222, c: '放デイ２１・人欠・未計画２・拘減・' },
  { s: '639462', v: 188, c: '放デイ２１・人欠・未計画２・評減・拘減・' },
  { s: '639463', v: 461, c: '放デイ２１・有資格７・人欠・' },
  { s: '639464', v: 392, c: '放デイ２１・有資格７・人欠・評減・' },
  { s: '639465', v: 323, c: '放デイ２１・有資格７・人欠・未計画・' },
  { s: '639466', v: 275, c: '放デイ２１・有資格７・人欠・未計画・評減・' },
  { s: '639467', v: 231, c: '放デイ２１・有資格７・人欠・未計画２・' },
  { s: '639468', v: 196, c: '放デイ２１・有資格７・人欠・未計画２・評減・' },
  { s: '639469', v: 456, c: '放デイ２１・有資格７・人欠・拘減・' },
  { s: '639470', v: 387, c: '放デイ２１・有資格７・人欠・評減・拘減・' },
  { s: '639471', v: 318, c: '放デイ２１・有資格７・人欠・未計画・拘減・' },
  { s: '639472', v: 270, c: '放デイ２１・有資格７・人欠・未計画・評減・拘減・' },
  { s: '639473', v: 226, c: '放デイ２１・有資格７・人欠・未計画２・拘減・' },
  { s: '639474', v: 191, c: '放デイ２１・有資格７・人欠・未計画２・評減・拘減・' },
  { s: '639475', v: 325, c: '放デイ２１・人欠２・' },
  { s: '639476', v: 276, c: '放デイ２１・人欠２・評減・' },
  { s: '639477', v: 228, c: '放デイ２１・人欠２・未計画・' },
  { s: '639478', v: 194, c: '放デイ２１・人欠２・未計画・評減・' },
  { s: '639479', v: 163, c: '放デイ２１・人欠２・未計画２・' },
  { s: '639480', v: 139, c: '放デイ２１・人欠２・未計画２・評減・' },
  { s: '639481', v: 320, c: '放デイ２１・人欠２・拘減・' },
  { s: '639482', v: 271, c: '放デイ２１・人欠２・評減・拘減・' },
  { s: '639483', v: 223, c: '放デイ２１・人欠２・未計画・拘減・' },
  { s: '639484', v: 189, c: '放デイ２１・人欠２・未計画・評減・拘減・' },
  { s: '639485', v: 158, c: '放デイ２１・人欠２・未計画２・拘減・' },
  { s: '639486', v: 134, c: '放デイ２１・人欠２・未計画２・評減・拘減・' },
  { s: '639487', v: 329, c: '放デイ２１・有資格７・人欠２・' },
  { s: '639488', v: 280, c: '放デイ２１・有資格７・人欠２・評減・' },
  { s: '639489', v: 230, c: '放デイ２１・有資格７・人欠２・未計画・' },
  { s: '639490', v: 196, c: '放デイ２１・有資格７・人欠２・未計画・評減・' },
  { s: '639491', v: 165, c: '放デイ２１・有資格７・人欠２・未計画２・' },
  { s: '639492', v: 140, c: '放デイ２１・有資格７・人欠２・未計画２・評減・' },
  { s: '639493', v: 324, c: '放デイ２１・有資格７・人欠２・拘減・' },
  { s: '639494', v: 275, c: '放デイ２１・有資格７・人欠２・評減・拘減・' },
  { s: '639495', v: 225, c: '放デイ２１・有資格７・人欠２・未計画・拘減・' },
  { s: '639496', v: 191, c: '放デイ２１・有資格７・人欠２・未計画・評減・拘減・' },
  { s: '639497', v: 160, c: '放デイ２１・有資格７・人欠２・未計画２・拘減・' },
  { s: '639498', v: 135, c: '放デイ２１・有資格７・人欠２・未計画２・評減・拘減・' },
  { s: '639501', v: 303, c: '放デイ２２・人欠・' },
  { s: '639502', v: 258, c: '放デイ２２・人欠・評減・' },
  { s: '639503', v: 212, c: '放デイ２２・人欠・未計画・' },
  { s: '639504', v: 180, c: '放デイ２２・人欠・未計画・評減・' },
  { s: '639505', v: 152, c: '放デイ２２・人欠・未計画２・' },
  { s: '639506', v: 129, c: '放デイ２２・人欠・未計画２・評減・' },
  { s: '639507', v: 298, c: '放デイ２２・人欠・拘減・' },
  { s: '639508', v: 253, c: '放デイ２２・人欠・評減・拘減・' },
  { s: '639509', v: 207, c: '放デイ２２・人欠・未計画・拘減・' },
  { s: '639510', v: 175, c: '放デイ２２・人欠・未計画・評減・拘減・' },
  { s: '639511', v: 147, c: '放デイ２２・人欠・未計画２・拘減・' },
  { s: '639512', v: 124, c: '放デイ２２・人欠・未計画２・評減・拘減・' },
  { s: '639513', v: 307, c: '放デイ２２・有資格８・人欠・' },
  { s: '639514', v: 261, c: '放デイ２２・有資格８・人欠・評減・' },
  { s: '639515', v: 215, c: '放デイ２２・有資格８・人欠・未計画・' },
  { s: '639516', v: 183, c: '放デイ２２・有資格８・人欠・未計画・評減・' },
  { s: '639517', v: 154, c: '放デイ２２・有資格８・人欠・未計画２・' },
  { s: '639518', v: 131, c: '放デイ２２・有資格８・人欠・未計画２・評減・' },
  { s: '639519', v: 302, c: '放デイ２２・有資格８・人欠・拘減・' },
  { s: '639520', v: 256, c: '放デイ２２・有資格８・人欠・評減・拘減・' },
  { s: '639521', v: 210, c: '放デイ２２・有資格８・人欠・未計画・拘減・' },
  { s: '639522', v: 178, c: '放デイ２２・有資格８・人欠・未計画・評減・拘減・' },
  { s: '639523', v: 149, c: '放デイ２２・有資格８・人欠・未計画２・拘減・' },
  { s: '639524', v: 126, c: '放デイ２２・有資格８・人欠・未計画２・評減・拘減・' },
  { s: '639525', v: 217, c: '放デイ２２・人欠２・' },
  { s: '639526', v: 184, c: '放デイ２２・人欠２・評減・' },
  { s: '639527', v: 152, c: '放デイ２２・人欠２・未計画・' },
  { s: '639528', v: 129, c: '放デイ２２・人欠２・未計画・評減・' },
  { s: '639529', v: 109, c: '放デイ２２・人欠２・未計画２・' },
  { s: '639530', v: 93, c: '放デイ２２・人欠２・未計画２・評減・' },
  { s: '639531', v: 212, c: '放デイ２２・人欠２・拘減・' },
  { s: '639532', v: 179, c: '放デイ２２・人欠２・評減・拘減・' },
  { s: '639533', v: 147, c: '放デイ２２・人欠２・未計画・拘減・' },
  { s: '639534', v: 124, c: '放デイ２２・人欠２・未計画・評減・拘減・' },
  { s: '639535', v: 104, c: '放デイ２２・人欠２・未計画２・拘減・' },
  { s: '639536', v: 88, c: '放デイ２２・人欠２・未計画２・評減・拘減・' },
  { s: '639537', v: 220, c: '放デイ２２・有資格８・人欠２・' },
  { s: '639538', v: 187, c: '放デイ２２・有資格８・人欠２・評減・' },
  { s: '639539', v: 154, c: '放デイ２２・有資格８・人欠２・未計画・' },
  { s: '639540', v: 131, c: '放デイ２２・有資格８・人欠２・未計画・評減・' },
  { s: '639541', v: 110, c: '放デイ２２・有資格８・人欠２・未計画２・' },
  { s: '639542', v: 94, c: '放デイ２２・有資格８・人欠２・未計画２・評減・' },
  { s: '639543', v: 215, c: '放デイ２２・有資格８・人欠２・拘減・' },
  { s: '639544', v: 182, c: '放デイ２２・有資格８・人欠２・評減・拘減・' },
  { s: '639545', v: 149, c: '放デイ２２・有資格８・人欠２・未計画・拘減・' },
  { s: '639546', v: 126, c: '放デイ２２・有資格８・人欠２・未計画・評減・拘減・' },
  { s: '639547', v: 105, c: '放デイ２２・有資格８・人欠２・未計画２・拘減・' },
  { s: '639548', v: 89, c: '放デイ２２・有資格８・人欠２・未計画２・評減・拘減・' },
  { s: '639551', v: 228, c: '放デイ２３・人欠・' },
  { s: '639552', v: 194, c: '放デイ２３・人欠・評減・' },
  { s: '639553', v: 160, c: '放デイ２３・人欠・未計画・' },
  { s: '639554', v: 136, c: '放デイ２３・人欠・未計画・評減・' },
  { s: '639555', v: 114, c: '放デイ２３・人欠・未計画２・' },
  { s: '639556', v: 97, c: '放デイ２３・人欠・未計画２・評減・' },
  { s: '639557', v: 223, c: '放デイ２３・人欠・拘減・' },
  { s: '639558', v: 189, c: '放デイ２３・人欠・評減・拘減・' },
  { s: '639559', v: 155, c: '放デイ２３・人欠・未計画・拘減・' },
  { s: '639560', v: 131, c: '放デイ２３・人欠・未計画・評減・拘減・' },
  { s: '639561', v: 109, c: '放デイ２３・人欠・未計画２・拘減・' },
  { s: '639562', v: 92, c: '放デイ２３・人欠・未計画２・評減・拘減・' },
  { s: '639563', v: 231, c: '放デイ２３・有資格９・人欠・' },
  { s: '639564', v: 196, c: '放デイ２３・有資格９・人欠・評減・' },
  { s: '639565', v: 162, c: '放デイ２３・有資格９・人欠・未計画・' },
  { s: '639566', v: 138, c: '放デイ２３・有資格９・人欠・未計画・評減・' },
  { s: '639567', v: 116, c: '放デイ２３・有資格９・人欠・未計画２・' },
  { s: '639568', v: 99, c: '放デイ２３・有資格９・人欠・未計画２・評減・' },
  { s: '639569', v: 226, c: '放デイ２３・有資格９・人欠・拘減・' },
  { s: '639570', v: 191, c: '放デイ２３・有資格９・人欠・評減・拘減・' },
  { s: '639571', v: 157, c: '放デイ２３・有資格９・人欠・未計画・拘減・' },
  { s: '639572', v: 133, c: '放デイ２３・有資格９・人欠・未計画・評減・拘減・' },
  { s: '639573', v: 111, c: '放デイ２３・有資格９・人欠・未計画２・拘減・' },
  { s: '639574', v: 94, c: '放デイ２３・有資格９・人欠・未計画２・評減・拘減・' },
  { s: '639575', v: 163, c: '放デイ２３・人欠２・' },
  { s: '639576', v: 139, c: '放デイ２３・人欠２・評減・' },
  { s: '639577', v: 114, c: '放デイ２３・人欠２・未計画・' },
  { s: '639578', v: 97, c: '放デイ２３・人欠２・未計画・評減・' },
  { s: '639579', v: 82, c: '放デイ２３・人欠２・未計画２・' },
  { s: '639580', v: 70, c: '放デイ２３・人欠２・未計画２・評減・' },
  { s: '639581', v: 158, c: '放デイ２３・人欠２・拘減・' },
  { s: '639582', v: 134, c: '放デイ２３・人欠２・評減・拘減・' },
  { s: '639583', v: 109, c: '放デイ２３・人欠２・未計画・拘減・' },
  { s: '639584', v: 92, c: '放デイ２３・人欠２・未計画・評減・拘減・' },
  { s: '639585', v: 77, c: '放デイ２３・人欠２・未計画２・拘減・' },
  { s: '639586', v: 65, c: '放デイ２３・人欠２・未計画２・評減・拘減・' },
  { s: '639587', v: 165, c: '放デイ２３・有資格９・人欠２・' },
  { s: '639588', v: 140, c: '放デイ２３・有資格９・人欠２・評減・' },
  { s: '639589', v: 116, c: '放デイ２３・有資格９・人欠２・未計画・' },
  { s: '639590', v: 99, c: '放デイ２３・有資格９・人欠２・未計画・評減・' },
  { s: '639591', v: 83, c: '放デイ２３・有資格９・人欠２・未計画２・' },
  { s: '639592', v: 71, c: '放デイ２３・有資格９・人欠２・未計画２・評減・' },
  { s: '639593', v: 160, c: '放デイ２３・有資格９・人欠２・拘減・' },
  { s: '639594', v: 135, c: '放デイ２３・有資格９・人欠２・評減・拘減・' },
  { s: '639595', v: 111, c: '放デイ２３・有資格９・人欠２・未計画・拘減・' },
  { s: '639596', v: 94, c: '放デイ２３・有資格９・人欠２・未計画・評減・拘減・' },
  { s: '639597', v: 78, c: '放デイ２３・有資格９・人欠２・未計画２・拘減・' },
  { s: '639598', v: 66, c: '放デイ２３・有資格９・人欠２・未計画２・評減・拘減・' },
  { s: '639601', v: 428, c: '放デイ２４・人欠・' },
  { s: '639602', v: 364, c: '放デイ２４・人欠・評減・' },
  { s: '639603', v: 300, c: '放デイ２４・人欠・未計画・' },
  { s: '639604', v: 255, c: '放デイ２４・人欠・未計画・評減・' },
  { s: '639605', v: 214, c: '放デイ２４・人欠・未計画２・' },
  { s: '639606', v: 182, c: '放デイ２４・人欠・未計画２・評減・' },
  { s: '639607', v: 423, c: '放デイ２４・人欠・拘減・' },
  { s: '639608', v: 359, c: '放デイ２４・人欠・評減・拘減・' },
  { s: '639609', v: 295, c: '放デイ２４・人欠・未計画・拘減・' },
  { s: '639610', v: 250, c: '放デイ２４・人欠・未計画・評減・拘減・' },
  { s: '639611', v: 209, c: '放デイ２４・人欠・未計画２・拘減・' },
  { s: '639612', v: 177, c: '放デイ２４・人欠・未計画２・評減・拘減・' },
  { s: '639613', v: 435, c: '放デイ２４・有資格１０・人欠・' },
  { s: '639614', v: 370, c: '放デイ２４・有資格１０・人欠・評減・' },
  { s: '639615', v: 305, c: '放デイ２４・有資格１０・人欠・未計画・' },
  { s: '639616', v: 259, c: '放デイ２４・有資格１０・人欠・未計画・評減・' },
  { s: '639617', v: 218, c: '放デイ２４・有資格１０・人欠・未計画２・' },
  { s: '639618', v: 185, c: '放デイ２４・有資格１０・人欠・未計画２・評減・' },
  { s: '639619', v: 430, c: '放デイ２４・有資格１０・人欠・拘減・' },
  { s: '639620', v: 365, c: '放デイ２４・有資格１０・人欠・評減・拘減・' },
  { s: '639621', v: 300, c: '放デイ２４・有資格１０・人欠・未計画・拘減・' },
  { s: '639622', v: 254, c: '放デイ２４・有資格１０・人欠・未計画・評減・拘減・' },
  { s: '639623', v: 213, c: '放デイ２４・有資格１０・人欠・未計画２・拘減・' },
  { s: '639624', v: 180, c: '放デイ２４・有資格１０・人欠・未計画２・評減・拘減・' },
  { s: '639625', v: 306, c: '放デイ２４・人欠２・' },
  { s: '639626', v: 260, c: '放デイ２４・人欠２・評減・' },
  { s: '639627', v: 214, c: '放デイ２４・人欠２・未計画・' },
  { s: '639628', v: 182, c: '放デイ２４・人欠２・未計画・評減・' },
  { s: '639629', v: 153, c: '放デイ２４・人欠２・未計画２・' },
  { s: '639630', v: 130, c: '放デイ２４・人欠２・未計画２・評減・' },
  { s: '639631', v: 301, c: '放デイ２４・人欠２・拘減・' },
  { s: '639632', v: 255, c: '放デイ２４・人欠２・評減・拘減・' },
  { s: '639633', v: 209, c: '放デイ２４・人欠２・未計画・拘減・' },
  { s: '639634', v: 177, c: '放デイ２４・人欠２・未計画・評減・拘減・' },
  { s: '639635', v: 148, c: '放デイ２４・人欠２・未計画２・拘減・' },
  { s: '639636', v: 125, c: '放デイ２４・人欠２・未計画２・評減・拘減・' },
  { s: '639637', v: 311, c: '放デイ２４・有資格１０・人欠２・' },
  { s: '639638', v: 264, c: '放デイ２４・有資格１０・人欠２・評減・' },
  { s: '639639', v: 218, c: '放デイ２４・有資格１０・人欠２・未計画・' },
  { s: '639640', v: 185, c: '放デイ２４・有資格１０・人欠２・未計画・評減・' },
  { s: '639641', v: 156, c: '放デイ２４・有資格１０・人欠２・未計画２・' },
  { s: '639642', v: 133, c: '放デイ２４・有資格１０・人欠２・未計画２・評減・' },
  { s: '639643', v: 306, c: '放デイ２４・有資格１０・人欠２・拘減・' },
  { s: '639644', v: 259, c: '放デイ２４・有資格１０・人欠２・評減・拘減・' },
  { s: '639645', v: 213, c: '放デイ２４・有資格１０・人欠２・未計画・拘減・' },
  { s: '639646', v: 180, c: '放デイ２４・有資格１０・人欠２・未計画・評減・拘減・' },
  { s: '639647', v: 151, c: '放デイ２４・有資格１０・人欠２・未計画２・拘減・' },
  { s: '639648', v: 128, c: '放デイ２４・有資格１０・人欠２・未計画２・評減・拘減・' },
  { s: '639651', v: 285, c: '放デイ２５・人欠・' },
  { s: '639652', v: 242, c: '放デイ２５・人欠・評減・' },
  { s: '639653', v: 200, c: '放デイ２５・人欠・未計画・' },
  { s: '639654', v: 170, c: '放デイ２５・人欠・未計画・評減・' },
  { s: '639655', v: 143, c: '放デイ２５・人欠・未計画２・' },
  { s: '639656', v: 122, c: '放デイ２５・人欠・未計画２・評減・' },
  { s: '639657', v: 280, c: '放デイ２５・人欠・拘減・' },
  { s: '639658', v: 237, c: '放デイ２５・人欠・評減・拘減・' },
  { s: '639659', v: 195, c: '放デイ２５・人欠・未計画・拘減・' },
  { s: '639660', v: 165, c: '放デイ２５・人欠・未計画・評減・拘減・' },
  { s: '639661', v: 138, c: '放デイ２５・人欠・未計画２・拘減・' },
  { s: '639662', v: 117, c: '放デイ２５・人欠・未計画２・評減・拘減・' },
  { s: '639663', v: 289, c: '放デイ２５・有資格１１・人欠・' },
  { s: '639664', v: 246, c: '放デイ２５・有資格１１・人欠・評減・' },
  { s: '639665', v: 202, c: '放デイ２５・有資格１１・人欠・未計画・' },
  { s: '639666', v: 172, c: '放デイ２５・有資格１１・人欠・未計画・評減・' },
  { s: '639667', v: 145, c: '放デイ２５・有資格１１・人欠・未計画２・' },
  { s: '639668', v: 123, c: '放デイ２５・有資格１１・人欠・未計画２・評減・' },
  { s: '639669', v: 284, c: '放デイ２５・有資格１１・人欠・拘減・' },
  { s: '639670', v: 241, c: '放デイ２５・有資格１１・人欠・評減・拘減・' },
  { s: '639671', v: 197, c: '放デイ２５・有資格１１・人欠・未計画・拘減・' },
  { s: '639672', v: 167, c: '放デイ２５・有資格１１・人欠・未計画・評減・拘減・' },
  { s: '639673', v: 140, c: '放デイ２５・有資格１１・人欠・未計画２・拘減・' },
  { s: '639674', v: 118, c: '放デイ２５・有資格１１・人欠・未計画２・評減・拘減・' },
  { s: '639675', v: 204, c: '放デイ２５・人欠２・' },
  { s: '639676', v: 173, c: '放デイ２５・人欠２・評減・' },
  { s: '639677', v: 143, c: '放デイ２５・人欠２・未計画・' },
  { s: '639678', v: 122, c: '放デイ２５・人欠２・未計画・評減・' },
  { s: '639679', v: 102, c: '放デイ２５・人欠２・未計画２・' },
  { s: '639680', v: 87, c: '放デイ２５・人欠２・未計画２・評減・' },
  { s: '639681', v: 199, c: '放デイ２５・人欠２・拘減・' },
  { s: '639682', v: 168, c: '放デイ２５・人欠２・評減・拘減・' },
  { s: '639683', v: 138, c: '放デイ２５・人欠２・未計画・拘減・' },
  { s: '639684', v: 117, c: '放デイ２５・人欠２・未計画・評減・拘減・' },
  { s: '639685', v: 97, c: '放デイ２５・人欠２・未計画２・拘減・' },
  { s: '639686', v: 82, c: '放デイ２５・人欠２・未計画２・評減・拘減・' },
  { s: '639687', v: 207, c: '放デイ２５・有資格１１・人欠２・' },
  { s: '639688', v: 176, c: '放デイ２５・有資格１１・人欠２・評減・' },
  { s: '639689', v: 145, c: '放デイ２５・有資格１１・人欠２・未計画・' },
  { s: '639690', v: 123, c: '放デイ２５・有資格１１・人欠２・未計画・評減・' },
  { s: '639691', v: 104, c: '放デイ２５・有資格１１・人欠２・未計画２・' },
  { s: '639692', v: 88, c: '放デイ２５・有資格１１・人欠２・未計画２・評減・' },
  { s: '639693', v: 202, c: '放デイ２５・有資格１１・人欠２・拘減・' },
  { s: '639694', v: 171, c: '放デイ２５・有資格１１・人欠２・評減・拘減・' },
  { s: '639695', v: 140, c: '放デイ２５・有資格１１・人欠２・未計画・拘減・' },
  { s: '639696', v: 118, c: '放デイ２５・有資格１１・人欠２・未計画・評減・拘減・' },
  { s: '639697', v: 99, c: '放デイ２５・有資格１１・人欠２・未計画２・拘減・' },
  { s: '639698', v: 83, c: '放デイ２５・有資格１１・人欠２・未計画２・評減・拘減・' },
  { s: '639701', v: 214, c: '放デイ２６・人欠・' },
  { s: '639702', v: 182, c: '放デイ２６・人欠・評減・' },
  { s: '639703', v: 150, c: '放デイ２６・人欠・未計画・' },
  { s: '639704', v: 128, c: '放デイ２６・人欠・未計画・評減・' },
  { s: '639705', v: 107, c: '放デイ２６・人欠・未計画２・' },
  { s: '639706', v: 91, c: '放デイ２６・人欠・未計画２・評減・' },
  { s: '639707', v: 209, c: '放デイ２６・人欠・拘減・' },
  { s: '639708', v: 177, c: '放デイ２６・人欠・評減・拘減・' },
  { s: '639709', v: 145, c: '放デイ２６・人欠・未計画・拘減・' },
  { s: '639710', v: 123, c: '放デイ２６・人欠・未計画・評減・拘減・' },
  { s: '639711', v: 102, c: '放デイ２６・人欠・未計画２・拘減・' },
  { s: '639712', v: 86, c: '放デイ２６・人欠・未計画２・評減・拘減・' },
  { s: '639713', v: 217, c: '放デイ２６・有資格１２・人欠・' },
  { s: '639714', v: 184, c: '放デイ２６・有資格１２・人欠・評減・' },
  { s: '639715', v: 152, c: '放デイ２６・有資格１２・人欠・未計画・' },
  { s: '639716', v: 129, c: '放デイ２６・有資格１２・人欠・未計画・評減・' },
  { s: '639717', v: 109, c: '放デイ２６・有資格１２・人欠・未計画２・' },
  { s: '639718', v: 93, c: '放デイ２６・有資格１２・人欠・未計画２・評減・' },
  { s: '639719', v: 212, c: '放デイ２６・有資格１２・人欠・拘減・' },
  { s: '639720', v: 179, c: '放デイ２６・有資格１２・人欠・評減・拘減・' },
  { s: '639721', v: 147, c: '放デイ２６・有資格１２・人欠・未計画・拘減・' },
  { s: '639722', v: 124, c: '放デイ２６・有資格１２・人欠・未計画・評減・拘減・' },
  { s: '639723', v: 104, c: '放デイ２６・有資格１２・人欠・未計画２・拘減・' },
  { s: '639724', v: 88, c: '放デイ２６・有資格１２・人欠・未計画２・評減・拘減・' },
  { s: '639725', v: 153, c: '放デイ２６・人欠２・' },
  { s: '639726', v: 130, c: '放デイ２６・人欠２・評減・' },
  { s: '639727', v: 107, c: '放デイ２６・人欠２・未計画・' },
  { s: '639728', v: 91, c: '放デイ２６・人欠２・未計画・評減・' },
  { s: '639729', v: 77, c: '放デイ２６・人欠２・未計画２・' },
  { s: '639730', v: 65, c: '放デイ２６・人欠２・未計画２・評減・' },
  { s: '639731', v: 148, c: '放デイ２６・人欠２・拘減・' },
  { s: '639732', v: 125, c: '放デイ２６・人欠２・評減・拘減・' },
  { s: '639733', v: 102, c: '放デイ２６・人欠２・未計画・拘減・' },
  { s: '639734', v: 86, c: '放デイ２６・人欠２・未計画・評減・拘減・' },
  { s: '639735', v: 72, c: '放デイ２６・人欠２・未計画２・拘減・' },
  { s: '639736', v: 60, c: '放デイ２６・人欠２・未計画２・評減・拘減・' },
  { s: '639737', v: 155, c: '放デイ２６・有資格１２・人欠２・' },
  { s: '639738', v: 132, c: '放デイ２６・有資格１２・人欠２・評減・' },
  { s: '639739', v: 109, c: '放デイ２６・有資格１２・人欠２・未計画・' },
  { s: '639740', v: 93, c: '放デイ２６・有資格１２・人欠２・未計画・評減・' },
  { s: '639741', v: 78, c: '放デイ２６・有資格１２・人欠２・未計画２・' },
  { s: '639742', v: 66, c: '放デイ２６・有資格１２・人欠２・未計画２・評減・' },
  { s: '639743', v: 150, c: '放デイ２６・有資格１２・人欠２・拘減・' },
  { s: '639744', v: 127, c: '放デイ２６・有資格１２・人欠２・評減・拘減・' },
  { s: '639745', v: 104, c: '放デイ２６・有資格１２・人欠２・未計画・拘減・' },
  { s: '639746', v: 88, c: '放デイ２６・有資格１２・人欠２・未計画・評減・拘減・' },
  { s: '639747', v: 73, c: '放デイ２６・有資格１２・人欠２・未計画２・拘減・' },
  { s: '639748', v: 61, c: '放デイ２６・有資格１２・人欠２・未計画２・評減・拘減・' },
  { s: '639751', v: 419, c: '放デイ２７・人欠・' },
  { s: '639752', v: 356, c: '放デイ２７・人欠・評減・' },
  { s: '639753', v: 293, c: '放デイ２７・人欠・未計画・' },
  { s: '639754', v: 249, c: '放デイ２７・人欠・未計画・評減・' },
  { s: '639755', v: 210, c: '放デイ２７・人欠・未計画２・' },
  { s: '639756', v: 179, c: '放デイ２７・人欠・未計画２・評減・' },
  { s: '639757', v: 414, c: '放デイ２７・人欠・拘減・' },
  { s: '639758', v: 351, c: '放デイ２７・人欠・評減・拘減・' },
  { s: '639759', v: 288, c: '放デイ２７・人欠・未計画・拘減・' },
  { s: '639760', v: 244, c: '放デイ２７・人欠・未計画・評減・拘減・' },
  { s: '639761', v: 205, c: '放デイ２７・人欠・未計画２・拘減・' },
  { s: '639762', v: 174, c: '放デイ２７・人欠・未計画２・評減・拘減・' },
  { s: '639763', v: 426, c: '放デイ２７・有資格１３・人欠・' },
  { s: '639764', v: 362, c: '放デイ２７・有資格１３・人欠・評減・' },
  { s: '639765', v: 298, c: '放デイ２７・有資格１３・人欠・未計画・' },
  { s: '639766', v: 253, c: '放デイ２７・有資格１３・人欠・未計画・評減・' },
  { s: '639767', v: 213, c: '放デイ２７・有資格１３・人欠・未計画２・' },
  { s: '639768', v: 181, c: '放デイ２７・有資格１３・人欠・未計画２・評減・' },
  { s: '639769', v: 421, c: '放デイ２７・有資格１３・人欠・拘減・' },
  { s: '639770', v: 357, c: '放デイ２７・有資格１３・人欠・評減・拘減・' },
  { s: '639771', v: 293, c: '放デイ２７・有資格１３・人欠・未計画・拘減・' },
  { s: '639772', v: 248, c: '放デイ２７・有資格１３・人欠・未計画・評減・拘減・' },
  { s: '639773', v: 208, c: '放デイ２７・有資格１３・人欠・未計画２・拘減・' },
  { s: '639774', v: 176, c: '放デイ２７・有資格１３・人欠・未計画２・評減・拘減・' },
  { s: '639775', v: 300, c: '放デイ２７・人欠２・' },
  { s: '639776', v: 255, c: '放デイ２７・人欠２・評減・' },
  { s: '639777', v: 210, c: '放デイ２７・人欠２・未計画・' },
  { s: '639778', v: 179, c: '放デイ２７・人欠２・未計画・評減・' },
  { s: '639779', v: 150, c: '放デイ２７・人欠２・未計画２・' },
  { s: '639780', v: 128, c: '放デイ２７・人欠２・未計画２・評減・' },
  { s: '639781', v: 295, c: '放デイ２７・人欠２・拘減・' },
  { s: '639782', v: 250, c: '放デイ２７・人欠２・評減・拘減・' },
  { s: '639783', v: 205, c: '放デイ２７・人欠２・未計画・拘減・' },
  { s: '639784', v: 174, c: '放デイ２７・人欠２・未計画・評減・拘減・' },
  { s: '639785', v: 145, c: '放デイ２７・人欠２・未計画２・拘減・' },
  { s: '639786', v: 123, c: '放デイ２７・人欠２・未計画２・評減・拘減・' },
  { s: '639787', v: 304, c: '放デイ２７・有資格１３・人欠２・' },
  { s: '639788', v: 258, c: '放デイ２７・有資格１３・人欠２・評減・' },
  { s: '639789', v: 213, c: '放デイ２７・有資格１３・人欠２・未計画・' },
  { s: '639790', v: 181, c: '放デイ２７・有資格１３・人欠２・未計画・評減・' },
  { s: '639791', v: 152, c: '放デイ２７・有資格１３・人欠２・未計画２・' },
  { s: '639792', v: 129, c: '放デイ２７・有資格１３・人欠２・未計画２・評減・' },
  { s: '639793', v: 299, c: '放デイ２７・有資格１３・人欠２・拘減・' },
  { s: '639794', v: 253, c: '放デイ２７・有資格１３・人欠２・評減・拘減・' },
  { s: '639795', v: 208, c: '放デイ２７・有資格１３・人欠２・未計画・拘減・' },
  { s: '639796', v: 176, c: '放デイ２７・有資格１３・人欠２・未計画・評減・拘減・' },
  { s: '639797', v: 147, c: '放デイ２７・有資格１３・人欠２・未計画２・拘減・' },
  { s: '639798', v: 124, c: '放デイ２７・有資格１３・人欠２・未計画２・評減・拘減・' },
  { s: '639801', v: 279, c: '放デイ２８・人欠・' },
  { s: '639802', v: 237, c: '放デイ２８・人欠・評減・' },
  { s: '639803', v: 195, c: '放デイ２８・人欠・未計画・' },
  { s: '639804', v: 166, c: '放デイ２８・人欠・未計画・評減・' },
  { s: '639805', v: 140, c: '放デイ２８・人欠・未計画２・' },
  { s: '639806', v: 119, c: '放デイ２８・人欠・未計画２・評減・' },
  { s: '639807', v: 274, c: '放デイ２８・人欠・拘減・' },
  { s: '639808', v: 232, c: '放デイ２８・人欠・評減・拘減・' },
  { s: '639809', v: 190, c: '放デイ２８・人欠・未計画・拘減・' },
  { s: '639810', v: 161, c: '放デイ２８・人欠・未計画・評減・拘減・' },
  { s: '639811', v: 135, c: '放デイ２８・人欠・未計画２・拘減・' },
  { s: '639812', v: 114, c: '放デイ２８・人欠・未計画２・評減・拘減・' },
  { s: '639813', v: 283, c: '放デイ２８・有資格１４・人欠・' },
  { s: '639814', v: 241, c: '放デイ２８・有資格１４・人欠・評減・' },
  { s: '639815', v: 198, c: '放デイ２８・有資格１４・人欠・未計画・' },
  { s: '639816', v: 168, c: '放デイ２８・有資格１４・人欠・未計画・評減・' },
  { s: '639817', v: 142, c: '放デイ２８・有資格１４・人欠・未計画２・' },
  { s: '639818', v: 121, c: '放デイ２８・有資格１４・人欠・未計画２・評減・' },
  { s: '639819', v: 278, c: '放デイ２８・有資格１４・人欠・拘減・' },
  { s: '639820', v: 236, c: '放デイ２８・有資格１４・人欠・評減・拘減・' },
  { s: '639821', v: 193, c: '放デイ２８・有資格１４・人欠・未計画・拘減・' },
  { s: '639822', v: 163, c: '放デイ２８・有資格１４・人欠・未計画・評減・拘減・' },
  { s: '639823', v: 137, c: '放デイ２８・有資格１４・人欠・未計画２・拘減・' },
  { s: '639824', v: 116, c: '放デイ２８・有資格１４・人欠・未計画２・評減・拘減・' },
  { s: '639825', v: 199, c: '放デイ２８・人欠２・' },
  { s: '639826', v: 169, c: '放デイ２８・人欠２・評減・' },
  { s: '639827', v: 139, c: '放デイ２８・人欠２・未計画・' },
  { s: '639828', v: 118, c: '放デイ２８・人欠２・未計画・評減・' },
  { s: '639829', v: 100, c: '放デイ２８・人欠２・未計画２・' },
  { s: '639830', v: 85, c: '放デイ２８・人欠２・未計画２・評減・' },
  { s: '639831', v: 194, c: '放デイ２８・人欠２・拘減・' },
  { s: '639832', v: 164, c: '放デイ２８・人欠２・評減・拘減・' },
  { s: '639833', v: 134, c: '放デイ２８・人欠２・未計画・拘減・' },
  { s: '639834', v: 113, c: '放デイ２８・人欠２・未計画・評減・拘減・' },
  { s: '639835', v: 95, c: '放デイ２８・人欠２・未計画２・拘減・' },
  { s: '639836', v: 80, c: '放デイ２８・人欠２・未計画２・評減・拘減・' },
  { s: '639837', v: 202, c: '放デイ２８・有資格１４・人欠２・' },
  { s: '639838', v: 172, c: '放デイ２８・有資格１４・人欠２・評減・' },
  { s: '639839', v: 141, c: '放デイ２８・有資格１４・人欠２・未計画・' },
  { s: '639840', v: 120, c: '放デイ２８・有資格１４・人欠２・未計画・評減・' },
  { s: '639841', v: 101, c: '放デイ２８・有資格１４・人欠２・未計画２・' },
  { s: '639842', v: 86, c: '放デイ２８・有資格１４・人欠２・未計画２・評減・' },
  { s: '639843', v: 197, c: '放デイ２８・有資格１４・人欠２・拘減・' },
  { s: '639844', v: 167, c: '放デイ２８・有資格１４・人欠２・評減・拘減・' },
  { s: '639845', v: 136, c: '放デイ２８・有資格１４・人欠２・未計画・拘減・' },
  { s: '639846', v: 115, c: '放デイ２８・有資格１４・人欠２・未計画・評減・拘減・' },
  { s: '639847', v: 96, c: '放デイ２８・有資格１４・人欠２・未計画２・拘減・' },
  { s: '639848', v: 81, c: '放デイ２８・有資格１４・人欠２・未計画２・評減・拘減・' },
  { s: '639851', v: 209, c: '放デイ２９・人欠・' },
  { s: '639852', v: 178, c: '放デイ２９・人欠・評減・' },
  { s: '639853', v: 146, c: '放デイ２９・人欠・未計画・' },
  { s: '639854', v: 124, c: '放デイ２９・人欠・未計画・評減・' },
  { s: '639855', v: 105, c: '放デイ２９・人欠・未計画２・' },
  { s: '639856', v: 89, c: '放デイ２９・人欠・未計画２・評減・' },
  { s: '639857', v: 204, c: '放デイ２９・人欠・拘減・' },
  { s: '639858', v: 173, c: '放デイ２９・人欠・評減・拘減・' },
  { s: '639859', v: 141, c: '放デイ２９・人欠・未計画・拘減・' },
  { s: '639860', v: 119, c: '放デイ２９・人欠・未計画・評減・拘減・' },
  { s: '639861', v: 100, c: '放デイ２９・人欠・未計画２・拘減・' },
  { s: '639862', v: 84, c: '放デイ２９・人欠・未計画２・評減・拘減・' },
  { s: '639863', v: 212, c: '放デイ２９・有資格１５・人欠・' },
  { s: '639864', v: 180, c: '放デイ２９・有資格１５・人欠・評減・' },
  { s: '639865', v: 148, c: '放デイ２９・有資格１５・人欠・未計画・' },
  { s: '639866', v: 126, c: '放デイ２９・有資格１５・人欠・未計画・評減・' },
  { s: '639867', v: 106, c: '放デイ２９・有資格１５・人欠・未計画２・' },
  { s: '639868', v: 90, c: '放デイ２９・有資格１５・人欠・未計画２・評減・' },
  { s: '639869', v: 207, c: '放デイ２９・有資格１５・人欠・拘減・' },
  { s: '639870', v: 175, c: '放デイ２９・有資格１５・人欠・評減・拘減・' },
  { s: '639871', v: 143, c: '放デイ２９・有資格１５・人欠・未計画・拘減・' },
  { s: '639872', v: 121, c: '放デイ２９・有資格１５・人欠・未計画・評減・拘減・' },
  { s: '639873', v: 101, c: '放デイ２９・有資格１５・人欠・未計画２・拘減・' },
  { s: '639874', v: 85, c: '放デイ２９・有資格１５・人欠・未計画２・評減・拘減・' },
  { s: '639875', v: 150, c: '放デイ２９・人欠２・' },
  { s: '639876', v: 128, c: '放デイ２９・人欠２・評減・' },
  { s: '639877', v: 105, c: '放デイ２９・人欠２・未計画・' },
  { s: '639878', v: 89, c: '放デイ２９・人欠２・未計画・評減・' },
  { s: '639879', v: 75, c: '放デイ２９・人欠２・未計画２・' },
  { s: '639880', v: 64, c: '放デイ２９・人欠２・未計画２・評減・' },
  { s: '639881', v: 145, c: '放デイ２９・人欠２・拘減・' },
  { s: '639882', v: 123, c: '放デイ２９・人欠２・評減・拘減・' },
  { s: '639883', v: 100, c: '放デイ２９・人欠２・未計画・拘減・' },
  { s: '639884', v: 84, c: '放デイ２９・人欠２・未計画・評減・拘減・' },
  { s: '639885', v: 70, c: '放デイ２９・人欠２・未計画２・拘減・' },
  { s: '639886', v: 59, c: '放デイ２９・人欠２・未計画２・評減・拘減・' },
  { s: '639887', v: 152, c: '放デイ２９・有資格１５・人欠２・' },
  { s: '639888', v: 129, c: '放デイ２９・有資格１５・人欠２・評減・' },
  { s: '639889', v: 106, c: '放デイ２９・有資格１５・人欠２・未計画・' },
  { s: '639890', v: 90, c: '放デイ２９・有資格１５・人欠２・未計画・評減・' },
  { s: '639891', v: 76, c: '放デイ２９・有資格１５・人欠２・未計画２・' },
  { s: '639892', v: 65, c: '放デイ２９・有資格１５・人欠２・未計画２・評減・' },
  { s: '639893', v: 147, c: '放デイ２９・有資格１５・人欠２・拘減・' },
  { s: '639894', v: 124, c: '放デイ２９・有資格１５・人欠２・評減・拘減・' },
  { s: '639895', v: 101, c: '放デイ２９・有資格１５・人欠２・未計画・拘減・' },
  { s: '639896', v: 85, c: '放デイ２９・有資格１５・人欠２・未計画・評減・拘減・' },
  { s: '639897', v: 71, c: '放デイ２９・有資格１５・人欠２・未計画２・拘減・' },
  { s: '639898', v: 60, c: '放デイ２９・有資格１５・人欠２・未計画２・評減・拘減・' },
  { s: '639211', v: 554, c: '放デイ４・人欠・' },
  { s: '639901', v: 471, c: '放デイ４・人欠・評減・' },
  { s: '639212', v: 388, c: '放デイ４・人欠・開減１・' },
  { s: '639902', v: 330, c: '放デイ４・人欠・開減１・評減・' },
  { s: '639215', v: 471, c: '放デイ４・人欠・開減２・' },
  { s: '639903', v: 400, c: '放デイ４・人欠・開減２・評減・' },
  { s: '639904', v: 549, c: '放デイ４・人欠・拘減・' },
  { s: '639905', v: 466, c: '放デイ４・人欠・評減・拘減・' },
  { s: '639906', v: 383, c: '放デイ４・人欠・開減１・拘減・' },
  { s: '639907', v: 325, c: '放デイ４・人欠・開減１・評減・拘減・' },
  { s: '639908', v: 466, c: '放デイ４・人欠・開減２・拘減・' },
  { s: '639909', v: 395, c: '放デイ４・人欠・開減２・評減・拘減・' },
  { s: '639213', v: 388, c: '放デイ４・人欠・未計画・' },
  { s: '639910', v: 330, c: '放デイ４・人欠・未計画・評減・' },
  { s: '639214', v: 272, c: '放デイ４・人欠・未計画・開減１・' },
  { s: '639911', v: 231, c: '放デイ４・人欠・未計画・開減１・評減・' },
  { s: '639216', v: 330, c: '放デイ４・人欠・未計画・開減２・' },
  { s: '639912', v: 281, c: '放デイ４・人欠・未計画・開減２・評減・' },
  { s: '639913', v: 383, c: '放デイ４・人欠・未計画・拘減・' },
  { s: '639914', v: 325, c: '放デイ４・人欠・未計画・評減・拘減・' },
  { s: '639915', v: 267, c: '放デイ４・人欠・未計画・開減１・拘減・' },
  { s: '639916', v: 226, c: '放デイ４・人欠・未計画・開減１・評減・拘減・' },
  { s: '639917', v: 325, c: '放デイ４・人欠・未計画・開減２・拘減・' },
  { s: '639918', v: 276, c: '放デイ４・人欠・未計画・開減２・評減・拘減・' },
  { s: '639919', v: 277, c: '放デイ４・人欠・未計画２・' },
  { s: '639920', v: 235, c: '放デイ４・人欠・未計画２・評減・' },
  { s: '639921', v: 194, c: '放デイ４・人欠・未計画２・開減１・' },
  { s: '639922', v: 165, c: '放デイ４・人欠・未計画２・開減１・評減・' },
  { s: '639923', v: 235, c: '放デイ４・人欠・未計画２・開減２・' },
  { s: '639924', v: 200, c: '放デイ４・人欠・未計画２・開減２・評減・' },
  { s: '639925', v: 272, c: '放デイ４・人欠・未計画２・拘減・' },
  { s: '639926', v: 230, c: '放デイ４・人欠・未計画２・評減・拘減・' },
  { s: '639927', v: 189, c: '放デイ４・人欠・未計画２・開減１・拘減・' },
  { s: '639928', v: 160, c: '放デイ４・人欠・未計画２・開減１・評減・拘減・' },
  { s: '639929', v: 230, c: '放デイ４・人欠・未計画２・開減２・拘減・' },
  { s: '639930', v: 195, c: '放デイ４・人欠・未計画２・開減２・評減・拘減・' },
  { s: '639217', v: 563, c: '放デイ４・有資格４・人欠・' },
  { s: '639931', v: 479, c: '放デイ４・有資格４・人欠・評減・' },
  { s: '639218', v: 394, c: '放デイ４・有資格４・人欠・開減１・' },
  { s: '639932', v: 335, c: '放デイ４・有資格４・人欠・開減１・評減・' },
  { s: '639219', v: 479, c: '放デイ４・有資格４・人欠・開減２・' },
  { s: '639933', v: 407, c: '放デイ４・有資格４・人欠・開減２・評減・' },
  { s: '639934', v: 558, c: '放デイ４・有資格４・人欠・拘減・' },
  { s: '639935', v: 474, c: '放デイ４・有資格４・人欠・評減・拘減・' },
  { s: '639936', v: 389, c: '放デイ４・有資格４・人欠・開減１・拘減・' },
  { s: '639937', v: 330, c: '放デイ４・有資格４・人欠・開減１・評減・拘減・' },
  { s: '639938', v: 474, c: '放デイ４・有資格４・人欠・開減２・拘減・' },
  { s: '639939', v: 402, c: '放デイ４・有資格４・人欠・開減２・評減・拘減・' },
  { s: '639220', v: 394, c: '放デイ４・有資格４・人欠・未計画・' },
  { s: '639940', v: 335, c: '放デイ４・有資格４・人欠・未計画・評減・' },
  { s: '639241', v: 276, c: '放デイ４・有資格４・人欠・未計画・開減１・' },
  { s: '639941', v: 235, c: '放デイ４・有資格４・人欠・未計画・開減１・評減・' },
  { s: '639242', v: 335, c: '放デイ４・有資格４・人欠・未計画・開減２・' },
  { s: '639942', v: 285, c: '放デイ４・有資格４・人欠・未計画・開減２・評減・' },
  { s: '639943', v: 389, c: '放デイ４・有資格４・人欠・未計画・拘減・' },
  { s: '639944', v: 330, c: '放デイ４・有資格４・人欠・未計画・評減・拘減・' },
  { s: '639945', v: 271, c: '放デイ４・有資格４・人欠・未計画・開減１・拘減・' },
  { s: '639946', v: 230, c: '放デイ４・有資格４・人欠・未計画・開減１・評減・拘減・' },
  { s: '639947', v: 330, c: '放デイ４・有資格４・人欠・未計画・開減２・拘減・' },
  { s: '639948', v: 280, c: '放デイ４・有資格４・人欠・未計画・開減２・評減・拘減・' },
  { s: '639949', v: 282, c: '放デイ４・有資格４・人欠・未計画２・' },
  { s: '639950', v: 240, c: '放デイ４・有資格４・人欠・未計画２・評減・' },
  { s: '639951', v: 197, c: '放デイ４・有資格４・人欠・未計画２・開減１・' },
  { s: '639952', v: 167, c: '放デイ４・有資格４・人欠・未計画２・開減１・評減・' },
  { s: '639953', v: 240, c: '放デイ４・有資格４・人欠・未計画２・開減２・' },
  { s: '639954', v: 204, c: '放デイ４・有資格４・人欠・未計画２・開減２・評減・' },
  { s: '639955', v: 277, c: '放デイ４・有資格４・人欠・未計画２・拘減・' },
  { s: '639956', v: 235, c: '放デイ４・有資格４・人欠・未計画２・評減・拘減・' },
  { s: '639957', v: 192, c: '放デイ４・有資格４・人欠・未計画２・開減１・拘減・' },
  { s: '639958', v: 162, c: '放デイ４・有資格４・人欠・未計画２・開減１・評減・拘減・' },
  { s: '639959', v: 235, c: '放デイ４・有資格４・人欠・未計画２・開減２・拘減・' },
  { s: '639960', v: 199, c: '放デイ４・有資格４・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C001', v: 396, c: '放デイ４・人欠２・' },
  { s: '63C002', v: 337, c: '放デイ４・人欠２・評減・' },
  { s: '63C003', v: 277, c: '放デイ４・人欠２・開減１・' },
  { s: '63C004', v: 235, c: '放デイ４・人欠２・開減１・評減・' },
  { s: '63C005', v: 337, c: '放デイ４・人欠２・開減２・' },
  { s: '63C006', v: 286, c: '放デイ４・人欠２・開減２・評減・' },
  { s: '63C007', v: 391, c: '放デイ４・人欠２・拘減・' },
  { s: '63C008', v: 332, c: '放デイ４・人欠２・評減・拘減・' },
  { s: '63C009', v: 272, c: '放デイ４・人欠２・開減１・拘減・' },
  { s: '63C010', v: 230, c: '放デイ４・人欠２・開減１・評減・拘減・' },
  { s: '63C011', v: 332, c: '放デイ４・人欠２・開減２・拘減・' },
  { s: '63C012', v: 281, c: '放デイ４・人欠２・開減２・評減・拘減・' },
  { s: '63C013', v: 277, c: '放デイ４・人欠２・未計画・' },
  { s: '63C014', v: 235, c: '放デイ４・人欠２・未計画・評減・' },
  { s: '63C015', v: 194, c: '放デイ４・人欠２・未計画・開減１・' },
  { s: '63C016', v: 165, c: '放デイ４・人欠２・未計画・開減１・評減・' },
  { s: '63C017', v: 235, c: '放デイ４・人欠２・未計画・開減２・' },
  { s: '63C018', v: 200, c: '放デイ４・人欠２・未計画・開減２・評減・' },
  { s: '63C019', v: 272, c: '放デイ４・人欠２・未計画・拘減・' },
  { s: '63C020', v: 230, c: '放デイ４・人欠２・未計画・評減・拘減・' },
  { s: '63C021', v: 189, c: '放デイ４・人欠２・未計画・開減１・拘減・' },
  { s: '63C022', v: 160, c: '放デイ４・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C023', v: 230, c: '放デイ４・人欠２・未計画・開減２・拘減・' },
  { s: '63C024', v: 195, c: '放デイ４・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C025', v: 198, c: '放デイ４・人欠２・未計画２・' },
  { s: '63C026', v: 168, c: '放デイ４・人欠２・未計画２・評減・' },
  { s: '63C027', v: 139, c: '放デイ４・人欠２・未計画２・開減１・' },
  { s: '63C028', v: 118, c: '放デイ４・人欠２・未計画２・開減１・評減・' },
  { s: '63C029', v: 168, c: '放デイ４・人欠２・未計画２・開減２・' },
  { s: '63C030', v: 143, c: '放デイ４・人欠２・未計画２・開減２・評減・' },
  { s: '63C031', v: 193, c: '放デイ４・人欠２・未計画２・拘減・' },
  { s: '63C032', v: 163, c: '放デイ４・人欠２・未計画２・評減・拘減・' },
  { s: '63C033', v: 134, c: '放デイ４・人欠２・未計画２・開減１・拘減・' },
  { s: '63C034', v: 113, c: '放デイ４・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C035', v: 163, c: '放デイ４・人欠２・未計画２・開減２・拘減・' },
  { s: '63C036', v: 138, c: '放デイ４・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C037', v: 402, c: '放デイ４・有資格４・人欠２・' },
  { s: '63C038', v: 342, c: '放デイ４・有資格４・人欠２・評減・' },
  { s: '63C039', v: 281, c: '放デイ４・有資格４・人欠２・開減１・' },
  { s: '63C040', v: 239, c: '放デイ４・有資格４・人欠２・開減１・評減・' },
  { s: '63C041', v: 342, c: '放デイ４・有資格４・人欠２・開減２・' },
  { s: '63C042', v: 291, c: '放デイ４・有資格４・人欠２・開減２・評減・' },
  { s: '63C043', v: 397, c: '放デイ４・有資格４・人欠２・拘減・' },
  { s: '63C044', v: 337, c: '放デイ４・有資格４・人欠２・評減・拘減・' },
  { s: '63C045', v: 276, c: '放デイ４・有資格４・人欠２・開減１・拘減・' },
  { s: '63C046', v: 234, c: '放デイ４・有資格４・人欠２・開減１・評減・拘減・' },
  { s: '63C047', v: 337, c: '放デイ４・有資格４・人欠２・開減２・拘減・' },
  { s: '63C048', v: 286, c: '放デイ４・有資格４・人欠２・開減２・評減・拘減・' },
  { s: '63C049', v: 281, c: '放デイ４・有資格４・人欠２・未計画・' },
  { s: '63C050', v: 239, c: '放デイ４・有資格４・人欠２・未計画・評減・' },
  { s: '63C051', v: 197, c: '放デイ４・有資格４・人欠２・未計画・開減１・' },
  { s: '63C052', v: 167, c: '放デイ４・有資格４・人欠２・未計画・開減１・評減・' },
  { s: '63C053', v: 239, c: '放デイ４・有資格４・人欠２・未計画・開減２・' },
  { s: '63C054', v: 203, c: '放デイ４・有資格４・人欠２・未計画・開減２・評減・' },
  { s: '63C055', v: 276, c: '放デイ４・有資格４・人欠２・未計画・拘減・' },
  { s: '63C056', v: 234, c: '放デイ４・有資格４・人欠２・未計画・評減・拘減・' },
  { s: '63C057', v: 192, c: '放デイ４・有資格４・人欠２・未計画・開減１・拘減・' },
  { s: '63C058', v: 162, c: '放デイ４・有資格４・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C059', v: 234, c: '放デイ４・有資格４・人欠２・未計画・開減２・拘減・' },
  { s: '63C060', v: 198, c: '放デイ４・有資格４・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C061', v: 201, c: '放デイ４・有資格４・人欠２・未計画２・' },
  { s: '63C062', v: 171, c: '放デイ４・有資格４・人欠２・未計画２・評減・' },
  { s: '63C063', v: 141, c: '放デイ４・有資格４・人欠２・未計画２・開減１・' },
  { s: '63C064', v: 120, c: '放デイ４・有資格４・人欠２・未計画２・開減１・評減・' },
  { s: '63C065', v: 171, c: '放デイ４・有資格４・人欠２・未計画２・開減２・' },
  { s: '63C066', v: 145, c: '放デイ４・有資格４・人欠２・未計画２・開減２・評減・' },
  { s: '63C067', v: 196, c: '放デイ４・有資格４・人欠２・未計画２・拘減・' },
  { s: '63C068', v: 166, c: '放デイ４・有資格４・人欠２・未計画２・評減・拘減・' },
  { s: '63C069', v: 136, c: '放デイ４・有資格４・人欠２・未計画２・開減１・拘減・' },
  { s: '63C070', v: 115, c: '放デイ４・有資格４・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C071', v: 166, c: '放デイ４・有資格４・人欠２・未計画２・開減２・拘減・' },
  { s: '63C072', v: 140, c: '放デイ４・有資格４・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '639221', v: 372, c: '放デイ５・人欠・' },
  { s: '63C073', v: 316, c: '放デイ５・人欠・評減・' },
  { s: '639222', v: 260, c: '放デイ５・人欠・開減１・' },
  { s: '63C074', v: 221, c: '放デイ５・人欠・開減１・評減・' },
  { s: '639225', v: 316, c: '放デイ５・人欠・開減２・' },
  { s: '63C075', v: 269, c: '放デイ５・人欠・開減２・評減・' },
  { s: '63C076', v: 367, c: '放デイ５・人欠・拘減・' },
  { s: '63C077', v: 311, c: '放デイ５・人欠・評減・拘減・' },
  { s: '63C078', v: 255, c: '放デイ５・人欠・開減１・拘減・' },
  { s: '63C079', v: 216, c: '放デイ５・人欠・開減１・評減・拘減・' },
  { s: '63C080', v: 311, c: '放デイ５・人欠・開減２・拘減・' },
  { s: '63C081', v: 264, c: '放デイ５・人欠・開減２・評減・拘減・' },
  { s: '639223', v: 260, c: '放デイ５・人欠・未計画・' },
  { s: '63C082', v: 221, c: '放デイ５・人欠・未計画・評減・' },
  { s: '639224', v: 182, c: '放デイ５・人欠・未計画・開減１・' },
  { s: '63C083', v: 155, c: '放デイ５・人欠・未計画・開減１・評減・' },
  { s: '639226', v: 221, c: '放デイ５・人欠・未計画・開減２・' },
  { s: '63C084', v: 188, c: '放デイ５・人欠・未計画・開減２・評減・' },
  { s: '63C085', v: 255, c: '放デイ５・人欠・未計画・拘減・' },
  { s: '63C086', v: 216, c: '放デイ５・人欠・未計画・評減・拘減・' },
  { s: '63C087', v: 177, c: '放デイ５・人欠・未計画・開減１・拘減・' },
  { s: '63C088', v: 150, c: '放デイ５・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C089', v: 216, c: '放デイ５・人欠・未計画・開減２・拘減・' },
  { s: '63C090', v: 183, c: '放デイ５・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C091', v: 186, c: '放デイ５・人欠・未計画２・' },
  { s: '63C092', v: 158, c: '放デイ５・人欠・未計画２・評減・' },
  { s: '63C093', v: 130, c: '放デイ５・人欠・未計画２・開減１・' },
  { s: '63C094', v: 111, c: '放デイ５・人欠・未計画２・開減１・評減・' },
  { s: '63C095', v: 158, c: '放デイ５・人欠・未計画２・開減２・' },
  { s: '63C096', v: 134, c: '放デイ５・人欠・未計画２・開減２・評減・' },
  { s: '63C097', v: 181, c: '放デイ５・人欠・未計画２・拘減・' },
  { s: '63C098', v: 153, c: '放デイ５・人欠・未計画２・評減・拘減・' },
  { s: '63C099', v: 125, c: '放デイ５・人欠・未計画２・開減１・拘減・' },
  { s: '63C100', v: 106, c: '放デイ５・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C101', v: 153, c: '放デイ５・人欠・未計画２・開減２・拘減・' },
  { s: '63C102', v: 129, c: '放デイ５・人欠・未計画２・開減２・評減・拘減・' },
  { s: '639227', v: 378, c: '放デイ５・有資格５・人欠・' },
  { s: '63C103', v: 321, c: '放デイ５・有資格５・人欠・評減・' },
  { s: '639228', v: 265, c: '放デイ５・有資格５・人欠・開減１・' },
  { s: '63C104', v: 225, c: '放デイ５・有資格５・人欠・開減１・評減・' },
  { s: '639229', v: 321, c: '放デイ５・有資格５・人欠・開減２・' },
  { s: '63C105', v: 273, c: '放デイ５・有資格５・人欠・開減２・評減・' },
  { s: '63C106', v: 373, c: '放デイ５・有資格５・人欠・拘減・' },
  { s: '63C107', v: 316, c: '放デイ５・有資格５・人欠・評減・拘減・' },
  { s: '63C108', v: 260, c: '放デイ５・有資格５・人欠・開減１・拘減・' },
  { s: '63C109', v: 220, c: '放デイ５・有資格５・人欠・開減１・評減・拘減・' },
  { s: '63C110', v: 316, c: '放デイ５・有資格５・人欠・開減２・拘減・' },
  { s: '63C111', v: 268, c: '放デイ５・有資格５・人欠・開減２・評減・拘減・' },
  { s: '639230', v: 265, c: '放デイ５・有資格５・人欠・未計画・' },
  { s: '63C112', v: 225, c: '放デイ５・有資格５・人欠・未計画・評減・' },
  { s: '639251', v: 186, c: '放デイ５・有資格５・人欠・未計画・開減１・' },
  { s: '63C113', v: 158, c: '放デイ５・有資格５・人欠・未計画・開減１・評減・' },
  { s: '639252', v: 225, c: '放デイ５・有資格５・人欠・未計画・開減２・' },
  { s: '63C114', v: 191, c: '放デイ５・有資格５・人欠・未計画・開減２・評減・' },
  { s: '63C115', v: 260, c: '放デイ５・有資格５・人欠・未計画・拘減・' },
  { s: '63C116', v: 220, c: '放デイ５・有資格５・人欠・未計画・評減・拘減・' },
  { s: '63C117', v: 181, c: '放デイ５・有資格５・人欠・未計画・開減１・拘減・' },
  { s: '63C118', v: 153, c: '放デイ５・有資格５・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C119', v: 220, c: '放デイ５・有資格５・人欠・未計画・開減２・拘減・' },
  { s: '63C120', v: 186, c: '放デイ５・有資格５・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C121', v: 189, c: '放デイ５・有資格５・人欠・未計画２・' },
  { s: '63C122', v: 161, c: '放デイ５・有資格５・人欠・未計画２・評減・' },
  { s: '63C123', v: 132, c: '放デイ５・有資格５・人欠・未計画２・開減１・' },
  { s: '63C124', v: 112, c: '放デイ５・有資格５・人欠・未計画２・開減１・評減・' },
  { s: '63C125', v: 161, c: '放デイ５・有資格５・人欠・未計画２・開減２・' },
  { s: '63C126', v: 137, c: '放デイ５・有資格５・人欠・未計画２・開減２・評減・' },
  { s: '63C127', v: 184, c: '放デイ５・有資格５・人欠・未計画２・拘減・' },
  { s: '63C128', v: 156, c: '放デイ５・有資格５・人欠・未計画２・評減・拘減・' },
  { s: '63C129', v: 127, c: '放デイ５・有資格５・人欠・未計画２・開減１・拘減・' },
  { s: '63C130', v: 107, c: '放デイ５・有資格５・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C131', v: 156, c: '放デイ５・有資格５・人欠・未計画２・開減２・拘減・' },
  { s: '63C132', v: 132, c: '放デイ５・有資格５・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C133', v: 266, c: '放デイ５・人欠２・' },
  { s: '63C134', v: 226, c: '放デイ５・人欠２・評減・' },
  { s: '63C135', v: 186, c: '放デイ５・人欠２・開減１・' },
  { s: '63C136', v: 158, c: '放デイ５・人欠２・開減１・評減・' },
  { s: '63C137', v: 226, c: '放デイ５・人欠２・開減２・' },
  { s: '63C138', v: 192, c: '放デイ５・人欠２・開減２・評減・' },
  { s: '63C139', v: 261, c: '放デイ５・人欠２・拘減・' },
  { s: '63C140', v: 221, c: '放デイ５・人欠２・評減・拘減・' },
  { s: '63C141', v: 181, c: '放デイ５・人欠２・開減１・拘減・' },
  { s: '63C142', v: 153, c: '放デイ５・人欠２・開減１・評減・拘減・' },
  { s: '63C143', v: 221, c: '放デイ５・人欠２・開減２・拘減・' },
  { s: '63C144', v: 187, c: '放デイ５・人欠２・開減２・評減・拘減・' },
  { s: '63C145', v: 186, c: '放デイ５・人欠２・未計画・' },
  { s: '63C146', v: 158, c: '放デイ５・人欠２・未計画・評減・' },
  { s: '63C147', v: 130, c: '放デイ５・人欠２・未計画・開減１・' },
  { s: '63C148', v: 111, c: '放デイ５・人欠２・未計画・開減１・評減・' },
  { s: '63C149', v: 158, c: '放デイ５・人欠２・未計画・開減２・' },
  { s: '63C150', v: 134, c: '放デイ５・人欠２・未計画・開減２・評減・' },
  { s: '63C151', v: 181, c: '放デイ５・人欠２・未計画・拘減・' },
  { s: '63C152', v: 153, c: '放デイ５・人欠２・未計画・評減・拘減・' },
  { s: '63C153', v: 125, c: '放デイ５・人欠２・未計画・開減１・拘減・' },
  { s: '63C154', v: 106, c: '放デイ５・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C155', v: 153, c: '放デイ５・人欠２・未計画・開減２・拘減・' },
  { s: '63C156', v: 129, c: '放デイ５・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C157', v: 133, c: '放デイ５・人欠２・未計画２・' },
  { s: '63C158', v: 113, c: '放デイ５・人欠２・未計画２・評減・' },
  { s: '63C159', v: 93, c: '放デイ５・人欠２・未計画２・開減１・' },
  { s: '63C160', v: 79, c: '放デイ５・人欠２・未計画２・開減１・評減・' },
  { s: '63C161', v: 113, c: '放デイ５・人欠２・未計画２・開減２・' },
  { s: '63C162', v: 96, c: '放デイ５・人欠２・未計画２・開減２・評減・' },
  { s: '63C163', v: 128, c: '放デイ５・人欠２・未計画２・拘減・' },
  { s: '63C164', v: 108, c: '放デイ５・人欠２・未計画２・評減・拘減・' },
  { s: '63C165', v: 88, c: '放デイ５・人欠２・未計画２・開減１・拘減・' },
  { s: '63C166', v: 74, c: '放デイ５・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C167', v: 108, c: '放デイ５・人欠２・未計画２・開減２・拘減・' },
  { s: '63C168', v: 91, c: '放デイ５・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C169', v: 270, c: '放デイ５・有資格５・人欠２・' },
  { s: '63C170', v: 230, c: '放デイ５・有資格５・人欠２・評減・' },
  { s: '63C171', v: 189, c: '放デイ５・有資格５・人欠２・開減１・' },
  { s: '63C172', v: 161, c: '放デイ５・有資格５・人欠２・開減１・評減・' },
  { s: '63C173', v: 230, c: '放デイ５・有資格５・人欠２・開減２・' },
  { s: '63C174', v: 196, c: '放デイ５・有資格５・人欠２・開減２・評減・' },
  { s: '63C175', v: 265, c: '放デイ５・有資格５・人欠２・拘減・' },
  { s: '63C176', v: 225, c: '放デイ５・有資格５・人欠２・評減・拘減・' },
  { s: '63C177', v: 184, c: '放デイ５・有資格５・人欠２・開減１・拘減・' },
  { s: '63C178', v: 156, c: '放デイ５・有資格５・人欠２・開減１・評減・拘減・' },
  { s: '63C179', v: 225, c: '放デイ５・有資格５・人欠２・開減２・拘減・' },
  { s: '63C180', v: 191, c: '放デイ５・有資格５・人欠２・開減２・評減・拘減・' },
  { s: '63C181', v: 189, c: '放デイ５・有資格５・人欠２・未計画・' },
  { s: '63C182', v: 161, c: '放デイ５・有資格５・人欠２・未計画・評減・' },
  { s: '63C183', v: 132, c: '放デイ５・有資格５・人欠２・未計画・開減１・' },
  { s: '63C184', v: 112, c: '放デイ５・有資格５・人欠２・未計画・開減１・評減・' },
  { s: '63C185', v: 161, c: '放デイ５・有資格５・人欠２・未計画・開減２・' },
  { s: '63C186', v: 137, c: '放デイ５・有資格５・人欠２・未計画・開減２・評減・' },
  { s: '63C187', v: 184, c: '放デイ５・有資格５・人欠２・未計画・拘減・' },
  { s: '63C188', v: 156, c: '放デイ５・有資格５・人欠２・未計画・評減・拘減・' },
  { s: '63C189', v: 127, c: '放デイ５・有資格５・人欠２・未計画・開減１・拘減・' },
  { s: '63C190', v: 107, c: '放デイ５・有資格５・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C191', v: 156, c: '放デイ５・有資格５・人欠２・未計画・開減２・拘減・' },
  { s: '63C192', v: 132, c: '放デイ５・有資格５・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C193', v: 135, c: '放デイ５・有資格５・人欠２・未計画２・' },
  { s: '63C194', v: 115, c: '放デイ５・有資格５・人欠２・未計画２・評減・' },
  { s: '63C195', v: 95, c: '放デイ５・有資格５・人欠２・未計画２・開減１・' },
  { s: '63C196', v: 81, c: '放デイ５・有資格５・人欠２・未計画２・開減１・評減・' },
  { s: '63C197', v: 115, c: '放デイ５・有資格５・人欠２・未計画２・開減２・' },
  { s: '63C198', v: 98, c: '放デイ５・有資格５・人欠２・未計画２・開減２・評減・' },
  { s: '63C199', v: 130, c: '放デイ５・有資格５・人欠２・未計画２・拘減・' },
  { s: '63C200', v: 110, c: '放デイ５・有資格５・人欠２・未計画２・評減・拘減・' },
  { s: '63C201', v: 90, c: '放デイ５・有資格５・人欠２・未計画２・開減１・拘減・' },
  { s: '63C202', v: 76, c: '放デイ５・有資格５・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C203', v: 110, c: '放デイ５・有資格５・人欠２・未計画２・開減２・拘減・' },
  { s: '63C204', v: 93, c: '放デイ５・有資格５・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '639231', v: 288, c: '放デイ６・人欠・' },
  { s: '63C205', v: 245, c: '放デイ６・人欠・評減・' },
  { s: '639232', v: 202, c: '放デイ６・人欠・開減１・' },
  { s: '63C206', v: 172, c: '放デイ６・人欠・開減１・評減・' },
  { s: '639235', v: 245, c: '放デイ６・人欠・開減２・' },
  { s: '63C207', v: 208, c: '放デイ６・人欠・開減２・評減・' },
  { s: '63C208', v: 283, c: '放デイ６・人欠・拘減・' },
  { s: '63C209', v: 240, c: '放デイ６・人欠・評減・拘減・' },
  { s: '63C210', v: 197, c: '放デイ６・人欠・開減１・拘減・' },
  { s: '63C211', v: 167, c: '放デイ６・人欠・開減１・評減・拘減・' },
  { s: '63C212', v: 240, c: '放デイ６・人欠・開減２・拘減・' },
  { s: '63C213', v: 203, c: '放デイ６・人欠・開減２・評減・拘減・' },
  { s: '639233', v: 202, c: '放デイ６・人欠・未計画・' },
  { s: '63C214', v: 172, c: '放デイ６・人欠・未計画・評減・' },
  { s: '639234', v: 141, c: '放デイ６・人欠・未計画・開減１・' },
  { s: '63C215', v: 120, c: '放デイ６・人欠・未計画・開減１・評減・' },
  { s: '639236', v: 172, c: '放デイ６・人欠・未計画・開減２・' },
  { s: '63C216', v: 146, c: '放デイ６・人欠・未計画・開減２・評減・' },
  { s: '63C217', v: 197, c: '放デイ６・人欠・未計画・拘減・' },
  { s: '63C218', v: 167, c: '放デイ６・人欠・未計画・評減・拘減・' },
  { s: '63C219', v: 136, c: '放デイ６・人欠・未計画・開減１・拘減・' },
  { s: '63C220', v: 115, c: '放デイ６・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C221', v: 167, c: '放デイ６・人欠・未計画・開減２・拘減・' },
  { s: '63C222', v: 141, c: '放デイ６・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C223', v: 144, c: '放デイ６・人欠・未計画２・' },
  { s: '63C224', v: 122, c: '放デイ６・人欠・未計画２・評減・' },
  { s: '63C225', v: 101, c: '放デイ６・人欠・未計画２・開減１・' },
  { s: '63C226', v: 86, c: '放デイ６・人欠・未計画２・開減１・評減・' },
  { s: '63C227', v: 122, c: '放デイ６・人欠・未計画２・開減２・' },
  { s: '63C228', v: 104, c: '放デイ６・人欠・未計画２・開減２・評減・' },
  { s: '63C229', v: 139, c: '放デイ６・人欠・未計画２・拘減・' },
  { s: '63C230', v: 117, c: '放デイ６・人欠・未計画２・評減・拘減・' },
  { s: '63C231', v: 96, c: '放デイ６・人欠・未計画２・開減１・拘減・' },
  { s: '63C232', v: 81, c: '放デイ６・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C233', v: 117, c: '放デイ６・人欠・未計画２・開減２・拘減・' },
  { s: '63C234', v: 99, c: '放デイ６・人欠・未計画２・開減２・評減・拘減・' },
  { s: '639237', v: 293, c: '放デイ６・有資格６・人欠・' },
  { s: '63C235', v: 249, c: '放デイ６・有資格６・人欠・評減・' },
  { s: '639238', v: 205, c: '放デイ６・有資格６・人欠・開減１・' },
  { s: '63C236', v: 174, c: '放デイ６・有資格６・人欠・開減１・評減・' },
  { s: '639239', v: 249, c: '放デイ６・有資格６・人欠・開減２・' },
  { s: '63C237', v: 212, c: '放デイ６・有資格６・人欠・開減２・評減・' },
  { s: '63C238', v: 288, c: '放デイ６・有資格６・人欠・拘減・' },
  { s: '63C239', v: 244, c: '放デイ６・有資格６・人欠・評減・拘減・' },
  { s: '63C240', v: 200, c: '放デイ６・有資格６・人欠・開減１・拘減・' },
  { s: '63C241', v: 169, c: '放デイ６・有資格６・人欠・開減１・評減・拘減・' },
  { s: '63C242', v: 244, c: '放デイ６・有資格６・人欠・開減２・拘減・' },
  { s: '63C243', v: 207, c: '放デイ６・有資格６・人欠・開減２・評減・拘減・' },
  { s: '639240', v: 205, c: '放デイ６・有資格６・人欠・未計画・' },
  { s: '63C244', v: 174, c: '放デイ６・有資格６・人欠・未計画・評減・' },
  { s: '639261', v: 144, c: '放デイ６・有資格６・人欠・未計画・開減１・' },
  { s: '63C245', v: 122, c: '放デイ６・有資格６・人欠・未計画・開減１・評減・' },
  { s: '639262', v: 174, c: '放デイ６・有資格６・人欠・未計画・開減２・' },
  { s: '63C246', v: 148, c: '放デイ６・有資格６・人欠・未計画・開減２・評減・' },
  { s: '63C247', v: 200, c: '放デイ６・有資格６・人欠・未計画・拘減・' },
  { s: '63C248', v: 169, c: '放デイ６・有資格６・人欠・未計画・評減・拘減・' },
  { s: '63C249', v: 139, c: '放デイ６・有資格６・人欠・未計画・開減１・拘減・' },
  { s: '63C250', v: 117, c: '放デイ６・有資格６・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C251', v: 169, c: '放デイ６・有資格６・人欠・未計画・開減２・拘減・' },
  { s: '63C252', v: 143, c: '放デイ６・有資格６・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C253', v: 147, c: '放デイ６・有資格６・人欠・未計画２・' },
  { s: '63C254', v: 125, c: '放デイ６・有資格６・人欠・未計画２・評減・' },
  { s: '63C255', v: 103, c: '放デイ６・有資格６・人欠・未計画２・開減１・' },
  { s: '63C256', v: 88, c: '放デイ６・有資格６・人欠・未計画２・開減１・評減・' },
  { s: '63C257', v: 125, c: '放デイ６・有資格６・人欠・未計画２・開減２・' },
  { s: '63C258', v: 106, c: '放デイ６・有資格６・人欠・未計画２・開減２・評減・' },
  { s: '63C259', v: 142, c: '放デイ６・有資格６・人欠・未計画２・拘減・' },
  { s: '63C260', v: 120, c: '放デイ６・有資格６・人欠・未計画２・評減・拘減・' },
  { s: '63C261', v: 98, c: '放デイ６・有資格６・人欠・未計画２・開減１・拘減・' },
  { s: '63C262', v: 83, c: '放デイ６・有資格６・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C263', v: 120, c: '放デイ６・有資格６・人欠・未計画２・開減２・拘減・' },
  { s: '63C264', v: 101, c: '放デイ６・有資格６・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C265', v: 206, c: '放デイ６・人欠２・' },
  { s: '63C266', v: 175, c: '放デイ６・人欠２・評減・' },
  { s: '63C267', v: 144, c: '放デイ６・人欠２・開減１・' },
  { s: '63C268', v: 122, c: '放デイ６・人欠２・開減１・評減・' },
  { s: '63C269', v: 175, c: '放デイ６・人欠２・開減２・' },
  { s: '63C270', v: 149, c: '放デイ６・人欠２・開減２・評減・' },
  { s: '63C271', v: 201, c: '放デイ６・人欠２・拘減・' },
  { s: '63C272', v: 170, c: '放デイ６・人欠２・評減・拘減・' },
  { s: '63C273', v: 139, c: '放デイ６・人欠２・開減１・拘減・' },
  { s: '63C274', v: 117, c: '放デイ６・人欠２・開減１・評減・拘減・' },
  { s: '63C275', v: 170, c: '放デイ６・人欠２・開減２・拘減・' },
  { s: '63C276', v: 144, c: '放デイ６・人欠２・開減２・評減・拘減・' },
  { s: '63C277', v: 144, c: '放デイ６・人欠２・未計画・' },
  { s: '63C278', v: 122, c: '放デイ６・人欠２・未計画・評減・' },
  { s: '63C279', v: 101, c: '放デイ６・人欠２・未計画・開減１・' },
  { s: '63C280', v: 86, c: '放デイ６・人欠２・未計画・開減１・評減・' },
  { s: '63C281', v: 122, c: '放デイ６・人欠２・未計画・開減２・' },
  { s: '63C282', v: 104, c: '放デイ６・人欠２・未計画・開減２・評減・' },
  { s: '63C283', v: 139, c: '放デイ６・人欠２・未計画・拘減・' },
  { s: '63C284', v: 117, c: '放デイ６・人欠２・未計画・評減・拘減・' },
  { s: '63C285', v: 96, c: '放デイ６・人欠２・未計画・開減１・拘減・' },
  { s: '63C286', v: 81, c: '放デイ６・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C287', v: 117, c: '放デイ６・人欠２・未計画・開減２・拘減・' },
  { s: '63C288', v: 99, c: '放デイ６・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C289', v: 103, c: '放デイ６・人欠２・未計画２・' },
  { s: '63C290', v: 88, c: '放デイ６・人欠２・未計画２・評減・' },
  { s: '63C291', v: 72, c: '放デイ６・人欠２・未計画２・開減１・' },
  { s: '63C292', v: 61, c: '放デイ６・人欠２・未計画２・開減１・評減・' },
  { s: '63C293', v: 88, c: '放デイ６・人欠２・未計画２・開減２・' },
  { s: '63C294', v: 75, c: '放デイ６・人欠２・未計画２・開減２・評減・' },
  { s: '63C295', v: 98, c: '放デイ６・人欠２・未計画２・拘減・' },
  { s: '63C296', v: 83, c: '放デイ６・人欠２・未計画２・評減・拘減・' },
  { s: '63C297', v: 67, c: '放デイ６・人欠２・未計画２・開減１・拘減・' },
  { s: '63C298', v: 56, c: '放デイ６・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C299', v: 83, c: '放デイ６・人欠２・未計画２・開減２・拘減・' },
  { s: '63C300', v: 70, c: '放デイ６・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C301', v: 209, c: '放デイ６・有資格６・人欠２・' },
  { s: '63C302', v: 178, c: '放デイ６・有資格６・人欠２・評減・' },
  { s: '63C303', v: 146, c: '放デイ６・有資格６・人欠２・開減１・' },
  { s: '63C304', v: 124, c: '放デイ６・有資格６・人欠２・開減１・評減・' },
  { s: '63C305', v: 178, c: '放デイ６・有資格６・人欠２・開減２・' },
  { s: '63C306', v: 151, c: '放デイ６・有資格６・人欠２・開減２・評減・' },
  { s: '63C307', v: 204, c: '放デイ６・有資格６・人欠２・拘減・' },
  { s: '63C308', v: 173, c: '放デイ６・有資格６・人欠２・評減・拘減・' },
  { s: '63C309', v: 141, c: '放デイ６・有資格６・人欠２・開減１・拘減・' },
  { s: '63C310', v: 119, c: '放デイ６・有資格６・人欠２・開減１・評減・拘減・' },
  { s: '63C311', v: 173, c: '放デイ６・有資格６・人欠２・開減２・拘減・' },
  { s: '63C312', v: 146, c: '放デイ６・有資格６・人欠２・開減２・評減・拘減・' },
  { s: '63C313', v: 146, c: '放デイ６・有資格６・人欠２・未計画・' },
  { s: '63C314', v: 124, c: '放デイ６・有資格６・人欠２・未計画・評減・' },
  { s: '63C315', v: 102, c: '放デイ６・有資格６・人欠２・未計画・開減１・' },
  { s: '63C316', v: 87, c: '放デイ６・有資格６・人欠２・未計画・開減１・評減・' },
  { s: '63C317', v: 124, c: '放デイ６・有資格６・人欠２・未計画・開減２・' },
  { s: '63C318', v: 105, c: '放デイ６・有資格６・人欠２・未計画・開減２・評減・' },
  { s: '63C319', v: 141, c: '放デイ６・有資格６・人欠２・未計画・拘減・' },
  { s: '63C320', v: 119, c: '放デイ６・有資格６・人欠２・未計画・評減・拘減・' },
  { s: '63C321', v: 97, c: '放デイ６・有資格６・人欠２・未計画・開減１・拘減・' },
  { s: '63C322', v: 82, c: '放デイ６・有資格６・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C323', v: 119, c: '放デイ６・有資格６・人欠２・未計画・開減２・拘減・' },
  { s: '63C324', v: 100, c: '放デイ６・有資格６・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C325', v: 105, c: '放デイ６・有資格６・人欠２・未計画２・' },
  { s: '63C326', v: 89, c: '放デイ６・有資格６・人欠２・未計画２・評減・' },
  { s: '63C327', v: 74, c: '放デイ６・有資格６・人欠２・未計画２・開減１・' },
  { s: '63C328', v: 63, c: '放デイ６・有資格６・人欠２・未計画２・開減１・評減・' },
  { s: '63C329', v: 89, c: '放デイ６・有資格６・人欠２・未計画２・開減２・' },
  { s: '63C330', v: 76, c: '放デイ６・有資格６・人欠２・未計画２・開減２・評減・' },
  { s: '63C331', v: 100, c: '放デイ６・有資格６・人欠２・未計画２・拘減・' },
  { s: '63C332', v: 84, c: '放デイ６・有資格６・人欠２・未計画２・評減・拘減・' },
  { s: '63C333', v: 69, c: '放デイ６・有資格６・人欠２・未計画２・開減１・拘減・' },
  { s: '63C334', v: 58, c: '放デイ６・有資格６・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C335', v: 84, c: '放デイ６・有資格６・人欠２・未計画２・開減２・拘減・' },
  { s: '63C336', v: 71, c: '放デイ６・有資格６・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C337', v: 511, c: '放デイ３０・人欠・' },
  { s: '63C338', v: 434, c: '放デイ３０・人欠・評減・' },
  { s: '63C339', v: 358, c: '放デイ３０・人欠・開減１・' },
  { s: '63C340', v: 304, c: '放デイ３０・人欠・開減１・評減・' },
  { s: '63C341', v: 434, c: '放デイ３０・人欠・開減２・' },
  { s: '63C342', v: 369, c: '放デイ３０・人欠・開減２・評減・' },
  { s: '63C343', v: 506, c: '放デイ３０・人欠・拘減・' },
  { s: '63C344', v: 429, c: '放デイ３０・人欠・評減・拘減・' },
  { s: '63C345', v: 353, c: '放デイ３０・人欠・開減１・拘減・' },
  { s: '63C346', v: 299, c: '放デイ３０・人欠・開減１・評減・拘減・' },
  { s: '63C347', v: 429, c: '放デイ３０・人欠・開減２・拘減・' },
  { s: '63C348', v: 364, c: '放デイ３０・人欠・開減２・評減・拘減・' },
  { s: '63C349', v: 358, c: '放デイ３０・人欠・未計画・' },
  { s: '63C350', v: 304, c: '放デイ３０・人欠・未計画・評減・' },
  { s: '63C351', v: 251, c: '放デイ３０・人欠・未計画・開減１・' },
  { s: '63C352', v: 213, c: '放デイ３０・人欠・未計画・開減１・評減・' },
  { s: '63C353', v: 304, c: '放デイ３０・人欠・未計画・開減２・' },
  { s: '63C354', v: 258, c: '放デイ３０・人欠・未計画・開減２・評減・' },
  { s: '63C355', v: 353, c: '放デイ３０・人欠・未計画・拘減・' },
  { s: '63C356', v: 299, c: '放デイ３０・人欠・未計画・評減・拘減・' },
  { s: '63C357', v: 246, c: '放デイ３０・人欠・未計画・開減１・拘減・' },
  { s: '63C358', v: 208, c: '放デイ３０・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C359', v: 299, c: '放デイ３０・人欠・未計画・開減２・拘減・' },
  { s: '63C360', v: 253, c: '放デイ３０・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C361', v: 256, c: '放デイ３０・人欠・未計画２・' },
  { s: '63C362', v: 218, c: '放デイ３０・人欠・未計画２・評減・' },
  { s: '63C363', v: 179, c: '放デイ３０・人欠・未計画２・開減１・' },
  { s: '63C364', v: 152, c: '放デイ３０・人欠・未計画２・開減１・評減・' },
  { s: '63C365', v: 218, c: '放デイ３０・人欠・未計画２・開減２・' },
  { s: '63C366', v: 185, c: '放デイ３０・人欠・未計画２・開減２・評減・' },
  { s: '63C367', v: 251, c: '放デイ３０・人欠・未計画２・拘減・' },
  { s: '63C368', v: 213, c: '放デイ３０・人欠・未計画２・評減・拘減・' },
  { s: '63C369', v: 174, c: '放デイ３０・人欠・未計画２・開減１・拘減・' },
  { s: '63C370', v: 147, c: '放デイ３０・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C371', v: 213, c: '放デイ３０・人欠・未計画２・開減２・拘減・' },
  { s: '63C372', v: 180, c: '放デイ３０・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C373', v: 519, c: '放デイ３０・有資格１６・人欠・' },
  { s: '63C374', v: 441, c: '放デイ３０・有資格１６・人欠・評減・' },
  { s: '63C375', v: 363, c: '放デイ３０・有資格１６・人欠・開減１・' },
  { s: '63C376', v: 309, c: '放デイ３０・有資格１６・人欠・開減１・評減・' },
  { s: '63C377', v: 441, c: '放デイ３０・有資格１６・人欠・開減２・' },
  { s: '63C378', v: 375, c: '放デイ３０・有資格１６・人欠・開減２・評減・' },
  { s: '63C379', v: 514, c: '放デイ３０・有資格１６・人欠・拘減・' },
  { s: '63C380', v: 436, c: '放デイ３０・有資格１６・人欠・評減・拘減・' },
  { s: '63C381', v: 358, c: '放デイ３０・有資格１６・人欠・開減１・拘減・' },
  { s: '63C382', v: 304, c: '放デイ３０・有資格１６・人欠・開減１・評減・拘減・' },
  { s: '63C383', v: 436, c: '放デイ３０・有資格１６・人欠・開減２・拘減・' },
  { s: '63C384', v: 370, c: '放デイ３０・有資格１６・人欠・開減２・評減・拘減・' },
  { s: '63C385', v: 363, c: '放デイ３０・有資格１６・人欠・未計画・' },
  { s: '63C386', v: 309, c: '放デイ３０・有資格１６・人欠・未計画・評減・' },
  { s: '63C387', v: 254, c: '放デイ３０・有資格１６・人欠・未計画・開減１・' },
  { s: '63C388', v: 216, c: '放デイ３０・有資格１６・人欠・未計画・開減１・評減・' },
  { s: '63C389', v: 309, c: '放デイ３０・有資格１６・人欠・未計画・開減２・' },
  { s: '63C390', v: 263, c: '放デイ３０・有資格１６・人欠・未計画・開減２・評減・' },
  { s: '63C391', v: 358, c: '放デイ３０・有資格１６・人欠・未計画・拘減・' },
  { s: '63C392', v: 304, c: '放デイ３０・有資格１６・人欠・未計画・評減・拘減・' },
  { s: '63C393', v: 249, c: '放デイ３０・有資格１６・人欠・未計画・開減１・拘減・' },
  { s: '63C394', v: 211, c: '放デイ３０・有資格１６・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C395', v: 304, c: '放デイ３０・有資格１６・人欠・未計画・開減２・拘減・' },
  { s: '63C396', v: 258, c: '放デイ３０・有資格１６・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C397', v: 260, c: '放デイ３０・有資格１６・人欠・未計画２・' },
  { s: '63C398', v: 221, c: '放デイ３０・有資格１６・人欠・未計画２・評減・' },
  { s: '63C399', v: 182, c: '放デイ３０・有資格１６・人欠・未計画２・開減１・' },
  { s: '63C400', v: 155, c: '放デイ３０・有資格１６・人欠・未計画２・開減１・評減・' },
  { s: '63C401', v: 221, c: '放デイ３０・有資格１６・人欠・未計画２・開減２・' },
  { s: '63C402', v: 188, c: '放デイ３０・有資格１６・人欠・未計画２・開減２・評減・' },
  { s: '63C403', v: 255, c: '放デイ３０・有資格１６・人欠・未計画２・拘減・' },
  { s: '63C404', v: 216, c: '放デイ３０・有資格１６・人欠・未計画２・評減・拘減・' },
  { s: '63C405', v: 177, c: '放デイ３０・有資格１６・人欠・未計画２・開減１・拘減・' },
  { s: '63C406', v: 150, c: '放デイ３０・有資格１６・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C407', v: 216, c: '放デイ３０・有資格１６・人欠・未計画２・開減２・拘減・' },
  { s: '63C408', v: 183, c: '放デイ３０・有資格１６・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C409', v: 365, c: '放デイ３０・人欠２・' },
  { s: '63C410', v: 310, c: '放デイ３０・人欠２・評減・' },
  { s: '63C411', v: 256, c: '放デイ３０・人欠２・開減１・' },
  { s: '63C412', v: 218, c: '放デイ３０・人欠２・開減１・評減・' },
  { s: '63C413', v: 310, c: '放デイ３０・人欠２・開減２・' },
  { s: '63C414', v: 264, c: '放デイ３０・人欠２・開減２・評減・' },
  { s: '63C415', v: 360, c: '放デイ３０・人欠２・拘減・' },
  { s: '63C416', v: 305, c: '放デイ３０・人欠２・評減・拘減・' },
  { s: '63C417', v: 251, c: '放デイ３０・人欠２・開減１・拘減・' },
  { s: '63C418', v: 213, c: '放デイ３０・人欠２・開減１・評減・拘減・' },
  { s: '63C419', v: 305, c: '放デイ３０・人欠２・開減２・拘減・' },
  { s: '63C420', v: 259, c: '放デイ３０・人欠２・開減２・評減・拘減・' },
  { s: '63C421', v: 256, c: '放デイ３０・人欠２・未計画・' },
  { s: '63C422', v: 218, c: '放デイ３０・人欠２・未計画・評減・' },
  { s: '63C423', v: 179, c: '放デイ３０・人欠２・未計画・開減１・' },
  { s: '63C424', v: 152, c: '放デイ３０・人欠２・未計画・開減１・評減・' },
  { s: '63C425', v: 218, c: '放デイ３０・人欠２・未計画・開減２・' },
  { s: '63C426', v: 185, c: '放デイ３０・人欠２・未計画・開減２・評減・' },
  { s: '63C427', v: 251, c: '放デイ３０・人欠２・未計画・拘減・' },
  { s: '63C428', v: 213, c: '放デイ３０・人欠２・未計画・評減・拘減・' },
  { s: '63C429', v: 174, c: '放デイ３０・人欠２・未計画・開減１・拘減・' },
  { s: '63C430', v: 147, c: '放デイ３０・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C431', v: 213, c: '放デイ３０・人欠２・未計画・開減２・拘減・' },
  { s: '63C432', v: 180, c: '放デイ３０・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C433', v: 183, c: '放デイ３０・人欠２・未計画２・' },
  { s: '63C434', v: 156, c: '放デイ３０・人欠２・未計画２・評減・' },
  { s: '63C435', v: 128, c: '放デイ３０・人欠２・未計画２・開減１・' },
  { s: '63C436', v: 109, c: '放デイ３０・人欠２・未計画２・開減１・評減・' },
  { s: '63C437', v: 156, c: '放デイ３０・人欠２・未計画２・開減２・' },
  { s: '63C438', v: 133, c: '放デイ３０・人欠２・未計画２・開減２・評減・' },
  { s: '63C439', v: 178, c: '放デイ３０・人欠２・未計画２・拘減・' },
  { s: '63C440', v: 151, c: '放デイ３０・人欠２・未計画２・評減・拘減・' },
  { s: '63C441', v: 123, c: '放デイ３０・人欠２・未計画２・開減１・拘減・' },
  { s: '63C442', v: 104, c: '放デイ３０・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C443', v: 151, c: '放デイ３０・人欠２・未計画２・開減２・拘減・' },
  { s: '63C444', v: 128, c: '放デイ３０・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C445', v: 371, c: '放デイ３０・有資格１６・人欠２・' },
  { s: '63C446', v: 315, c: '放デイ３０・有資格１６・人欠２・評減・' },
  { s: '63C447', v: 260, c: '放デイ３０・有資格１６・人欠２・開減１・' },
  { s: '63C448', v: 221, c: '放デイ３０・有資格１６・人欠２・開減１・評減・' },
  { s: '63C449', v: 315, c: '放デイ３０・有資格１６・人欠２・開減２・' },
  { s: '63C450', v: 268, c: '放デイ３０・有資格１６・人欠２・開減２・評減・' },
  { s: '63C451', v: 366, c: '放デイ３０・有資格１６・人欠２・拘減・' },
  { s: '63C452', v: 310, c: '放デイ３０・有資格１６・人欠２・評減・拘減・' },
  { s: '63C453', v: 255, c: '放デイ３０・有資格１６・人欠２・開減１・拘減・' },
  { s: '63C454', v: 216, c: '放デイ３０・有資格１６・人欠２・開減１・評減・拘減・' },
  { s: '63C455', v: 310, c: '放デイ３０・有資格１６・人欠２・開減２・拘減・' },
  { s: '63C456', v: 263, c: '放デイ３０・有資格１６・人欠２・開減２・評減・拘減・' },
  { s: '63C457', v: 260, c: '放デイ３０・有資格１６・人欠２・未計画・' },
  { s: '63C458', v: 221, c: '放デイ３０・有資格１６・人欠２・未計画・評減・' },
  { s: '63C459', v: 182, c: '放デイ３０・有資格１６・人欠２・未計画・開減１・' },
  { s: '63C460', v: 155, c: '放デイ３０・有資格１６・人欠２・未計画・開減１・評減・' },
  { s: '63C461', v: 221, c: '放デイ３０・有資格１６・人欠２・未計画・開減２・' },
  { s: '63C462', v: 188, c: '放デイ３０・有資格１６・人欠２・未計画・開減２・評減・' },
  { s: '63C463', v: 255, c: '放デイ３０・有資格１６・人欠２・未計画・拘減・' },
  { s: '63C464', v: 216, c: '放デイ３０・有資格１６・人欠２・未計画・評減・拘減・' },
  { s: '63C465', v: 177, c: '放デイ３０・有資格１６・人欠２・未計画・開減１・拘減・' },
  { s: '63C466', v: 150, c: '放デイ３０・有資格１６・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C467', v: 216, c: '放デイ３０・有資格１６・人欠２・未計画・開減２・拘減・' },
  { s: '63C468', v: 183, c: '放デイ３０・有資格１６・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C469', v: 186, c: '放デイ３０・有資格１６・人欠２・未計画２・' },
  { s: '63C470', v: 158, c: '放デイ３０・有資格１６・人欠２・未計画２・評減・' },
  { s: '63C471', v: 130, c: '放デイ３０・有資格１６・人欠２・未計画２・開減１・' },
  { s: '63C472', v: 111, c: '放デイ３０・有資格１６・人欠２・未計画２・開減１・評減・' },
  { s: '63C473', v: 158, c: '放デイ３０・有資格１６・人欠２・未計画２・開減２・' },
  { s: '63C474', v: 134, c: '放デイ３０・有資格１６・人欠２・未計画２・開減２・評減・' },
  { s: '63C475', v: 181, c: '放デイ３０・有資格１６・人欠２・未計画２・拘減・' },
  { s: '63C476', v: 153, c: '放デイ３０・有資格１６・人欠２・未計画２・評減・拘減・' },
  { s: '63C477', v: 125, c: '放デイ３０・有資格１６・人欠２・未計画２・開減１・拘減・' },
  { s: '63C478', v: 106, c: '放デイ３０・有資格１６・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C479', v: 153, c: '放デイ３０・有資格１６・人欠２・未計画２・開減２・拘減・' },
  { s: '63C480', v: 129, c: '放デイ３０・有資格１６・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C481', v: 340, c: '放デイ３１・人欠・' },
  { s: '63C482', v: 289, c: '放デイ３１・人欠・評減・' },
  { s: '63C483', v: 238, c: '放デイ３１・人欠・開減１・' },
  { s: '63C484', v: 202, c: '放デイ３１・人欠・開減１・評減・' },
  { s: '63C485', v: 289, c: '放デイ３１・人欠・開減２・' },
  { s: '63C486', v: 246, c: '放デイ３１・人欠・開減２・評減・' },
  { s: '63C487', v: 335, c: '放デイ３１・人欠・拘減・' },
  { s: '63C488', v: 284, c: '放デイ３１・人欠・評減・拘減・' },
  { s: '63C489', v: 233, c: '放デイ３１・人欠・開減１・拘減・' },
  { s: '63C490', v: 197, c: '放デイ３１・人欠・開減１・評減・拘減・' },
  { s: '63C491', v: 284, c: '放デイ３１・人欠・開減２・拘減・' },
  { s: '63C492', v: 241, c: '放デイ３１・人欠・開減２・評減・拘減・' },
  { s: '63C493', v: 238, c: '放デイ３１・人欠・未計画・' },
  { s: '63C494', v: 202, c: '放デイ３１・人欠・未計画・評減・' },
  { s: '63C495', v: 167, c: '放デイ３１・人欠・未計画・開減１・' },
  { s: '63C496', v: 142, c: '放デイ３１・人欠・未計画・開減１・評減・' },
  { s: '63C497', v: 202, c: '放デイ３１・人欠・未計画・開減２・' },
  { s: '63C498', v: 172, c: '放デイ３１・人欠・未計画・開減２・評減・' },
  { s: '63C499', v: 233, c: '放デイ３１・人欠・未計画・拘減・' },
  { s: '63C500', v: 197, c: '放デイ３１・人欠・未計画・評減・拘減・' },
  { s: '63C501', v: 162, c: '放デイ３１・人欠・未計画・開減１・拘減・' },
  { s: '63C502', v: 137, c: '放デイ３１・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C503', v: 197, c: '放デイ３１・人欠・未計画・開減２・拘減・' },
  { s: '63C504', v: 167, c: '放デイ３１・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C505', v: 170, c: '放デイ３１・人欠・未計画２・' },
  { s: '63C506', v: 145, c: '放デイ３１・人欠・未計画２・評減・' },
  { s: '63C507', v: 119, c: '放デイ３１・人欠・未計画２・開減１・' },
  { s: '63C508', v: 101, c: '放デイ３１・人欠・未計画２・開減１・評減・' },
  { s: '63C509', v: 145, c: '放デイ３１・人欠・未計画２・開減２・' },
  { s: '63C510', v: 123, c: '放デイ３１・人欠・未計画２・開減２・評減・' },
  { s: '63C511', v: 165, c: '放デイ３１・人欠・未計画２・拘減・' },
  { s: '63C512', v: 140, c: '放デイ３１・人欠・未計画２・評減・拘減・' },
  { s: '63C513', v: 114, c: '放デイ３１・人欠・未計画２・開減１・拘減・' },
  { s: '63C514', v: 96, c: '放デイ３１・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C515', v: 140, c: '放デイ３１・人欠・未計画２・開減２・拘減・' },
  { s: '63C516', v: 118, c: '放デイ３１・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C517', v: 346, c: '放デイ３１・有資格１７・人欠・' },
  { s: '63C518', v: 294, c: '放デイ３１・有資格１７・人欠・評減・' },
  { s: '63C519', v: 242, c: '放デイ３１・有資格１７・人欠・開減１・' },
  { s: '63C520', v: 206, c: '放デイ３１・有資格１７・人欠・開減１・評減・' },
  { s: '63C521', v: 294, c: '放デイ３１・有資格１７・人欠・開減２・' },
  { s: '63C522', v: 250, c: '放デイ３１・有資格１７・人欠・開減２・評減・' },
  { s: '63C523', v: 341, c: '放デイ３１・有資格１７・人欠・拘減・' },
  { s: '63C524', v: 289, c: '放デイ３１・有資格１７・人欠・評減・拘減・' },
  { s: '63C525', v: 237, c: '放デイ３１・有資格１７・人欠・開減１・拘減・' },
  { s: '63C526', v: 201, c: '放デイ３１・有資格１７・人欠・開減１・評減・拘減・' },
  { s: '63C527', v: 289, c: '放デイ３１・有資格１７・人欠・開減２・拘減・' },
  { s: '63C528', v: 245, c: '放デイ３１・有資格１７・人欠・開減２・評減・拘減・' },
  { s: '63C529', v: 242, c: '放デイ３１・有資格１７・人欠・未計画・' },
  { s: '63C530', v: 206, c: '放デイ３１・有資格１７・人欠・未計画・評減・' },
  { s: '63C531', v: 169, c: '放デイ３１・有資格１７・人欠・未計画・開減１・' },
  { s: '63C532', v: 144, c: '放デイ３１・有資格１７・人欠・未計画・開減１・評減・' },
  { s: '63C533', v: 206, c: '放デイ３１・有資格１７・人欠・未計画・開減２・' },
  { s: '63C534', v: 175, c: '放デイ３１・有資格１７・人欠・未計画・開減２・評減・' },
  { s: '63C535', v: 237, c: '放デイ３１・有資格１７・人欠・未計画・拘減・' },
  { s: '63C536', v: 201, c: '放デイ３１・有資格１７・人欠・未計画・評減・拘減・' },
  { s: '63C537', v: 164, c: '放デイ３１・有資格１７・人欠・未計画・開減１・拘減・' },
  { s: '63C538', v: 139, c: '放デイ３１・有資格１７・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C539', v: 201, c: '放デイ３１・有資格１７・人欠・未計画・開減２・拘減・' },
  { s: '63C540', v: 170, c: '放デイ３１・有資格１７・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C541', v: 173, c: '放デイ３１・有資格１７・人欠・未計画２・' },
  { s: '63C542', v: 147, c: '放デイ３１・有資格１７・人欠・未計画２・評減・' },
  { s: '63C543', v: 121, c: '放デイ３１・有資格１７・人欠・未計画２・開減１・' },
  { s: '63C544', v: 103, c: '放デイ３１・有資格１７・人欠・未計画２・開減１・評減・' },
  { s: '63C545', v: 147, c: '放デイ３１・有資格１７・人欠・未計画２・開減２・' },
  { s: '63C546', v: 125, c: '放デイ３１・有資格１７・人欠・未計画２・開減２・評減・' },
  { s: '63C547', v: 168, c: '放デイ３１・有資格１７・人欠・未計画２・拘減・' },
  { s: '63C548', v: 142, c: '放デイ３１・有資格１７・人欠・未計画２・評減・拘減・' },
  { s: '63C549', v: 116, c: '放デイ３１・有資格１７・人欠・未計画２・開減１・拘減・' },
  { s: '63C550', v: 98, c: '放デイ３１・有資格１７・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C551', v: 142, c: '放デイ３１・有資格１７・人欠・未計画２・開減２・拘減・' },
  { s: '63C552', v: 120, c: '放デイ３１・有資格１７・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C553', v: 243, c: '放デイ３１・人欠２・' },
  { s: '63C554', v: 207, c: '放デイ３１・人欠２・評減・' },
  { s: '63C555', v: 170, c: '放デイ３１・人欠２・開減１・' },
  { s: '63C556', v: 145, c: '放デイ３１・人欠２・開減１・評減・' },
  { s: '63C557', v: 207, c: '放デイ３１・人欠２・開減２・' },
  { s: '63C558', v: 176, c: '放デイ３１・人欠２・開減２・評減・' },
  { s: '63C559', v: 238, c: '放デイ３１・人欠２・拘減・' },
  { s: '63C560', v: 202, c: '放デイ３１・人欠２・評減・拘減・' },
  { s: '63C561', v: 165, c: '放デイ３１・人欠２・開減１・拘減・' },
  { s: '63C562', v: 140, c: '放デイ３１・人欠２・開減１・評減・拘減・' },
  { s: '63C563', v: 202, c: '放デイ３１・人欠２・開減２・拘減・' },
  { s: '63C564', v: 171, c: '放デイ３１・人欠２・開減２・評減・拘減・' },
  { s: '63C565', v: 170, c: '放デイ３１・人欠２・未計画・' },
  { s: '63C566', v: 145, c: '放デイ３１・人欠２・未計画・評減・' },
  { s: '63C567', v: 119, c: '放デイ３１・人欠２・未計画・開減１・' },
  { s: '63C568', v: 101, c: '放デイ３１・人欠２・未計画・開減１・評減・' },
  { s: '63C569', v: 145, c: '放デイ３１・人欠２・未計画・開減２・' },
  { s: '63C570', v: 123, c: '放デイ３１・人欠２・未計画・開減２・評減・' },
  { s: '63C571', v: 165, c: '放デイ３１・人欠２・未計画・拘減・' },
  { s: '63C572', v: 140, c: '放デイ３１・人欠２・未計画・評減・拘減・' },
  { s: '63C573', v: 114, c: '放デイ３１・人欠２・未計画・開減１・拘減・' },
  { s: '63C574', v: 96, c: '放デイ３１・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C575', v: 140, c: '放デイ３１・人欠２・未計画・開減２・拘減・' },
  { s: '63C576', v: 118, c: '放デイ３１・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C577', v: 122, c: '放デイ３１・人欠２・未計画２・' },
  { s: '63C578', v: 104, c: '放デイ３１・人欠２・未計画２・評減・' },
  { s: '63C579', v: 85, c: '放デイ３１・人欠２・未計画２・開減１・' },
  { s: '63C580', v: 72, c: '放デイ３１・人欠２・未計画２・開減１・評減・' },
  { s: '63C581', v: 104, c: '放デイ３１・人欠２・未計画２・開減２・' },
  { s: '63C582', v: 88, c: '放デイ３１・人欠２・未計画２・開減２・評減・' },
  { s: '63C583', v: 117, c: '放デイ３１・人欠２・未計画２・拘減・' },
  { s: '63C584', v: 99, c: '放デイ３１・人欠２・未計画２・評減・拘減・' },
  { s: '63C585', v: 80, c: '放デイ３１・人欠２・未計画２・開減１・拘減・' },
  { s: '63C586', v: 67, c: '放デイ３１・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C587', v: 99, c: '放デイ３１・人欠２・未計画２・開減２・拘減・' },
  { s: '63C588', v: 83, c: '放デイ３１・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C589', v: 247, c: '放デイ３１・有資格１７・人欠２・' },
  { s: '63C590', v: 210, c: '放デイ３１・有資格１７・人欠２・評減・' },
  { s: '63C591', v: 173, c: '放デイ３１・有資格１７・人欠２・開減１・' },
  { s: '63C592', v: 147, c: '放デイ３１・有資格１７・人欠２・開減１・評減・' },
  { s: '63C593', v: 210, c: '放デイ３１・有資格１７・人欠２・開減２・' },
  { s: '63C594', v: 179, c: '放デイ３１・有資格１７・人欠２・開減２・評減・' },
  { s: '63C595', v: 242, c: '放デイ３１・有資格１７・人欠２・拘減・' },
  { s: '63C596', v: 205, c: '放デイ３１・有資格１７・人欠２・評減・拘減・' },
  { s: '63C597', v: 168, c: '放デイ３１・有資格１７・人欠２・開減１・拘減・' },
  { s: '63C598', v: 142, c: '放デイ３１・有資格１７・人欠２・開減１・評減・拘減・' },
  { s: '63C599', v: 205, c: '放デイ３１・有資格１７・人欠２・開減２・拘減・' },
  { s: '63C600', v: 174, c: '放デイ３１・有資格１７・人欠２・開減２・評減・拘減・' },
  { s: '63C601', v: 173, c: '放デイ３１・有資格１７・人欠２・未計画・' },
  { s: '63C602', v: 147, c: '放デイ３１・有資格１７・人欠２・未計画・評減・' },
  { s: '63C603', v: 121, c: '放デイ３１・有資格１７・人欠２・未計画・開減１・' },
  { s: '63C604', v: 103, c: '放デイ３１・有資格１７・人欠２・未計画・開減１・評減・' },
  { s: '63C605', v: 147, c: '放デイ３１・有資格１７・人欠２・未計画・開減２・' },
  { s: '63C606', v: 125, c: '放デイ３１・有資格１７・人欠２・未計画・開減２・評減・' },
  { s: '63C607', v: 168, c: '放デイ３１・有資格１７・人欠２・未計画・拘減・' },
  { s: '63C608', v: 142, c: '放デイ３１・有資格１７・人欠２・未計画・評減・拘減・' },
  { s: '63C609', v: 116, c: '放デイ３１・有資格１７・人欠２・未計画・開減１・拘減・' },
  { s: '63C610', v: 98, c: '放デイ３１・有資格１７・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C611', v: 142, c: '放デイ３１・有資格１７・人欠２・未計画・開減２・拘減・' },
  { s: '63C612', v: 120, c: '放デイ３１・有資格１７・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C613', v: 124, c: '放デイ３１・有資格１７・人欠２・未計画２・' },
  { s: '63C614', v: 105, c: '放デイ３１・有資格１７・人欠２・未計画２・評減・' },
  { s: '63C615', v: 87, c: '放デイ３１・有資格１７・人欠２・未計画２・開減１・' },
  { s: '63C616', v: 74, c: '放デイ３１・有資格１７・人欠２・未計画２・開減１・評減・' },
  { s: '63C617', v: 105, c: '放デイ３１・有資格１７・人欠２・未計画２・開減２・' },
  { s: '63C618', v: 89, c: '放デイ３１・有資格１７・人欠２・未計画２・開減２・評減・' },
  { s: '63C619', v: 119, c: '放デイ３１・有資格１７・人欠２・未計画２・拘減・' },
  { s: '63C620', v: 100, c: '放デイ３１・有資格１７・人欠２・未計画２・評減・拘減・' },
  { s: '63C621', v: 82, c: '放デイ３１・有資格１７・人欠２・未計画２・開減１・拘減・' },
  { s: '63C622', v: 69, c: '放デイ３１・有資格１７・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C623', v: 100, c: '放デイ３１・有資格１７・人欠２・未計画２・開減２・拘減・' },
  { s: '63C624', v: 84, c: '放デイ３１・有資格１７・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C625', v: 263, c: '放デイ３２・人欠・' },
  { s: '63C626', v: 224, c: '放デイ３２・人欠・評減・' },
  { s: '63C627', v: 184, c: '放デイ３２・人欠・開減１・' },
  { s: '63C628', v: 156, c: '放デイ３２・人欠・開減１・評減・' },
  { s: '63C629', v: 224, c: '放デイ３２・人欠・開減２・' },
  { s: '63C630', v: 190, c: '放デイ３２・人欠・開減２・評減・' },
  { s: '63C631', v: 258, c: '放デイ３２・人欠・拘減・' },
  { s: '63C632', v: 219, c: '放デイ３２・人欠・評減・拘減・' },
  { s: '63C633', v: 179, c: '放デイ３２・人欠・開減１・拘減・' },
  { s: '63C634', v: 151, c: '放デイ３２・人欠・開減１・評減・拘減・' },
  { s: '63C635', v: 219, c: '放デイ３２・人欠・開減２・拘減・' },
  { s: '63C636', v: 185, c: '放デイ３２・人欠・開減２・評減・拘減・' },
  { s: '63C637', v: 184, c: '放デイ３２・人欠・未計画・' },
  { s: '63C638', v: 156, c: '放デイ３２・人欠・未計画・評減・' },
  { s: '63C639', v: 129, c: '放デイ３２・人欠・未計画・開減１・' },
  { s: '63C640', v: 110, c: '放デイ３２・人欠・未計画・開減１・評減・' },
  { s: '63C641', v: 156, c: '放デイ３２・人欠・未計画・開減２・' },
  { s: '63C642', v: 133, c: '放デイ３２・人欠・未計画・開減２・評減・' },
  { s: '63C643', v: 179, c: '放デイ３２・人欠・未計画・拘減・' },
  { s: '63C644', v: 151, c: '放デイ３２・人欠・未計画・評減・拘減・' },
  { s: '63C645', v: 124, c: '放デイ３２・人欠・未計画・開減１・拘減・' },
  { s: '63C646', v: 105, c: '放デイ３２・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C647', v: 151, c: '放デイ３２・人欠・未計画・開減２・拘減・' },
  { s: '63C648', v: 128, c: '放デイ３２・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C649', v: 132, c: '放デイ３２・人欠・未計画２・' },
  { s: '63C650', v: 112, c: '放デイ３２・人欠・未計画２・評減・' },
  { s: '63C651', v: 92, c: '放デイ３２・人欠・未計画２・開減１・' },
  { s: '63C652', v: 78, c: '放デイ３２・人欠・未計画２・開減１・評減・' },
  { s: '63C653', v: 112, c: '放デイ３２・人欠・未計画２・開減２・' },
  { s: '63C654', v: 95, c: '放デイ３２・人欠・未計画２・開減２・評減・' },
  { s: '63C655', v: 127, c: '放デイ３２・人欠・未計画２・拘減・' },
  { s: '63C656', v: 107, c: '放デイ３２・人欠・未計画２・評減・拘減・' },
  { s: '63C657', v: 87, c: '放デイ３２・人欠・未計画２・開減１・拘減・' },
  { s: '63C658', v: 73, c: '放デイ３２・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C659', v: 107, c: '放デイ３２・人欠・未計画２・開減２・拘減・' },
  { s: '63C660', v: 90, c: '放デイ３２・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C661', v: 267, c: '放デイ３２・有資格１８・人欠・' },
  { s: '63C662', v: 227, c: '放デイ３２・有資格１８・人欠・評減・' },
  { s: '63C663', v: 187, c: '放デイ３２・有資格１８・人欠・開減１・' },
  { s: '63C664', v: 159, c: '放デイ３２・有資格１８・人欠・開減１・評減・' },
  { s: '63C665', v: 227, c: '放デイ３２・有資格１８・人欠・開減２・' },
  { s: '63C666', v: 193, c: '放デイ３２・有資格１８・人欠・開減２・評減・' },
  { s: '63C667', v: 262, c: '放デイ３２・有資格１８・人欠・拘減・' },
  { s: '63C668', v: 222, c: '放デイ３２・有資格１８・人欠・評減・拘減・' },
  { s: '63C669', v: 182, c: '放デイ３２・有資格１８・人欠・開減１・拘減・' },
  { s: '63C670', v: 154, c: '放デイ３２・有資格１８・人欠・開減１・評減・拘減・' },
  { s: '63C671', v: 222, c: '放デイ３２・有資格１８・人欠・開減２・拘減・' },
  { s: '63C672', v: 188, c: '放デイ３２・有資格１８・人欠・開減２・評減・拘減・' },
  { s: '63C673', v: 187, c: '放デイ３２・有資格１８・人欠・未計画・' },
  { s: '63C674', v: 159, c: '放デイ３２・有資格１８・人欠・未計画・評減・' },
  { s: '63C675', v: 131, c: '放デイ３２・有資格１８・人欠・未計画・開減１・' },
  { s: '63C676', v: 111, c: '放デイ３２・有資格１８・人欠・未計画・開減１・評減・' },
  { s: '63C677', v: 159, c: '放デイ３２・有資格１８・人欠・未計画・開減２・' },
  { s: '63C678', v: 135, c: '放デイ３２・有資格１８・人欠・未計画・開減２・評減・' },
  { s: '63C679', v: 182, c: '放デイ３２・有資格１８・人欠・未計画・拘減・' },
  { s: '63C680', v: 154, c: '放デイ３２・有資格１８・人欠・未計画・評減・拘減・' },
  { s: '63C681', v: 126, c: '放デイ３２・有資格１８・人欠・未計画・開減１・拘減・' },
  { s: '63C682', v: 106, c: '放デイ３２・有資格１８・人欠・未計画・開減１・評減・拘減・' },
  { s: '63C683', v: 154, c: '放デイ３２・有資格１８・人欠・未計画・開減２・拘減・' },
  { s: '63C684', v: 130, c: '放デイ３２・有資格１８・人欠・未計画・開減２・評減・拘減・' },
  { s: '63C685', v: 134, c: '放デイ３２・有資格１８・人欠・未計画２・' },
  { s: '63C686', v: 114, c: '放デイ３２・有資格１８・人欠・未計画２・評減・' },
  { s: '63C687', v: 94, c: '放デイ３２・有資格１８・人欠・未計画２・開減１・' },
  { s: '63C688', v: 80, c: '放デイ３２・有資格１８・人欠・未計画２・開減１・評減・' },
  { s: '63C689', v: 114, c: '放デイ３２・有資格１８・人欠・未計画２・開減２・' },
  { s: '63C690', v: 97, c: '放デイ３２・有資格１８・人欠・未計画２・開減２・評減・' },
  { s: '63C691', v: 129, c: '放デイ３２・有資格１８・人欠・未計画２・拘減・' },
  { s: '63C692', v: 109, c: '放デイ３２・有資格１８・人欠・未計画２・評減・拘減・' },
  { s: '63C693', v: 89, c: '放デイ３２・有資格１８・人欠・未計画２・開減１・拘減・' },
  { s: '63C694', v: 75, c: '放デイ３２・有資格１８・人欠・未計画２・開減１・評減・拘減・' },
  { s: '63C695', v: 109, c: '放デイ３２・有資格１８・人欠・未計画２・開減２・拘減・' },
  { s: '63C696', v: 92, c: '放デイ３２・有資格１８・人欠・未計画２・開減２・評減・拘減・' },
  { s: '63C697', v: 188, c: '放デイ３２・人欠２・' },
  { s: '63C698', v: 160, c: '放デイ３２・人欠２・評減・' },
  { s: '63C699', v: 132, c: '放デイ３２・人欠２・開減１・' },
  { s: '63C700', v: 112, c: '放デイ３２・人欠２・開減１・評減・' },
  { s: '63C701', v: 160, c: '放デイ３２・人欠２・開減２・' },
  { s: '63C702', v: 136, c: '放デイ３２・人欠２・開減２・評減・' },
  { s: '63C703', v: 183, c: '放デイ３２・人欠２・拘減・' },
  { s: '63C704', v: 155, c: '放デイ３２・人欠２・評減・拘減・' },
  { s: '63C705', v: 127, c: '放デイ３２・人欠２・開減１・拘減・' },
  { s: '63C706', v: 107, c: '放デイ３２・人欠２・開減１・評減・拘減・' },
  { s: '63C707', v: 155, c: '放デイ３２・人欠２・開減２・拘減・' },
  { s: '63C708', v: 131, c: '放デイ３２・人欠２・開減２・評減・拘減・' },
  { s: '63C709', v: 132, c: '放デイ３２・人欠２・未計画・' },
  { s: '63C710', v: 112, c: '放デイ３２・人欠２・未計画・評減・' },
  { s: '63C711', v: 92, c: '放デイ３２・人欠２・未計画・開減１・' },
  { s: '63C712', v: 78, c: '放デイ３２・人欠２・未計画・開減１・評減・' },
  { s: '63C713', v: 112, c: '放デイ３２・人欠２・未計画・開減２・' },
  { s: '63C714', v: 95, c: '放デイ３２・人欠２・未計画・開減２・評減・' },
  { s: '63C715', v: 127, c: '放デイ３２・人欠２・未計画・拘減・' },
  { s: '63C716', v: 107, c: '放デイ３２・人欠２・未計画・評減・拘減・' },
  { s: '63C717', v: 87, c: '放デイ３２・人欠２・未計画・開減１・拘減・' },
  { s: '63C718', v: 73, c: '放デイ３２・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C719', v: 107, c: '放デイ３２・人欠２・未計画・開減２・拘減・' },
  { s: '63C720', v: 90, c: '放デイ３２・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C721', v: 94, c: '放デイ３２・人欠２・未計画２・' },
  { s: '63C722', v: 80, c: '放デイ３２・人欠２・未計画２・評減・' },
  { s: '63C723', v: 66, c: '放デイ３２・人欠２・未計画２・開減１・' },
  { s: '63C724', v: 56, c: '放デイ３２・人欠２・未計画２・開減１・評減・' },
  { s: '63C725', v: 80, c: '放デイ３２・人欠２・未計画２・開減２・' },
  { s: '63C726', v: 68, c: '放デイ３２・人欠２・未計画２・開減２・評減・' },
  { s: '63C727', v: 89, c: '放デイ３２・人欠２・未計画２・拘減・' },
  { s: '63C728', v: 75, c: '放デイ３２・人欠２・未計画２・評減・拘減・' },
  { s: '63C729', v: 61, c: '放デイ３２・人欠２・未計画２・開減１・拘減・' },
  { s: '63C730', v: 51, c: '放デイ３２・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C731', v: 75, c: '放デイ３２・人欠２・未計画２・開減２・拘減・' },
  { s: '63C732', v: 63, c: '放デイ３２・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C733', v: 191, c: '放デイ３２・有資格１８・人欠２・' },
  { s: '63C734', v: 162, c: '放デイ３２・有資格１８・人欠２・評減・' },
  { s: '63C735', v: 134, c: '放デイ３２・有資格１８・人欠２・開減１・' },
  { s: '63C736', v: 114, c: '放デイ３２・有資格１８・人欠２・開減１・評減・' },
  { s: '63C737', v: 162, c: '放デイ３２・有資格１８・人欠２・開減２・' },
  { s: '63C738', v: 138, c: '放デイ３２・有資格１８・人欠２・開減２・評減・' },
  { s: '63C739', v: 186, c: '放デイ３２・有資格１８・人欠２・拘減・' },
  { s: '63C740', v: 157, c: '放デイ３２・有資格１８・人欠２・評減・拘減・' },
  { s: '63C741', v: 129, c: '放デイ３２・有資格１８・人欠２・開減１・拘減・' },
  { s: '63C742', v: 109, c: '放デイ３２・有資格１８・人欠２・開減１・評減・拘減・' },
  { s: '63C743', v: 157, c: '放デイ３２・有資格１８・人欠２・開減２・拘減・' },
  { s: '63C744', v: 133, c: '放デイ３２・有資格１８・人欠２・開減２・評減・拘減・' },
  { s: '63C745', v: 134, c: '放デイ３２・有資格１８・人欠２・未計画・' },
  { s: '63C746', v: 114, c: '放デイ３２・有資格１８・人欠２・未計画・評減・' },
  { s: '63C747', v: 94, c: '放デイ３２・有資格１８・人欠２・未計画・開減１・' },
  { s: '63C748', v: 80, c: '放デイ３２・有資格１８・人欠２・未計画・開減１・評減・' },
  { s: '63C749', v: 114, c: '放デイ３２・有資格１８・人欠２・未計画・開減２・' },
  { s: '63C750', v: 97, c: '放デイ３２・有資格１８・人欠２・未計画・開減２・評減・' },
  { s: '63C751', v: 129, c: '放デイ３２・有資格１８・人欠２・未計画・拘減・' },
  { s: '63C752', v: 109, c: '放デイ３２・有資格１８・人欠２・未計画・評減・拘減・' },
  { s: '63C753', v: 89, c: '放デイ３２・有資格１８・人欠２・未計画・開減１・拘減・' },
  { s: '63C754', v: 75, c: '放デイ３２・有資格１８・人欠２・未計画・開減１・評減・拘減・' },
  { s: '63C755', v: 109, c: '放デイ３２・有資格１８・人欠２・未計画・開減２・拘減・' },
  { s: '63C756', v: 92, c: '放デイ３２・有資格１８・人欠２・未計画・開減２・評減・拘減・' },
  { s: '63C757', v: 96, c: '放デイ３２・有資格１８・人欠２・未計画２・' },
  { s: '63C758', v: 82, c: '放デイ３２・有資格１８・人欠２・未計画２・評減・' },
  { s: '63C759', v: 67, c: '放デイ３２・有資格１８・人欠２・未計画２・開減１・' },
  { s: '63C760', v: 57, c: '放デイ３２・有資格１８・人欠２・未計画２・開減１・評減・' },
  { s: '63C761', v: 82, c: '放デイ３２・有資格１８・人欠２・未計画２・開減２・' },
  { s: '63C762', v: 70, c: '放デイ３２・有資格１８・人欠２・未計画２・開減２・評減・' },
  { s: '63C763', v: 91, c: '放デイ３２・有資格１８・人欠２・未計画２・拘減・' },
  { s: '63C764', v: 77, c: '放デイ３２・有資格１８・人欠２・未計画２・評減・拘減・' },
  { s: '63C765', v: 62, c: '放デイ３２・有資格１８・人欠２・未計画２・開減１・拘減・' },
  { s: '63C766', v: 52, c: '放デイ３２・有資格１８・人欠２・未計画２・開減１・評減・拘減・' },
  { s: '63C767', v: 77, c: '放デイ３２・有資格１８・人欠２・未計画２・開減２・拘減・' },
  { s: '63C768', v: 65, c: '放デイ３２・有資格１８・人欠２・未計画２・開減２・評減・拘減・' },
  { s: '63C769', v: 373, c: '基準該当放デイⅠ１・人欠・' },
  { s: '63C770', v: 317, c: '基準該当放デイⅠ１・人欠・評減・' },
  { s: '63C771', v: 261, c: '基準該当放デイⅠ１・人欠・未計画・' },
  { s: '63C772', v: 222, c: '基準該当放デイⅠ１・人欠・未計画・評減・' },
  { s: '63C773', v: 187, c: '基準該当放デイⅠ１・人欠・未計画２・' },
  { s: '63C774', v: 159, c: '基準該当放デイⅠ１・人欠・未計画２・評減・' },
  { s: '63C775', v: 267, c: '基準該当放デイⅠ１・人欠２・' },
  { s: '63C776', v: 227, c: '基準該当放デイⅠ１・人欠２・評減・' },
  { s: '63C777', v: 187, c: '基準該当放デイⅠ１・人欠２・未計画・' },
  { s: '63C778', v: 159, c: '基準該当放デイⅠ１・人欠２・未計画・評減・' },
  { s: '63C779', v: 134, c: '基準該当放デイⅠ１・人欠２・未計画２・' },
  { s: '63C780', v: 114, c: '基準該当放デイⅠ１・人欠２・未計画２・評減・' },
  { s: '63C781', v: 461, c: '基準該当放デイⅠ２・人欠・' },
  { s: '63C782', v: 392, c: '基準該当放デイⅠ２・人欠・評減・' },
  { s: '63C783', v: 323, c: '基準該当放デイⅠ２・人欠・開減１・' },
  { s: '63C784', v: 275, c: '基準該当放デイⅠ２・人欠・開減１・評減・' },
  { s: '63C785', v: 392, c: '基準該当放デイⅠ２・人欠・開減２・' },
  { s: '63C786', v: 333, c: '基準該当放デイⅠ２・人欠・開減２・評減・' },
  { s: '63C787', v: 323, c: '基準該当放デイⅠ２・人欠・未計画・' },
  { s: '63C788', v: 275, c: '基準該当放デイⅠ２・人欠・未計画・評減・' },
  { s: '63C789', v: 226, c: '基準該当放デイⅠ２・人欠・未計画・開減１・' },
  { s: '63C790', v: 192, c: '基準該当放デイⅠ２・人欠・未計画・開減１・評減・' },
  { s: '63C791', v: 275, c: '基準該当放デイⅠ２・人欠・未計画・開減２・' },
  { s: '63C792', v: 234, c: '基準該当放デイⅠ２・人欠・未計画・開減２・評減・' },
  { s: '63C793', v: 231, c: '基準該当放デイⅠ２・人欠・未計画２・' },
  { s: '63C794', v: 196, c: '基準該当放デイⅠ２・人欠・未計画２・評減・' },
  { s: '63C795', v: 162, c: '基準該当放デイⅠ２・人欠・未計画２・開減１・' },
  { s: '63C796', v: 138, c: '基準該当放デイⅠ２・人欠・未計画２・開減１・評減・' },
  { s: '63C797', v: 196, c: '基準該当放デイⅠ２・人欠・未計画２・開減２・' },
  { s: '63C798', v: 167, c: '基準該当放デイⅠ２・人欠・未計画２・開減２・評減・' },
  { s: '63C799', v: 329, c: '基準該当放デイⅠ２・人欠２・' },
  { s: '63C800', v: 280, c: '基準該当放デイⅠ２・人欠２・評減・' },
  { s: '63C801', v: 230, c: '基準該当放デイⅠ２・人欠２・開減１・' },
  { s: '63C802', v: 196, c: '基準該当放デイⅠ２・人欠２・開減１・評減・' },
  { s: '63C803', v: 280, c: '基準該当放デイⅠ２・人欠２・開減２・' },
  { s: '63C804', v: 238, c: '基準該当放デイⅠ２・人欠２・開減２・評減・' },
  { s: '63C805', v: 230, c: '基準該当放デイⅠ２・人欠２・未計画・' },
  { s: '63C806', v: 196, c: '基準該当放デイⅠ２・人欠２・未計画・評減・' },
  { s: '63C807', v: 161, c: '基準該当放デイⅠ２・人欠２・未計画・開減１・' },
  { s: '63C808', v: 137, c: '基準該当放デイⅠ２・人欠２・未計画・開減１・評減・' },
  { s: '63C809', v: 196, c: '基準該当放デイⅠ２・人欠２・未計画・開減２・' },
  { s: '63C810', v: 167, c: '基準該当放デイⅠ２・人欠２・未計画・開減２・評減・' },
  { s: '63C811', v: 165, c: '基準該当放デイⅠ２・人欠２・未計画２・' },
  { s: '63C812', v: 140, c: '基準該当放デイⅠ２・人欠２・未計画２・評減・' },
  { s: '63C813', v: 116, c: '基準該当放デイⅠ２・人欠２・未計画２・開減１・' },
  { s: '63C814', v: 99, c: '基準該当放デイⅠ２・人欠２・未計画２・開減１・評減・' },
  { s: '63C815', v: 140, c: '基準該当放デイⅠ２・人欠２・未計画２・開減２・' },
  { s: '63C816', v: 119, c: '基準該当放デイⅠ２・人欠２・未計画２・開減２・評減・' },
  { s: '63E001', v: 462, c: '放デイ１・責欠・' },
  { s: '63E002', v: 393, c: '放デイ１・責欠・評減・' },
  { s: '63E007', v: 457, c: '放デイ１・責欠・拘減・' },
  { s: '63E008', v: 388, c: '放デイ１・責欠・評減・拘減・' },
  { s: '63E013', v: 468, c: '放デイ１・有資格１・責欠・' },
  { s: '63E014', v: 398, c: '放デイ１・有資格１・責欠・評減・' },
  { s: '63E019', v: 463, c: '放デイ１・有資格１・責欠・拘減・' },
  { s: '63E020', v: 393, c: '放デイ１・有資格１・責欠・評減・拘減・' },
  { s: '63E025', v: 330, c: '放デイ１・責欠２・' },
  { s: '63E026', v: 281, c: '放デイ１・責欠２・評減・' },
  { s: '63E031', v: 325, c: '放デイ１・責欠２・拘減・' },
  { s: '63E032', v: 276, c: '放デイ１・責欠２・評減・拘減・' },
  { s: '63E037', v: 335, c: '放デイ１・有資格１・責欠２・' },
  { s: '63E038', v: 285, c: '放デイ１・有資格１・責欠２・評減・' },
  { s: '63E043', v: 330, c: '放デイ１・有資格１・責欠２・拘減・' },
  { s: '63E044', v: 280, c: '放デイ１・有資格１・責欠２・評減・拘減・' },
  { s: '63E049', v: 310, c: '放デイ２・責欠・' },
  { s: '63E050', v: 264, c: '放デイ２・責欠・評減・' },
  { s: '63E055', v: 305, c: '放デイ２・責欠・拘減・' },
  { s: '63E056', v: 259, c: '放デイ２・責欠・評減・拘減・' },
  { s: '63E061', v: 314, c: '放デイ２・有資格２・責欠・' },
  { s: '63E062', v: 267, c: '放デイ２・有資格２・責欠・評減・' },
  { s: '63E067', v: 309, c: '放デイ２・有資格２・責欠・拘減・' },
  { s: '63E068', v: 262, c: '放デイ２・有資格２・責欠・評減・拘減・' },
  { s: '63E073', v: 222, c: '放デイ２・責欠２・' },
  { s: '63E074', v: 189, c: '放デイ２・責欠２・評減・' },
  { s: '63E079', v: 217, c: '放デイ２・責欠２・拘減・' },
  { s: '63E080', v: 184, c: '放デイ２・責欠２・評減・拘減・' },
  { s: '63E085', v: 225, c: '放デイ２・有資格２・責欠２・' },
  { s: '63E086', v: 191, c: '放デイ２・有資格２・責欠２・評減・' },
  { s: '63E091', v: 220, c: '放デイ２・有資格２・責欠２・拘減・' },
  { s: '63E092', v: 186, c: '放デイ２・有資格２・責欠２・評減・拘減・' },
  { s: '63E097', v: 233, c: '放デイ３・責欠・' },
  { s: '63E098', v: 198, c: '放デイ３・責欠・評減・' },
  { s: '63E103', v: 228, c: '放デイ３・責欠・拘減・' },
  { s: '63E104', v: 193, c: '放デイ３・責欠・評減・拘減・' },
  { s: '63E109', v: 236, c: '放デイ３・有資格３・責欠・' },
  { s: '63E110', v: 201, c: '放デイ３・有資格３・責欠・評減・' },
  { s: '63E115', v: 231, c: '放デイ３・有資格３・責欠・拘減・' },
  { s: '63E116', v: 196, c: '放デイ３・有資格３・責欠・評減・拘減・' },
  { s: '63E121', v: 167, c: '放デイ３・責欠２・' },
  { s: '63E122', v: 142, c: '放デイ３・責欠２・評減・' },
  { s: '63E127', v: 162, c: '放デイ３・責欠２・拘減・' },
  { s: '63E128', v: 137, c: '放デイ３・責欠２・評減・拘減・' },
  { s: '63E133', v: 169, c: '放デイ３・有資格３・責欠２・' },
  { s: '63E134', v: 144, c: '放デイ３・有資格３・責欠２・評減・' },
  { s: '63E139', v: 164, c: '放デイ３・有資格３・責欠２・拘減・' },
  { s: '63E140', v: 139, c: '放デイ３・有資格３・責欠２・評減・拘減・' },
  { s: '63E145', v: 454, c: '放デイ２１・責欠・' },
  { s: '63E146', v: 386, c: '放デイ２１・責欠・評減・' },
  { s: '63E151', v: 449, c: '放デイ２１・責欠・拘減・' },
  { s: '63E152', v: 381, c: '放デイ２１・責欠・評減・拘減・' },
  { s: '63E157', v: 461, c: '放デイ２１・有資格７・責欠・' },
  { s: '63E158', v: 392, c: '放デイ２１・有資格７・責欠・評減・' },
  { s: '63E163', v: 456, c: '放デイ２１・有資格７・責欠・拘減・' },
  { s: '63E164', v: 387, c: '放デイ２１・有資格７・責欠・評減・拘減・' },
  { s: '63E169', v: 325, c: '放デイ２１・責欠２・' },
  { s: '63E170', v: 276, c: '放デイ２１・責欠２・評減・' },
  { s: '63E175', v: 320, c: '放デイ２１・責欠２・拘減・' },
  { s: '63E176', v: 271, c: '放デイ２１・責欠２・評減・拘減・' },
  { s: '63E181', v: 329, c: '放デイ２１・有資格７・責欠２・' },
  { s: '63E182', v: 280, c: '放デイ２１・有資格７・責欠２・評減・' },
  { s: '63E187', v: 324, c: '放デイ２１・有資格７・責欠２・拘減・' },
  { s: '63E188', v: 275, c: '放デイ２１・有資格７・責欠２・評減・拘減・' },
  { s: '63E193', v: 303, c: '放デイ２２・責欠・' },
  { s: '63E194', v: 258, c: '放デイ２２・責欠・評減・' },
  { s: '63E199', v: 298, c: '放デイ２２・責欠・拘減・' },
  { s: '63E200', v: 253, c: '放デイ２２・責欠・評減・拘減・' },
  { s: '63E205', v: 307, c: '放デイ２２・有資格８・責欠・' },
  { s: '63E206', v: 261, c: '放デイ２２・有資格８・責欠・評減・' },
  { s: '63E211', v: 302, c: '放デイ２２・有資格８・責欠・拘減・' },
  { s: '63E212', v: 256, c: '放デイ２２・有資格８・責欠・評減・拘減・' },
  { s: '63E217', v: 217, c: '放デイ２２・責欠２・' },
  { s: '63E218', v: 184, c: '放デイ２２・責欠２・評減・' },
  { s: '63E223', v: 212, c: '放デイ２２・責欠２・拘減・' },
  { s: '63E224', v: 179, c: '放デイ２２・責欠２・評減・拘減・' },
  { s: '63E229', v: 220, c: '放デイ２２・有資格８・責欠２・' },
  { s: '63E230', v: 187, c: '放デイ２２・有資格８・責欠２・評減・' },
  { s: '63E235', v: 215, c: '放デイ２２・有資格８・責欠２・拘減・' },
  { s: '63E236', v: 182, c: '放デイ２２・有資格８・責欠２・評減・拘減・' },
  { s: '63E241', v: 228, c: '放デイ２３・責欠・' },
  { s: '63E242', v: 194, c: '放デイ２３・責欠・評減・' },
  { s: '63E247', v: 223, c: '放デイ２３・責欠・拘減・' },
  { s: '63E248', v: 189, c: '放デイ２３・責欠・評減・拘減・' },
  { s: '63E253', v: 231, c: '放デイ２３・有資格９・責欠・' },
  { s: '63E254', v: 196, c: '放デイ２３・有資格９・責欠・評減・' },
  { s: '63E259', v: 226, c: '放デイ２３・有資格９・責欠・拘減・' },
  { s: '63E260', v: 191, c: '放デイ２３・有資格９・責欠・評減・拘減・' },
  { s: '63E265', v: 163, c: '放デイ２３・責欠２・' },
  { s: '63E266', v: 139, c: '放デイ２３・責欠２・評減・' },
  { s: '63E271', v: 158, c: '放デイ２３・責欠２・拘減・' },
  { s: '63E272', v: 134, c: '放デイ２３・責欠２・評減・拘減・' },
  { s: '63E277', v: 165, c: '放デイ２３・有資格９・責欠２・' },
  { s: '63E278', v: 140, c: '放デイ２３・有資格９・責欠２・評減・' },
  { s: '63E283', v: 160, c: '放デイ２３・有資格９・責欠２・拘減・' },
  { s: '63E284', v: 135, c: '放デイ２３・有資格９・責欠２・評減・拘減・' },
  { s: '63E289', v: 428, c: '放デイ２４・責欠・' },
  { s: '63E290', v: 364, c: '放デイ２４・責欠・評減・' },
  { s: '63E295', v: 423, c: '放デイ２４・責欠・拘減・' },
  { s: '63E296', v: 359, c: '放デイ２４・責欠・評減・拘減・' },
  { s: '63E301', v: 435, c: '放デイ２４・有資格１０・責欠・' },
  { s: '63E302', v: 370, c: '放デイ２４・有資格１０・責欠・評減・' },
  { s: '63E307', v: 430, c: '放デイ２４・有資格１０・責欠・拘減・' },
  { s: '63E308', v: 365, c: '放デイ２４・有資格１０・責欠・評減・拘減・' },
  { s: '63E313', v: 306, c: '放デイ２４・責欠２・' },
  { s: '63E314', v: 260, c: '放デイ２４・責欠２・評減・' },
  { s: '63E319', v: 301, c: '放デイ２４・責欠２・拘減・' },
  { s: '63E320', v: 255, c: '放デイ２４・責欠２・評減・拘減・' },
  { s: '63E325', v: 311, c: '放デイ２４・有資格１０・責欠２・' },
  { s: '63E326', v: 264, c: '放デイ２４・有資格１０・責欠２・評減・' },
  { s: '63E331', v: 306, c: '放デイ２４・有資格１０・責欠２・拘減・' },
  { s: '63E332', v: 259, c: '放デイ２４・有資格１０・責欠２・評減・拘減・' },
  { s: '63E337', v: 285, c: '放デイ２５・責欠・' },
  { s: '63E338', v: 242, c: '放デイ２５・責欠・評減・' },
  { s: '63E343', v: 280, c: '放デイ２５・責欠・拘減・' },
  { s: '63E344', v: 237, c: '放デイ２５・責欠・評減・拘減・' },
  { s: '63E349', v: 289, c: '放デイ２５・有資格１１・責欠・' },
  { s: '63E350', v: 246, c: '放デイ２５・有資格１１・責欠・評減・' },
  { s: '63E355', v: 284, c: '放デイ２５・有資格１１・責欠・拘減・' },
  { s: '63E356', v: 241, c: '放デイ２５・有資格１１・責欠・評減・拘減・' },
  { s: '63E361', v: 204, c: '放デイ２５・責欠２・' },
  { s: '63E362', v: 173, c: '放デイ２５・責欠２・評減・' },
  { s: '63E367', v: 199, c: '放デイ２５・責欠２・拘減・' },
  { s: '63E368', v: 168, c: '放デイ２５・責欠２・評減・拘減・' },
  { s: '63E373', v: 207, c: '放デイ２５・有資格１１・責欠２・' },
  { s: '63E374', v: 176, c: '放デイ２５・有資格１１・責欠２・評減・' },
  { s: '63E379', v: 202, c: '放デイ２５・有資格１１・責欠２・拘減・' },
  { s: '63E380', v: 171, c: '放デイ２５・有資格１１・責欠２・評減・拘減・' },
  { s: '63E385', v: 214, c: '放デイ２６・責欠・' },
  { s: '63E386', v: 182, c: '放デイ２６・責欠・評減・' },
  { s: '63E391', v: 209, c: '放デイ２６・責欠・拘減・' },
  { s: '63E392', v: 177, c: '放デイ２６・責欠・評減・拘減・' },
  { s: '63E397', v: 217, c: '放デイ２６・有資格１２・責欠・' },
  { s: '63E398', v: 184, c: '放デイ２６・有資格１２・責欠・評減・' },
  { s: '63E403', v: 212, c: '放デイ２６・有資格１２・責欠・拘減・' },
  { s: '63E404', v: 179, c: '放デイ２６・有資格１２・責欠・評減・拘減・' },
  { s: '63E409', v: 153, c: '放デイ２６・責欠２・' },
  { s: '63E410', v: 130, c: '放デイ２６・責欠２・評減・' },
  { s: '63E415', v: 148, c: '放デイ２６・責欠２・拘減・' },
  { s: '63E416', v: 125, c: '放デイ２６・責欠２・評減・拘減・' },
  { s: '63E421', v: 155, c: '放デイ２６・有資格１２・責欠２・' },
  { s: '63E422', v: 132, c: '放デイ２６・有資格１２・責欠２・評減・' },
  { s: '63E427', v: 150, c: '放デイ２６・有資格１２・責欠２・拘減・' },
  { s: '63E428', v: 127, c: '放デイ２６・有資格１２・責欠２・評減・拘減・' },
  { s: '63E433', v: 419, c: '放デイ２７・責欠・' },
  { s: '63E434', v: 356, c: '放デイ２７・責欠・評減・' },
  { s: '63E439', v: 414, c: '放デイ２７・責欠・拘減・' },
  { s: '63E440', v: 351, c: '放デイ２７・責欠・評減・拘減・' },
  { s: '63E445', v: 426, c: '放デイ２７・有資格１３・責欠・' },
  { s: '63E446', v: 362, c: '放デイ２７・有資格１３・責欠・評減・' },
  { s: '63E451', v: 421, c: '放デイ２７・有資格１３・責欠・拘減・' },
  { s: '63E452', v: 357, c: '放デイ２７・有資格１３・責欠・評減・拘減・' },
  { s: '63E457', v: 300, c: '放デイ２７・責欠２・' },
  { s: '63E458', v: 255, c: '放デイ２７・責欠２・評減・' },
  { s: '63E463', v: 295, c: '放デイ２７・責欠２・拘減・' },
  { s: '63E464', v: 250, c: '放デイ２７・責欠２・評減・拘減・' },
  { s: '63E469', v: 304, c: '放デイ２７・有資格１３・責欠２・' },
  { s: '63E470', v: 258, c: '放デイ２７・有資格１３・責欠２・評減・' },
  { s: '63E475', v: 299, c: '放デイ２７・有資格１３・責欠２・拘減・' },
  { s: '63E476', v: 253, c: '放デイ２７・有資格１３・責欠２・評減・拘減・' },
  { s: '63E481', v: 279, c: '放デイ２８・責欠・' },
  { s: '63E482', v: 237, c: '放デイ２８・責欠・評減・' },
  { s: '63E487', v: 274, c: '放デイ２８・責欠・拘減・' },
  { s: '63E488', v: 232, c: '放デイ２８・責欠・評減・拘減・' },
  { s: '63E493', v: 283, c: '放デイ２８・有資格１４・責欠・' },
  { s: '63E494', v: 241, c: '放デイ２８・有資格１４・責欠・評減・' },
  { s: '63E499', v: 278, c: '放デイ２８・有資格１４・責欠・拘減・' },
  { s: '63E500', v: 236, c: '放デイ２８・有資格１４・責欠・評減・拘減・' },
  { s: '63E505', v: 199, c: '放デイ２８・責欠２・' },
  { s: '63E506', v: 169, c: '放デイ２８・責欠２・評減・' },
  { s: '63E511', v: 194, c: '放デイ２８・責欠２・拘減・' },
  { s: '63E512', v: 164, c: '放デイ２８・責欠２・評減・拘減・' },
  { s: '63E517', v: 202, c: '放デイ２８・有資格１４・責欠２・' },
  { s: '63E518', v: 172, c: '放デイ２８・有資格１４・責欠２・評減・' },
  { s: '63E523', v: 197, c: '放デイ２８・有資格１４・責欠２・拘減・' },
  { s: '63E524', v: 167, c: '放デイ２８・有資格１４・責欠２・評減・拘減・' },
  { s: '63E529', v: 209, c: '放デイ２９・責欠・' },
  { s: '63E530', v: 178, c: '放デイ２９・責欠・評減・' },
  { s: '63E535', v: 204, c: '放デイ２９・責欠・拘減・' },
  { s: '63E536', v: 173, c: '放デイ２９・責欠・評減・拘減・' },
  { s: '63E541', v: 212, c: '放デイ２９・有資格１５・責欠・' },
  { s: '63E542', v: 180, c: '放デイ２９・有資格１５・責欠・評減・' },
  { s: '63E547', v: 207, c: '放デイ２９・有資格１５・責欠・拘減・' },
  { s: '63E548', v: 175, c: '放デイ２９・有資格１５・責欠・評減・拘減・' },
  { s: '63E553', v: 150, c: '放デイ２９・責欠２・' },
  { s: '63E554', v: 128, c: '放デイ２９・責欠２・評減・' },
  { s: '63E559', v: 145, c: '放デイ２９・責欠２・拘減・' },
  { s: '63E560', v: 123, c: '放デイ２９・責欠２・評減・拘減・' },
  { s: '63E565', v: 152, c: '放デイ２９・有資格１５・責欠２・' },
  { s: '63E566', v: 129, c: '放デイ２９・有資格１５・責欠２・評減・' },
  { s: '63E571', v: 147, c: '放デイ２９・有資格１５・責欠２・拘減・' },
  { s: '63E572', v: 124, c: '放デイ２９・有資格１５・責欠２・評減・拘減・' },
  { s: '63E577', v: 554, c: '放デイ４・責欠・' },
  { s: '63E578', v: 471, c: '放デイ４・責欠・評減・' },
  { s: '63E579', v: 388, c: '放デイ４・責欠・開減１・' },
  { s: '63E580', v: 330, c: '放デイ４・責欠・開減１・評減・' },
  { s: '63E581', v: 471, c: '放デイ４・責欠・開減２・' },
  { s: '63E582', v: 400, c: '放デイ４・責欠・開減２・評減・' },
  { s: '63E583', v: 549, c: '放デイ４・責欠・拘減・' },
  { s: '63E584', v: 466, c: '放デイ４・責欠・評減・拘減・' },
  { s: '63E585', v: 383, c: '放デイ４・責欠・開減１・拘減・' },
  { s: '63E586', v: 325, c: '放デイ４・責欠・開減１・評減・拘減・' },
  { s: '63E587', v: 466, c: '放デイ４・責欠・開減２・拘減・' },
  { s: '63E588', v: 395, c: '放デイ４・責欠・開減２・評減・拘減・' },
  { s: '63E613', v: 563, c: '放デイ４・有資格４・責欠・' },
  { s: '63E614', v: 479, c: '放デイ４・有資格４・責欠・評減・' },
  { s: '63E615', v: 394, c: '放デイ４・有資格４・責欠・開減１・' },
  { s: '63E616', v: 335, c: '放デイ４・有資格４・責欠・開減１・評減・' },
  { s: '63E617', v: 479, c: '放デイ４・有資格４・責欠・開減２・' },
  { s: '63E618', v: 407, c: '放デイ４・有資格４・責欠・開減２・評減・' },
  { s: '63E619', v: 558, c: '放デイ４・有資格４・責欠・拘減・' },
  { s: '63E620', v: 474, c: '放デイ４・有資格４・責欠・評減・拘減・' },
  { s: '63E621', v: 389, c: '放デイ４・有資格４・責欠・開減１・拘減・' },
  { s: '63E622', v: 330, c: '放デイ４・有資格４・責欠・開減１・評減・拘減・' },
  { s: '63E623', v: 474, c: '放デイ４・有資格４・責欠・開減２・拘減・' },
  { s: '63E624', v: 402, c: '放デイ４・有資格４・責欠・開減２・評減・拘減・' },
  { s: '63E649', v: 396, c: '放デイ４・責欠２・' },
  { s: '63E650', v: 337, c: '放デイ４・責欠２・評減・' },
  { s: '63E651', v: 277, c: '放デイ４・責欠２・開減１・' },
  { s: '63E652', v: 235, c: '放デイ４・責欠２・開減１・評減・' },
  { s: '63E653', v: 337, c: '放デイ４・責欠２・開減２・' },
  { s: '63E654', v: 286, c: '放デイ４・責欠２・開減２・評減・' },
  { s: '63E655', v: 391, c: '放デイ４・責欠２・拘減・' },
  { s: '63E656', v: 332, c: '放デイ４・責欠２・評減・拘減・' },
  { s: '63E657', v: 272, c: '放デイ４・責欠２・開減１・拘減・' },
  { s: '63E658', v: 230, c: '放デイ４・責欠２・開減１・評減・拘減・' },
  { s: '63E659', v: 332, c: '放デイ４・責欠２・開減２・拘減・' },
  { s: '63E660', v: 281, c: '放デイ４・責欠２・開減２・評減・拘減・' },
  { s: '63E685', v: 402, c: '放デイ４・有資格４・責欠２・' },
  { s: '63E686', v: 342, c: '放デイ４・有資格４・責欠２・評減・' },
  { s: '63E687', v: 281, c: '放デイ４・有資格４・責欠２・開減１・' },
  { s: '63E688', v: 239, c: '放デイ４・有資格４・責欠２・開減１・評減・' },
  { s: '63E689', v: 342, c: '放デイ４・有資格４・責欠２・開減２・' },
  { s: '63E690', v: 291, c: '放デイ４・有資格４・責欠２・開減２・評減・' },
  { s: '63E691', v: 397, c: '放デイ４・有資格４・責欠２・拘減・' },
  { s: '63E692', v: 337, c: '放デイ４・有資格４・責欠２・評減・拘減・' },
  { s: '63E693', v: 276, c: '放デイ４・有資格４・責欠２・開減１・拘減・' },
  { s: '63E694', v: 234, c: '放デイ４・有資格４・責欠２・開減１・評減・拘減・' },
  { s: '63E695', v: 337, c: '放デイ４・有資格４・責欠２・開減２・拘減・' },
  { s: '63E696', v: 286, c: '放デイ４・有資格４・責欠２・開減２・評減・拘減・' },
  { s: '63E721', v: 372, c: '放デイ５・責欠・' },
  { s: '63E722', v: 316, c: '放デイ５・責欠・評減・' },
  { s: '63E723', v: 260, c: '放デイ５・責欠・開減１・' },
  { s: '63E724', v: 221, c: '放デイ５・責欠・開減１・評減・' },
  { s: '63E725', v: 316, c: '放デイ５・責欠・開減２・' },
  { s: '63E726', v: 269, c: '放デイ５・責欠・開減２・評減・' },
  { s: '63E727', v: 367, c: '放デイ５・責欠・拘減・' },
  { s: '63E728', v: 311, c: '放デイ５・責欠・評減・拘減・' },
  { s: '63E729', v: 255, c: '放デイ５・責欠・開減１・拘減・' },
  { s: '63E730', v: 216, c: '放デイ５・責欠・開減１・評減・拘減・' },
  { s: '63E731', v: 311, c: '放デイ５・責欠・開減２・拘減・' },
  { s: '63E732', v: 264, c: '放デイ５・責欠・開減２・評減・拘減・' },
  { s: '63E757', v: 378, c: '放デイ５・有資格５・責欠・' },
  { s: '63E758', v: 321, c: '放デイ５・有資格５・責欠・評減・' },
  { s: '63E759', v: 265, c: '放デイ５・有資格５・責欠・開減１・' },
  { s: '63E760', v: 225, c: '放デイ５・有資格５・責欠・開減１・評減・' },
  { s: '63E761', v: 321, c: '放デイ５・有資格５・責欠・開減２・' },
  { s: '63E762', v: 273, c: '放デイ５・有資格５・責欠・開減２・評減・' },
  { s: '63E763', v: 373, c: '放デイ５・有資格５・責欠・拘減・' },
  { s: '63E764', v: 316, c: '放デイ５・有資格５・責欠・評減・拘減・' },
  { s: '63E765', v: 260, c: '放デイ５・有資格５・責欠・開減１・拘減・' },
  { s: '63E766', v: 220, c: '放デイ５・有資格５・責欠・開減１・評減・拘減・' },
  { s: '63E767', v: 316, c: '放デイ５・有資格５・責欠・開減２・拘減・' },
  { s: '63E768', v: 268, c: '放デイ５・有資格５・責欠・開減２・評減・拘減・' },
  { s: '63E793', v: 266, c: '放デイ５・責欠２・' },
  { s: '63E794', v: 226, c: '放デイ５・責欠２・評減・' },
  { s: '63E795', v: 186, c: '放デイ５・責欠２・開減１・' },
  { s: '63E796', v: 158, c: '放デイ５・責欠２・開減１・評減・' },
  { s: '63E797', v: 226, c: '放デイ５・責欠２・開減２・' },
  { s: '63E798', v: 192, c: '放デイ５・責欠２・開減２・評減・' },
  { s: '63E799', v: 261, c: '放デイ５・責欠２・拘減・' },
  { s: '63E800', v: 221, c: '放デイ５・責欠２・評減・拘減・' },
  { s: '63E801', v: 181, c: '放デイ５・責欠２・開減１・拘減・' },
  { s: '63E802', v: 153, c: '放デイ５・責欠２・開減１・評減・拘減・' },
  { s: '63E803', v: 221, c: '放デイ５・責欠２・開減２・拘減・' },
  { s: '63E804', v: 187, c: '放デイ５・責欠２・開減２・評減・拘減・' },
  { s: '63E829', v: 270, c: '放デイ５・有資格５・責欠２・' },
  { s: '63E830', v: 230, c: '放デイ５・有資格５・責欠２・評減・' },
  { s: '63E831', v: 189, c: '放デイ５・有資格５・責欠２・開減１・' },
  { s: '63E832', v: 161, c: '放デイ５・有資格５・責欠２・開減１・評減・' },
  { s: '63E833', v: 230, c: '放デイ５・有資格５・責欠２・開減２・' },
  { s: '63E834', v: 196, c: '放デイ５・有資格５・責欠２・開減２・評減・' },
  { s: '63E835', v: 265, c: '放デイ５・有資格５・責欠２・拘減・' },
  { s: '63E836', v: 225, c: '放デイ５・有資格５・責欠２・評減・拘減・' },
  { s: '63E837', v: 184, c: '放デイ５・有資格５・責欠２・開減１・拘減・' },
  { s: '63E838', v: 156, c: '放デイ５・有資格５・責欠２・開減１・評減・拘減・' },
  { s: '63E839', v: 225, c: '放デイ５・有資格５・責欠２・開減２・拘減・' },
  { s: '63E840', v: 191, c: '放デイ５・有資格５・責欠２・開減２・評減・拘減・' },
  { s: '63E865', v: 288, c: '放デイ６・責欠・' },
  { s: '63E866', v: 245, c: '放デイ６・責欠・評減・' },
  { s: '63E867', v: 202, c: '放デイ６・責欠・開減１・' },
  { s: '63E868', v: 172, c: '放デイ６・責欠・開減１・評減・' },
  { s: '63E869', v: 245, c: '放デイ６・責欠・開減２・' },
  { s: '63E870', v: 208, c: '放デイ６・責欠・開減２・評減・' },
  { s: '63E871', v: 283, c: '放デイ６・責欠・拘減・' },
  { s: '63E872', v: 240, c: '放デイ６・責欠・評減・拘減・' },
  { s: '63E873', v: 197, c: '放デイ６・責欠・開減１・拘減・' },
  { s: '63E874', v: 167, c: '放デイ６・責欠・開減１・評減・拘減・' },
  { s: '63E875', v: 240, c: '放デイ６・責欠・開減２・拘減・' },
  { s: '63E876', v: 203, c: '放デイ６・責欠・開減２・評減・拘減・' },
  { s: '63E901', v: 293, c: '放デイ６・有資格６・責欠・' },
  { s: '63E902', v: 249, c: '放デイ６・有資格６・責欠・評減・' },
  { s: '63E903', v: 205, c: '放デイ６・有資格６・責欠・開減１・' },
  { s: '63E904', v: 174, c: '放デイ６・有資格６・責欠・開減１・評減・' },
  { s: '63E905', v: 249, c: '放デイ６・有資格６・責欠・開減２・' },
  { s: '63E906', v: 212, c: '放デイ６・有資格６・責欠・開減２・評減・' },
  { s: '63E907', v: 288, c: '放デイ６・有資格６・責欠・拘減・' },
  { s: '63E908', v: 244, c: '放デイ６・有資格６・責欠・評減・拘減・' },
  { s: '63E909', v: 200, c: '放デイ６・有資格６・責欠・開減１・拘減・' },
  { s: '63E910', v: 169, c: '放デイ６・有資格６・責欠・開減１・評減・拘減・' },
  { s: '63E911', v: 244, c: '放デイ６・有資格６・責欠・開減２・拘減・' },
  { s: '63E912', v: 207, c: '放デイ６・有資格６・責欠・開減２・評減・拘減・' },
  { s: '63E937', v: 206, c: '放デイ６・責欠２・' },
  { s: '63E938', v: 175, c: '放デイ６・責欠２・評減・' },
  { s: '63E939', v: 144, c: '放デイ６・責欠２・開減１・' },
  { s: '63E940', v: 122, c: '放デイ６・責欠２・開減１・評減・' },
  { s: '63E941', v: 175, c: '放デイ６・責欠２・開減２・' },
  { s: '63E942', v: 149, c: '放デイ６・責欠２・開減２・評減・' },
  { s: '63E943', v: 201, c: '放デイ６・責欠２・拘減・' },
  { s: '63E944', v: 170, c: '放デイ６・責欠２・評減・拘減・' },
  { s: '63E945', v: 139, c: '放デイ６・責欠２・開減１・拘減・' },
  { s: '63E946', v: 117, c: '放デイ６・責欠２・開減１・評減・拘減・' },
  { s: '63E947', v: 170, c: '放デイ６・責欠２・開減２・拘減・' },
  { s: '63E948', v: 144, c: '放デイ６・責欠２・開減２・評減・拘減・' },
  { s: '63E973', v: 209, c: '放デイ６・有資格６・責欠２・' },
  { s: '63E974', v: 178, c: '放デイ６・有資格６・責欠２・評減・' },
  { s: '63E975', v: 146, c: '放デイ６・有資格６・責欠２・開減１・' },
  { s: '63E976', v: 124, c: '放デイ６・有資格６・責欠２・開減１・評減・' },
  { s: '63E977', v: 178, c: '放デイ６・有資格６・責欠２・開減２・' },
  { s: '63E978', v: 151, c: '放デイ６・有資格６・責欠２・開減２・評減・' },
  { s: '63E979', v: 204, c: '放デイ６・有資格６・責欠２・拘減・' },
  { s: '63E980', v: 173, c: '放デイ６・有資格６・責欠２・評減・拘減・' },
  { s: '63E981', v: 141, c: '放デイ６・有資格６・責欠２・開減１・拘減・' },
  { s: '63E982', v: 119, c: '放デイ６・有資格６・責欠２・開減１・評減・拘減・' },
  { s: '63E983', v: 173, c: '放デイ６・有資格６・責欠２・開減２・拘減・' },
  { s: '63E984', v: 146, c: '放デイ６・有資格６・責欠２・開減２・評減・拘減・' },
  { s: '63F010', v: 511, c: '放デイ３０・責欠・' },
  { s: '63F011', v: 434, c: '放デイ３０・責欠・評減・' },
  { s: '63F012', v: 358, c: '放デイ３０・責欠・開減１・' },
  { s: '63F013', v: 304, c: '放デイ３０・責欠・開減１・評減・' },
  { s: '63F014', v: 434, c: '放デイ３０・責欠・開減２・' },
  { s: '63F015', v: 369, c: '放デイ３０・責欠・開減２・評減・' },
  { s: '63F016', v: 506, c: '放デイ３０・責欠・拘減・' },
  { s: '63F017', v: 429, c: '放デイ３０・責欠・評減・拘減・' },
  { s: '63F018', v: 353, c: '放デイ３０・責欠・開減１・拘減・' },
  { s: '63F019', v: 299, c: '放デイ３０・責欠・開減１・評減・拘減・' },
  { s: '63F020', v: 429, c: '放デイ３０・責欠・開減２・拘減・' },
  { s: '63F021', v: 364, c: '放デイ３０・責欠・開減２・評減・拘減・' },
  { s: '63F046', v: 519, c: '放デイ３０・有資格１６・責欠・' },
  { s: '63F047', v: 441, c: '放デイ３０・有資格１６・責欠・評減・' },
  { s: '63F048', v: 363, c: '放デイ３０・有資格１６・責欠・開減１・' },
  { s: '63F049', v: 309, c: '放デイ３０・有資格１６・責欠・開減１・評減・' },
  { s: '63F050', v: 441, c: '放デイ３０・有資格１６・責欠・開減２・' },
  { s: '63F051', v: 375, c: '放デイ３０・有資格１６・責欠・開減２・評減・' },
  { s: '63F052', v: 514, c: '放デイ３０・有資格１６・責欠・拘減・' },
  { s: '63F053', v: 436, c: '放デイ３０・有資格１６・責欠・評減・拘減・' },
  { s: '63F054', v: 358, c: '放デイ３０・有資格１６・責欠・開減１・拘減・' },
  { s: '63F055', v: 304, c: '放デイ３０・有資格１６・責欠・開減１・評減・拘減・' },
  { s: '63F056', v: 436, c: '放デイ３０・有資格１６・責欠・開減２・拘減・' },
  { s: '63F057', v: 370, c: '放デイ３０・有資格１６・責欠・開減２・評減・拘減・' },
  { s: '63F082', v: 365, c: '放デイ３０・責欠２・' },
  { s: '63F083', v: 310, c: '放デイ３０・責欠２・評減・' },
  { s: '63F084', v: 256, c: '放デイ３０・責欠２・開減１・' },
  { s: '63F085', v: 218, c: '放デイ３０・責欠２・開減１・評減・' },
  { s: '63F086', v: 310, c: '放デイ３０・責欠２・開減２・' },
  { s: '63F087', v: 264, c: '放デイ３０・責欠２・開減２・評減・' },
  { s: '63F088', v: 360, c: '放デイ３０・責欠２・拘減・' },
  { s: '63F089', v: 305, c: '放デイ３０・責欠２・評減・拘減・' },
  { s: '63F090', v: 251, c: '放デイ３０・責欠２・開減１・拘減・' },
  { s: '63F091', v: 213, c: '放デイ３０・責欠２・開減１・評減・拘減・' },
  { s: '63F092', v: 305, c: '放デイ３０・責欠２・開減２・拘減・' },
  { s: '63F093', v: 259, c: '放デイ３０・責欠２・開減２・評減・拘減・' },
  { s: '63F118', v: 371, c: '放デイ３０・有資格１６・責欠２・' },
  { s: '63F119', v: 315, c: '放デイ３０・有資格１６・責欠２・評減・' },
  { s: '63F120', v: 260, c: '放デイ３０・有資格１６・責欠２・開減１・' },
  { s: '63F121', v: 221, c: '放デイ３０・有資格１６・責欠２・開減１・評減・' },
  { s: '63F122', v: 315, c: '放デイ３０・有資格１６・責欠２・開減２・' },
  { s: '63F123', v: 268, c: '放デイ３０・有資格１６・責欠２・開減２・評減・' },
  { s: '63F124', v: 366, c: '放デイ３０・有資格１６・責欠２・拘減・' },
  { s: '63F125', v: 310, c: '放デイ３０・有資格１６・責欠２・評減・拘減・' },
  { s: '63F126', v: 255, c: '放デイ３０・有資格１６・責欠２・開減１・拘減・' },
  { s: '63F127', v: 216, c: '放デイ３０・有資格１６・責欠２・開減１・評減・拘減・' },
  { s: '63F128', v: 310, c: '放デイ３０・有資格１６・責欠２・開減２・拘減・' },
  { s: '63F129', v: 263, c: '放デイ３０・有資格１６・責欠２・開減２・評減・拘減・' },
  { s: '63F154', v: 340, c: '放デイ３１・責欠・' },
  { s: '63F155', v: 289, c: '放デイ３１・責欠・評減・' },
  { s: '63F156', v: 238, c: '放デイ３１・責欠・開減１・' },
  { s: '63F157', v: 202, c: '放デイ３１・責欠・開減１・評減・' },
  { s: '63F158', v: 289, c: '放デイ３１・責欠・開減２・' },
  { s: '63F159', v: 246, c: '放デイ３１・責欠・開減２・評減・' },
  { s: '63F160', v: 335, c: '放デイ３１・責欠・拘減・' },
  { s: '63F161', v: 284, c: '放デイ３１・責欠・評減・拘減・' },
  { s: '63F162', v: 233, c: '放デイ３１・責欠・開減１・拘減・' },
  { s: '63F163', v: 197, c: '放デイ３１・責欠・開減１・評減・拘減・' },
  { s: '63F164', v: 284, c: '放デイ３１・責欠・開減２・拘減・' },
  { s: '63F165', v: 241, c: '放デイ３１・責欠・開減２・評減・拘減・' },
  { s: '63F190', v: 346, c: '放デイ３１・有資格１７・責欠・' },
  { s: '63F191', v: 294, c: '放デイ３１・有資格１７・責欠・評減・' },
  { s: '63F192', v: 242, c: '放デイ３１・有資格１７・責欠・開減１・' },
  { s: '63F193', v: 206, c: '放デイ３１・有資格１７・責欠・開減１・評減・' },
  { s: '63F194', v: 294, c: '放デイ３１・有資格１７・責欠・開減２・' },
  { s: '63F195', v: 250, c: '放デイ３１・有資格１７・責欠・開減２・評減・' },
  { s: '63F196', v: 341, c: '放デイ３１・有資格１７・責欠・拘減・' },
  { s: '63F197', v: 289, c: '放デイ３１・有資格１７・責欠・評減・拘減・' },
  { s: '63F198', v: 237, c: '放デイ３１・有資格１７・責欠・開減１・拘減・' },
  { s: '63F199', v: 201, c: '放デイ３１・有資格１７・責欠・開減１・評減・拘減・' },
  { s: '63F200', v: 289, c: '放デイ３１・有資格１７・責欠・開減２・拘減・' },
  { s: '63F201', v: 245, c: '放デイ３１・有資格１７・責欠・開減２・評減・拘減・' },
  { s: '63F226', v: 243, c: '放デイ３１・責欠２・' },
  { s: '63F227', v: 207, c: '放デイ３１・責欠２・評減・' },
  { s: '63F228', v: 170, c: '放デイ３１・責欠２・開減１・' },
  { s: '63F229', v: 145, c: '放デイ３１・責欠２・開減１・評減・' },
  { s: '63F230', v: 207, c: '放デイ３１・責欠２・開減２・' },
  { s: '63F231', v: 176, c: '放デイ３１・責欠２・開減２・評減・' },
  { s: '63F232', v: 238, c: '放デイ３１・責欠２・拘減・' },
  { s: '63F233', v: 202, c: '放デイ３１・責欠２・評減・拘減・' },
  { s: '63F234', v: 165, c: '放デイ３１・責欠２・開減１・拘減・' },
  { s: '63F235', v: 140, c: '放デイ３１・責欠２・開減１・評減・拘減・' },
  { s: '63F236', v: 202, c: '放デイ３１・責欠２・開減２・拘減・' },
  { s: '63F237', v: 171, c: '放デイ３１・責欠２・開減２・評減・拘減・' },
  { s: '63F262', v: 247, c: '放デイ３１・有資格１７・責欠２・' },
  { s: '63F263', v: 210, c: '放デイ３１・有資格１７・責欠２・評減・' },
  { s: '63F264', v: 173, c: '放デイ３１・有資格１７・責欠２・開減１・' },
  { s: '63F265', v: 147, c: '放デイ３１・有資格１７・責欠２・開減１・評減・' },
  { s: '63F266', v: 210, c: '放デイ３１・有資格１７・責欠２・開減２・' },
  { s: '63F267', v: 179, c: '放デイ３１・有資格１７・責欠２・開減２・評減・' },
  { s: '63F268', v: 242, c: '放デイ３１・有資格１７・責欠２・拘減・' },
  { s: '63F269', v: 205, c: '放デイ３１・有資格１７・責欠２・評減・拘減・' },
  { s: '63F270', v: 168, c: '放デイ３１・有資格１７・責欠２・開減１・拘減・' },
  { s: '63F271', v: 142, c: '放デイ３１・有資格１７・責欠２・開減１・評減・拘減・' },
  { s: '63F272', v: 205, c: '放デイ３１・有資格１７・責欠２・開減２・拘減・' },
  { s: '63F273', v: 174, c: '放デイ３１・有資格１７・責欠２・開減２・評減・拘減・' },
  { s: '63F298', v: 263, c: '放デイ３２・責欠・' },
  { s: '63F299', v: 224, c: '放デイ３２・責欠・評減・' },
  { s: '63F300', v: 184, c: '放デイ３２・責欠・開減１・' },
  { s: '63F301', v: 156, c: '放デイ３２・責欠・開減１・評減・' },
  { s: '63F302', v: 224, c: '放デイ３２・責欠・開減２・' },
  { s: '63F303', v: 190, c: '放デイ３２・責欠・開減２・評減・' },
  { s: '63F304', v: 258, c: '放デイ３２・責欠・拘減・' },
  { s: '63F305', v: 219, c: '放デイ３２・責欠・評減・拘減・' },
  { s: '63F306', v: 179, c: '放デイ３２・責欠・開減１・拘減・' },
  { s: '63F307', v: 151, c: '放デイ３２・責欠・開減１・評減・拘減・' },
  { s: '63F308', v: 219, c: '放デイ３２・責欠・開減２・拘減・' },
  { s: '63F309', v: 185, c: '放デイ３２・責欠・開減２・評減・拘減・' },
  { s: '63F334', v: 267, c: '放デイ３２・有資格１８・責欠・' },
  { s: '63F335', v: 227, c: '放デイ３２・有資格１８・責欠・評減・' },
  { s: '63F336', v: 187, c: '放デイ３２・有資格１８・責欠・開減１・' },
  { s: '63F337', v: 159, c: '放デイ３２・有資格１８・責欠・開減１・評減・' },
  { s: '63F338', v: 227, c: '放デイ３２・有資格１８・責欠・開減２・' },
  { s: '63F339', v: 193, c: '放デイ３２・有資格１８・責欠・開減２・評減・' },
  { s: '63F340', v: 262, c: '放デイ３２・有資格１８・責欠・拘減・' },
  { s: '63F341', v: 222, c: '放デイ３２・有資格１８・責欠・評減・拘減・' },
  { s: '63F342', v: 182, c: '放デイ３２・有資格１８・責欠・開減１・拘減・' },
  { s: '63F343', v: 154, c: '放デイ３２・有資格１８・責欠・開減１・評減・拘減・' },
  { s: '63F344', v: 222, c: '放デイ３２・有資格１８・責欠・開減２・拘減・' },
  { s: '63F345', v: 188, c: '放デイ３２・有資格１８・責欠・開減２・評減・拘減・' },
  { s: '63F370', v: 188, c: '放デイ３２・責欠２・' },
  { s: '63F371', v: 160, c: '放デイ３２・責欠２・評減・' },
  { s: '63F372', v: 132, c: '放デイ３２・責欠２・開減１・' },
  { s: '63F373', v: 112, c: '放デイ３２・責欠２・開減１・評減・' },
  { s: '63F374', v: 160, c: '放デイ３２・責欠２・開減２・' },
  { s: '63F375', v: 136, c: '放デイ３２・責欠２・開減２・評減・' },
  { s: '63F376', v: 183, c: '放デイ３２・責欠２・拘減・' },
  { s: '63F377', v: 155, c: '放デイ３２・責欠２・評減・拘減・' },
  { s: '63F378', v: 127, c: '放デイ３２・責欠２・開減１・拘減・' },
  { s: '63F379', v: 107, c: '放デイ３２・責欠２・開減１・評減・拘減・' },
  { s: '63F380', v: 155, c: '放デイ３２・責欠２・開減２・拘減・' },
  { s: '63F381', v: 131, c: '放デイ３２・責欠２・開減２・評減・拘減・' },
  { s: '63F406', v: 191, c: '放デイ３２・有資格１８・責欠２・' },
  { s: '63F407', v: 162, c: '放デイ３２・有資格１８・責欠２・評減・' },
  { s: '63F408', v: 134, c: '放デイ３２・有資格１８・責欠２・開減１・' },
  { s: '63F409', v: 114, c: '放デイ３２・有資格１８・責欠２・開減１・評減・' },
  { s: '63F410', v: 162, c: '放デイ３２・有資格１８・責欠２・開減２・' },
  { s: '63F411', v: 138, c: '放デイ３２・有資格１８・責欠２・開減２・評減・' },
  { s: '63F412', v: 186, c: '放デイ３２・有資格１８・責欠２・拘減・' },
  { s: '63F413', v: 157, c: '放デイ３２・有資格１８・責欠２・評減・拘減・' },
  { s: '63F414', v: 129, c: '放デイ３２・有資格１８・責欠２・開減１・拘減・' },
  { s: '63F415', v: 109, c: '放デイ３２・有資格１８・責欠２・開減１・評減・拘減・' },
  { s: '63F416', v: 157, c: '放デイ３２・有資格１８・責欠２・開減２・拘減・' },
  { s: '63F417', v: 133, c: '放デイ３２・有資格１８・責欠２・開減２・評減・拘減・' },
  { s: '63F442', v: 373, c: '基準該当放デイⅠ１・責欠・' },
  { s: '63F443', v: 317, c: '基準該当放デイⅠ１・責欠・評減・' },
  { s: '63F448', v: 267, c: '基準該当放デイⅠ１・責欠２・' },
  { s: '63F449', v: 227, c: '基準該当放デイⅠ１・責欠２・評減・' },
  { s: '63F454', v: 461, c: '基準該当放デイⅠ２・責欠・' },
  { s: '63F455', v: 392, c: '基準該当放デイⅠ２・責欠・評減・' },
  { s: '63F456', v: 323, c: '基準該当放デイⅠ２・責欠・開減１・' },
  { s: '63F457', v: 275, c: '基準該当放デイⅠ２・責欠・開減１・評減・' },
  { s: '63F458', v: 392, c: '基準該当放デイⅠ２・責欠・開減２・' },
  { s: '63F459', v: 333, c: '基準該当放デイⅠ２・責欠・開減２・評減・' },
  { s: '63F472', v: 329, c: '基準該当放デイⅠ２・責欠２・' },
  { s: '63F473', v: 280, c: '基準該当放デイⅠ２・責欠２・評減・' },
  { s: '63F474', v: 230, c: '基準該当放デイⅠ２・責欠２・開減１・' },
  { s: '63F475', v: 196, c: '基準該当放デイⅠ２・責欠２・開減１・評減・' },
  { s: '63F476', v: 280, c: '基準該当放デイⅠ２・責欠２・開減２・' },
  { s: '63F477', v: 238, c: '基準該当放デイⅠ２・責欠２・開減２・評減・' },
];

export const houdayKasan = [
  { s: '634000', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・１・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634001', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・２・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634002', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・３・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634003', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・４・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634004', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・５・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634005', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・６・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634006', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・７・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634007', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・８・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634008', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・９・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634009', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・１０・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634010', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・１１・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634011', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・１２・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634012', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・１３・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634013', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・１４・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634014', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・１５・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634015', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・１６・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分２・10人以下・', limit: '', syoguu: '' },
  { s: '634016', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・１７・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分２・11から20・', limit: '', syoguu: '' },
  { s: '634017', v: 84, c: '放デイ児童指導員等加配加算Ⅰ１・１８・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・区分２・21人以上・', limit: '', syoguu: '' },
  { s: '634018', v: 418, c: '放デイ児童指導員等加配加算Ⅰ１・１９・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・5人・', limit: '', syoguu: '' },
  { s: '634019', v: 348, c: '放デイ児童指導員等加配加算Ⅰ１・２０・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・6人・', limit: '', syoguu: '' },
  { s: '634020', v: 299, c: '放デイ児童指導員等加配加算Ⅰ１・２１・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・7人・', limit: '', syoguu: '' },
  { s: '634021', v: 261, c: '放デイ児童指導員等加配加算Ⅰ１・２２・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・8人・', limit: '', syoguu: '' },
  { s: '634022', v: 232, c: '放デイ児童指導員等加配加算Ⅰ１・２３・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・9人・', limit: '', syoguu: '' },
  { s: '634023', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・２４・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・10人・', limit: '', syoguu: '' },
  { s: '634024', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・２５・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・平日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634025', v: 418, c: '放デイ児童指導員等加配加算Ⅰ１・２６・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・5人・', limit: '', syoguu: '' },
  { s: '634026', v: 348, c: '放デイ児童指導員等加配加算Ⅰ１・２７・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・6人・', limit: '', syoguu: '' },
  { s: '634027', v: 299, c: '放デイ児童指導員等加配加算Ⅰ１・２８・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・7人・', limit: '', syoguu: '' },
  { s: '634028', v: 261, c: '放デイ児童指導員等加配加算Ⅰ１・２９・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・8人・', limit: '', syoguu: '' },
  { s: '634029', v: 232, c: '放デイ児童指導員等加配加算Ⅰ１・３０・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・9人・', limit: '', syoguu: '' },
  { s: '634030', v: 209, c: '放デイ児童指導員等加配加算Ⅰ１・３１・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・10人・', limit: '', syoguu: '' },
  { s: '634031', v: 139, c: '放デイ児童指導員等加配加算Ⅰ１・３２・', name: '児童指導員等加配加算（Ⅰ）', value: '理学療法士等', opt: '理学療法士等・休日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634040', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・１・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634041', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・２・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634042', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・３・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634043', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・４・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634044', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・５・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634045', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・６・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634046', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・７・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634047', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・８・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634048', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・９・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634049', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・１０・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634050', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・１１・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634051', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・１２・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634052', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・１３・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634053', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・１４・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634054', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・１５・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634055', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・１６・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分２・10人以下・', limit: '', syoguu: '' },
  { s: '634056', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・１７・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分２・11から20・', limit: '', syoguu: '' },
  { s: '634057', v: 62, c: '放デイ児童指導員等加配加算Ⅰ２・１８・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・区分２・21人以上・', limit: '', syoguu: '' },
  { s: '634058', v: 309, c: '放デイ児童指導員等加配加算Ⅰ２・１９・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・5・', limit: '', syoguu: '' },
  { s: '634059', v: 258, c: '放デイ児童指導員等加配加算Ⅰ２・２０・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・6・', limit: '', syoguu: '' },
  { s: '634060', v: 221, c: '放デイ児童指導員等加配加算Ⅰ２・２１・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・7・', limit: '', syoguu: '' },
  { s: '634061', v: 193, c: '放デイ児童指導員等加配加算Ⅰ２・２２・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・8・', limit: '', syoguu: '' },
  { s: '634062', v: 172, c: '放デイ児童指導員等加配加算Ⅰ２・２３・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・9・', limit: '', syoguu: '' },
  { s: '634063', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・２４・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・10・', limit: '', syoguu: '' },
  { s: '634064', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・２５・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・平日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634065', v: 309, c: '放デイ児童指導員等加配加算Ⅰ２・２６・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・5・', limit: '', syoguu: '' },
  { s: '634066', v: 258, c: '放デイ児童指導員等加配加算Ⅰ２・２７・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・6・', limit: '', syoguu: '' },
  { s: '634067', v: 221, c: '放デイ児童指導員等加配加算Ⅰ２・２８・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・7・', limit: '', syoguu: '' },
  { s: '634068', v: 193, c: '放デイ児童指導員等加配加算Ⅰ２・２９・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・8・', limit: '', syoguu: '' },
  { s: '634069', v: 172, c: '放デイ児童指導員等加配加算Ⅰ２・３０・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・9・', limit: '', syoguu: '' },
  { s: '634070', v: 155, c: '放デイ児童指導員等加配加算Ⅰ２・３１・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・10・', limit: '', syoguu: '' },
  { s: '634071', v: 103, c: '放デイ児童指導員等加配加算Ⅰ２・３２・', name: '児童指導員等加配加算（Ⅰ）', value: '児童指導員等', opt: '児童指導員・休日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634081', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・１・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634082', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・２・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634083', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・３・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634084', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・４・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634085', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・５・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634086', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・６・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634087', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・７・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634088', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・８・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634089', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・９・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634090', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・１０・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634091', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・１１・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634092', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・１２・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634093', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・１３・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634094', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・１４・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634095', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・１５・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634096', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・１６・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分２・10人以下・', limit: '', syoguu: '' },
  { s: '634097', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・１７・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分２・11から20・', limit: '', syoguu: '' },
  { s: '634098', v: 36, c: '放デイ児童指導員等加配加算Ⅰ３・１８・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・区分２・21人以上・', limit: '', syoguu: '' },
  { s: '634099', v: 182, c: '放デイ児童指導員等加配加算Ⅰ３・１９・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・5・', limit: '', syoguu: '' },
  { s: '634100', v: 152, c: '放デイ児童指導員等加配加算Ⅰ３・２０・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・6・', limit: '', syoguu: '' },
  { s: '634101', v: 130, c: '放デイ児童指導員等加配加算Ⅰ３・２１・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・7・', limit: '', syoguu: '' },
  { s: '634102', v: 114, c: '放デイ児童指導員等加配加算Ⅰ３・２２・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・8・', limit: '', syoguu: '' },
  { s: '634103', v: 101, c: '放デイ児童指導員等加配加算Ⅰ３・２３・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・9・', limit: '', syoguu: '' },
  { s: '634104', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・２４・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・10・', limit: '', syoguu: '' },
  { s: '634105', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・２５・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・平日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634106', v: 182, c: '放デイ児童指導員等加配加算Ⅰ３・２６・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・5・', limit: '', syoguu: '' },
  { s: '634107', v: 152, c: '放デイ児童指導員等加配加算Ⅰ３・２７・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・6・', limit: '', syoguu: '' },
  { s: '634108', v: 130, c: '放デイ児童指導員等加配加算Ⅰ３・２８・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・7・', limit: '', syoguu: '' },
  { s: '634109', v: 114, c: '放デイ児童指導員等加配加算Ⅰ３・２９・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・8・', limit: '', syoguu: '' },
  { s: '634110', v: 101, c: '放デイ児童指導員等加配加算Ⅰ３・３０・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・9・', limit: '', syoguu: '' },
  { s: '634111', v: 91, c: '放デイ児童指導員等加配加算Ⅰ３・３１・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・10・', limit: '', syoguu: '' },
  { s: '634112', v: 61, c: '放デイ児童指導員等加配加算Ⅰ３・３２・', name: '児童指導員等加配加算（Ⅰ）', value: 'その他の従業者', opt: 'その他・休日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634120', v: 209, c: '放デイ児童指導員等加配加算Ⅱ１・１・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634121', v: 139, c: '放デイ児童指導員等加配加算Ⅱ１・２・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634122', v: 84, c: '放デイ児童指導員等加配加算Ⅱ１・３・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634123', v: 209, c: '放デイ児童指導員等加配加算Ⅱ１・４・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634124', v: 139, c: '放デイ児童指導員等加配加算Ⅱ１・５・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634125', v: 84, c: '放デイ児童指導員等加配加算Ⅱ１・６・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634126', v: 209, c: '放デイ児童指導員等加配加算Ⅱ１・７・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634127', v: 139, c: '放デイ児童指導員等加配加算Ⅱ１・８・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634128', v: 84, c: '放デイ児童指導員等加配加算Ⅱ１・９・', name: '児童指導員等加配加算（Ⅱ）', value: '理学療法士等', opt: '理学療法士等・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634130', v: 155, c: '放デイ児童指導員等加配加算Ⅱ２・１・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634131', v: 103, c: '放デイ児童指導員等加配加算Ⅱ２・２・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634132', v: 62, c: '放デイ児童指導員等加配加算Ⅱ２・３・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634133', v: 155, c: '放デイ児童指導員等加配加算Ⅱ２・４・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634134', v: 103, c: '放デイ児童指導員等加配加算Ⅱ２・５・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634135', v: 62, c: '放デイ児童指導員等加配加算Ⅱ２・６・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634136', v: 155, c: '放デイ児童指導員等加配加算Ⅱ２・７・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634137', v: 103, c: '放デイ児童指導員等加配加算Ⅱ２・８・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634138', v: 62, c: '放デイ児童指導員等加配加算Ⅱ２・９・', name: '児童指導員等加配加算（Ⅱ）', value: '児童指導員等', opt: '児童指導員・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634140', v: 91, c: '放デイ児童指導員等加配加算Ⅱ３・１・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634141', v: 61, c: '放デイ児童指導員等加配加算Ⅱ３・２・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634142', v: 36, c: '放デイ児童指導員等加配加算Ⅱ３・３・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634143', v: 91, c: '放デイ児童指導員等加配加算Ⅱ３・４・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634144', v: 61, c: '放デイ児童指導員等加配加算Ⅱ３・５・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634145', v: 36, c: '放デイ児童指導員等加配加算Ⅱ３・６・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634146', v: 91, c: '放デイ児童指導員等加配加算Ⅱ３・７・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634147', v: 61, c: '放デイ児童指導員等加配加算Ⅱ３・８・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634148', v: 36, c: '放デイ児童指導員等加配加算Ⅱ３・９・', name: '児童指導員等加配加算（Ⅱ）', value: 'その他の従業者', opt: 'その他・休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634200', v: 200, c: '放デイ看護職員加配加算１・１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634201', v: 133, c: '放デイ看護職員加配加算１・２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634202', v: 80, c: '放デイ看護職員加配加算１・３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634203', v: 200, c: '放デイ看護職員加配加算１・４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634204', v: 133, c: '放デイ看護職員加配加算１・５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634205', v: 80, c: '放デイ看護職員加配加算１・６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634206', v: 200, c: '放デイ看護職員加配加算１・７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634207', v: 133, c: '放デイ看護職員加配加算１・８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634208', v: 80, c: '放デイ看護職員加配加算１・９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634209', v: 200, c: '放デイ看護職員加配加算１・１０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634210', v: 133, c: '放デイ看護職員加配加算１・１１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634211', v: 80, c: '放デイ看護職員加配加算１・１２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634212', v: 200, c: '放デイ看護職員加配加算１・１３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634213', v: 133, c: '放デイ看護職員加配加算１・１４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634214', v: 80, c: '放デイ看護職員加配加算１・１５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634215', v: 200, c: '放デイ看護職員加配加算１・１６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分２・10人以下・', limit: '', syoguu: '' },
  { s: '634216', v: 133, c: '放デイ看護職員加配加算１・１７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分２・11から20・', limit: '', syoguu: '' },
  { s: '634217', v: 80, c: '放デイ看護職員加配加算１・１８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・区分２・21人以上・', limit: '', syoguu: '' },
  { s: '634218', v: 400, c: '放デイ看護職員加配加算１・１９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・5・', limit: '', syoguu: '' },
  { s: '634219', v: 333, c: '放デイ看護職員加配加算１・２０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・6・', limit: '', syoguu: '' },
  { s: '634220', v: 286, c: '放デイ看護職員加配加算１・２１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・7・', limit: '', syoguu: '' },
  { s: '634221', v: 250, c: '放デイ看護職員加配加算１・２２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・8・', limit: '', syoguu: '' },
  { s: '634222', v: 222, c: '放デイ看護職員加配加算１・２３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・9・', limit: '', syoguu: '' },
  { s: '634223', v: 200, c: '放デイ看護職員加配加算１・２４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・10・', limit: '', syoguu: '' },
  { s: '634224', v: 133, c: '放デイ看護職員加配加算１・２５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '平日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634225', v: 400, c: '放デイ看護職員加配加算１・２６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・5・', limit: '', syoguu: '' },
  { s: '634226', v: 333, c: '放デイ看護職員加配加算１・２７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・6・', limit: '', syoguu: '' },
  { s: '634227', v: 286, c: '放デイ看護職員加配加算１・２８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・7・', limit: '', syoguu: '' },
  { s: '634228', v: 250, c: '放デイ看護職員加配加算１・２９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・8・', limit: '', syoguu: '' },
  { s: '634229', v: 222, c: '放デイ看護職員加配加算１・３０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・9・', limit: '', syoguu: '' },
  { s: '634230', v: 200, c: '放デイ看護職員加配加算１・３１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・10・', limit: '', syoguu: '' },
  { s: '634231', v: 133, c: '放デイ看護職員加配加算１・３２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅰ', opt: '休日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634240', v: 400, c: '放デイ看護職員加配加算２・１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634241', v: 266, c: '放デイ看護職員加配加算２・２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634242', v: 160, c: '放デイ看護職員加配加算２・３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634243', v: 400, c: '放デイ看護職員加配加算２・４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634244', v: 266, c: '放デイ看護職員加配加算２・５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634245', v: 160, c: '放デイ看護職員加配加算２・６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634246', v: 400, c: '放デイ看護職員加配加算２・７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634247', v: 266, c: '放デイ看護職員加配加算２・８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634248', v: 160, c: '放デイ看護職員加配加算２・９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634249', v: 400, c: '放デイ看護職員加配加算２・１０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634250', v: 266, c: '放デイ看護職員加配加算２・１１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634251', v: 160, c: '放デイ看護職員加配加算２・１２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634252', v: 400, c: '放デイ看護職員加配加算２・１３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634253', v: 266, c: '放デイ看護職員加配加算２・１４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634254', v: 160, c: '放デイ看護職員加配加算２・１５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634255', v: 400, c: '放デイ看護職員加配加算２・１６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分2・10人以下・', limit: '', syoguu: '' },
  { s: '634256', v: 266, c: '放デイ看護職員加配加算２・１７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分2・11から20・', limit: '', syoguu: '' },
  { s: '634257', v: 160, c: '放デイ看護職員加配加算２・１８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・区分2・21人以上・', limit: '', syoguu: '' },
  { s: '634258', v: 800, c: '放デイ看護職員加配加算２・１９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・5・', limit: '', syoguu: '' },
  { s: '634259', v: 666, c: '放デイ看護職員加配加算２・２０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・6・', limit: '', syoguu: '' },
  { s: '634260', v: 572, c: '放デイ看護職員加配加算２・２１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・7・', limit: '', syoguu: '' },
  { s: '634261', v: 500, c: '放デイ看護職員加配加算２・２２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・8・', limit: '', syoguu: '' },
  { s: '634262', v: 444, c: '放デイ看護職員加配加算２・２３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・9・', limit: '', syoguu: '' },
  { s: '634263', v: 400, c: '放デイ看護職員加配加算２・２４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・10・', limit: '', syoguu: '' },
  { s: '634264', v: 266, c: '放デイ看護職員加配加算２・２５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '平日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634265', v: 800, c: '放デイ看護職員加配加算２・２６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・5・', limit: '', syoguu: '' },
  { s: '634266', v: 666, c: '放デイ看護職員加配加算２・２７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・6・', limit: '', syoguu: '' },
  { s: '634267', v: 572, c: '放デイ看護職員加配加算２・２８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・7・', limit: '', syoguu: '' },
  { s: '634268', v: 500, c: '放デイ看護職員加配加算２・２９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・8・', limit: '', syoguu: '' },
  { s: '634269', v: 444, c: '放デイ看護職員加配加算２・３０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・9・', limit: '', syoguu: '' },
  { s: '634270', v: 400, c: '放デイ看護職員加配加算２・３１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・10・', limit: '', syoguu: '' },
  { s: '634271', v: 266, c: '放デイ看護職員加配加算２・３２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅱ', opt: '休日・重心・11以上・', limit: '', syoguu: '' },
  { s: '634280', v: 600, c: '放デイ看護職員加配加算３・１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の１・10人以下・', limit: '', syoguu: '' },
  { s: '634281', v: 399, c: '放デイ看護職員加配加算３・２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の１・11から20・', limit: '', syoguu: '' },
  { s: '634282', v: 240, c: '放デイ看護職員加配加算３・３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の１・21人以上・', limit: '', syoguu: '' },
  { s: '634283', v: 600, c: '放デイ看護職員加配加算３・４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の２・10人以下・', limit: '', syoguu: '' },
  { s: '634284', v: 399, c: '放デイ看護職員加配加算３・５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の２・11から20・', limit: '', syoguu: '' },
  { s: '634285', v: 240, c: '放デイ看護職員加配加算３・６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分１の２・21人以上・', limit: '', syoguu: '' },
  { s: '634286', v: 600, c: '放デイ看護職員加配加算３・７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の１・10人以下・', limit: '', syoguu: '' },
  { s: '634287', v: 399, c: '放デイ看護職員加配加算３・８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の１・11から20・', limit: '', syoguu: '' },
  { s: '634288', v: 240, c: '放デイ看護職員加配加算３・９・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の１・21人以上・', limit: '', syoguu: '' },
  { s: '634289', v: 600, c: '放デイ看護職員加配加算３・１０・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の２・10人以下・', limit: '', syoguu: '' },
  { s: '634290', v: 399, c: '放デイ看護職員加配加算３・１１・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の２・11から20・', limit: '', syoguu: '' },
  { s: '634291', v: 240, c: '放デイ看護職員加配加算３・１２・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '平日・区分２の２・21人以上・', limit: '', syoguu: '' },
  { s: '634292', v: 600, c: '放デイ看護職員加配加算３・１３・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分１・10人以下・', limit: '', syoguu: '' },
  { s: '634293', v: 399, c: '放デイ看護職員加配加算３・１４・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分１・11から20・', limit: '', syoguu: '' },
  { s: '634294', v: 240, c: '放デイ看護職員加配加算３・１５・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分１・21人以上・', limit: '', syoguu: '' },
  { s: '634295', v: 600, c: '放デイ看護職員加配加算３・１６・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分2・10人以下・', limit: '', syoguu: '' },
  { s: '634296', v: 399, c: '放デイ看護職員加配加算３・１７・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分2・11から20・', limit: '', syoguu: '' },
  { s: '634297', v: 240, c: '放デイ看護職員加配加算３・１８・', name: '看護職員加配加算', value: '看護職員加配加算Ⅲ', opt: '休日・区分2・21人以上・', limit: '', syoguu: '' },
  { s: '634300', v: 181, c: '放デイ共生型サービス体制強化加算１・', name: '共生型サービス体制強化加算', value: '児発管かつ保育士又は児童指導員の場合', opt: '', limit: '', syoguu: '' },
  { s: '634301', v: 103, c: '放デイ共生型サービス体制強化加算２・', name: '共生型サービス体制強化加算', value: '児発管の場合', opt: '', limit: '', syoguu: '' },
  { s: '634302', v: 78, c: '放デイ共生型サービス体制強化加算３・', name: '共生型サービス体制強化加算', value: '保育士又は児童指導員の場合', opt: '', limit: '', syoguu: '' },
  { s: '635350', v: 187, c: '放デイ家庭連携加算１・', name: '家庭連携加算', value: '1時間未満', opt: '', limit: '2', syoguu: '' },
  { s: '635351', v: 280, c: '放デイ家庭連携加算２・', name: '家庭連携加算', value: '1時間以上', opt: '', limit: '', syoguu: '' },
  { s: '636805', v: 35, c: '放デイ事業所内相談支援加算・', name: '事業所内相談支援加算', value: '1', opt: '', limit: '1', syoguu: '' },
  { s: '635360', v: 187, c: '放デイ訪問支援特別加算１・', name: '訪問支援特別加算', value: '1時間未満の場合', opt: '', limit: '2', syoguu: '' },
  { s: '635361', v: 280, c: '放デイ訪問支援特別加算２・', name: '訪問支援特別加算', value: '1時間以上の場合', opt: '', limit: '', syoguu: '' },
  { s: '635370', v: 150, c: '放デイ上限額管理加算・', name: '利用者負担上限額管理加算', value: '1', opt: '', limit: 'once', syoguu: '' },
  { s: '635492', v: 15, c: '放デイ福祉専門職員配置等加算Ⅰ・', name: '福祉専門職員配置等加算', value: '福祉専門職員配置等加算Ⅰ', opt: '', limit: '', syoguu: '' },
  { s: '635490', v: 10, c: '放デイ福祉専門職員配置等加算Ⅱ・', name: '福祉専門職員配置等加算', value: '福祉専門職員配置等加算Ⅱ', opt: '', limit: '', syoguu: '' },
  { s: '635491', v: 6, c: '放デイ福祉専門職員配置等加算Ⅲ・', name: '福祉専門職員配置等加算', value: '福祉専門職員配置等加算Ⅲ', opt: '', limit: '', syoguu: '' },
  { s: '635495', v: 94, c: '放デイ欠席時対応加算・', name: '欠席時対応加算', value: '1', opt: '', limit: '4', syoguu: '' },
  { s: '635496', v: 94, c: '放デイ欠席時対応加算（重心）・', name: '欠席時対応加算', value: '重心', opt: '', limit: '8', syoguu: '' },
  { s: '636220', v: 54, c: '放デイ特別支援加算・', name: '特別支援加算', value: '1', opt: '', limit: '', syoguu: '' },
  { s: '636225', v: 155, c: '放デイ強度行動障害児支援加算・', name: '強度行動障害児支援加算', value: '1', opt: '', limit: '', syoguu: '' },
  { s: '636230', v: 500, c: '放デイ医療連携体制加算Ⅰ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅰ', opt: '', limit: '', syoguu: '' },
  { s: '636231', v: 250, c: '放デイ医療連携体制加算Ⅱ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅱ', opt: '', limit: '', syoguu: '' },
  { s: '639992', v: 0, c: '放デイ医療連携体制加算Ⅲ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅲ', opt: '', limit: '', syoguu: '' },
  { s: '636233', v: 100, c: '放デイ医療連携体制加算Ⅳ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅳ', opt: '', limit: '', syoguu: '' },
  { s: '636234', v: 1000, c: '放デイ医療連携体制加算Ⅴ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅴ', opt: '', limit: '', syoguu: '' },
  { s: '636235', v: 500, c: '放デイ医療連携体制加算Ⅵ・', name: '医療連携体制加算', value: '医療連携体制加算Ⅵ', opt: '', limit: '', syoguu: '' },
  { s: '636240', v: 54, c: '放デイ送迎加算Ⅰ・', name: '送迎加算', value: '送迎加算Ⅰ', opt: '', limit: '', syoguu: '' },
  { s: '636243', v: 38, c: '放デイ送迎加算Ⅰ（同一敷地）・', name: '送迎加算', value: '送迎加算Ⅰ同一敷地内', opt: '', limit: '', syoguu: '' },
  { s: '636241', v: 37, c: '放デイ送迎加算Ⅱ・', name: '送迎加算', value: '送迎加算Ⅱ', opt: '', limit: '', syoguu: '' },
  { s: '636244', v: 26, c: '放デイ送迎加算Ⅱ（同一敷地）・', name: '送迎加算', value: '送迎加算Ⅱ同一敷地内', opt: '', limit: '', syoguu: '' },
  { s: '636242', v: 37, c: '放デイ送迎加算Ⅰ（一定条件）・', name: '送迎加算', value: '送迎加算Ⅰ一定条件', opt: '', limit: '', syoguu: '' },
  { s: '636250', v: 61, c: '放デイ延長支援加算１・', name: '延長支援加算', value: '1時間未満', opt: '', limit: '', syoguu: '' },
  { s: '636251', v: 92, c: '放デイ延長支援加算２・', name: '延長支援加算', value: '1時間以上2時間未満', opt: '', limit: '', syoguu: '' },
  { s: '636252', v: 123, c: '放デイ延長支援加算３・', name: '延長支援加算', value: '2時間以上', opt: '', limit: '', syoguu: '' },
  { s: '636253', v: 128, c: '放デイ延長支援加算４・', name: '延長支援加算', value: '1時間未満', opt: '重心・', limit: '', syoguu: '' },
  { s: '636254', v: 192, c: '放デイ延長支援加算５・', name: '延長支援加算', value: '1時間以上2時間未満', opt: '重心・', limit: '', syoguu: '' },
  { s: '636255', v: 256, c: '放デイ延長支援加算６・', name: '延長支援加算', value: '2時間以上', opt: '重心・', limit: '', syoguu: '' },
  { s: '636760', v: 200, c: '放デイ関係機関連携加算Ⅰ・', name: '関係機関連携加算', value: '関係機関連携加算Ⅰ', opt: '', limit: '', syoguu: '' },
  { s: '636761', v: 200, c: '放デイ関係機関連携加算Ⅱ・', name: '関係機関連携加算', value: '関係機関連携加算Ⅱ', opt: '', limit: '', syoguu: '' },
  { s: '636765', v: 500, c: '放デイ保育教育等移行支援加算・', name: '保育・教育等移行支援加算', value: '1', opt: '', limit: '1', syoguu: '' },
  { s: '636621', v: 8.1, c: '放デイ処遇改善加算Ⅰ・', name: '福祉・介護職員処遇改善加算', value: '福祉・介護職員処遇改善加算Ⅰ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636616', v: 5.9, c: '放デイ処遇改善加算Ⅱ・', name: '福祉・介護職員処遇改善加算', value: '福祉・介護職員処遇改善加算Ⅱ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636596', v: 3.3, c: '放デイ処遇改善加算Ⅲ・', name: '福祉・介護職員処遇改善加算', value: '福祉・介護職員処遇改善加算Ⅲ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636601', v: '3.3*0.9', c: '放デイ処遇改善加算Ⅳ・', name: '福祉・介護職員処遇改善加算', value: '福祉・介護職員処遇改善加算Ⅳ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636606', v: '3.3*0.8', c: '放デイ処遇改善加算Ⅴ・', name: '福祉・介護職員処遇改善加算', value: '福祉・介護職員処遇改善加算Ⅴ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636611', v: 1.1, c: '放デイ処遇改善特別加算・', name: '福祉・介護職員処遇改善特別加算', value: '1', opt: '', limit: 'once', syoguu: '1' },
  { s: '636772', v: 0.7, c: '放デイ特定処遇改善加算Ⅰ・', name: '特定処遇改善加算', value: '特定処遇改善加算Ⅰ', opt: '', limit: 'once', syoguu: '1' },
  { s: '636773', v: 0.5, c: '放デイ特定処遇改善加算Ⅱ・', name: '特定処遇改善加算', value: '特定処遇改善加算Ⅱ', opt: '', limit: 'once', syoguu: '1' },
];
