import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import { useLocation, useHistory } from 'react-router-dom';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyle = makeStyles((theme)=>({
  root :{
    ' & .MuiDialog-paperWidthSm':{
      minWidth:100
    }
  },
  absenceButton: {
    backgroundColor: red[500],
    color: "#fff",
    '&:hover' : {
      backgroundColor: red[600],
    },
  },
  allSubmitButton: {
    backgroundColor: indigo[700],
    color: "#fff",
    '&:hover': {
      backgroundColor: indigo[800],
    },
  },
  holidayInputRoot:{
    '& .MuiSelect-root':{
      paddingTop: 10,
    },
  },
  userScheduleLinkButton:{
    padding: 12,
    paddingTop: 16,
    paddingBottom: 0,
  },
  thisBttonWrap:{
    display:'flex',
    justifyContent: 'flex-end',
    padding: 8,
    '& button': {
      marginTop: 20,
    },
    '& .allSubmitOuter': {
      backgroundColor: indigo[50],
      borderRadius: 4,
      '& button': {
        marginTop: 0,
      },
      '& .small':{
        fontSize:'.8rem',
        paddingBottom: 2,
        paddingTop: 4,
        lineHeight: '14px',
        textAlign: 'center',
      },
    },
  },
  textAreaWrap:{
    width:'48%',margin:'0 1%',
    '& textarea': {
      padding:4, backgroundColor:'#eee', border: 'none', width: '100%'
    },
    '& .title': {
      fontSize: '.8rem', padding:0, transition: '.4s', padding: '0 4px'
    },
  }
}));

const absenceColor = red[500];

const CommentInput = (props) => {
  const classes = useStyle();
  const {placeholder, name, title, initVal} = props;
  const [val, setVal] = useState(initVal);
  const [titleStyle, setTitleStyle] = useState({height:0, opacity: 0});
  useEffect(()=>{
    if (val)  setTitleStyle({height:16, opacity: 1});
    else      setTitleStyle({height:0, opacity: 0});
  }, [val]);
  return (
    <div className={classes.textAreaWrap}>
      <div className='title' style={titleStyle}>{title}</div>
      <TextareaAutosize
        placeholder={placeholder} name={name} value={val}
        onChange={(e)=>setVal(e.currentTarget.value)}
      />
    </div>
  )
}


const SchEditDetailDialog = ()=> {
  const dispatch = useDispatch();
  const classes = useStyle();
  const path = useLocation().pathname;
  const hist = useHistory();

  // 必要な情報の取得
  const stdDate = useSelector(state=>state.stdDate);
  const actualCostList = useSelector(
    state => state.config.actualCostList
  )
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  // const bid = useSelector(state => state.bid);
  // const hid = useSelector(state => state.hid);
  // const dateList = useSelector(state => state.dateList);

  // storeからopenさせる
  let stateOpen = useSelector(
    state => state.controleMode.openSchEditDetailDialog
  )
  stateOpen = (stateOpen === undefined) ?
    { open: false, uid: '', did: '' } : stateOpen;
  
  const UID = stateOpen.uid;
  const did = stateOpen.did;
  const thisUser = comMod.getUser(UID, users);

  const handleClose = () => {
    comMod.setOpenSchEditDetailDialog(
      dispatch, { open: false, uid: '', did: '' }
    );
    comMod.setSchedleLastUpdate(dispatch, path);
  }

  // handlesubmit handleAbSenseがほぼ同じ動作になるので
  // 別関数として再定義
  // addprmsはサブミットのときに追加される
  // deleteKeysはそれらのキーが削除される
  // 20210303追加 didsに値があるときは全てのdidに対してdispatchする
  const doSubmit =( addPrms = {}, deleteKeys=[], dids=[] )=>{
    // これ以外の要素は加算項目として扱う
    const mainPrms = [
      'start', 'end', 'offSchool', 'actualCost', 'pickup', 'send',
      'notice', 'memo',
    ];

    const addVal = (obj, name, val) => {
      if (mainPrms.indexOf(name) > -1)
        // 数値だったらint化
        formsVal[name] = (isNaN(val)) ? val : parseInt(val);
      else {
        if (obj.dAddiction === undefined) obj.dAddiction = {};
        if (val)
          obj.dAddiction[name] = val;
      }
    }
    // 値が必要なエレメントを用意しておく
    const inputs = document.querySelectorAll('#fgr649hg input');
    const selects = document.querySelectorAll('#fgr649hg select');
    const textareas = document.querySelectorAll('#fgr649hg textarea');
    const formsVal = comMod.getFormDatas([inputs, selects, textareas])

    // 実費項目
    Object.keys(formsVal.actualCost).map(e => {
      if (formsVal.actualCost[e]) {
        formsVal.actualCost[e] = actualCostList[e];
      }
      else {
        delete formsVal.actualCost[e];
      }
    })

    // 加算項目をdaddictionとしてひとまとめにする
    Object.keys(formsVal).forEach(e => {
      addVal(formsVal, e, formsVal[e])
      // formsVal[e.getAttribute('name')] = e.value;
    });
    // 送迎だけ配列になっているので処理を追加
    formsVal.transfer = [];
    formsVal.transfer[0] =
      (formsVal.pickup !== undefined) ? formsVal.pickup : '';
    formsVal.transfer[1] =
      (formsVal.send !== undefined) ? formsVal.send : '';
    delete formsVal.pickup; delete formsVal.send;

    // formデータにサービスが含まれていないため追加する
    formsVal.service = service;

    // addPrmsの追加
    Object.assign(formsVal, addPrms);

    // deleteKeysの削除
    deleteKeys.map(elm=>{delete formsVal[elm];})
    // 多数同時更新
    if (dids.length){
      // memoとnoticeは複数書き込みしない
      const formsVal_ = {...formsVal};
      delete formsVal_.notice;
      delete formsVal_.memo;
      dids.map(_=>{
        dispatch(Actions.replaceSchedule(UID, _, formsVal_));
      });
    }
    // 通常
    dispatch(Actions.replaceSchedule(UID, did, formsVal));

    comMod.setSchedleLastUpdate(dispatch, path);
    handleClose();
  }

  // 全て変更するときのDIDリストを作成
  // UIDが確定しているときのみ
  const getDidsForEdit = () =>{
    const pi = v => parseInt(v);
    if (UID){
      const tmpDids = comMod.setOfUidDid(schedule, UID);
      // --- 現在の放課後休日を取得
      const thisOffSchool = schedule[UID][did].offSchool;
      // 現在の放課後休日フラグと一致していないdidは削除
      // 利用実績があるdidも削除
      // 現在のdid未満も削除
      const dids = tmpDids
      .filter(_ => 
        pi(schedule[UID][_].offSchool) === pi(thisOffSchool) &&
        _ >= did &&
        schedule[UID][_].useResult !== true
      );
      return dids;
    }
    else{
      return [];
    }
  }
  const dids = getDidsForEdit();
  const didLength = dids.length;
  const handleAllSubmit = (e) => {
    e.preventDefault();
    doSubmit({}, [], dids);  // 追加オブジェクトなし 削除なし 複数更新
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    doSubmit({}, ['absence']);  // 追加オブジェクトなし abSenceを削除
  }
  // 欠席扱いにする
  // scheduleの日付オブジェクト配下にabsence:trueを挿入して
  // ダイアログを閉じる
  const handleAbcense =(e)=>{
    e.preventDefault();
    doSubmit({ absence:true }, []);  // absenceを追加
  }
  // Scheduleオブジェクトの値を読み取ってボタンテキストを制御
  const thisAbsenced = comMod.findDeepPath(schedule, [UID, did, 'absence']);
  const submitBtnText = (thisAbsenced) ? '出席にする' : '書き込み';
  const absenceBtnText = (thisAbsenced) ? '欠席として保存' : '欠席にする';
  // ユーザー別スケジュールページから開かれたらユーザーリンクは表示しない
  const userScheduleLinkButton = (path.indexOf('users') > -1) ?
    {display:'none'}: {display:'block'};

  // noticeとmemoの初期値をstateから取得
  const thisNotice = comMod.findDeepPath(schedule, [UID, did, 'notice']);
  const thisMemo = comMod.findDeepPath(schedule, [UID, did, 'memo']);
  return (<>
    <Dialog 
      open={stateOpen.open}
      onClose={handleClose}
      className={classes.root}
    >
      <form className="dialogForm " id="fgr649hg">
        <div className='formTitle'>
          利用予定詳細設定
        </div>
        <div className="formSubTitle">
          <div className="date">{
            did.substr(1, 4) + "年 " + 
            did.substr(5, 2) + "月 " + 
            did.substr(7, 2) + "日 "
          }</div>
          <div className="user">{thisUser.name + ' 様 '}</div>
          <div className="age">{thisUser.ageStr}</div>
          <div className="belongs">{thisUser.belongs1}</div>
        </div>
        <div className='cntRow'>
          <sfp.TimeInput 
            name='start' label='開始' 
            value={schedule} uid={UID} did={did}
            required size='middle' 
          />
          <sfp.TimeInput
            name='end' label='終了'
            value={schedule} uid={UID} did={did}
            required size='middle'
          />
          <div className={classes.holidayInputRoot}>
            {/* パディング調整用のラッピングをする */}
            <sfp.HolidayOrNot
              name='offSchool' label='平日/休日利用'
              value={schedule} uid={UID} did={did}
              required size='middle'
            />
          </div>
          {/* ユーザー別スケジュールページから開かれたときは非表示
          スタイルで設定を行う */}
          <div 
            className={classes.userScheduleLinkButton} 
            style={userScheduleLinkButton}
          >
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                hist.push('/schedule/users/' + thisUser.uid + '/');
                handleClose();
              }}
            >
              ユーザー別予定へ
            </Button>
          </div>
        </div>
        <div className='cntRow'>
          <sfp.Transfer
            name='pickup' label='迎え'
            value={schedule} uid={UID} did={did}
            required size='middle'
          />
          <sfp.Transfer
            name='send' label='送り'
            value={schedule} uid={UID} did={did}
            required size='middle'
          />

        </div>
        <div className='cntRow'>
          <sfp.ActualCostCheckBox
            value={schedule} uid={UID} did={did}
            required size='middle'
          />
        </div>
        <div className='cntRow'>
          <afp.JiShidouKaHai1 uid={UID} did={did} size='middle' dLayer={3} />
          <afp.JiShidouKaHai2 uid={UID} did={did} size='middle' dLayer={3} />
          <afp.KangoKahai uid={UID} did={did} size='middle' dLayer={3} />
          <afp.EnchouShien uid={UID} did={did} size='middle' dLayer={3} />
          <afp.TokubetsuShien uid={UID} did={did} size='middle' dLayer={3} />
          <afp.KateiRenkei uid={UID} did={did} size='middle' dLayer={3} />
          <afp.HoumonShien uid={UID} did={did} size='middle' dLayer={3} />
          <afp.KessekiTaiou uid={UID} did={did} size='middle' dLayer={3} />
          <afp.IryouRenkei uid={UID} did={did} size='middle' dLayer={3} />
          <afp.JigyousyoSoudan uid={UID} did={did} size='middle' dLayer={3} />
          <afp.KyoudoKoudou uid={UID} did={did} size='middle' dLayer={3} />
          <afp.HoikuKyouiku uid={UID} did={did} size='middle' dLayer={3} />
        </div>
        <div className='cntRow'>
          <CommentInput 
            name='notice' initVal={thisNotice}
            placeholder='家庭連携加算、欠席加算の説明など' title='備考（加算説明等）' 
          />
          <CommentInput 
            name='memo' initVal={thisMemo}
            placeholder='事業所内の連絡事項' title='メモ（事業所内連絡）' 
          />
        </div>
      </form>
      <div className={'buttonWrapper ' + classes.thisBttonWrap}>

        <div className={'allSubmitOuter'}>
          <div className='small'>
            {
              (
                did.substr(5, 2) + "月" + 
                did.substr(7, 2) + "日").replace(/0/g, '')
            }以降
          </div>
          <div>
            <Button
              className={classes.allSubmitButton}
              variant="contained"
              startIcon={<PeopleAltIcon />}
              onClick={handleAllSubmit}
            >
              一括変更<span>{didLength}</span>件
            </Button>
          </div>
        </div>

        <Button
          className={classes.absenceButton}
          variant="contained"
          startIcon={<BlockRoundedIcon />}
          onClick={handleAbcense}
        >
          { absenceBtnText }
        </Button>

        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={handleClose}
        />
        <mui.ButtonGP
          color='primary'
          label={submitBtnText}
          type="submit"
          onClick={handleSubmit}
        />
      </div>

    </Dialog>
  </>);
}

export default SchEditDetailDialog;