import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, ServerStyleSheets } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts';
import * as sfp from '../common/StdFormParts';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import Button from '@material-ui/core/Button';
import {
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from '../Billing/blMakeData';
import {
  proseedByUsersDt
} from '../Billing/Proseed'
import { endPoint } from '../../Actions'
import axios from 'axios';
import { LoadingSpinner, UserSelectDialog } from '../common/commonParts';
import useInterval from 'use-interval';
import GroupIcon from '@material-ui/icons/Group';
import { AddToPhotosOutlined, CenterFocusStrong, ColorizeSharp, FormatBold, FullscreenExit, MicNone } from '@material-ui/icons';
import SnackMsg from '../common/SnackMsg';
import teal from '@material-ui/core/colors/teal';
import { serviceSyubetu } from '../Billing/BlCalcData';

// 通所給付明細
// 国定形式の代理受領通知明細も兼ねる

const useStyles = makeStyles({
  // 利用者負担額一覧用
  reportTeikyou:{
    width:'90%',
    maxWidth: 900,
    margin:'120px auto 0',
    '@media print':{
      width: '262mm',
      maxWidth: '262mm',
      margin:'15mm 0mm 10mm 20mm',
      margin: 0,
      breakAfter: 'always',
      '&:last':{
      breakAfter: 'auto',
      }
    },
    '& .vhcenter':{
      display:'flex',alignItems:'center',justifyContent:'center',
    },
    '& .outerLine' : {
      borderTop: '2px #333 solid',
      borderLeft: '2px #333 solid',
      // borderRight: '1px #333 solid', // 右線は引かない。内部セルに任せる
      borderBottom: '1px #333 solid',
      '& > div': {
        borderRight: '1px #333 solid',
        borderBottom: '1px #333 solid',
        textAlign: 'center',
        fontSize: '.8rem',
        padding: 4,
        minHeight: 28,
      },
      '& .date':{
        fontSize: '.75rem',
        padding: '4px 2px',
      },
      '& .bb' :{borderBottom: '2px #333 solid'}, // 下線太く
      '& .rb' :{borderRight: '2px #333 solid'}, // 右線太く
      '& .uDbl' : {borderTop: '1px #333 double'}, // 上二重線     
      '& .small' :{
        fontSize: '.7rem',
      },
      '& .xsmall' :{
        fontSize: '.5rem',
      },
    },
    '& .lineheigher': {lineHeight: '1.4rem'},
    '& .title':{
      display:'grid',
      gridTemplateColumns: '20% 60% 20%',
      fontSize: '1.2rem',
      textAlign: 'center',
      '& .date':{
        fontSize: '.8rem',
        textAlign: 'left',
      },
    },
    '& .headGrid': {
      display:'grid',
      gridTemplateColumns:'5fr repeat(10, 1fr) 7fr 10fr 5fr 1fr repeat(10, 1fr)',
      marginTop: 24,
      '& > div': {
        display:'flex',alignItems:'center',justifyContent:'center',
        lineHeight: '1.2rem',
      },
      '& .volume':{
        minHeight: 48,
      },
    },
    '& .mainGrid':{
      display:'grid',
      gridTemplateColumns:'3ch 3ch 3fr 2fr 3fr 3fr 1.5fr 1.5fr ' + 
      '4fr 4fr 4fr 3fr 7fr',
      marginTop: 24,
      '& > div': {
        display:'flex',alignItems:'center',justifyContent:'center',
        lineHeight: '1.2rem',
      },
      '& .comment': {fontSize: '.7rem', lineHeight: 1.05, textAlign:'left'}
    },
    '& .lastRow': {
      width:'20%',
      marginLeft: '80%',
      marginTop: 24,
      display:'grid',
      gridTemplateColumns: '1fr 1.5fr 1fr 1.5fr',
      '& > div': {
        display:'flex',alignItems:'center',justifyContent:'center',
        lineHeight: '1.2rem',
      },

    }
  },
});
// 受け取った文字を一文字ずつdivで括って出力する
export const StrToDivs = (props) => {
  let {
    str, length, strPadding, right, className, styleo,
    bb, rb, // 下線太く、右線太く
    cs, rs, // gridColumunStart gridRowStart
    re, // rowend 
  } = props;
  str = (str !== undefined)? str: '';
  strPadding = (strPadding)? strPadding: ' ';
  right = (right)? true: false;
  className = (className)? className: '';
  styleo = (styleo)? styleo: {};
  length = {length}? length: str.length;
  re = (re)? `span ${re}`: '';
  // 下線太くするクラス名追加
  className = (bb)? className + ' bb': className;
  if (right){
    str = strPadding.repeat(length) + str;
    str = str.slice(length * (-1));
  }
  else{
    str = str + strPadding.repeat(length);
    str = str.substr(0, length);
  }
  const rt = Array.from(str).map((e, i)=> {
    // 最終セルのみ右線太く
    const cls = ((i === (str.length - 1)) && rb)? className + ' rb': className;
    const st = {...styleo, };
    if (cs) st.gridColumn = cs + i;
    if (rs) st.gridRow = rs;
    if (re) st.gridRowEnd = re;
    return (
      <div className={cls} style={st} key={i}>{e}</div>
    )
  })
  return rt
}
// 2021-01-01フォーマットから和暦などの日付情報を取り出す
const str2gdex = (s) =>{
  return comMod.getDateEx(s.split('-')[0], s.split('-')[1], s.split('-')[2]);
}

const ReportTeikyouJissekiOne = (props) => {
  const classes = useStyles();
  const {UID, ...others} = props;
  const thisSch = useSelector(state=>state.schedule[UID]);
  const users = useSelector(state=>state.users);
  const thisUser = comMod.getUser(UID, users);
  const stdDate = useSelector(state=>state.stdDate);
  const gengou = str2gdex(stdDate).wr.l; // 元号
  const wry = str2gdex(stdDate).wr.y; // 和暦の年
  const month = str2gdex(stdDate).m;

  // 該当ユーザーのスケジュールオブジェクトから日付キーを取り出す。
  const days = Object.keys(thisSch).filter(e=>e.indexOf('D2') === 0);
  // daysの長さを揃える
  const filler = Array(31).fill('z-filler');
  days.push(...filler.slice(days.length));
  days.sort((a, b)=>(a > b ? 1 : -1)); // 一応ソートしておく

  // カウント用オブジェクトキー定義
  const cntKey = Object.keys(thisSch).filter(e=>e.indexOf('D2') === 0)
  // 送迎回数のカウント
  let sougeiCnt = 0;
  cntKey.map(e=>{
    if (!thisSch[e].absence){
      if (thisSch[e].transfer){
        if (thisSch[e].transfer[0])  sougeiCnt++;
        if (thisSch[e].transfer[1])  sougeiCnt++;
      }
    }
  });
  // 他加算カウント
  let kateiCnt = 0, houmonCnt = 0, iryouCnt = 0, soudanCnt = 0;
  cntKey.map(e=>{
    if (thisSch[e].dAddiction){
      if (thisSch[e].dAddiction.訪問支援特別加算 !== undefined)  houmonCnt++;
      if (thisSch[e].dAddiction.医療連携体制加算 !== undefined)  iryouCnt++;
      if (thisSch[e].dAddiction.家庭連携加算 !== undefined)  kateiCnt++;
      if (thisSch[e].dAddiction.事業所内相談支援加算 !== undefined)  soudanCnt++;
    }
  });

  // D2xxxMMDDのフォーマットから曜日文字列を返す
  const wdFromD = (s) =>{
    if (s.indexOf('D2') !== 0)  return '';
    const v = comMod.getDateEx(
      s.substr(1, 4), s.substr(5, 2), s.substr(7 ,2)
    );
    return (v.wd.jp);
  }
  
  const gp = (columnStart, columsSpan, rowStart, rowSpan) => ({
    gridColumn: `${columnStart} / span ${columsSpan}`,
    gridRow: `${rowStart} / span ${rowSpan}`,    
  });

  const Title = () => (<>
    <div className='title'>
      <div className='date'>{`${gengou}${wry}年${month}月分`}</div>
      <div>放課後等デイサービス提供実績記録票</div>
      <div></div>
    </div>
  </>)

  const HeadGrid = () => {
    // const hnoStObj = {re: 'span 2'}
    return(
      <div className='headGrid outerLine'>
        <div style={gp(1,1,1,2)}>受給者証<br></br>番号</div>
        <StrToDivs 
          str={thisUser.hno} 
          cs={2} rs={1} re={2} length={10}
        />
        <div style={gp(12,1,1,2)} className='small'>
          給付決定保護者氏名<br></br>(障害児氏名)
        </div>
        <div style={gp(13,1,1,2)}>
          {thisUser.pname}<br></br>({thisUser.name})
        </div>
        <div style={gp(14,2,1,1)}>事業所番号</div>
        <StrToDivs 
          str={thisUser.jino} cs={16} rs={1} length={10} rb
        />
        <div style={gp(1,1,3,1)} className='volume'>契約支給量</div>
        <div style={gp(1,13,3,1)}>{thisUser.volume}日</div>
        <div style={gp(14,1,2,2)}>事業者およびその事業所</div>
        <div style={gp(15,11,2,2)} className='rb'>{thisUser.bname}</div>
      </div>
    )
  }
  // mainGridの1行を返す
  const MainGridRow = (props) => {
    const d = props.d;
    // スケジュールが存在しないときはエラーにならないようにダミーのオブジェクトを作成
    const thisDay = thisSch[d] ? thisSch[d] : {transfer:['','']};
    const kesseki = thisDay.absence ? '欠席' : '';
    let sougeiPickup = thisDay.transfer[0] ? 1 : '';
    let sougeiSend = thisDay.transfer[1] ? 1 : '';
    const day = (d.indexOf('D2') === 0) ? parseInt(d.substr(7, 2)) : '';
    const keitai = thisDay.offSchool !== undefined? thisDay.offSchool + 1: '';
    // 欠席時の表示抑制
    const start = thisDay.absence? '' : thisDay.start;
    const end = thisDay.absence? '' : thisDay.end;
    sougeiPickup = thisDay.absence? '': sougeiPickup;
    sougeiSend = thisDay.absence? '': sougeiSend;

    return(<>
      <div className='date'>{day}</div> {/* 日付 */}
      <div>{wdFromD(d)}</div>  {/* 曜日 */}
      <div>{kesseki}</div>  {/* 提供状況 */}
      <div>{keitai}</div>  {/* 提供形態 */}
      <div>{start}</div>  {/* 開始時間 */}
      <div>{end}</div>  {/* 終了時間 */}
      <div>{sougeiSend}</div>  {/* 送迎往 */}
      <div>{sougeiSend}</div>  {/* 送迎復 */}
      <div>{thisDay.家庭連携加算}</div>  {/* 家庭連携加算 */}
      {/* <div>{thisDay.訪問支援特別加算}</div>  訪問支援特別加算 */}
      <div>{thisDay.事業所内相談支援加算}</div>  {/* 事業所内相談支援特別加算 */}
      <div>{thisDay.医療連携体制加算}</div>  {/* 医療連携体制加算 */}
      <div></div>  {/* 確認印 */}
      <div className='rb comment'>{thisDay.notice}</div>  {/* 備考 */}
    </>)
  }
  // フッター行
  const FooterRow = () => {
    return(<>
      <div className='uDbl' style={{gridColumnEnd:'span 6'}}>合計</div>
      <div className='uDbl' style={{gridColumnEnd:'span 2'}}>{sougeiCnt}回</div>
      <div className='uDbl' >{kateiCnt}回</div>
      {/* <div className='uDbl' >{houmonCnt}回</div> */}
      <div className='uDbl' >{soudanCnt}回</div>
      <div className='uDbl' >{iryouCnt}回</div>
      <div className='uDbl' ></div>
      <div className='uDbl rb' ></div>
    </>);

  }

  const MainGrid = () => {
    const dayNodes = days.map((e, i)=>(
      <MainGridRow d={e} key={i} />
    ));
    return(
      <div className='mainGrid outerLine'>
        <div style={gp(1,1,1,3)} className='bb'>日付</div>
        <div style={gp(2,1,1,3)} className='bb'>曜日</div>
        <div style={gp(3,9,1,1)} >サービス提供実績</div>
        <div style={gp(3,1,2,2)} className='small bb'>サービス<br></br>提供状況</div>
        <div style={gp(4,1,2,2)} className='bb'>提供<br></br>形態</div>
        <div style={gp(5,1,2,2)} className='bb'>開始時間</div>
        <div style={gp(6,1,2,2)} className='bb'>終了時間</div>
        <div style={gp(7,2,2,1)} className='small'>送迎加算</div>
        <div style={gp(7,1,3,1)} className='bb'>往</div>
        <div style={gp(8,1,3,1)} className='bb'>復</div>
        <div style={gp(9,1,2,1)} className='xsmall'>家庭連携加算</div>
        {/* <div style={gp(10,1,2,1)} className='xsmall'>訪問支援特別加算</div> */}
        <div style={gp(9,1,3,1)} className='bb'>時間数</div>
        {/* <div style={gp(10,1,3,1)} className='bb'>時間数</div> */}
        <div style={gp(10,1,2,2)} className='small bb'>
          事業所内<br></br>相談支援加算
        </div>
        <div style={gp(11,1,2,2)} className='small bb'>
          医療連携<br></br>体制加算
        </div>
        <div style={gp(12,1,1,3)} className='bb'>保護者等確認印</div>
        <div style={gp(13,1,1,3)} className='rb bb'>備考</div>
        {dayNodes}
        <FooterRow />
      </div>
    )
  }

  const LastRow = () => (
    <div className='lastRow outerLine'>
      <div>1</div><div>枚中</div>
      <div>1</div><div>枚目</div>
    </div>
  );

  return(<>
    <div className={classes.reportTeikyou}>
      <Title />
      <HeadGrid />
      <MainGrid />
      <LastRow />
    </div>
    <div className='pageBreak'></div>
  </>)
}

const ReportTeikyouJisseki = (props) =>{
  const {userList, ...others} = props;
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state=>state.users);
  // スケジュールに定義されているUIDリスト
  const uidsInSch = Object.keys(schedule).filter(e=>e.indexOf('UID') === 0);
  // userListでフィルタ
  const uidsFiltered = uidsInSch
    .filter(e=>userList.find(f=>'UID' + f.uid === e).checked === true);
  // ユーザーリストの並び順にソート
  uidsFiltered.sort((a, b)=>(
    comMod.getUser(a, users).sindex - comMod.getUser(b, users).sindex
  ));
  console.log(uidsFiltered);
  const pages = uidsFiltered.map((e, i)=>{
    return(
      <ReportTeikyouJissekiOne UID={e} key={i}/>
    )
  });
  return pages;
}

const TeikyouJisseki = (props) => {
  const {userList, preview, ...others} = props;
  const reportProps = {userList}
  // リストにないプレビューが送られてきたら何もしないで終了
  const nameList = ['提供実績管理票', ];
  if (nameList.indexOf(preview) < 0)  return null;
  if (preview === '提供実績管理票'){
    return (
      <ReportTeikyouJisseki {...reportProps} />
    )  
  }
  else {
    return (
      <div style={{margin:120}}>表示するデータが見つかりませんでした。</div>
    )
  }
}
export default TeikyouJisseki;