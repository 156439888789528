import React, { useEffect, useState } from 'react';
import * as mui from '../common/materialUi'
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux';
import { common } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { Block, PartyModeSharp } from '@material-ui/icons';
import SnackMsg from '../common/SnackMsg';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { LoadingSpinner } from '../common/commonParts';
import { fetchAll } from '../common/Login';


axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

const useStyles = makeStyles({
  newAccountForm :{
    '& .name' :{
      width: '12ch',
      margin: 8,
    },
    '& .mail' :{
      width: '25ch',
      margin: 8,
    },
    '& .buttonWrap':{
      textAlign: 'right',
    },
  },
  accoutNoticeRoot:{
    margin: '10vh auto 0',
    width:'50%',
    maxWidth: '580px',
    minWidth: '310px',
    '& > div': {
      padding:'8px 0',
    },
    '& .main':{
      fontSize: '1.2rem',
      '& .l':{fontSize: '1.3rem', color:teal[800]},
      '& .mail':{padding:'16px 0'},
      '& .small':{fontSize: '.7rem',},
    },
    '& .detail':{
      fontSize: '1.0rem',
      '& .l':{fontSize: '1.3rem', color:teal[800]},
    },
    '& .strong':{fontSize: '.7rem',color:red[800]},
    '& .error':{fontSize: '1.0rem',color:red[800]},
  },
  resetform :{
    margin: '16px auto',
    width:'50%',
    maxWidth: '580px',
    minWidth: '310px',
    '& .MuiTextField-root': {
      width: '100%',
      maxWidth: '30ch',
      padding: '8px 0'
    },
  },
  hidden :{
    display:'none',
  },
  buttonWrapper: {
    padding: '8px 0',
    width:'60%',
    maxWidth: '580px',
    minWidth: '310px',
    margin: '0 auto',
    textAlign: 'center',
    '& .MuiButtonBase-root':{
      margin: '0 4%',
    },
  },
  mainImg:{
    width:'30%',
    maxWidth: '380px',
    minWidth: '210px',
    margin: '16px auto 0',
  },
  acDiv:{
    marginBottom: 24,
    ' & h5': {
      backgroundColor: teal[50],
      padding: 8,
      paddingLeft: 16,
      paddingTop: 12,
      borderBottom: '1px solid ' + teal[200],
      color: teal[900],
      marginBottom: 16,
    },
    '& .conpWrap':{
      display: 'flex',
      width: '90%',
      margin: '0 auto',
      '& .text': {
        flex: 1,
        padding: 8,
      },
      '& .button': {
        width: 300,
      },
    },
    '& .accountChWrap': {
      maxWidth: 600,
      margin: '0 auto',
      '& .selectAccountRoot':{
        '& > a':{
          display: 'Block',
          padding: 8,
        },
      },
      '& .selectAccountRoot:nth-of-type(odd)':{
        backgroundColor: grey[100],
      },
    },
  },
  selectAccountRoot: {
    padding: 4
  },
});
// 既存のアカウントに対するパスワードリセットキーの付与
// パスワードリセットメールの送信リクエスト
const sendAccountKeyAndMail = async (params, setResponse) => {
  let response;
  try{
    // リセットキーをDBにセット
    params.a = 'resetAccountPass';
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    // メール送信のリクエスト
    params.a = 'sendAccountMail';
    params.mode = 'reset';
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    setResponse(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    setResponse(response);
  }
}

// 既存のアカウントに対するパスワードリセットキーの付与
// パスワードリセットメールの送信リクエスト
const sendNewAccountKeyAndMail = async (params, setResponse) => {
  let response;
  try{
    // リセットキーをDBにセット
    params.a = 'addAccount';
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result){
      // ここでのエラーはほぼメールアドレス重複
      response.duplicateMail = true;
      throw response;
    }
    // メール送信のリクエスト
    params.a = 'sendAccountMail';
    // 既存のアカウントかどうか
    const mode = (response.data.mailCount === 0)? 'new' : 'notNew';
    params.mode = mode;
    // 空白があるとget通信できないので空白を削除する
    Object.keys(params).map(e=>{
      params[e] = params[e].replace(/\s/g, '');
    });
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    setResponse(response);
  }
  catch(e){
    console.log(e);
    response.data.result = false;
    setResponse(response);
    
  }
}
// リセットキーによるアカウントリストを取得する
const getAccountByKey = async (resetkey, setResponse) => {
  let response;
  try{
    const params = {a: 'getAccountByRestkey', resetkey};
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    console.log('getAccountByKey running.')
    setResponse(response);
  }
  catch (e) {
    console.log(e);
    response.data.result = false;
    setResponse(response);
  }
}

const sendNewPassWd = async (mail, passwd, resetkey, setResponse) => {
  let response;
  try{
    const params = {a: 'updatePasswordsAll', passwd, mail, resetkey};
    response = await axios.post(Actions.endPoint, comMod.uPrms(params));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    console.log('getAccountByKey running.')
    setResponse(response);

  }
  catch(e){
    console.log(response);
    setResponse(response);
  }
}


// アカウントを新規追加する
// メールアドレスと氏名を入力する
// アカウントレコードの登録 キーの登録、メール送信のリクエストを行う
export const AddNewAccount = () =>{
  const classes = useStyles();
  const [response, setResponse] = useState({});
  // スナックバー用
  const [snackBar, setSnackBar] = useState({text: '', severity: ''});
  const saccount = useSelector(state=>state.account);
  // フォーム状態管理
  const [formStatus, setFormStatus] = useState({
    mail:{value:'', error: false, helperText: ''},
    lname:{value:'', error: false, helperText: ''},
    fname:{value:'', error: false, helperText: ''},
  });
  // スナックバーに渡す用 なんでこんなことに
  const setSnackMsg = (v) => {
    setSnackBar({...snackBar, text: v});
  }
  const setSnackSeverity = (v) => {
    setSnackBar({...snackBar, severity: v});
  }

  // バリデーション
  const handleBlur = (ev) => {
    const node = ev.currentTarget;
    if (node.required && !node.value){
      const t = {...formStatus};
      t[node.name].error = true;
      t[node.name].helperText = '入力必須項目です。';
      setFormStatus(t);
    }
    else if (node.name === 'mail'){
      if (!comMod.isMailAddress(node.value)){
        const t = {...formStatus};
        t[node.name].error = true;
        t[node.name].helperText = 'メールアドレスが正しくありません。';
        setFormStatus(t);
      }
      else{
        const t = {...formStatus};
        t[node.name].error = false;
        t[node.name].helperText = '';
        setFormStatus(t);  
      }
    }
    else {
      const t = {...formStatus};
      t[node.name].error = false;
      t[node.name].helperText = '';
      setFormStatus(t);
    }
  }
  // チェンジハンドラ
  const handeleChange = (ev) => {
    const node = ev.currentTarget;
    const t = {...formStatus};
    t[node.name].value = node.value;
    setFormStatus(t);
  }
  const clickHandler = () => {
    const mail = document.querySelector('#tynj44 [name=mail]').value;
    const lname = document.querySelector('#tynj44 [name=lname]').value;
    const fname = document.querySelector('#tynj44 [name=fname]').value;
    const resetkey = comMod.randomStr(30, 0);
    const hid = saccount.hid;
    const bid = saccount.bid;
    const hname = saccount.hname;
    const bname = saccount.bname;
    const prms = {hid, bid, mail, resetkey, lname, fname, hname, bname};
    sendNewAccountKeyAndMail(prms, setResponse);
    setFormStatus({
      mail:{value:'', error: false, helperText: ''},
      lname:{value:'', error: false, helperText: ''},
      fname:{value:'', error: false, helperText: ''},  
    });
  }
  
  useEffect(()=>{
    console.log('useEffect', response);
    if (response.status === 200){
      if (response.data.result){
        setSnackBar({text: '送信完了', severity: ''})
      }
      else if (response.duplicateMail){
        setSnackBar(
          {text: 'このメールアドレスは登録できません。', severity: 'warning'}
        );
      }
      else{
        setSnackBar({text: '送信エラー', severity: 'error'})
      }

    }
    else if (response.status){
      setSnackBar({text: '送信エラー', severity: 'error'})
    }
  }, [response]);
  return (<>
    {/* フォームの値にstateを使わないサンプル。エラーチェックとかのみstateを使ってる */}
    <form className={classes.newAccountForm} id = 'tynj44'>
      <div>
        <TextField className='mail' name='mail' label='mail' required 
          value={formStatus.mail.value}
          onBlur={(ev)=>{handleBlur(ev)}}
          onChange={(ev)=>handeleChange(ev)}
          error={formStatus.mail.error}
          helperText={formStatus.mail.helperText}
        />
      </div>
      <div>
        <TextField className="name" label='名字' required name='lname' 
          value={formStatus.lname.value}
          onBlur={(ev)=>{handleBlur(ev)}}
          onChange={(ev)=>handeleChange(ev)}
          error={formStatus.lname.error}
          helperText={formStatus.lname.helperText}
        />
        <TextField className="name" label='名前' required name='fname' 
          value={formStatus.fname.value}
          onBlur={(ev)=>{handleBlur(ev)}}
          onChange={(ev)=>handeleChange(ev)}
          error={formStatus.fname.error}
          helperText={formStatus.fname.helperText}
        />
      </div>
      <div className='buttonWrap'>
        <Button 
          variant='contained'
          color='primary'
          onClick={clickHandler}
        >
          送信
        </Button>
      </div>
    </form>
    <SnackMsg 
      msg={snackBar.text} severity={snackBar.severity} setmsg={setSnackMsg} 
    />
  </>)
}

// アカウントパスワードリセットのメールを送信する
export const AccountRestButton = () =>{
  const [response, setResponse] = useState({});
  // 送信メッセージ結果表示
  const [sendResult, setSendResult] = useState({result:undefined, text:''});
  // スナックバー用
  const [snackBar, setSnackBar] = useState({text: '', severity: ''});
  const setSnackMsg = (v) => {
    setSnackBar({...snackBar, text: v});
  }

  const saccount = useSelector(state=>state.account);
  const clickHandler = (e) =>{
    if (sendResult.result){
      setSnackBar({text: '送信済みです。', severity:''});
      return false;
    }
    const prms = {
      fname: saccount.fname,
      lname: saccount.lname,
      hname: saccount.hname,
      bname: saccount.bname,
      mail: saccount.mail,
      resetkey: comMod.randomStr(30, 0),
    }
    sendAccountKeyAndMail(prms, setResponse)
  }
  useEffect(()=>{
    console.log('useEffect', response);
    if (response.status === 200){
      if (response.data.result){
        setSendResult({result:true, text:'送信完了'})
        setSnackBar({text: '送信完了', severity: ''})
      }
      else{
        setSendResult({result:false, text:'送信エラー'})
        setSnackBar({text: '送信エラー', severity: 'error'})
      }
    }
    else if (response.status){
      setSendResult({result:false, text:'送信エラー'})
      setSnackBar({text: '送信エラー', severity: 'error'})
    }
  }, [response]);
  const buttonColor = (sendResult.result === undefined)? 'primary': 'default';
  return (<>
    <Button
      variant="contained"
      color={buttonColor}
      onClick={clickHandler}
    >
      {sendResult.result === undefined && 'パスワードリセット送信'}
      {sendResult.result !== undefined && sendResult.text}
    </Button>
    <SnackMsg 
      msg={snackBar.text} severity={snackBar.severity} setmsg={setSnackMsg}
    />
  </>)
}
// 強制的にログアウトしてリロードする
// リロードはuseDispatchにより自動で行われるはず
const ForthLogOut = () =>{
  const loginInfo = useSelector(state=>state.account);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLogin = Object.keys(loginInfo).length;
  useEffect(()=>{
    if (isLogin){
      setTimeout(() => {
        dispatch(Actions.clearAcount());
      }, 3000);  
    }
  }, []);
  if (isLogin){
    return (
      <div className={classes.accoutNoticeRoot}>
        <div className='main'>
          ログアウトしています。
        </div>
      </div>
    )
  }
  else{
    return null;
  }
}

export const ChangeAccount = () =>{
  const dispatch = useDispatch();
  const accountList = useSelector(state=>state.accountList);
  const handleClick = (ev) => {
    const index = ev.currentTarget.getAttribute('index');
    const bid = accountList[index].bid;
    const hid = accountList[index].hid;
    // アカウントだけ切り替わらないので単独でDispatch
    dispatch(Actions.setStore({account:accountList[index]}))    
    // ログイン動作のモジュールを実行
    fetchAll(hid, bid, dispatch);
  }
  const lst = accountList.map((e, i)=>{
    return(
      <div className='selectAccountRoot' key={i}>
        <a index={i} onClick={(ev)=>handleClick(ev)}>
          {e.shname} {e.sbname} {e.lname} {e.fname} さん
        </a>
      </div>
    );
  })
  return lst;
}

// アカウントリセットによりルーターからコールされる場面
// 単独の画面として提供されログイン状態に依存しない。
// 訂正ー>ログイン状態でも非ログイン状態でもどっちでも表示できる
export const ResetPassWd = () => {
  const classes = useStyles();
  const history = useHistory();
  const locationPrams = comMod.locationPrams();
  const loginInfo = useSelector(state=>state.account);
  const isLogin = Object.keys(loginInfo).length;

  // アカウント情報取得用state
  const [response, setReseponse] = useState(false);
  // 送信用レスポンス
  const [sendResponse, setSendResponse] = useState(false);
  // パスワード入力フォーム用state
  const [passwdForms, setPasswsForms] = useState({
    passwd1: {value:'', error: false, helperText: ''},
    passwd2: {value:'', error: false, helperText: ''},
  });
  // 表示非表示を切り替えるためのクラス設定
  const [dispCont, setDispCont] = useState({
    resetform: classes.resetform, 
    notice: classes.accoutNoticeRoot,
    buttonWrapper: classes.buttonWrapper,
    mainImg: classes.mainImg,
  });
  // ページロード時に発火 URLのキーからアカウント情報取得
  useEffect(()=>{
    getAccountByKey(locationPrams.detail.key, setReseponse);
  }, []);
  // 送信後に発火 あと、ロード直後にログイン情報を確認する
  useEffect(()=>{
    console.log('sendResponse', sendResponse);
    let t = {...dispCont};
    // フォーム、通知エリアの表示非表示を切り替える
    if (sendResponse || isLogin) {
      t = {...t,
        resetform: classes.hidden,
        notice: classes.hidden,
        buttonWrapper: classes.hidden,
      }
    }
    else if (isLogin){
      t = {...t,
        resetform: classes.hidden,
        notice: classes.hidden,
        buttonWrapper: classes.hidden,
        mainImg: classes.hidden,
      }
    }
    setDispCont(t);
    
  }, [sendResponse])

  // 通知エリア
  const AccountNotice = () =>{
    const NotFound = () => (
      <div className={dispCont.notice}>
        <div className='strong'>
          パスワード変更にアクセスできません。変更用のリンクが無効です。
          発行から24時間以内のアクセスが必要です。
        </div>
      </div>
    );
    
    if (!comMod.findDeepPath(response, ['data', 'result'])) return null;
    if (!response.data.dt.length) return <NotFound/>;
    const thisAccount = response.data.dt[0];  // アカウントリストの最初の要素を取得
    const accountCount = response.data.dt.length;
    // アカウントが一件の場合と複数件の場合でメッセージを変える
    const chMsg = (accountCount > 1)? '更新': '登録';
    return(
      <div className={dispCont.notice}>
        <div className='main'>
          <span className='l'>{thisAccount.lname} {thisAccount.fname} </span>
          さんのパスワードを{chMsg}します。
          <div className='mail'>
            <span className='small'>メールアドレス：</span>
            <span className='l'>{thisAccount.mail}</span>
          </div>
        </div>
        {/* 更新時のみ表示 */}
        {accountCount > 1 && <>
          <div className='detail'>
            同じメールアドレスで登録されている
            <span className='l'>{response.data.dt.length}</span>
            件の事業所のアカントが変更になります。
          </div>
          <div className='detail'>
            自動ログインの情報も消去されますのでご注意下さい。
          </div>
        </>}
        <div className='strong'>
          他サイトでご利用になっているパスワードの流用はお勧めしません。
          パスワード流出の原因になります。
        </div>
      </div>
    );
  }
  const handleChange = (ev) => {
    const node = ev.currentTarget;
    const t = {...passwdForms};
    t[node.name].value = node.value;
    if (node.name === 'passwd1'){
      const rt = comMod.chkPasword(node.value);
      t.passwd1.error = (rt.result)? false: true;
      t.passwd1.helperText = rt.msg;
      t.passwd2.value = ''; // 確認用パスワード消去
    }
    // 入力相違チェック
    if (node.name === 'passwd2' && node.value !== t.passwd1.value){
      t.passwd2.error = true;
      t.passwd2.helperText = '確認用パスワードが違います。';
    }
    // 入力相違エラー解除
    if (node.name === 'passwd2' && node.value === t.passwd1.value){
      t.passwd2.error = false;
      t.passwd2.helperText = 'パスワード確認OKです。';    
    }
    setPasswsForms(t);

  }
  const handleBlur = (ev) => {
    const node = ev.currentTarget;
    const t = {...passwdForms}
    if (!node.value && node.required){
      t[node.name].helperText = '入力必須項目です。';
      t[node.name].error = true;
    }
    // 入力必須のエラーのみ解除。パスワードチェックでエラーにすることもあるので
    if (node.value && t[node.name].helperText === '入力必須項目です。'){
      t[node.name].helperText = '';
      t[node.name].error = false;
    }
    setPasswsForms(t);
  }
  const handleSubmit = () => {
    console.log('submit!');
    const mail = response.data.dt[0].mail;
    const passwd = passwdForms.passwd1.value;
    const resetkey = locationPrams.detail.key;
    // ログインしていたらログアウトする
    sendNewPassWd(mail, passwd, resetkey,setSendResponse); 
  }
  // ルーターのヒストリーを使ってapplicationのルートに飛ぶ
  // ログインしていない前提なのでログイン画面に飛ぶはず
  const handleGotoLogin = () =>{
    history.push('/');
  }
  const handleReload = () => {
    faWindows.location.reload();
  }

  // 送信後の画面表示
  const AfterSubmit = () => {
    const classes = useStyles();
    const ButtonReload = () => (
      <Button
        variant='contained'
        color='secondary'
        onClick={handleReload}
      >
        再読み込み
      </Button>
    )
    const ButtonGotoLogin = () => (
      <Button
        variant='contained'
        color='primary'
        onClick={handleGotoLogin}
      >
        ログイン画面へ
      </Button>

    )
    if (!sendResponse) return null;
    if (sendResponse.status !== 200){
      return(<>
        <div className={classes.accoutNoticeRoot}>
          <div className='error'>
            送信エラーが発生しました。
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonReload />
          <ButtonGotoLogin/>
        </div>
      </>)
    }
    const resDt = sendResponse.data;
    return (
      <div>
        {resDt.result === false &&
          <div>
            <div className={classes.accoutNoticeRoot}>
              <div className='error'>
                更新エラーが発生しました。
              </div>
  1          </div>
            <div className={classes.buttonWrapper}>
              <ButtonReload />
              <ButtonGotoLogin/>
            </div>
          </div>
        }
        {(resDt.result === true && resDt.affected_rows > 0) &&
          <div className={classes.accoutNoticeRoot}>
            <div className='main'>
              {resDt.affected_rows}件のパスワードを変更しました。
            </div>
            <div className={classes.buttonWrapper}>
              <ButtonGotoLogin/>
            </div>


          </div>
        }
        {(resDt.result === true && resDt.affected_rows === 0) &&
          <div className={classes.accoutNoticeRoot}>
            <div className='error'>
              更新データが見つかりません。
            </div>
            <div className='detail'>
              変更前と変更後のパスワードが一緒だった、更新期限を過ぎてしまった、などの原因が考えられませす。
            </div>
            <div className={classes.buttonWrapper}>
              <ButtonReload />
              <ButtonGotoLogin/>
            </div>


          </div>
        }
      </div>
    )
  }
  
  // フォームにエラーがないこと、確認用パスワードが入力されていることで
  // サブミットボタンのenableとする
  const submitButtonDisable = (
    passwdForms.passwd1.error || passwdForms.passwd2.error
    || !passwdForms.passwd2.value
  )
  
  return(<>
    <div className={dispCont.mainImg}>
      <img src='../../img/logob.teal.svg'></img>
    </div>
    <ForthLogOut />
    <AccountNotice />
    <form className={dispCont.resetform} id='rrty55'>
      <TextField
        name='passwd1'
        required type="password" autoComplete='off'
        label='パスワード'
        value={passwdForms.passwd1.value}
        onChange={ev=>handleChange(ev)}
        onBlur={ev=>handleBlur(ev)}
        error={passwdForms.passwd1.error}
        helperText={passwdForms.passwd1.helperText}
      />
      <TextField
        name='passwd2'
        required type="password" autoComplete='off'
        label='パスワード確認用'
        value={passwdForms.passwd2.value}
        onChange={ev=>handleChange(ev)}
        onBlur={ev=>handleBlur(ev)}
        error={passwdForms.passwd2.error}
        helperText={passwdForms.passwd2.helperText}
      />

    </form>
    <div className={dispCont.buttonWrapper}>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        disabled={submitButtonDisable}
      >
        パスワード更新
      </Button>
    </div>
    <AfterSubmit />
  </>)
}

const AccountMain =()=>{
  const classes = useStyles();
  const saccount = useSelector(state=>state.account);
  return (
    <div className='AppPage account'>
      <div className={classes.acDiv}>
        <h5>
          パスワードリセット
        </h5>
        <div className='conpWrap'>
          <div className='button'>
            <AccountRestButton />
          </div>
          <div className='text'>
            {saccount.lname} {saccount.fname} さんのパスワードをリセットします。
            ボタンを押すとリセット用のメールが送信されます。
          </div>
        </div>
      </div>
      <div className={classes.acDiv}>
        <h5>アカウント追加</h5>
        <div className='conpWrap'>
          <div className='form'>
            <AddNewAccount />
          </div>
          <div className='text'>
            アカウントを新規追加するためのメールを送信します。
          </div>
        </div>

      </div>
      <div className={classes.acDiv}>
        <h5>アカウント切替</h5>
        <div className='accountChWrap'>
          <ChangeAccount />
        </div>
      </div>
    </div>
  )

}

const Account = () => {
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  if (loadingStatus.loaded){
    return (<AccountMain />)
  }
  else if (loadingStatus.error){
    return (<div>error occured.</div>)
  }
  else{
    return <LoadingSpinner/>
  }

}

export default Account;
