// コンポーネントにしない
// 計算用のFunctionを提供する
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { common } from '@material-ui/core/colors';
import { LoadingSpinner } from '../common/commonParts';
import {
  houdySirvice, houdayKasan, chiikiKubun, unitPrice,
  serviceSyubetu, ketteiScode,
  SOUGEY_SVC_CODE,
  KATEI_SVC_CODE,
  HOUMON_SVC_CODE,
  IREN_SVC_CODE,
} from './BlCalcData';

const ptn = /^D[0-9]+[0-9]+/; // D20xxmmddを検索するためのパターン

// サービスの基本アイテム
// 共生型サービスや条件デイなどはあとから追加していく
const serviceNameBase = [
  { wd: '放デイ１', ku: '区分１の１', min: 0, max: 10, hd: '放デイ４' },
  { wd: '放デイ２', ku: '区分１の１', min: 11, max: 20, hd: '放デイ５' },
  { wd: '放デイ３', ku: '区分１の１', min: 21, max: 999, hd: '放デイ６' },
  { wd: '放デイ２１', ku: '区分１の２', min: 0, max: 10, hd: '放デイ４' },
  { wd: '放デイ２２', ku: '区分１の２', min: 11, max: 20, hd: '放デイ５' },
  { wd: '放デイ２３', ku: '区分１の２', min: 21, max: 999, hd: '放デイ６' },
  { wd: '放デイ２４', ku: '区分２の１', min: 0, max: 10, hd: '放デイ３０' },
  { wd: '放デイ２５', ku: '区分２の１', min: 11, max: 20, hd: '放デイ３１' },
  { wd: '放デイ２６', ku: '区分２の１', min: 21, max: 999, hd: '放デイ３２' },
  { wd: '放デイ２７', ku: '区分２の２', min: 0, max: 10, hd: '放デイ３０' },
  { wd: '放デイ２８', ku: '区分２の２', min: 11, max: 20, hd: '放デイ３１' },
  { wd: '放デイ２９', ku: '区分２の２', min: 21, max: 999, hd: '放デイ３２' },
];

// csv作成用 定義する文字列や置換用シンボル
const REC_NO = 'REC_NO';
const REC_CNT = 'REC_CNT';
const JI_NO = 'JI_NO';
const H_NO = 'H_NO'; // 被保険者番号
const TOTAL_AMOUNT = 'TOTAL_AMOUNT';  // 費用合計
const TOTAL_COUNT = 'TOTAL_COUNT'     // 件数合計
const TOTAL_BILLED = 'TOTAL_BILLED';  // 国保連請求
const TOTAL_USER_BILLED = 'TOTAL_USER_BILLED';   // 利用者請求
const TOKUBETSU_TAISAKU = 0;   // K112_1 特別対策費
const TOTAL_TANNI = 'TOTAL_TANNI' // 単位数合計
const SRVC_AMOUNT = 'SRVC_AMOUNT';  // サービス毎費用合計
const SRVC_COUNT_TOTAL = 'SRVC_COUNT_TOTAL'     // サービス毎件数合計
const SRVC_BILLED = 'SRVC_BILLED';  // サービス毎国保連請求
const SRVC_USER_BILLED = 'SRVC_USER_BILLED';   // サービス毎利用者請求
const SRVC_TANNI_TOTAL = 'SRVC_TANNI_TOTAL' // サービス毎単位数合計
const SCITY_NO = 'SCITY_NO' // 市区町村番号
const START_DATE = 'START_DATE' // サービス開始日
const END_DATE = 'END_DATE' // サービス終了日
const S_SYUBETSU = 'S_SYUBETSU'; // サービス種別 放デイ63 自発61
const CNT_USED = 'CNT_USED' // ユーザーごとの利用回数
const SRVC_CODE = 'SRVC_CODE'; // サービスコード
const SRVC_TANNI = 'SRVC_TANNI'; // サービス毎の単位数
const SRVC_COUNT = 'SRVC_COUNT'; // サービス提供回数
const SRVC_SANTEI = 'SRVC_SANTEI'; // サービス算定額
// 地域コード
// const CHIKI_CODE = chiikiKubun[com.addiction[service].地域区分];
const CHIKI_CODE = 'CHIKI_CODE';
const JOUGEN = 'JOUGEN' // 上限額
const JOUGEN_JI = 'JOUGEN_JI' // 上限管理事業所
const JOUGEN_RES = 'JOUGEN_RES' // 上限管理結果額
const JOUGEN_GETSU_TYOUSEI = 'JOUGEN_GETSU_TYOUSEI'; // 上限管理結果額
const JOUGEN_KETTEI = 'JOUGEN_KETTEI'; // 上限決定額
const JOUGEN_TYOUSEIGO = 'JOUGEN_TYOUSEIGO'; // 上限調整後金額
const JOUGEN_KEKKA = 'JOUGEN_KEKKA' // 上限管理結果のフラグ
const USER_TANNI = 'USER_TANNI' // ユーザーごとの単位数
const NAME = 'NAME' // 利用者の名前半角カナ
const PNAME = 'PNAME' // 保護者の名前半角カナ
const SYUUKEI_BUNRUI = 1 // 集計分類番号 基本1だが重心で2になることも
const GETSUGAKU_TYOUSEI = 'GETSU_TY'; // 上限月額調整額 一割と上限を比較
 // 調整後利用者負担。福祉ソフトでは未設定 
const TYOUSEIGO_USER_BILLED = 'TYOUSEIGO_USER_BILLED';
const JOUGEN_USER_BILLED = 'J_USER_BIL'; // 上限額管理後利用者負担額を設定
const KETTEI_USER_BILLED = 'K_USER_BILLED'// 決定利用者負担額
const KETTEI_TOTAL_BILLED = 'K_TOTAL_BILLED' // 決定給付請求額
const KETTEI_SRVC_CODE = 'KTTEI_SRVC_CODE' // 決定サービスコード
const KEIYAKU_VOL = 'KEIYAKU_VOL' // 契約量*100
const KEIYAKU_DATE = 'KEIYAKU_DATE' // 契約日
const KEIYAKU_END = 'KEIYAKU_END' // 契約終了日
const KINYUU_BANGOU = 'KINYUU_BANGOU' // 事業者記入欄番号 
const ICHIWARI1 = 'ICHIWARI1' // 一割相当額 よくわからない
const ICHIWARI2 = 'ICHIWARI2' // 一割相当額 よくわからない
const KYUUFU_TANKA = 'KYUUFU_TANKA'　// 給付単価。サービス種別と級地で決まる 
const THIS_MONTH = 'THIS_MONTH';
const HEAD_SYUBETU = 'HEAD_SYUBETU'; // ヘッダレコードのレコード種別
// 上限管理ファイルで他事業所の請求額を含めて合算したもの
const ALL_AMOUNT = 'ALL_AMOUNT';
// 上限管理ファイルで他事業所の調整額=上限値と一割で金額が低い方を合計
const ALL_TYOUSEI = 'ALL_TYOUSEI';
// 上限管理ファイルで他事業所の利用者請求額を合算したもの
const ALL_JOUGEN = 'ALL_JOUGEN';
const SAKUSEI_KU = 'SAKUSEI_KU'; // 作成区分
const LINE_NO = 'LINE_NO'; // 項番
const KYO_JI = 'KYO_JI';

// 欠席対応加算確認用
const KESSEKI_SVC_CODE = ['635496', '635495',];
// 事業所内相談支援
const SOUDAN_SVC_CODE = ['636805',];


// レコードのテンプレート
const headRec = [ // ヘッダレコード
  1, REC_NO, 0, REC_CNT, HEAD_SYUBETU, 0, JI_NO, 0, 1, THIS_MONTH, 0
];

const jgKihonK411_1 = [ // 上限管理基本
  2, REC_NO, 'K411', 1, THIS_MONTH, SAKUSEI_KU, SCITY_NO, 
  JI_NO, H_NO, PNAME, NAME,
  JOUGEN, JOUGEN_KEKKA, ALL_AMOUNT, ALL_TYOUSEI, ALL_JOUGEN,
]
const jgMeisaiK411_2 = [ // 上限管理明細
  2, REC_NO, 'K411', 2, THIS_MONTH, SCITY_NO, JI_NO, H_NO, LINE_NO,
  KYO_JI, TOTAL_AMOUNT, JOUGEN_TYOUSEIGO, JOUGEN_KETTEI
]

const kihonK112_1 = [ // K112基本 市区町村ごと合計行
  2, REC_NO, 'K112', 1, THIS_MONTH, SCITY_NO, JI_NO, 
  TOTAL_BILLED, TOTAL_COUNT, TOTAL_TANNI, 
  TOTAL_AMOUNT, TOTAL_BILLED, 0, TOTAL_USER_BILLED, 0, 0, 0, 0,
  TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, TOKUBETSU_TAISAKU,
  TOTAL_USER_BILLED, 0,
];
const meisaiK112_2 = [ // K112明細 サービス種別毎の明細。自発だと別レコード
  2, REC_NO, 'K112', 2, THIS_MONTH, SCITY_NO, JI_NO, 1, S_SYUBETSU,
  SRVC_COUNT_TOTAL, SRVC_TANNI_TOTAL, SRVC_AMOUNT, SRVC_BILLED, 0,
  SRVC_USER_BILLED, 0,
];
const kihonK122_1 = [ // k122基本レコード
  2, REC_NO, 'K122', 1, THIS_MONTH, SCITY_NO, JI_NO, H_NO, '',
  PNAME, NAME,
  CHIKI_CODE, '', JOUGEN, '', '', JOUGEN_JI, JOUGEN_KEKKA, JOUGEN_RES,
  '', '', USER_TANNI, TOTAL_AMOUNT, 
  JOUGEN_GETSU_TYOUSEI, // 上限月額調整額
  '', '', '', 
  JOUGEN_TYOUSEIGO, // 上限調整後金額 
  JOUGEN_KETTEI, // 上限決定後
  TOTAL_BILLED
]
const nissuuK122_2 = [ // k122日数情報レコード
  2, REC_NO, 'K122', 2, THIS_MONTH, SCITY_NO, JI_NO, H_NO, S_SYUBETSU,
  START_DATE, END_DATE, CNT_USED,
];

const serviceMeisaiK122_3 = [ // k122 明細レコード サービスコードを記載
  2, REC_NO, 'K122', 3, THIS_MONTH, SCITY_NO, JI_NO, H_NO, SRVC_CODE,
  SRVC_TANNI, SRVC_COUNT, SRVC_SANTEI
]

const syuukeiK122_4 = [
  2, REC_NO, 'K122', 4, THIS_MONTH, SCITY_NO, JI_NO, H_NO, S_SYUBETSU,
  SYUUKEI_BUNRUI,
  SRVC_COUNT, // サービス回数 
  USER_TANNI, // 単位数
  KYUUFU_TANKA,　// 給付単価。サービス種別と級地で決まる 
  0, // 給付率 0固定
  TOTAL_BILLED, // 単位数✕給付単価
  ICHIWARI1, // 一割相当額
  ICHIWARI2, // 一割相当額、都道府県が絡んだり自発で多子だと変わる？
  GETSUGAKU_TYOUSEI, // 上限月額調整額 一割と上限を比較
  '', '', // 未設定項目＊２
  TYOUSEIGO_USER_BILLED, // 調整後利用者負担。
  JOUGEN_USER_BILLED, // 上限額管理後利用者負担額を設定
  KETTEI_USER_BILLED,// 決定利用者負担額
  KETTEI_TOTAL_BILLED, // 決定給付請求額
]

const keiyakuK122_5 = [ // 契約情報レコード
  2, REC_NO, 'K122', 5, THIS_MONTH, SCITY_NO, JI_NO, H_NO,
  KETTEI_SRVC_CODE, // 決定サービスコード
  KEIYAKU_VOL, // 契約量*100
  KEIYAKU_DATE, // 契約日
  KEIYAKU_END, // 契約終了日
  KINYUU_BANGOU, // 事業者記入欄番号 
];
const endRecord = [
  3, REC_NO
]


// 加算などのオブジェクトからサービスコードのキーになる文字列を配列にして返す
// 配列に格納される文字
// 開減１,開減２,拘減,人欠,人欠２,責欠,責欠２,定超,評減,未計画,未計画２,有資格X
const getKeyStrOfServiceItems = (adc, offSchool) => {
  const rt = [];
  const targetKey = [
    '開所時間減算',
    '身体拘束廃止未実施減算',
    'サービス提供職員欠如減算',
    '児童発達支援管理責任者欠如減算',
    '定員超過利用減算',
    '自己評価結果等未公表減算',
    '通所支援計画未作成減算',
    '児童指導員配置加算',
  ];
  const pi = (v) => {
    if (isNaN(v)) return "";
    else return parseInt(v);
  }
  Object.keys(adc).map(e => {
    if (targetKey.indexOf(e) === -1) return false;
    if (e === '開所時間減算' && adc[e] === '4時間未満' && offSchool)
      rt.push('開減１・');
    // 開所時間減算は休日利用だけ該当
    if (e === '開所時間減算' && adc[e] === '4時間以上6時間未満' && offSchool)
      rt.push('開減２・');
    if (e === '身体拘束廃止未実施減算' && pi(adc[e]) === 1)
      rt.push('拘減・');
    if (e === 'サービス提供職員欠如減算' && adc[e] === '二ヶ月まで')
      rt.push('人欠・');
    if (e === 'サービス提供職員欠如減算' && adc[e] === '三ヶ月以上')
      rt.push('人欠２・');
    if (e === '児童発達支援管理責任者欠如減算' && adc[e] === '五ヶ月未満')
      rt.push('責欠・');
    if (e === '児童発達支援管理責任者欠如減算' && adc[e] === '五ヶ月以上')
      rt.push('責欠２・');
    if (e === '定員超過利用減算' && pi(adc[e]) === 1)
      rt.push('定超・');
    if (e === '自己評価結果等未公表減算' && pi(adc[e]) === 1)
      rt.push('評減・');
    if (e === '通所支援計画未作成減算' && adc[e] === '3ヶ月未満')
      rt.push('未計画・');
    if (e === '通所支援計画未作成減算' && adc[e] === '3ヶ月以上')
      rt.push('未計画２・');
    if (e === '児童指導員配置加算' && pi(adc[e]) === 1)
      rt.push('有資格');
  });
  return rt;
}

// 配列から特定の文字列を検索して置換する
// const elmRep = (a, str, value) => {
//   const ndx = a.indexOf(str);
//   if (ndx === -1) return false;
//   a[ndx] = value;
//   return true;
// }
// 要素が複数あったときにも対応
const elmRep = (a, str, value) => {
  while (true){
    let ndx = a.indexOf(str);
    if (ndx === -1) return false;
    a[ndx] = value;
  }
}

// 配列の最後の配列から特定の文字を検索して置換する
const erl = (a, str, value) =>{
  elmRep(a[a.length - 1], str, value);
}

const getKasanItem = (addiction, offSchool, kubun, teiin, absence) => {
  const kasanNames = getKasanUniqName();
  const rt = [];
  Object.keys(addiction).map(e => {
    // 欠席指定がある場合、欠席対応加算以外は無視する
  if (absence && e.indexOf('欠席時対応') === -1)  return false;
    // 対象外の値は読み飛ばし
    if (kasanNames.indexOf(e) === -1) return false;
    const thisItem = getKasanOneItem(e, addiction, offSchool, kubun, teiin);
    if (thisItem.err) console.log(thisItem);
    rt.push(thisItem);
  });
  return rt;
}


// 加算の内容とサービス名のベース、休日かどうかなどの情報から
// サービスコードを含むオブジェクトを取得して返す
// 欠席ならnullを返す
const getBaseItem = (nameBase, addiction, offSchool, absence) => {
  if (absence)  return null // 欠席の処理
  // 休日か平日かで検索用サービス名称ベースを変える
  const nbStr = (!offSchool) ? nameBase.wd : nameBase.hd;
  // まずはベースになるサービス名で絞り込み
  let thisServece = houdySirvice.filter(e => e.c.indexOf(nbStr) === 0);
  // 加算オブジェクトに対応した文字配列を取得する
  const keyStrs = getKeyStrOfServiceItems(addiction, offSchool);
  // 全てのキーオブジェクトに対して絞り込みを行う
  keyStrs.map(e => {
    thisServece = thisServece.filter(f => f.c.indexOf(e) > -1);
  });
  // 絞り込みを行った結果、thisServece[x].cの中のデリミタ"・"が
  //　一番少ないやつがビンゴ
  let pos = 0; let minLen = 100;
  thisServece.map((e, i) => {
    const cnt = (e.c.match(/・/g) || []).length;
    pos = (minLen > cnt) ? i : pos;
    minLen = (minLen > cnt) ? cnt : minLen;
  });
  return thisServece[pos];
}
// 加算項目の送迎を求める
const getTrasferItem = (transfer, ku, absence) => {
  const rt = [];
  // オブジェクトがなかったり配列が空だったりしたら空白の配列を返す
  if (!transfer) return rt;
  if (!transfer.length) return rt;
  // 欠席のときも空配列
  if (absence) return rt;
  const transferItemN = houdayKasan.find(e => e.s === '636240');
  const transferItemJ = houdayKasan.find(e => e.s === '636241');
  // transferは配列で中身があれば送迎有りと判断。両方送迎なら二回アイテムを
  // プッシュする
  if (ku !== "重心") {
    if (transfer[0]) rt.push(transferItemN);
    if (transfer[1]) rt.push(transferItemN);
  }
  else {
    if (transfer[0]) rt.push(transferItemJ);
    if (transfer[1]) rt.push(transferItemJ);
  }
  return rt;
}

// 明細作成用のScheduleオブジェクトを市区町村順でソートして配列化
const objSortK122 = (src) => {
  const scitySet = new Set(); // Uniqueな市区町村番号を作成する
  Object.keys(src).map(e => {
    scitySet.add(src[e].scityNo);
  });
  // セットを配列化してソート
  const scityArray = Array.from(scitySet);
  scityArray.sort((a, b) => (a - b));
  // ソートされた配列の順に新しいオブジェクトに値をCOPY
  const rt = [];
  scityArray.map(e => {
    Object.keys(src).map(f => {
      if (src[f].scityNo === e) {
        // 利用件数ゼロは削除する
        if (!src[f].countOfUse) return false;
        src[f].UID = f; // キーだったuidをオブジェクトの中に入れる
        rt.push(src[f]);
      }
    })
  });
  return rt;
}

// 加算項目を特定するために加算オブジェクトのUniqueなキー値を得る
// houdayKasanから得られるnameを一致させてある
// そのUnique値を取得する
const getKasanUniqName = () => {
  const kasanSet = new Set();
  houdayKasan.map(e => {
    kasanSet.add(e.name);
  });
  return Array.from(kasanSet);
};

const getKasanOneItem = (key, addiction, offSchool, kubun, teiin) => {
  const value = addiction[key];
  const errObj = { err: 'item found err.', key, value };
  // まずはキーとバリューで絞り込み
  let kasan = houdayKasan.filter(e => (e.name === key && e.value === value));
  // これだけで特定できたらそのままリターン
  if (kasan.length === 1) return kasan[0];
  // この時点で見つからなければエラー
  if (!kasan.length) return errObj;
  // 平日は0なのでこの式で検索文字列を得る
  const schoolOffStr = (offSchool) ? '休日・' : '平日・';
  // 区分２の１ は休日なら区分２ 重心はそのまま
  const kubunStr = ((offSchool) ? kubun.substr(0, 3) : kubun) + '・';
  let teiinStr;
  if (kubun !== "重心") {
    if (teiin <= 10) teiinStr = "10人以下・";
    else if (teiin <= 20) teiinStr = "11から20・";
    else teiinStr = '21人以上・';
  }
  else {
    if (teiin > 10) teiinStr = "11以上・";
    else teiinStr = teiin + '・';
  }
  // 絞り込まれた配列の中で一番長いオプションを調べる
  let longestOpt = 0;
  kasan.map(e => {
    longestOpt = (longestOpt < (e.opt.match(/・/g) || []).length)
      ? (e.opt.match(/・/g) || []).length : longestOpt;
  });

  // 最長オプションが3以下の場合は一点ずつ調べて件数が一件になったらリターン
  // それでも2件以上が見つかる場合は今のところエラーを返す
  // オプションが2件の時がある場合は再度、このルーチンを見直す
  if (longestOpt < 3) {
    let shortOpt;
    shortOpt = kasan.filter(e => e.opt.indexOf(schoolOffStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    shortOpt = kasan.filter(e => e.opt.indexOf(kubunStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    shortOpt = kasan.filter(e => e.opt.indexOf(teiinStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    // それでも見つからないときはoptが空白のものを返す
    shortOpt = kasan.filter(e => !e.opt);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
  }
  // オプションで絞り込み
  kasan = kasan.filter(e => (
    e.opt.indexOf(schoolOffStr) > -1 &&
    e.opt.indexOf(kubunStr) > -1 &&
    e.opt.indexOf(teiinStr) > -1
  ));
  // 一件以外の検索結果はエラーとする
  kasan = (kasan.length !== 1) ? errObj : kasan[0];
  return (kasan);
}

// 計算用オブジェクトのitemsをユーザーごとに集計する
const totlizeItems = (tmpSch, masterRec)=>{
  Object.keys(tmpSch).map(e=>{
    const eachItem = [];  // itemを全部格納する配列
    const scodeSet = new Set();
    // 利用回数が無いときはスキップ
    if (!tmpSch[e].countOfUse) return false;
    // 日付のオブジェクト
    Object.keys(tmpSch[e]).map(f=>{
      if (!f.match(ptn))  return false; //日付オブジェクトではない
      const o = tmpSch[e][f];
      o.items.map(g=>{
        // キャンセルかどうか確認
        // キャンセルだったら特定のコード意外はスキップ
        if (o.absence && KESSEKI_SVC_CODE.indexOf(g.s) === -1)  return false;
        scodeSet.add(g.s);  // サービスコードを追加
        eachItem.push(g);
      });
    });
    // 配列化してソート
    const scodeArray = Array.from(scodeSet).sort((a, b) => (a - b));
    const itemTotal = scodeArray.map(f=>{
      const thisItem = eachItem.find(g=>g.s === f); // 該当アイテムを一個取得
      // 処遇改善、一回のみ場合は回数１で配列に追加
      if (thisItem.syoguu || thisItem.limit === 'once'){ 
        return ({...thisItem, count: 1});
      }
      else{
        // それ以外はitemは数を数える
        const count = eachItem.filter(g => g.s === f).length;
        return ({...thisItem, count})
      }
    });
    // サービスごとの単位数と合計を求める
    let tanniTotal = 0;
    itemTotal.map(f=>{
      if (!f.syoguu){
        f.tanniNum = f.v * f.count;
        tanniTotal += f.v * f.count;
      };
    });
    // ユーザーごとの単位数の合計を算定し処遇改善加算を求める
    itemTotal.map(f => {
      if (f.syoguu) {
        // 処遇改善加算の値が数値だったらそのまま乗算
        if (!isNaN(f.v)){
          f.tanniNum = Math.round(tanniTotal * (f.v / 100));
        }
        // 値が文字列なら乗数を二段階に分ける
        // 放デイ処遇改善加算4, 5
        else {
          const [v1, v2] = f.v.split('*');
          let t1 = Math.round(tanniTotal * (parseFloat(v1) / 100));
          f.tanniNum = Math.round(t1 * parseFloat(v2));
        }
        f.vbk = f.v;  // f.vを退避
        f.v = f.tanniNum; // 単位数をここに代入 縦計算整えるため
      };
    });
    // 単位合計を再計算
    // 処遇改善加算が複数あるため別ループにする
    tanniTotal = 0;
    itemTotal.map(f =>{
      tanniTotal += f.tanniNum;
    })
    // 単位数に単価を乗算して四捨五入。サービスごと利用者ごとの請求額を求める
    let userSanteiTotal = 0;
    // console.log(e.UID);
    itemTotal.map(f =>{
      // f.santei = Math.round(masterRec.unitPrice * f.tanniNum);
      // userSanteiTotal += f.santei;
      // console.log(
      //   f.c + ',' + f.v + '*' + f.count + '*' + masterRec.unitPrice
      // );
    });
    tmpSch[e].userSanteiTotal = 
      Math.floor(masterRec.unitPrice * tanniTotal);;
    tmpSch[e].itemTotal = itemTotal;
    tmpSch[e].tanniTotal = tanniTotal;
  });
 
}
// 上限管理するよ！！！
const makeUpperLimit = (dt) =>{
  // 管理事業所の場合の調整！
  const manegeThis = (udt) =>{
    if (!udt.協力事業所.length){
      return false;
    }
    const haibun = udt.協力事業所[0].haibun;
    const jAry = udt.協力事業所;
    if (haibun === '最多利用最大'){
      // 先頭に自社を入れる。
      jAry.unshift({ name: 'thisOffice', amount: udt.userSanteiTotal });
      // 協力事業所を利用学が大きい順にソート
      jAry.sort((a, b) => (parseInt(b.amount) - parseInt(a.amount)));
    }
    else { // それ以外の場合
      // 協力事業所を利用額が大きい順にソート
      jAry.sort((a, b) => (parseInt(b.amount) - parseInt(a.amount)));
      // 先頭に自社を入れる。
      jAry.unshift({ name: 'thisOffice', amount: udt.userSanteiTotal });
    }
    let lessPrice = parseInt(udt.priceLimit);
    // 先頭から自己負担額を割り振る
    jAry.map(e=>{
      e.amount = parseInt(e.amount);
      const ichiwari = Math.floor(e.amount * 0.1);
      const tyouseiGaku = (parseInt(udt.priceLimit) < ichiwari)
        ? parseInt(udt.priceLimit) : ichiwari;
      e.kettei = (ichiwari > lessPrice) ? lessPrice : ichiwari;
      e.ichiwari = ichiwari;
      e.tyouseiGaku = tyouseiGaku;      
      lessPrice -= e.kettei;
    });
    // 自己負担額が割り振られた事業所の数をカウントする
    const cnt = jAry.filter(e=>e.kettei).length;
    // 配分した結果を取得
    const kanrikekkagaku = jAry.find(e => e.name === "thisOffice").kettei;
    // 利用者負担額が割り振られた（負担額がある事業所が2以上）なら
    // 上限管理が発生したとしてtrueを返す
    const rt = {
      shared: (cnt > 1), // 配分されたかどうか
      kanrikekkagaku,
    }
    return rt;
    // scheduleにdispatch要検討

  }
  
  // それぞれの利用者を処理
  Object.keys(dt).map(e=>{
    const o = dt[e];
    if (!o.countOfUse)  return false; // 利用実績がない利用者は処理しない
    const ichiwari = Math.floor(o.userSanteiTotal * .1); // 一割。ここは切捨
    let upperlimit = parseInt(o.priceLimit);
    const tyouseigaku = Math.min(ichiwari, upperlimit); // 上限月額調整額
    let kanrikekkagaku = tyouseigaku; // 上限管理結果額 暫定
    let kanriKekka = 0; // 管理結果
    let kanriOk = false; // 上限管理がされているかどうかのフラグ
    // “1”管理事業所で利用者負担額を充当したため、他事業所では発生しない。
    // “2”利用者負担額の合計額が、負担上限月額以下のため、調整事務は行わない。
    // “3”利用者負担額の合計額が、負担上限月額を超過するため、調整した。 
    // 利用者負担上限額管理を行った場合のみ設定する。利用者負担上限額管理
    // が必要ない場合（例えば、利用者負担上限月額が 0 円の場合）は
    // 設定しない。 
    // 上記、仕様書より
    // 協力事業所でも管理事業所でもない場合は0設定なので初期値を0にする
    if (o.kanriType === '協力事業所'){
      if (o.管理事業所 === undefined || !o.管理事業所.length){
        // const kettei = o.管理事業所[0].kettei;
        // adjust2 = (tyouseigaku > adjust2)? adjust2 : tyouseigaku;
        // console.log(o.name + 'さんの上限管理情報がありません。');
        kanriOk = false;
      }
      else{
        kanriKekka = parseInt(o.管理事業所[0].kanriKekka);
        kanrikekkagaku = parseInt(o.管理事業所[0].kettei);
        kanriOk = true;
      }
    };
    if (o.kanriType === '管理事業所') {
      kanriKekka = (ichiwari > upperlimit) ? 1 : 2;
      // この場合、結果は暫定。他事業所で調整があれば3に変更
      const manageRt = manegeThis(o);
      kanriKekka = (manageRt.shared) ? 3 : kanriKekka; // 配分されたなら3
      kanrikekkagaku = manageRt.kanrikekkagaku;
      kanriOk = true;
      // 協力事業所情報がないとfalseが帰ってくる
      if (!manageRt){
        kanriKekka = 0;
      }
      if (o.協力事業所 === undefined || !o.協力事業所.length) {
        // console.log(o.name + 'さんの上限管理情報がありません。');
        kanriOk = false;
      }
    }

    o.kanriOk = kanriOk;
    o.kanriKekka = kanriKekka;
    o.tyouseigaku = tyouseigaku;
    o.kanrikekkagaku = kanrikekkagaku;
    o.ketteigaku = Math.min(kanrikekkagaku, tyouseigaku)
  });
}

// 市区町村、サービスごとに集計データを作成する
// k112の項目を埋めるために使用する
const totlizeCityAndService = (dt, masterRec) =>{
  // まずはUniqueな配列から
  const cityServiceSet = new Set();
  Object.keys(dt).map(e=>{
    const o = dt[e];
    cityServiceSet.add(o.scityNo + ',' + o.serviceSyubetu);
  });
  // 配列化ソート。これで文字列のソートが出来るっぽい
  const cityService = Array.from(cityServiceSet)
  .sort((a, b)=>((a < b)? 1: -1));
  // 集計して配列に加える
  const totalized = [];
  cityService.map(e=>{
    let adjust1 = 0;
    let adjust2 = 0;
    let kanrikekkagaku = 0;
    let countOfUse = 0;
    let tanniTotal = 0;
    let userSanteiTotal = 0;
    let countOfUsers = 0;
    const scityNo = e.split(',')[0]; // カンマ区切りで格納されているので展開
    const serviceSyubetu = e.split(',')[1];
    Object.keys(dt).map(key=>{
      const f = dt[key];
      if (f.scityNo !== scityNo || f.serviceSyubetu === serviceSyubetu) {
        return false;
      }
      // 利用実績がなければスキップ
      if (!f.countOfUse)  return false;
      countOfUse += f.countOfUse;
      tanniTotal += f.tanniTotal;
      userSanteiTotal += f.userSanteiTotal;
      kanrikekkagaku += f.kanrikekkagaku;
      if (isNaN(f.kanrikekkagaku)){
        console.log(f.kana + 'さんのに問題があります。')
      }
      countOfUsers++;
    })
    totalized.push({
      scityNo, serviceSyubetu, adjust1, adjust2, countOfUse,
      tanniTotal, userSanteiTotal, kanrikekkagaku, countOfUsers
    });
  });
  // マスターレコードに追加！
  masterRec.totalized = totalized;
}


// Scheduleオブジェクトをdeep COPYして
// 事業所ごと、日毎、ユーザーごとの加算アイテムをuid.didにまとめる
// 加算で入力設定されたオブジェクトからサービスコードを特定してuid.did.itemsに
// まとめる
export const setBillInfoToSch = (prms) => {
  const { stdDate, schedule, users, com, service } = prms;
  // サービス名が未確定の時は空のオブジェクトを返す
  if (!service){
    return { billingDt:{}, masterRec:{} };
  }
  // 現状放デイ以外も空白を返す
  if (service !== '放課後等デイサービス') {
    return { billingDt: {}, masterRec: {} };
  }

  // サービス名ベースを特定
  const comAdic = com.addiction[service];
  console.log('comAdic', comAdic);
  const ku = comAdic.障害児状態等区分;
  const teiin = parseInt(comAdic.定員);
  // 区分で絞って定員で特定
  const nameBase = serviceNameBase.filter(e => e.ku === ku)
    .find(e => e.min <= teiin && e.max >= teiin);
  // 上限管理のオブジェクト。予め定義しておく
  const jougenKanri = houdayKasan.filter(e => e.s === '635370')[0];
  // Scheduleを要素選択しつつdeep copy
  const schTmp = {};
  Object.keys(schedule).map(e => {
    // uidではない要素はスキップ
    if (e.indexOf('UID') < 0) return false;
    // 該当サービス以外のユーザーはスキップ
    const u = comMod.getUser(e, users);
    if (!u) return false; // uid自体が存在しないことも想定される
    if (u.service !== service) return false;
    schTmp[e] = JSON.parse(JSON.stringify(schedule[e]));
  });
  // 全ての加算情報を個々のスケジュールデータに集める
  // schTmp[uid][did].dAddinction ←ここにまとめて入れる。
  // 加算じゃないものも全部入れる
  Object.keys(schTmp).map(e => {
    Object.keys(schTmp[e]).map(f => {
      // didでない要素はスキップ
      if (!f.match(/^D[0-9]+/)) return false;
      // 日毎の加算要素を追加。nullを追加しても無問題
      const dayAddiction = comMod.findDeepPath(schedule, [service, f]);
      // 追加先がnullだとエラーになるので。
      if (!schTmp[e][f].dAddiction) schTmp[e][f].dAddiction = {};
      Object.assign(schTmp[e][f].dAddiction, dayAddiction);
      // ユーザーの加算要素を追加 nullを（ｒｙ
      const u = comMod.getUser(e, users);
      const uAddiction = comMod.findDeepPath(u, 'etc.addiction');
      Object.assign(schTmp[e][f].dAddiction, uAddiction);
      // 事業所の（ｒｙ
      Object.assign(schTmp[e][f].dAddiction, comAdic);
    })
  });

  // 読みが原則ひらがななので半角カタカナに変換する
  //　名字と名前の間に入っているスペースも削除する
  const convName = (str) => {
    str = str.replace(' ', '');
    str = comMod.convHiraToKata(str);
    str = comMod.zen2han(str);
    return str;
  }
  // schTmpに基本項目「放デイｘｘ」を格納する
  // schTmpに加算項目を追加する
  // 送迎も加算項目として追加する
  // ユーザー情報から必要な情報を集めておく
  Object.keys(schTmp).map(e => {
    const thisUser = comMod.getUser(e, users);
    schTmp[e].hno = thisUser.hno;
    schTmp[e].name = thisUser.name; // 名前は使わないがメッセージ用に
    schTmp[e].pkana = convName(thisUser.pkana);
    schTmp[e].kana = convName(thisUser.kana);
    schTmp[e].scityNo = thisUser.scity_no;
    schTmp[e].startDate = thisUser.startDate.replace(/\-/g, '');
    // 契約日。入力項目になっていないので後から変更が必要
    schTmp[e].keiyakuDate = thisUser.contractDate.replace(/\-/g, '');
    const endDate = thisUser.endDate.replace(/\-/g, '');
    schTmp[e].endDate = (endDate === '00000000') ? '' : endDate;
    schTmp[e].priceLimit = thisUser.priceLimit;
    schTmp[e].volume = thisUser.volume;
    schTmp[e].kinyuuBangou = thisUser.lineNo; // 保険証記入番号
    schTmp[e].countOfUse = 0; // 利用回数カウント
    schTmp[e].kanriType = thisUser.kanri_type;
    schTmp[e].serviceSyubetu = serviceSyubetu[service];

    // 上限管理事業所コード
    let jougenJi = '';
    let jougenJiName = '';
    if (thisUser.kanri_type === '管理事業所') {
      jougenJi = com.jino;
    }
    else if (thisUser.etc) {
      if (thisUser.etc.管理事業所 && thisUser.etc.管理事業所.length > 0) {
        jougenJi = thisUser.etc.管理事業所[0].no;
        jougenJiName = (thisUser.etc.管理事業所[0].lname)?
          thisUser.etc.管理事業所[0].lname : thisUser.etc.管理事業所[0].name;
      }
    }
    schTmp[e].jougenJi = jougenJi;
    schTmp[e].jougenJiName = jougenJiName;
    schTmp[e].actualCost = 0;
    schTmp[e].actualCostDetail = [];

    Object.keys(schTmp[e]).map(f => {
      // didでない要素はスキップ
      if (!f.match(/^D[0-9]+/)) return false;
      const o = schTmp[e][f];
      // 基本項目の追加
      const baseItem = getBaseItem(
        nameBase, o.dAddiction, o.offSchool, o.absence
      );
      if (o.items === undefined) o.items = [];
      if (baseItem) o.items.push(baseItem);
      // baseitem存在確認したら利用回数をインクリメント
      if (baseItem) schTmp[e].countOfUse++;
      // 加算項目の追加
      const kasanItem = getKasanItem(
        o.dAddiction, o.offSchool, ku, teiin, o.absence
      );
      o.items = o.items.concat(kasanItem);
      const transfer = getTrasferItem(o.transfer, ku, o.absence);
      o.items = o.items.concat(transfer);
      // 管理事業所の場合、上限管理加算をここで入れる
      if (thisUser.kanri_type === '管理事業所')
        o.items.push(jougenKanri);
      // 実費項目の積算
      Object.keys(o.actualCost).map(f=>{
        schTmp[e].actualCost += parseInt(o.actualCost[f]);
        // 実費明細作成
        const detail = schTmp[e].actualCostDetail;
        const i = detail.findIndex(g => g.name === f);
        // 更新
        if ( i >  -1){
          detail[i].count++;
          detail[i].price += parseInt(o.actualCost[f]);
        }
        // 追加
        else{
          detail.push({ 
            name: f, count: 1, 
            price: parseInt(o.actualCost[f]),
            unitPrice: parseInt(o.actualCost[f]),
          })
        }
      });
    });
  });
  // マスターレコード 単価や各種集計値などを格納する
  const masterRec = { 
    unitPrice: unitPrice[service][com.addiction[service].地域区分],
    chiikiKubun: chiikiKubun[comAdic.地域区分],
    jino: com.jino,
    thisMonth: stdDate.substr(0, 7).replace('-', ''), 
  };
  // サービスアイテムなどを集計する
  totlizeItems(schTmp, masterRec);
  // 上限管理を作成する！！！
  makeUpperLimit(schTmp);
  // 市区町村、サービス別の集計をマスターレコードに記載
  totlizeCityAndService(schTmp, masterRec);
  // 市区町村別にソート
  const billingDt = objSortK122(schTmp);
  console.log('schTmp', schTmp);
  return { billingDt, masterRec};
}

const csvHaed = (outputRec, masterRec, syubetu) =>{
  // 1, REC_NO, 0, REC_CNT, 'K11', 0, JI_NO, 0, 1, THIS_MONTH, 0
  outputRec.push([...headRec]);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, HEAD_SYUBETU, syubetu);

}
// K112基本 市区町村ごと合計行}

const billingCsvK112_1 = (outputRec, masterRec, e) =>{
  // 2, REC_NO, 'K112', 1, THIS_MONTH, SCITY_NO, JI_NO, TOTAL_AMOUNT, TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, 0, TOTAL_USER_BILLED, 0, 0, 0, 0,
  //   TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, TOKUBETSU_TAISAKU,
  //   TOTAL_USER_BILLED, 0,
  const thisDt = e;
  outputRec.push([...kihonK112_1]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, TOTAL_AMOUNT, thisDt.userSanteiTotal);
  erl(outputRec, TOTAL_COUNT, thisDt.countOfUsers);
  erl(outputRec, TOTAL_TANNI, thisDt.tanniTotal);
  // 給付請求額 合計からユーザー負担を除算
  erl(outputRec, TOTAL_BILLED, thisDt.userSanteiTotal - thisDt.kanrikekkagaku);
  erl(outputRec, TOTAL_USER_BILLED, thisDt.kanrikekkagaku);

}
// K112明細 サービス種別毎の明細。自発と放デイは別レコードになる
const billingCsvK112_2 = (outputRec, masterRec, e) =>{
  // 2, REC_NO, 'K112', 2, THIS_MONTH, SCITY_NO, JI_NO, 1, S_SYUBETSU,
  // SRVC_COUNT_TOTAL, SRVC_TANNI_TOTAL, SRVC_AMOUNT, SRVC_BILLED, 0,
  // SRVC_USER_BILLED, 0,
  const thisDt = e;
  outputRec.push([...meisaiK112_2]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, S_SYUBETSU, thisDt.serviceSyubetu);
  erl(outputRec, SRVC_COUNT_TOTAL, thisDt.countOfUsers);
  erl(outputRec, SRVC_TANNI_TOTAL, thisDt.tanniTotal);
  erl(outputRec, SRVC_AMOUNT, thisDt.userSanteiTotal);
  // 給付請求額 合計からユーザー負担を除算
  erl(outputRec, SRVC_BILLED, thisDt.userSanteiTotal - thisDt.kanrikekkagaku);
  erl(outputRec, SRVC_USER_BILLED, thisDt.kanrikekkagaku);
}
const billingCsvK122_4 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 4, THIS_MONTH, SCITY_NO, 
  // JI_NO, H_NO, S_SYUBETSU,
  // SYUUKEI_BUNRUI,  SRVC_COUNT,
  // USER_TANNI, KYUUFU_TANKA, 0, TOTAL_BILLED, ICHIWARI1, ICHIWARI2, 
  // GETSUGAKU_TYOUSEI,  '', '', TYOUSEIGO_USER_BILLED, 
  // JOUGEN_USER_BILLED, KETTEI_USER_BILLED, KETTEI_TOTAL_BILLED, 
  outputRec.push([...syuukeiK122_4]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisDt.hno);
  erl(outputRec, S_SYUBETSU, thisDt.serviceSyubetu);
  // 欠席対応加算などのカウントを調べる。
  const kesseki = thisDt.itemTotal
    .filter(e=>KESSEKI_SVC_CODE.indexOf(e.s) > -1).length;
  erl(outputRec, SRVC_COUNT, thisDt.countOfUse + kesseki);
  erl(outputRec, USER_TANNI, thisDt.tanniTotal);
  erl(outputRec, KYUUFU_TANKA, masterRec.unitPrice * 1000);
  const totalBilled = Math.floor(masterRec.unitPrice * thisDt.tanniTotal);
  erl(outputRec, TOTAL_BILLED, totalBilled);
  const ichiwari = Math.floor(totalBilled * 0.1);
  erl(outputRec, ICHIWARI1, ichiwari);
  erl(outputRec, ICHIWARI2, ichiwari);
  // erl(outputRec,GETSUGAKU_TYOUSEI, thisDt.kanrikekkagaku);
  erl(
    outputRec, GETSUGAKU_TYOUSEI, 
    ichiwari < thisDt.priceLimit ? ichiwari : thisDt.priceLimit
  );

  erl(outputRec, TYOUSEIGO_USER_BILLED, ''); // 福祉ソフトで未設定項目？
  // 福祉ソフトで0出力を''にしているがゼロでもいいんじゃね？
  // この項目は上限管理が存在しなければ設定しない
  erl(
    outputRec,
    JOUGEN_USER_BILLED, thisDt.kanriKekka ? thisDt.kanrikekkagaku : ''
  )
  erl(outputRec, KETTEI_USER_BILLED, thisDt.kanrikekkagaku);
  erl(
    outputRec, 
    KETTEI_TOTAL_BILLED, thisDt.userSanteiTotal - thisDt.kanrikekkagaku
  );
}

// 契約情報レコード
const billingCsvK122_5 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 5, THIS_MONTH, SCITY_NO, JI_NO, H_NO,
  // KETTEI_SRVC_CODE, // 決定サービスコード
  // KEIYAKU_VOL, // 契約量*100
  // KEIYAKU_DATE, // 契約日
  // KEIYAKU_END, // 契約終了日
  // KINYUU_BANGOU, // 事業者記入欄番号 
  outputRec.push([...keiyakuK122_5]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisDt.hno);
  erl(outputRec, KETTEI_SRVC_CODE, thisDt.serviceSyubetu + '1000');
  erl(outputRec, KEIYAKU_VOL, parseInt(thisDt.volume) * 100);
  erl(outputRec, KEIYAKU_DATE, thisDt.keiyakuDate);
  erl(outputRec, KEIYAKU_END, thisDt.endDate);
  erl(outputRec, KINYUU_BANGOU, thisDt.kinyuuBangou);
}

// k122 明細レコード サービスコードを記載
const billingCsvK122_3 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 3, THIS_MONTH, SCITY_NO, JI_NO, H_NO, 
  // SRVC_CODE, SRVC_TANNI, SRVC_COUNT, SRVC_SANTEI
  
  if (!thisDt.itemTotal) return false; // 利用実績がない場合
  // itemTotalをなめる
  thisDt.itemTotal.map(e=>{
    outputRec.push([...serviceMeisaiK122_3]);
    erl(outputRec, THIS_MONTH, masterRec.thisMonth);
    erl(outputRec, SCITY_NO, thisDt.scityNo);
    erl(outputRec, JI_NO, masterRec.jino);
    erl(outputRec, H_NO, thisDt.hno);
    erl(outputRec, SRVC_CODE, e.s);
    erl(outputRec, SRVC_TANNI, e.v);
    erl(outputRec, SRVC_COUNT, e.count);
    erl(outputRec, SRVC_SANTEI, e.tanniNum);
  })
}

// k122日数情報レコード
const billingCsvK122_2 = (outputRec, masterRec, dt) => {
  // 2, REC_NO, 'K122', 2, THIS_MONTH, SCITY_NO, JI_NO, 
  // H_NO, S_SYUBETSU, START_DATE, END_DATE, CNT_USED,
  outputRec.push([...nissuuK122_2]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, dt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, dt.hno);
  erl(outputRec, S_SYUBETSU, dt.serviceSyubetu);
  erl(outputRec, START_DATE, dt.startDate);
  erl(outputRec, END_DATE, dt.endDate);
  erl(outputRec, CNT_USED, dt.countOfUse);
}

// k122_1 基本レコード
const billingCsvK122_1 = (outputRec, masterRec, dt) =>{
  // 2, REC_NO, 'K122', 1, THIS_MONTH, SCITY_NO, JI_NO, H_NO, '',
  // PNAME, NAME,
  // CHIKI_CODE, '', JOUGEN, '', '', JOUGEN_JI, JOUGEN_KEKKA, JOUGEN_RES,
  // '', '', USER_TANNI, TOTAL_AMOUNT,
  // JOUGEN_GETSU_TYOUSEI, // 上限月額調整額
  // '', '', '',
  // JOUGEN_TYOUSEIGO, // 上限調整後金額 
  // JOUGEN_KETTEI, // 上限決定後
  // TOTAL_BILLED
  outputRec.push([...kihonK122_1]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, dt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, dt.hno);
  erl(outputRec, PNAME, dt.pkana);
  erl(outputRec, NAME, dt.kana);
  erl(outputRec, CHIKI_CODE, masterRec.chiikiKubun); // 地域区分コード
  erl(outputRec, JOUGEN, dt.priceLimit);
  erl(outputRec, JOUGEN_JI, dt.jougenJi); // 上限管理事業所
  erl(outputRec, USER_TANNI, dt.tanniTotal); // 単位合計
  erl(outputRec, TOTAL_AMOUNT, dt.userSanteiTotal); // 請求額合計
  erl(outputRec, JOUGEN_GETSU_TYOUSEI, dt.tyouseigaku); // 上限管理結果額
  erl(outputRec, JOUGEN_KEKKA, dt.kanriKekka); // 管理結果フラグ
  // 上限管理結果が0（上限管理を行っていない）場合は出力しない
  erl(outputRec, JOUGEN_RES, ((dt.kanriKekka) ? dt.kanrikekkagaku: ''));
  // 調整ご利用者負担も同じ処理
  erl(outputRec, JOUGEN_TYOUSEIGO,((dt.kanriKekka) ? dt.kanrikekkagaku : ''));
  erl(outputRec, JOUGEN_KETTEI, dt.kanrikekkagaku); // 上限決定額
  // 請求額
  erl(outputRec, TOTAL_BILLED, dt.userSanteiTotal - dt.kanrikekkagaku); 

  // // 上限管理の結果の金額。
  // const kanriKekkaPrice = (dt.kanrikekka) ? dt.adjust2 : '';
  // erl(outputRec, JOUGEN_RES, kanriKekkaPrice); // 上限管理結果額
  // // 上限管理がされていたら上限管理前の金額。上限管理されていたら上限管理後の金額
  // const jougenGetuTyousei = (dt.kanrikekka) ? dt.adjust2 : dt.adjust1;
  // erl(outputRec, JOUGEN_GETSU_TYOUSEI, jougenGetuTyousei);
  // // 最終結果。月額管理前の金額と管理後の金額安い方 でいいのか？
  // const jougenFinal = (dt.adjust1 < dt.adjust2) ? dt.adjust1 : dt.adjust2
  // erl(outputRec, JOUGEN_KETTEI, jougenFinal);
  // // 保険給付金 総額からユーザー負担を引く
  // erl(outputRec, TOTAL_BILLED, dt.userSanteiTotal - jougenFinal);
}

const billingCsvUsers = (outputRec, billingDt, masterRec, totalized) =>{
  //    ユーザーヘッダ
  //      K122_1
  //      K122_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // const totalized = e; // 集計業を定義
  const scityNo = totalized.scityNo; // 処理すべき市区
  const syubetu = totalized.serviceSyubetu; // 処理すべきサービス種別
  // billingDt配列から対象となるサービスを抽出
  const target = billingDt.filter(e => (
    parseInt(e.serviceSyubetu) === parseInt(syubetu) &&
    parseInt(e.scityNo) === parseInt(scityNo)
  ));
  // 対象となる各ユーザーを処理
  target.map(e=>{
    billingCsvK122_1(outputRec, masterRec, e);
    billingCsvK122_2(outputRec, masterRec, e);
    billingCsvK122_3(outputRec, masterRec, e);
    billingCsvK122_4(outputRec, masterRec, e);
    billingCsvK122_5(outputRec, masterRec, e);
  });

}

const billingCsvScity = (outputRec, billingDt, masterRec) =>{
  // 市区町村ヘッダ
  //    K112_1
  //    K112_2
  //    ユーザーヘッダ
  //      K122_1
  //      K122_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // マスターレコードの集計データが格納されている配列をなめる
  // 自発が入ってくるとここの構造が変わると思われる
  masterRec.totalized.map(e=>{
    billingCsvK112_1(outputRec, masterRec, e);
    billingCsvK112_2(outputRec, masterRec, e);
    billingCsvUsers(outputRec, billingDt, masterRec, e);
  })
}

// setBillInfoToSchで作成されたオブジェクトを元にcsvのベースになる配列を作成する
// 情報は整理する意味でも渡された引数から取得する
// 足りなきゃ何処かにセットする！
export const makeBiling = (billingDt, masterRec)=>{
  // ヘッダレコード出力 K11
  // 市区町村ヘッダ
  //    K112_1
  //    K112_2
  //    ユーザーヘッダ
  //      K122_1
  //      K122_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // エンドレコード出力
  const outputRec = [];
  csvHaed(outputRec, masterRec, 'K11');
  billingCsvScity(outputRec, billingDt, masterRec);
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i)=>{
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  console.log('outputRec', outputRec);
  return outputRec;
}

const makeJougenOneUser = (thisUser, masterRec, outputRec) =>{
  console.log(thisUser.hno);
  outputRec.push([...jgKihonK411_1]);
  // k411_1
  // 2, REC_NO, 'K411', 1, THIS_MONTH, SAKUSEI_KU, SCITY_NO,
  // JI_NO, H_NO, PNAME, NAME,
  // JOUGEN, JOUGEN_KEKKA, ALL_AMOUNT, ALL_TYOUSEI, ALL_JOUGEN,
  // const jgMeisaiK411_2 = [ // 上限管理明細
  //   2, REC_NO, 'K411', 2, THIS_MONTH, SCITY_NO, JI_NO, H_NO, LINE_NO,
  //   KYO_JI, TOTAL_AMOUNT, JOUGEN_TYOUSEIGO, JOUGEN_KETTEI
  // ]

  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisUser.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisUser.hno);
  erl(outputRec, PNAME, thisUser.pkana);
  erl(outputRec, NAME, thisUser.kana);
  erl(outputRec, JOUGEN, thisUser.priceLimit);
  erl(outputRec, JOUGEN_KEKKA, thisUser.kanriKekka);
  erl(outputRec, SAKUSEI_KU, 1);  // 今のところ常に１

  const jiGrp = thisUser.協力事業所;
  let allAmount = 0;
  let allTyousei = 0;
  let allJougen = 0;
  jiGrp.map(e=>{
    allAmount += e.amount;
    allTyousei += e.tyouseiGaku;
    allJougen += e.kettei;
  });
  erl(outputRec, ALL_AMOUNT, allAmount);
  erl(outputRec, ALL_TYOUSEI, allTyousei);
  erl(outputRec, ALL_JOUGEN, allJougen);
  // 上限管理明細を作成
  let ln = 1; // 行番号
  const makeJougenMeisai = (o)=>{
    outputRec.push([...jgMeisaiK411_2]);
    erl(outputRec, THIS_MONTH, masterRec.thisMonth);
    erl(outputRec, SCITY_NO, thisUser.scityNo);
    erl(outputRec, JI_NO, masterRec.jino);
    erl(outputRec, H_NO, thisUser.hno);
    erl(outputRec, LINE_NO, ln++);
    // 自社のデータなら自社の事業所番号、他社なら他社の
    const jno = (o.name === 'thisOffice') ? masterRec.jino : o.no;
    erl(outputRec, KYO_JI, jno);
    erl(outputRec, TOTAL_AMOUNT, o.amount);
    erl(outputRec, JOUGEN_TYOUSEIGO, o.tyouseiGaku);
    erl(outputRec, JOUGEN_KETTEI, o.kettei);
  }
  // 自社を最初に処理する
  const thisOffice = jiGrp.find(e=>e.name==='thisOffice');
  makeJougenMeisai(thisOffice);
  jiGrp.map(e=>{
    if (e.name === 'thisOffice')  return false; // 自社は処理済。スキップ
    makeJougenMeisai(e);
  });
}

// 上限管理イメージを作成する
export const makeJugenkanri = (billingDt, masterRec) =>{
  const outputRec = [];
  csvHaed(outputRec, masterRec, 'K41');
  Object.keys(billingDt).map(e=>{
    const thisUser = billingDt[e];
    // 管理事業所で管理結果が0以上なら上限管理データ作成
    if (thisUser.kanriKekka > 0 && thisUser.kanriType === '管理事業所')  
      makeJougenOneUser(thisUser, masterRec, outputRec)
  });
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i) => {
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  return outputRec;
}

// 提供実績イメージ作成
// 提供実績はカラム数がムダに多いのでカラム位置を定義して配列を扱う
// カラムの位置はCSVの仕様書と合わせるためレコードの作製してから
// 先頭に１カラム挿入する。
// 共用
const C_YEAR_MOMTH = 3; //年月
const C_CITY = 4; //市区
const C_JI = 5; //事業所番号
const C_HNO = 6;  //保険番号
const C_YOUSHIKI = 7; //様式
// 明細レコード
const C_DATE = 9;  //日付
const C_START_TIME = 14;  //開始時刻
const C_END_TIME = 15;  //終了時刻
const C_PICKUP = 21;  //送迎往路
const C_SEND = 22;  //送迎復路
const C_TEIKYOUKEITAI = 34; //提供形態
const C_TEIKYOUJOUKYOU = 36;  //提供状況
// 基本レコード
const C_CNT_SOUGEI = 34;//送迎回数片道
const C_CNT_KATEIREN = 35;//家庭連携回数
const C_CNT_KATEIREN_SAN = 36;//家庭連携算定回数
const C_CNT_HOUMON = 51;//訪問支援特別
const C_CNT_HOUMON_SAN = 52;//訪問支援特別加算算定:
const C_CNT_IRYOU = 117;//医療連携体制加算
const C_SOUDAN = 121;//事業所内相談支援回数:

// 利用実績データ1ユーザー分
const makeTeikyouJissekiOneUser = (masterRec, thisUser, outputRec) =>{
  // 最終レコードを書き換えるだけ
  const er = (target, value)=>{
    outputRec[outputRec.length - 1][target] = value;
  }
  
  // 基本情報レコード
  const kihonK611_1 = Array(143).fill('');
  kihonK611_1[0] = 'REC_NO';
  kihonK611_1[1] = 'K611';
  kihonK611_1[2] = '01';
  outputRec.push([...kihonK611_1]);

  er(C_YEAR_MOMTH, masterRec.thisMonth);
  er(C_CITY, thisUser.scityNo);
  er(C_JI, masterRec.jino);
  er(C_HNO, thisUser.hno);
  er(C_YOUSHIKI, '0501');
  //送迎回数片道
  let sougeiCnt = 0;
  thisUser.itemTotal.filter(e=>SOUGEY_SVC_CODE.indexOf(e.s) > -1)
  .map(e=>{sougeiCnt += e.count});
  er(C_CNT_SOUGEI, sougeiCnt);
  //家庭連携回数 算定回数？
  let kateiRenCnt = 0;
  let kateiRenSantei = 0;
  thisUser.itemTotal.filter(e => KATEI_SVC_CODE.indexOf(e.s) > -1)
  .map(e => {
    kateiRenCnt += e.count;
    // 算定は制限回数を超えない範囲で
    kateiRenSantei = (kateiRenCnt > e.limit) ? e.limit : kateiRenCnt;
  });
  er(C_CNT_KATEIREN, kateiRenSantei);
  er(C_CNT_KATEIREN_SAN, kateiRenSantei);
  // 訪問支援回数
  let houmonCnt = 0;
  let houmonSan = 0;
  thisUser.itemTotal.filter(e => HOUMON_SVC_CODE.indexOf(e.s) > -1)
  .map(e => {
    houmonCnt += e.count;
    // 制限回数チェックして設定
    houmonSan = (houmonSan > e.limit) ? e.limit : houmonSan;
  });
  er(C_CNT_HOUMON, houmonCnt);        //訪問支援特別
  er(C_CNT_HOUMON_SAN, houmonSan);        //訪問支援特別算定
  // 医療連携体制加算回数
  let iryourenkeiCnt = 0;
  thisUser.itemTotal.filter(e => IREN_SVC_CODE.indexOf(e.s) > -1)
  .map(e => { iryourenkeiCnt += e.count });
  er(C_CNT_IRYOU, iryourenkeiCnt);         //医療連携体制加算
  
  // 事業所内相談支援回数
  let soudanCnt = 0;
  thisUser.itemTotal.filter(e => SOUDAN_SVC_CODE.indexOf(e.s) > -1)
    .map(e => { soudanCnt += e.count });
  er(C_SOUDAN, soudanCnt);         //事業所内相談支援回数
  
  // 処理終了後、最初のカラムに2を挿入。
  outputRec[outputRec.length - 1].unshift(2);

  // 明細情報レコード
  const maisaiK611_2 = Array(87).fill('');
  // 共通項目は予めテンプレにセットしておく
  maisaiK611_2[0] = 'REC_NO';
  maisaiK611_2[1] = 'K611';
  maisaiK611_2[2] = '02';
  maisaiK611_2[C_YEAR_MOMTH] = masterRec.thisMonth;
  maisaiK611_2[C_CITY] = thisUser.scityNo;
  maisaiK611_2[C_JI] = masterRec.jino;
  maisaiK611_2[C_HNO] = thisUser.hno;
  maisaiK611_2[C_YOUSHIKI] = '0501';
  // 日付オブジェクトのキーを抽出してソートしておく。
  // オブジェクトの検出順が変わることがあるため
  const daysUsed = Object.keys(thisUser)
    .filter(e => e.match(/^D2[0-9]/))
    .sort((a, b) => (a > b) ? 1 : -1);
  // userオブジェクトから日付オブジェクトを抽出して舐める
  daysUsed.map(e=>{
    const o = thisUser[e];
    outputRec.push([...maisaiK611_2]);
    // 欠席加算の検出
    const kesseki = o.items.find(e => KESSEKI_SVC_CODE.indexOf(e.s) > -1);
    er(C_TEIKYOUJOUKYOU, (kesseki) ? 8 : ''); //欠席なら8を設定
    er(C_DATE, parseInt(e.substr(7, 2)));// 日付の日にちだけ キーの下二桁
    // 欠席の場合、最初のカラム挿入だけして終了
    if (kesseki){
      outputRec[outputRec.length - 1].unshift(2);
      return false;
    }
    er(C_START_TIME, o.start.replace(':', ''));//開始時刻
    er(C_END_TIME, o.end.replace(':', ''));//終了時刻
    // 送迎ありなし。配列の中身が空白なら送迎なし
    er(C_PICKUP, (o.transfer[0]) ? 1 : '');
    er(C_SEND, (o.transfer[1]) ? 1 : '');
    // er(C_TEIKYOUKEITAI, 1); // 利用実績があれば1
    er(C_TEIKYOUKEITAI, o.offSchool === 0 ? 1 : 2); // 平日=1、休日=2
    // 明細レコードは処理終了後、最初のカラムに2を挿入。
    outputRec[outputRec.length - 1].unshift(2);
  });
}

export const makeTeikyouJisseki = (billingDt, masterRec) =>{
  const outputRec = [];
  // ヘッダの作成
  csvHaed(outputRec, masterRec, 'K61');
  Object.keys(billingDt).map(e=>{
    const thisUser = billingDt[e];
    makeTeikyouJissekiOneUser(masterRec, thisUser, outputRec);
  });
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i) => {
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  // console.log('outputRec stringify', JSON.stringify(outputRec));
  return outputRec;
}
