import React from 'react';
export const Rev = ()=>(<span>β.1048</span>);
export default Rev;

// β.1048 2021/05/06
// アカウト管理。帳票の追加など

// α1047 2021/03/08
// 利用者追加時の挙動バグフィックス

// α1046 2021/03/04
// 送迎予定表など

// α1045 2021/03/03
// 色々変わってる

// α1045 2020/01/20
// 学齢変更時にstateの更新漏れが合ったので訂正
// appバー作り直し現時点で完了

// α1044 2020/01/20
// 学齢計算バグフィックス

// α1043 2020/01/20
// 予定関連ページ内ナビゲーション作り直し

// α1042 2020/01/20
// appバーの作り変え中
// 予定一覧の管理事業所、協力事業所の表示方法変更

// α1041 2020/01/18
// 設定>表示にユーザーの並び替えの順番を追加。細かい順番の指定は数字で設定する。
// ユーザー表示設定に伴う他画面の調整。ユーザーを追加すると常に最終に追加されるなど。

// 1026 2020/10/26 予定入力用リリース
// 1027 2020/10/26 
// 加算項目数カウント不具合訂正。送迎数カウント不具合訂正。実費項目が修正時にすべてonになってしまうのを修正
// 1028 月間予定にユーザー別加算項目、日毎加算項目を示唆するドットを表示。
// 1029 文字化け対応
// 1030 最初の読み込みのときにScheduleデータをロードしない不具合を解消。
// 1031 2020/11/02 
// 上限管理入力を追加。自動セーブとのコンフリクトがあり。セーブ実行時にダイアログが閉じられてしまう。
// 1032 2020/11/02
// 加算フォームパーツのバグフィックス。一部、チェックボックスから転用したフォームパーツの値がvalueではなくcheckedに紐付いていた。
// 1033 2020/11/17
// 契約書記入番号追加。協力事業所の上限管理区分を入力追加。欠席管理追加。
// 自動セーブ一旦保留。CSV作成機能絶賛実装中
// 1034 2020/11/19
// 固定ツールバーに仮対応。協力事業所入力項目が書き込まれない（フォーム
// 初期値として読み込めない）バグフィックス
// 1035 2020/12/04
// 固定ツールバー方法変更
// 1036 2020/12/14
// 自動セーブ追加。スナックバー表示方法変更
// 売上管理を追加
// 1037 2020/12/14
// 開始終了時間一括入力並び順を設定
// スケジュール修正ダイアログ、日付まで表示
// 1038 2020/12/17
// 事業所情報入力を追加
// 1038 2020/12/25
// api.php エラーレポーティング設定。get通信をデバック時以外は禁止にする。
// 事業所基本情報設定
// スケジュールテンプレート設定
// スケジュール入力、合計値の表示訂正 稼働率の表示
// フォーム送信キャンセルボタンイベント見直し まだ課題あり。パチクリする
// ロゴ、マークのsvg化
// メニュードロワの追加
// 1039 2020/12/28
// csv出力のバグフィックス
// メニューバー作り変え
// 1040 2020/01/14
// 帳票作成対応。ユーザー別売上管理を追加
