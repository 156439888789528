import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, ServerStyleSheets } from '@material-ui/core/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts';
import * as sfp from '../common/StdFormParts';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import Button from '@material-ui/core/Button';
import {
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from '../Billing/blMakeData';
import {
  proseedByUsersDt
} from '../Billing/Proseed'
import { endPoint } from '../../Actions'
import axios from 'axios';
import { LoadingSpinner, UserSelectDialog } from '../common/commonParts';
import useInterval from 'use-interval';
import GroupIcon from '@material-ui/icons/Group';
import { AddToPhotosOutlined, CenterFocusStrong, ColorizeSharp, FormatBold, FullscreenExit, MicNone } from '@material-ui/icons';
import SnackMsg from '../common/SnackMsg';
import teal from '@material-ui/core/colors/teal';
import { serviceSyubetu } from '../Billing/BlCalcData';

// 通所給付明細
// 国定形式の代理受領通知明細も兼ねる

const useStyles = makeStyles({
  gridRepotRoot:{
    width:'90%',
    maxWidth: 900,
    margin:'120px auto 0',
    '@media print':{
      width: '262mm',
      maxWidth: '262mm',
      margin:'15mm 0mm 10mm 20mm',
      margin: 0,
      breakAfter: 'always',
      '&:last':{
      breakAfter: 'auto',
      }
    },
    '& .title':{
      fontSize:'1.8rem',
      textAlign: 'center',
      padding: '8px 0 32px',
      '& .comment':{
        fontSize: '1.0rem',
        padding: 8,
      }
    },
    '& .vhcenter':{
      display:'flex',alignItems:'center',justifyContent:'center',
    },
    '& .cells':{
      // border: '1px #333 solid',
      borderRight: '1px #333 solid',
      borderBottom: '1px #333 solid',
      textAlign: 'center',
      fontSize: '.8rem',
      padding: 4,
    },
    '& .outerLine' : {
      borderTop: '2px #333 solid',
      borderLeft: '2px #333 solid',
      // borderRight: '1px #333 solid', // 右線は引かない。内部セルに任せる
      borderBottom: '1px #333 solid',
      '& > div': {
        borderRight: '1px #333 solid',
        borderBottom: '1px #333 solid',
        textAlign: 'center',
        fontSize: '.8rem',
        padding: 4,
        minHeight: 18,
      },
      '& .bb' :{borderBottom: '2px #333 solid'}, // 下線太く
      '& .rb' :{borderRight: '2px #333 solid'}, // 右線太く      
    },
    '& .row1':{
      display:'flex',
      justifyContent:'flex-end',
      alignItems: 'flex-start',
      '& .col1':{
        width:'22%',
        display:'grid',
        gridTemplateColumns:'20% 1fr 1fr 1fr 1fr 1fr 20%',
        // borderTop: '1px #333 solid',
        // borderleft: '1px #333 solid',
        padding: '1px 0 0 1px'
      },
      
    },
    '& .row2':{
      display:'flex',
      alignItems:'flex-end',
      justifyContent:'space-between',
      marginTop: 24,
      '& .col1':{
        width:'45%',
        display:'grid',
        gridTemplateColumns:'6fr repeat(10, 1fr)',
        '& .filler': {
          gridColumn: '8/12',
        },
        '& .content' : {
          gridColumn: '2/12'
        },
      },
      '& .col2':{
        width:'54%',
        display:'grid',
        gridTemplateColumns:'2ch 3fr repeat(10, 1fr)',
        '& .vheader':{ // 縦の見出し
          gridRow:'1 / 3',gridColumn: 1,
        },
        '& .content': {
          gridColumn: '3/13',
          fontSize: '1.0rem',
          height: 80,
        },
      },
    },
    '& .row3': {
      width:'35%',
      display:'grid',
      gridTemplateColumns:'20ch repeat(5, 1fr)',
      marginTop: 24,
    },
    '& .row4': {
      marginTop: 24,
      '& .r1': {
        display:'flex',
        width: '40%',
        fontSize: '.8rem',
        '& .r1c1': {
          width:'85%',
          border:'#333 2px solid',
          borderBottom: 'none',
          borderRight: 'none',
          padding: 8,
          textAlign: 'center',
        },
        '& .r1c2': {
          width:'15%',
          border:'#333 2px solid',
          padding: 8,
          textAlign: 'center',
        },
      },
      '& .r2' : {
        marginTop: -2,
        border:'#333 2px solid',
        padding: '36px 10%',
        '& > div': { padding: 4, fontSize: '.8rem',},
      }
    },
    '& .row56': {
      display:'grid',
      gridTemplateColumns:
        '2ch 9fr repeat(30, 1fr) ',
      marginTop: 24,
      '& .vHead': {
        gridRow: '1/7',
        wordBreak:'break-word',
        lineHeight: '1.05rem',
      },
      '& .rHead': { gridColumnStart: 2,},
      '& .jiName' : {height: 120,},
      '& .total': { gridRow: '1/4' },
    },
    '& .sizem': {fontSize: '1.0rem',},
    '& .sizes': {fontSize: '.65rem',},
    '& .sizexs': {fontSize: '.50rem',},
    '& .outerLine .tallPadding': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '& .heightInGrid': {height: 20,},
    '& .textLeft': {textAlign: 'left',},
    '& .usersSig': {
      width: '50%',
      marginLeft: '50%',
      padding: 12,
      '& >div': {
        padding: 8,
      } ,
      '& .sig':{
        width: '90%',
        marginLeft: '10%',
        borderBottom: '#333 1px solid',
      }
    }
  },
  // 利用者負担額一覧用
  gridReportFtnRoot:{
    width:'90%',
    maxWidth: 900,
    margin:'120px auto 0',
    '@media print':{
      width: '262mm',
      maxWidth: '262mm',
      margin:'15mm 0mm 10mm 20mm',
      margin: 0,
      breakAfter: 'always',
      '&:last':{
      breakAfter: 'auto',
      }
    },
    '& .title':{
      fontSize:'1.8rem',
      textAlign: 'center',
      padding: '8px 0',
      '& .date':{
        fontSize: '1.0rem',
        padding: 8,
        textAlign:'right',
      }
    },
    '& .vhcenter':{
      display:'flex',alignItems:'center',justifyContent:'center',
    },
    '& .outerLine' : {
      borderTop: '2px #333 solid',
      borderLeft: '2px #333 solid',
      // borderRight: '1px #333 solid', // 右線は引かない。内部セルに任せる
      borderBottom: '1px #333 solid',
      '& > div': {
        borderRight: '1px #333 solid',
        borderBottom: '1px #333 solid',
        textAlign: 'center',
        fontSize: '.8rem',
        padding: 4,
        minHeight: 18,
      },
      '& .bb' :{borderBottom: '2px #333 solid'}, // 下線太く
      '& .rb' :{borderRight: '2px #333 solid'}, // 右線太く      
    },
    '& .lineheigher': {lineHeight: '1.4rem'},
    '& .row1': {
      display: 'flex',
      fontSize: '.8rem',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      '& .col1': {
        width: '45%',
        '& > div': {
          margin: '4px 0',
        },
        '& .jiname': {
          display: 'flex',
          alignItems: 'flex-end',
          '& .name': {
            height: 120,
            fontSize: '1.0rem',
            flex: 1,
          },
          '& .dono': {
            width: '4ch',
            display: 'flex',
          }
        },
        '& .month':{
          display: 'grid',
          width: '30ch',
          gridTemplateColumns: '2fr 1fr 1fr 1.5fr 1fr 1fr 2fr'
        }
      },
      '& .col2':{
        display: 'grid',
        gridTemplateColumns: '3ch 8fr repeat(10, 1fr)',
        width: '53%',
      },
      '& .vHead': { gridRow: '1/5',},
      '& .rHead': {gridColumnStart: 2, },
      '& .content' : {
        gridColumnStart: 3, gridColumn: '3/13', 
      },
      '& .tall': {minHeight: 80,},
    },
    '& .mainGrid': {
      marginTop: 24,
      display: 'grid',
      gridTemplateColumns: '3ch 4fr 6fr repeat(10, 1fr) 8fr repeat(6, 1fr)' + 
        '6fr 1fr fr 10fr',
      '& .cHead1' : {fontSize: '.5rem'},
      '& .cHead2' : {gridColumn: '2/26'},
      '& .vHead': { gridColumn: 1},
      '& .rHead1': {gridColumn: '2 / span 3'},
      '& .rHead2': {gridColumn: '2 / span 2'},
      '& .mHead': {gridColumn : 15},
      '& .name' : {gridColumn: '4 / span 11'},
      '& .serviceLabel': {gridColumn: 22, gridRow: 'span 3'},
      '& .service': {gridColumn: 25, },
    }
  }
});
// 受け取った文字を一文字ずつdivで括って出力する
export const StrToDivs = (props) => {
  let {
    str, length, strPadding, right, className, styleo,
    bb, rb, // 下線太く、右線太く
    cs, rs, // gridColumunStart gridRowStart
  } = props;
  str = (str !== undefined)? str: '';
  strPadding = (strPadding)? strPadding: ' ';
  right = (right)? true: false;
  className = (className)? className: '';
  styleo = (styleo)? styleo: {};
  length = {length}? length: str.length;
  // 下線太くするクラス名追加
  className = (bb)? className + ' bb': className;
  if (right){
    str = strPadding.repeat(length) + str;
    str = str.slice(length * (-1));
  }
  else{
    str = str + strPadding.repeat(length);
    str = str.substr(0, length);
  }
  const rt = Array.from(str).map((e, i)=> {
    // 最終セルのみ右線太く
    const cls = ((i === (str.length - 1)) && rb)? className + ' rb': className;
    const st = {...styleo};
    if (cs) st.gridColumn = cs + i;
    if (rs) st.gridRow = rs;
    return (
      <div className={cls} style={st} key={i}>{e}</div>
    )
  })
  return rt
}
// 2021-01-01フォーマットから和暦などの日付情報を取り出す
const str2gdex = (s) =>{
  return comMod.getDateEx(s.split('-')[0], s.split('-')[1], s.split('-')[2]);
}

export const ReportKanriKekkaOne = (props) => {
  const classes = useStyles();
  const {
    thisBdt, com, service, account, thisUser, masterRec, preview
  } = props.props;
  const schedule = useSelector(state=>state.schedule);
  const stdDate = useSelector(state=>state.stdDate);
  if (thisBdt.kanriType !== '管理事業所') return null;
  const bname = com.bname;
  const tkubun = com.addiction.放課後等デイサービス.地域区分;
  const gengou = str2gdex(stdDate).wr.l; // 元号
  const wry = str2gdex(stdDate).wr.y; // 和暦の年
  const month = str2gdex(stdDate).m;
  const jCityNum = '';
  const hno = thisBdt.hno;
  const name = thisBdt.name;
  const hname = thisUser.pname;
  const jougen = thisUser.priceLimit;
  const kanriJi = (thisBdt.jougenJi)? thisBdt.jougenJi: '';
  const kanriJiName = thisBdt.jougenJiName;
  const kanrikekka = thisBdt.kanrikekka;
  const kanrikkekkaGaku = (thisBdt.kanrikkekkaGaku)? thisBdt.kanrikkekkaGaku: 0;
  const startDate = thisUser.startDate;
  const endDate = thisUser.endDate;
  const useCount = thisBdt.countOfUse;
  const sSyubetsu = (service === '放課後等デイサービス')? "63" : "62";
  const itemTotal = thisBdt.itemTotal;
  const unitPrice = masterRec.unitPrice * 100;
  const tanniTotal = thisBdt.tanniTotal;
  const kyouryokuji = [...thisBdt.協力事業所];
  // 協力事業所が9箇所記述できるようになっているので9件まで配列拡張する
  if (kyouryokuji.length < 9){
    const tmp = Array(9 - kyouryokuji.length).fill(
      {amount:'', name:'', ichiwari:'', kettei:'', lname: ''}
    );
    tmp.map(e=>{kyouryokuji.push(e)});
  }
  // 合計覧表示のために合計値を配列の最後に追加する。
  const totalRec = {amount:0, name:0, ichiwari:0, kettei:0,tyouseiGaku:0,}
  kyouryokuji.map(e=>{
    totalRec.amount += e.amount;
    totalRec.ichiwari += e.ichiwari;
    totalRec.tyouseiGaku += (isNaN(e.tyouseiGaku)) ? 0: e.tyouseiGaku;
    totalRec.kettei += e.kettei;
  });
  kyouryokuji.push(totalRec);

  const ichiwari = Math.floor(thisBdt.userSanteiTotal * .1);
  const ketteigaku = (thisBdt.ketteigaku || !isNaN(thisBdt.ketteigaku))?
    thisBdt.ketteigaku: 0;
  let sd = startDate.split('-');
  const Row1 = () => {
    return (<>
      <div >{gengou}</div>
      <StrToDivs 
        str={wry} length={2}  strPadding='0' right={true}
      />
      <div >年</div>
      <StrToDivs 
        str={month} length={2}  
        strPadding='0' right={true} 
      />
      <div className='rb'>月分</div>
    </>)
  }
  const Row2Col1 = () => {
    return (<>
      <div className='vhcenter rb'>市町村番号</div>
      <StrToDivs str={thisBdt.scityNo} length={6} />
      <div className='filler rb'></div>
      <div className='vhcenter rb'>受給者証番号</div>
      <StrToDivs str={thisBdt.hno} length={10} rb />
      <div className='vhcenter rb'>支給決定障害者等<br></br>氏名</div>
      <div className='rb content vhcenter'>{hname}</div>
      <div className='vhcenter rb'>支給決定に係る<br></br>障害児氏名</div>
      <div className='rb content vhcenter'>{name}</div>

    </>);
  } 
  const Row2Col2 = () => {
    return (<>
      <div className='vheader vhcenter'>管理事業者</div>
      <div className='vhcenter'>指定事業所番号</div>
      <StrToDivs str={com.jino} length={10} rb />
      <div className='vhcenter tallPadding'>
        事業所及び<br></br>その事業所<br></br>の名称
      </div>
      <div className='vhcenter content rb '>{com.bname}</div>
    </>);
  }
  const Row3 = () => (<>
    <dic className='cells'>利用者負担上限月額</dic>
    <StrToDivs str={jougen} className='cells' rb right={true} length={5} />
  </>);
  const Row4 = () => (<>
    <div className='r1'>
      <div className='r1c1'>利用者上限負担管理結果</div>
      <div className='r1c2'>{thisBdt.kanriKekka}</div>
    </div>
    <div className='r2'>
      <div>1　管理事業所で利用者負担額を充当したため、他事業所の利用者負担は発生しない。</div>
      <div>2　利用者負担額の合算額が、負担上限月額以下のため、調整事務は行わない。</div>
      <div>3　利用者負担額の合算額が、負担上限月額を超過するため、下記のとおり調整した。</div>
    </div>
    
  </>)
  // 協力事業所を描画する。1ブロックずつ
  const KyoJiInRow56 = (props) => {
    const p = props.p;
    const q = (p >= 5)? p - 5: p;
    const clms = (q * 6) + 3;  // カラム位置
    if (p === undefined) return null;
    // grid位置をスタイルとして渡す
    const grs = (r) => ({
      gridRowStart:r, gridColumnStart: clms, gridColumnEnd: clms + 6
    });
    const e = kyouryokuji[p];
    const jino = (p === 0)? com.jino: e.no;
    const jiname = (p === 0)? com.bname: (e.lname)? e.lname: e.name;
    const num = (e.name)? (p + 1) : ''; // 項番。空のレコードのときは空白  
    return(<>
      {p !== (kyouryokuji.length - 1) && <>
        <div className='bb rb' style={grs(1)}>{num}</div>
        <div className='rb' style={grs(2)}>{jino}</div>
        <div className='jiName vhcenter bb rb' style={grs(3)}>{jiname}</div>
      </>}
      {p === (kyouryokuji.length - 1) && <>
        <div className='total bb rb vhcenter' style={grs(1)}>合計</div>
      </>}
      <StrToDivs str={e.amount} rs={4} cs={clms} length={6} right rb />
      <StrToDivs str={e.tyouseiGaku} rs={5} cs={clms} length={6} right rb bb/>
      <StrToDivs str={e.kettei} rs={6} cs={clms} length={6} right rb />
    </>);
  }
  const Row56 = (props) => {
    const p = props.p;
    const eachBrunch = [...Array(5).keys()].map((e, i)=>{
      return(
        <KyoJiInRow56 p = {p + i} />
      )
    });
    return (<>
      <div className='vHead rb vhcenter'>利用者負担額集計・調整欄</div>
      <div className='vhcenter rHead bb rb'>項番</div>
      <div className='vhcenter rHead rb'>事業所番号</div>
      <div className='vhcenter rHead bb rb'>事業所名称</div>
      <div className='vhcenter rHead rb'>総費用額</div>
      <div className='vhcenter rHead bb rb'>利用者負担額</div>
      <div className='vhcenter rHead rb'>管理結果後利用者負担額</div>
      {/* <KyoJiInRow56 p={p} />
      <KyoJiInRow56 p={p + 1} />
      <KyoJiInRow56 p={p + 2} />
      <KyoJiInRow56 p={p + 3} />
      <KyoJiInRow56 p={p + 4} />
       */}
      {eachBrunch}
    </>)
  }

  return(<>
    <div className={classes.gridRepotRoot}>
      <div className='title'>利用者負担上限管理結果票</div>
      <div className='row1'>
        <div className='col1 outerLine'><Row1/></div>
      </div>
      <div className='row2'>
        <div className='col1 outerLine'>
          <Row2Col1/>
        </div>
        <div className='col2 outerLine'>
          <Row2Col2/>
        </div>
      </div>
      <div className='row3 outerLine'><Row3/></div>
      <div className='row4'><Row4/></div>
      <div className='row56 outerLine'><Row56 p={0}/></div>
      <div className='row56 outerLine'><Row56 p={5}/></div>
      <div className='usersSig'>
        <div>上記内容について確認しました。</div>
        <div>令和　　年　　月　　日</div>
        <div className='sig'>支給決定障害者等氏名</div>
      </div>
    </div>
    <div className='pageBreak'></div>
  </>)
}

const ReportFutanIchiranOne = (props) => {
  const classes = useStyles();
  const stdDate = useSelector(state=>state.stdDate);
  const schedule = useSelector(state=>state.schedule);
  
  // thisKji はこのコンポーネントで処理を行う管理事業所
  const {thisKji, com, service, account, users, masterRec, } = props;
  console.log('管理事業所ごとの請求情報', thisKji);
  const Title = () => {
    // 受領通知日をストアから取得。未設定だったら基準日から1月後の1日設定
    const tDate = comMod.parseDate(stdDate).date.dt;
    const nDate = new Date(tDate.getFullYear(), tDate.getMonth() + 1, 1);
    const jtInit = comMod.formatDate(nDate, 'YYYY-MM-DD');
    const jtDate =
      (comMod.findDeepPath(schedule, [service, '利用者負担額一覧'])) ? 
      schedule[service].利用者負担額一覧 : jtInit;

    const gengou = str2gdex(jtDate).wr.l; // 元号
    const wry = str2gdex(jtDate).wr.y; // 和暦の年
    const month = str2gdex(jtDate).m;
    const day = str2gdex(jtDate).d;
    const wrdate = `${gengou}${wry}年${month}月${day}日`;
    return (<>
      <div className='title'>
        利用者負担額一覧
        <div className='date'>{wrdate}</div>
      
      </div>
    </>)
  }

  const Row1 = () => {
    const sDate = str2gdex(stdDate);
    return(<>
      <div className='row1'>
        <div className='col1'>
          <div>(提供先)</div>
          <div>上限管理事業者</div>
          <div className='jiname'>
            <div className='name vhcenter'>{thisKji.name}</div>
            <div className='dono'>殿</div>
          </div>
          <div>下記の通り提供します。</div>
          <div className='month outerLine'>
            <div>{sDate.wr.l}</div>
            <StrToDivs str={sDate.wr.y} length={2} right strPadding='0' />
            <div>年</div>
            <StrToDivs str={sDate.m} length={2} right strPadding='0' />
            <div className='rb'>月分</div>
          </div>
        </div>
        <div className='col2 outerLine'>
          <div className='vHead vhcenter'>事業者</div>
          <div className='rHead'>指定事業所番号</div>
          <StrToDivs str={com.jino} cs={3} length={10} rb />
          <div className='rHead lineheigher vhcenter'>
            住所<br></br>(所在地)
          </div>
          <div className='content vhcenter tall lineheigher rb'>
            {com.city}<br></br>{com.address}
          </div>
          <div className='rHead vhcenter'>電話番号</div>
          <div className='content vhcenter rb'>{com.tel}</div>
          <div className='rHead vhcenter'>名称</div>
          <div className='content vhcenter tall lineheigher rb'>{com.bname}</div>

        </div>
      </div>
    </>)
  }
  const MainGrid = () => {
    
    const DtRow = (thisBdt) => {
      const grStart = (r, v) =>({gridRowStart: r + v});
      const rowSpan = (r, s) => ({gridRow: `${r} / span ${s}`});
      const dtRowCnt = thisBdt.dtRowCnt;
      const rTop = dtRowCnt * 3 + 2; // この行のgridRowStart
      const thisUser = (comMod.getUser(thisBdt.UID, users))?
        comMod.getUser(thisBdt.UID, users): {};
      // ユーザー名 取得できない場合は空白
      const usersName = (thisBdt.UID)? 
        thisUser.pname + ' (' + thisUser.name + ')' : '';
      const lineNo = (thisBdt.UID)? dtRowCnt + 1: '';
      // calcdtからインポートしたオブジェクトによりサービス名称を取得する
      const serviceName = (thisBdt.serviceSyubetu) ? 
        serviceSyubetu[thisBdt.serviceSyubetu]: '';
      
      return(<>
        <div className='vHead br bb vhcenter' style={rowSpan(rTop, 3)}>
          {lineNo}
        </div>
        <div className='rHead1' style={grStart(rTop, 0)}>市町村番号</div>
        <StrToDivs str={thisBdt.scityNo} rb length={10} rs={rTop + 0} cs={5} />
        <div className='rHead1' style={grStart(rTop, 1)}>受給者証番号</div>
        <StrToDivs str={thisBdt.hno} rb length={10} rs={rTop + 1} cs={5} />
        <div className='rHead2 bb ' style={grStart(rTop, 2)}>氏名</div>
        <div className='name bb rb' style={grStart(rTop, 2)}>
          {usersName}
        </div>
        <div className='mHead' style={grStart(rTop, 0)}>総費用額</div>
        <StrToDivs 
          str={thisBdt.userSanteiTotal} cs={16} rs={rTop} length={6} right 
        />
        <div className='mHead' style={grStart(rTop, 1)}>利用者負担額</div>
        <StrToDivs 
          str={thisBdt.tyouseigaku} length={6} cs={16} rs={rTop + 1} right 
        />
        <div className='mHead bb' style={grStart(rTop, 2)}></div>
        <StrToDivs str={''} bb length={6} cs={16} rs={rTop + 2} right />
        <div className='serviceLabel bb vhcenter' style={rowSpan(rTop, 3)}>
          提供サービス
        </div>
        <StrToDivs str={thisBdt.serviceSyubetu} rs={rTop} cs={23} length={2} />
        <div className='service rb' style={rowSpan(rTop, 1)} >{serviceName}</div>
        <StrToDivs str={''} rs={rTop+1} cs={23} length={2} />
        <div className='service rb' style={rowSpan(rTop + 1, 1)}></div>
        <StrToDivs str={''} bb rs={rTop+2} cs={23} length={2} />
        <div className='service bb rb' style={rowSpan(rTop + 2, 1)}></div>
      </>);
    }
    // 処理すべきthisKjiのキー
    const detailKey = Object.keys(thisKji).filter(e=>e.indexOf('UID') === 0);
    // 支給市区町村順にキーをソートしておく
    detailKey.sort((a, b)=>((thisKji[a].scityNo > thisKji[b].scityNo)? 1: -1))
    // 空行を含め10行記述する必要があるため配列長さを10まで伸ばす
    const filler = Array(10).fill('').slice(detailKey.length);
    filler.map(e=>detailKey.push(e));

    const rows = detailKey.map((e, i)=>{
      return(
        <DtRow {...thisKji[e]} key={i} dtRowCnt={i} />
      )
    });
    return(<>
      <div className='mainGrid outerLine'>
        <div className='cHead1 bb'>項番</div>
        <div className='cHead2 bb rb'>支給決定障害者等欄</div>
        {rows}
      </div>
    </>)
  }
  
  return (<>
    <div className={classes.gridReportFtnRoot}>
      <Title />
      <Row1 />
      <MainGrid />
    </div>
    <div className='pageBreak'></div>
  </>)
}

const ReportKanriKekka = (props) => {
  const {
    billingDt, com, service, account, users, masterRec, preview, userList
  } = props.props;
  const pages = billingDt.map((e, i)=>{
    const thisUser = comMod.getUser(e.UID, users);
    // ユーザーリストによるスキップ
    if (!userList.find(f => f.uid === thisUser.uid).checked) return null;
    else{
      const rpprmas = {
        thisBdt: e, com, service, account, thisUser, masterRec, preview,
      };
      return (
        <ReportKanriKekkaOne props={rpprmas} key={i}/>
      )  
    }
  });
  return pages;
}

const ReportFutanIchiran = (props) => {
  const {
    ftnDt, com, service, account, users, masterRec, 
  } = props;
  const pages = Object.keys(ftnDt).map((e, i)=>{
    const prms={thisKji: ftnDt[e], com, service, account, users, masterRec, }
    return (
      <ReportFutanIchiranOne {...prms} key={i}/>
    )
  });
  return pages;
}

const JougenKanri = (props) => {
  const {userList, preview, ...others} = props;
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com);
  const service = useSelector(state => state.service);
  const account = useSelector(state => state.account);

  // リストにないプレビューが送られてきたら何もしないで終了
  const nameList = ['上限管理結果票', '利用者負担額一覧'];
  if (nameList.indexOf(preview) < 0)  return null;
  const bdprms = { stdDate, schedule, users, com, service };
  const { billingDt, masterRec } = setBillInfoToSch(bdprms);
  // 管理事業所番号をキーにしてbillingDtを再編成
  const ftnDt = {}; // 負担一覧用データ
  billingDt.map(e=>{
    if (e.jougenJi && e.kanriType === '協力事業所'){
      const key = e.jougenJi;
      const pre = (ftnDt[key])? ftnDt[key]: {};
      const f = {...e};
      ftnDt[key] = {...pre, [e.UID]:f}
      ftnDt[key].name = e.jougenJiName;
    }
  });
  console.log('ftnDt', ftnDt);
  const futanIchiranPrams = {
    ftnDt, com, service, account, users, masterRec, 
  };
  const jougenPrms = {
    billingDt, com, service, account, users, masterRec, preview, userList
  };
  if (preview === '上限管理結果票'){
    return (
      <ReportKanriKekka props={jougenPrms} />
    )  
  }
  else if (preview === '利用者負担額一覧'){
    return (
      <ReportFutanIchiran {...futanIchiranPrams} />
    )
  }
  else {
    return (
      <div style={{margin:120}}>表示するデータが見つかりませんでした。</div>
    )
  }
}
export default JougenKanri;