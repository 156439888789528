import React, {useState} from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
// import SchHeadNav from './SchHeadNav';
import SchEditDetailDialog from './SchEditDetailDialog';
import SchTableHead from './SchTableHead';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import {LoadingSpinner} from '../common/commonParts';
import SimpleModal from '../common/modal.sample';
import SchDailyDialog from './SchDailyDialog';
import { createMuiTheme, createStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { common } from '@material-ui/core/colors';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EachScheduleContent from './SchEachScheduleContent';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';
import brown from '@material-ui/core/colors/brown';
import green from '@material-ui/core/colors/green';
import deepPurple from '@material-ui/core/colors/deepPurple';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
import teal from '@material-ui/core/colors/teal';
import lightGreen from '@material-ui/core/colors/lightGreen';
import { menu } from './Schedule';
import { LinksTab } from '../common/commonParts';

import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { EditAttributes } from '@material-ui/icons';

const useStyles = makeStyles({
  transferRoot:{
    '& .start, .end' : {
      fontSize: '1.4rem',
      color: teal[700],
      padding: '8px 0 2px 0',
      textAlign: 'center',
      // backgroundColor: teal[50],
      background: 
        'linear-gradient(#fff 0%, #fff 70%, ' 
        + teal[50] + ' 70%, ' + teal[50] + ' 100%);'
    },
    '& .end' : {
      color: grey[700],
      background:
        'linear-gradient(#fff 0%, #fff 70%, '
        + grey[200] + ' 70%, ' + grey[200] + ' 100%);'
    },
    '& .place' : {
      fontSize: '.8rem',
      fontWeight: 600,
      padding: '8px 0 2px 8px',
      marginBottom: 4,
    },
    '& .transferUser' : {
      fontSize: '.8rem',
      color: grey[900],
      padding: '6px 0 2px 4px',
      display: 'flex',
      cursor:'pointer',
      '& .name':{
        flex: 1,
      },
      '&:hover': {
        '& .icon':{
          opacity: 1,
          backgroundColor: '#fff',
        },
      },
      '& .age' :{
        marginLeft: '.5rem',
        width: '1.5rem',
        position:'relative',
        '& .icon':{
          position:'absolute',
          opacity: 0,
          top: -4,
          right: 0,
          color: teal[400],
          '& .MuiSvgIcon-root':{
            width:'1.5rem',
            fontSize:'1.2rem',
          }
        },
      }
    }
  },
  arrowIcon : {
    paddingTop:0,
    paddingBottom:12,
    textAlign: 'center',
    color: grey[300],
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
    }
  }
});

// 利用者名の背景色-> 縦棒の色
const bkColors = [
  red[500], pink[500], purple[500], green[500],
  brown[400], orange[800], cyan[500], indigo[800],
  grey[600], deepPurple[300], amber[500], lightGreen[800],
];

const SpecifyStartDate = (props)=>{
  const {start, dispathcStart, dateList, ...other} = props;
  // 月初または月曜日を返す配列
  let j = 0;
  const points = dateList.filter((e, i)=> i === 0 || e.date.getDay() === 0);
  // console.log(points);
  const startPoints = points.map((e, i)=>{
    const dateFormat = (i === 0) ? 'YYYY年MM月DD日' : 'MM月DD日';
    return (
      <Button key={i} onClick={() => dispathcStart(e.date)}
        variant={e.date === start ? 'contained' : 'outlined'}
      >
        {comMod.formatDate(e.date, dateFormat)}
      </Button>
    )
  });
  return(<>
    <div className="weeklyButtonGrp">
      <ButtonGroup color="primary" >
        {startPoints}
      </ButtonGroup>
    </div>
  </>)
}
// ---- スケジュール配列を迎え時間順、送迎場所順にソートする
const schSortForTransefer = (dateSchArray) =>{
  let pickUpAry = []; // 迎え用の配列
  let sendAry = []; // 送り用の配列
  if (dateSchArray.length) {
    pickUpAry = dateSchArray.sort((a, b) => {
      if (a.thisSchedule.start < b.thisSchedule.start) return -1;
      if (a.thisSchedule.start > b.thisSchedule.start) return 1;
      if (a.thisSchedule.transfer[0] < b.thisSchedule.transfer[0]) return -1;
      if (a.thisSchedule.transfer[0] > b.thisSchedule.transfer[0]) return 1;
    });
    sendAry = dateSchArray.sort((a, b) => {
      if (a.thisSchedule.end < b.thisSchedule.end) return -1;
      if (a.thisSchedule.end > b.thisSchedule.end) return 1;
      if (a.thisSchedule.transfer[1] < b.thisSchedule.transfer[1]) return -1;
      if (a.thisSchedule.transfer[1] > b.thisSchedule.transfer[1]) return 1;
    });
    // pickUpAry.map(e => {
    //   console.log(e.thisSchedule.start, e.thisSchedule.transfer[0]);
    // })
  }
  return {pickUpAry, sendAry}
}
// 指定されている行き先をユニークにした配列を作る。
// 行き先別に色分けするため
const getUniqDest = (schedule) =>{
  const destSet = new Set();
  const keys = comMod.setOfUidDid(schedule);
  keys.map(e=>{
    destSet.add(schedule[e[0]][e[1]].transfer[0])
    destSet.add(schedule[e[0]][e[1]].transfer[1])
  });
  return Array.from(destSet);
}


const TitleOfPillar = (props) =>{
  const { start, ...other } = props;
  const dateList = useSelector(state=>state.dateList);
  const days = makeDatasOfWeeks(dateList, start);
  // 休日平日などを示すクラス名
  // 該当月でない場合 classOfDayはundefinedが格納されている
  const titles = days.map((e, i)=>{
    let classOfDay = ['', 'schooloff', 'off'][e.holiday];
    classOfDay = (classOfDay !== undefined) ? classOfDay : 'outOfMonth';
    return (
      <div className="schPillar title">
        <div className={"date " + classOfDay} key={i}>
          {comMod.formatDate(e.date, 'MM月DD日 AA')}
        </div >
      </div>
    );
  });
  return(
    <div className='schPillarOuter title'>
      {titles}
    </div>
  )
}

const OnePillar = (props)=>{
  const classes = useStyles();
  const {day, type,...other} = props;
  const dispatch = useDispatch();
  const schedule = useSelector(state => state.schedule);
  const service = useSelector(state => state.service);
  const classroom = useSelector(state => state.classroom);
  const users = useSelector(state=>state.users);
  // const prms = useParams().prms;
  // console.log(prms);
  
  // Scheduleから情報抽出するとソートが反映されないので
  // usersのstateから並び順にuidを取り出す
  // ついでに教室による絞り込み
  const sortedUsers = users
  .filter(e=>(
    e.service===service && 
    (classroom === '' || classroom === e.classroom)
  ))
  .map(e=>"UID" + e.uid);

  // ユニークな送迎先の配列
  // 送迎先別に色分けするのに使う
  const uniqDest = getUniqDest(schedule);

  // user情報とscheduleを持つ配列にして返す
  const getSchedulesByDate = (did) =>{
    const rt = [];
    sortedUsers.map(e=>{
      if (schedule[e] === undefined)  return false;
      Object.keys(schedule[e]).map(f=>{
        // 該当のオブジェクトを配列に追加
        if (f === did){
          const user = comMod.getUser(e, users);
          const thisSchedule = schedule[e][f];
          rt.push({user, thisSchedule});
        }
      });
    });
    return rt;
  }
  const did = comMod.convDid(day.date);
  const dateSchArray = getSchedulesByDate(did);
  // ここから普通の週間予定
  const daySchedule = dateSchArray.map((e, i)=>{
    const oneSchItemStyle = {cursor: 'pointer'}
    const handleClick = () =>{
      const uid = comMod.convUID(e.user.uid).str;
      const p = { open: true, uid: uid, did };
      comMod.setOpenSchEditDetailDialog(dispatch, p);
    }
    const sty = {
      borderLeft: '6px solid ' + 
        bkColors[parseInt(e.user.uid) % bkColors.length],
    }
    return (
      <div 
        className='oneSchItem' 
        onClick={()=>handleClick()}
        style={oneSchItemStyle}
      >
        <div className='user' style={sty}>{e.user.name}</div>
        <EachScheduleContent thisSchedule={e.thisSchedule} />
      </div>
    )
  });
  // ---- ここまでが普通の週間予定
  // 送迎用のコードはここから
  // ---- スケジュール配列を迎え時間順、送迎場所順にソートする
  const { pickUpAry, sendAry } = schSortForTransefer(dateSchArray);
  const DayTransfer = (props) =>{
    const classes = useStyles();
    const {ary, item} = props;
    let preTime = '';
    let prePlace = '';
    let newTime = false; // 新しい時刻として描画開始するフラグ
    let newPlace = false; // 新しい場所として描画開始するフラグ
    let placeNdx = (item === 'start')? 0: 1;  // transferから場所取得するindex
    const nodes = ary.map((e, i)=>{
      if (e.thisSchedule.absence) return false; // 欠席はここでは非表示
      const thisTime = e.thisSchedule[item];
      const thisPlace = e.thisSchedule.transfer[placeNdx];
      if (preTime !== thisTime) { newTime = true; newPlace = true;}
      else if (prePlace !== thisPlace) { newTime = false; newPlace=true;}
      else {newTime = false; newPlace = false};
      preTime = thisTime;
      prePlace = thisPlace;
      // 利用実績があるときは時刻を非表示
      if (e.thisSchedule.useResult) newTime = false
      if (e.thisSchedule.useResult) newPlace = false
      // ユニーク配列のインデックスを調べて行き先別の色を特定する
      // 利用実績があるときはグレイ一択 -> 非表示
      const colorNdx = 
        uniqDest.findIndex(f => (f === thisPlace)) % bkColors.length;
      const destStyle = {
          borderBottom: '2px solid ' + bkColors[colorNdx],
          color: bkColors[colorNdx],
      };
      const handleUserClick = () =>{
        const uid = comMod.convUID(e.user.uid).str;
        const p = { open: true, uid: uid, did };
        comMod.setOpenSchEditDetailDialog(dispatch, p);
      }
      return (
        <div className={classes.transferRoot} key={i}>
          {newTime &&
            <div className={item}>{thisTime}</div>
          }
          {(newPlace || newTime) &&
            <div className='place' style={destStyle}>{thisPlace}</div>
          }
          <div className='transferUser' onClick={handleUserClick}>
            <div className='name textEclips'>{e.user.name}</div>
            <div className='age'>
              {e.user.ageStr}
              <div className='icon'><EditIcon/></div>
            </div>
          </div>
        </div>
      );
    });
    return (<>{nodes}</>)
  }
  // console.log('dateSchArray', dateSchArray)
  return(
    <div className="schPillar">
      <div className="content">
        {type.type === 'pickup' && <>
          <DayTransfer ary={pickUpAry} item={'start'} />
        </>}
        {type.type === 'send' && <>
          {dateSchArray.length > 0 &&
            <div className={classes.arrowIcon}>
              <KeyboardArrowDownRoundedIcon />
            </div>
          }
          <DayTransfer ary={sendAry} item={'end'} />
        </>}
        {type.type === 'normal' &&
          // 条件付きレンダーは単一ノードにする必要がある
          <>{daySchedule}</> 
        }
      </div>
    </div>
  )
}
const SevenPillars = (props)=>{
  // startは月初か日曜日が与えられる。starの日付から7日を表示
  // startが1日の場合、前月の日付を持ってきて一週間にする
  // 月末の場合は次月の日付を持ってくる
  const {start, editOn, ...other} = props;
  const dateList = useSelector(state=>state.dateList);
  const days = makeDatasOfWeeks(dateList, start);
  const prms = useParams().prms;

  const Pillers = (type) =>{
    const nodes = days.map((e, i) => {
      return (<OnePillar key={i} day={e} type={type}/>)
    });
    return nodes;
  } 
  return (<>
    {prms !== 'transfer' && 
      <div className="schPillarOuter">
        <Pillers type='normal'/>
      </div>
    }
    {(prms === 'transfer') && <>
      <div className="schPillarOuter">
        <Pillers type='pickup' />
      </div>
      <div className="schPillarOuter">
        <Pillers type='send' />
      </div>

    </>}
  </>);
}
// 一週間の日付配列を作成する
const makeDatasOfWeeks = (dateList, start) => {
  const dateDiff = (a, b) => (a - b) / (24 * 60 * 60 * 1000);
  const days = dateList.filter((e, i) => (
    e.date >= start && dateDiff(e.date, start) < 7
  ));
  // 月桃で先頭の曜日をチェック。日曜日になるまで日付を先頭に追加
  const thisWeekday = days[0].date.getDay();
  const fd = new Date(days[0].date.getTime());
  for (let i = 1; i <= thisWeekday; i++) {
    const addDate = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() - i);
    days.unshift({ date: addDate });
  }
  // 月末の想定。日付配列の長さが0以下なら追加する
  const daysLength = days.length;
  const ld = days[days.length - 1].date;
  for (let i = 1; i <= (7 - daysLength); i++) {
    const addDate = new Date(ld.getFullYear(), ld.getMonth(), ld.getDate() + i);
    days.push({ date: addDate });
  }
  // lengthを入れると所定以上の名朝は削除されるみたい
  days.length = 7;
  return days;
}

const MainSchWeekly = ()=>{
  const dispatch = useDispatch();
  const allState = useSelector(state => state);
  const dateList = allState.dateList;
  const service = allState.service;
  const stdDate = allState.stdDate;
  const serviceItems = allState.serviceItems;
  const [editOn, seteditOn] = useState(false);
  // const FabHandleClick = (e) => {
  //   console.log('fav clicekd.');
  //   if (editOn) seteditOn(false);
  //   if (!editOn) seteditOn(true);
  // }
  // サービス未設定の場合は設定する
  if (!service) {
    dispatch(Actions.changeService(serviceItems[0]));
  }
  const yearMonth = (date) => (
    date.getFullYear() + '-' + date.getMonth()
  )

  // const [start, setstart] = useState(dateList[0].date);
  const dlZero = dateList[0].date;
  let start = useSelector(state => state.controleMode.weeklyStart);
  start = (start) ? start : dlZero;
  start = (yearMonth(start) === yearMonth(dlZero))? start: dlZero;
  const dispathcStart = (st) => {
    dispatch(Actions.setControleMode({ weeklyStart: st}));
  }
  const specifyStartDateProps = {
    start, dispathcStart, dateList
  }
  return (
    <>
    <LinksTab menu={menu} />
    <div className="AppPage schWeekly fixed">
      <SpecifyStartDate {...specifyStartDateProps} />
      <TitleOfPillar start={start} />
    </div>
    <div className="AppPage schWeekly scroll">
      <SevenPillars 
        start={start} editOn={editOn} 
      />
      {/* <mui.FabAddEdit 
        clickHandler={e => FabHandleClick(e)} 
        editOn={editOn}
        hideAdd hideSwap
      /> */}
      <SchEditDetailDialog />
    </div>
    </>
  )
}

const ErrSchByUsers = ()=>{
  return(<div>error occured.</div>)
}

const SchWeekly = ()=>{
  const userFtc = useSelector(state => state.userFtc);
  const fetchCalenderStatus = useSelector(state => state.fetchCalenderStatus);
  // fetch状態の取得
  const done = (
    userFtc.done && fetchCalenderStatus.done
  );
  const errorOccured = (
    userFtc.err || fetchCalenderStatus.err
  );
  const loading = (
    userFtc.loading || fetchCalenderStatus.loading
  );

  if (done) return (<MainSchWeekly />);
  else if (loading) return (<LoadingSpinner />);
  else if (errorOccured) return (<ErrSchByUsers />);
  else return null;
}

export default SchWeekly;