import React, {useState} from 'react';
import Login from './component/common/Login'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './Actions'
import { AccordionActions } from '@material-ui/core';
import SnackMsg from './component/common/SnackMsg';
import UseEffectTest from './component/common/useEffectTest';
import { useHistory, } from 'react-router-dom';
import { SideToolBar, } from './DrowerMenu'
import { UserSelectDialog} from './component/common/commonParts';
import * as mui from './component/common/materialUi';
import { useStyles } from './component/common/StdFormParts';
import * as sfp from './component/common/StdFormParts';
import {AccountRestButton, AddNewAccount} from './component/account/Account';
import { Link, useLocation } from 'react-router-dom';
import CheckProgress from './component/common/CheckProgress';
import RegistedParams from './component/Setting/RegistedParams';


const testfunc = ()=>{
  console.log('hoge');
}

const Hoge = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(state=>state.users);
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');
  const [dialogOpen, setDialogOpen] = useState('');
  const [userList, setUserList] = useState(
    users.map(e => ({ uid: e.uid, checked: false }))
  );
  const clickHandlr = (text)=>{
    setmsg(text);
    setseverity('');
  }
  const dispathcHandler = (text, severity)=>{
    dispatch(Actions.setSnackMsg(text, severity));
  }
  const tx = 'this is dispatch.';
  const loc = '/schedule';
  const [destList, setDestList] = useState(['校門', '裏庭', '駅前',]);
  return (
    <div className='AppPage proseed' style={{paddingTop:60}}>
      <div><a onClick={()=>setDialogOpen(true)}>open daialog.</a></div>
      <div><a onClick={() => clickHandlr('this is one')}>one</a></div>
      <div><a onClick={() => clickHandlr('this is tow')}>tow</a></div>
      <div><a onClick={() => dispathcHandler(tx, 'warning')}>
        {tx + ' warning'}
      </a></div>
      <div><a onClick={() => dispathcHandler(tx, 'success')}>
        {tx + ' success'}
      </a></div>
      <div><a onClick={() => dispathcHandler(tx, 'error')}>
        {tx + ' error'}
      </a></div>
      <div><a onClick={() => dispathcHandler(tx, '')}>
        {tx + ' (normal)'}
      </a></div>
      <UseEffectTest />
      <SnackMsg msg={msg} severity={severity} setmsg={setmsg}/>
      
      <div onClick={()=>history.push(loc)}>
        this is react-router histoy test.
      </div>
      <SideToolBar/>
      <UserSelectDialog 
        open={dialogOpen} setopen={setDialogOpen}
        userList={userList} setUserList={setUserList}
      />
      <mui.SelectClassRoom />
      <div className='dialogForm'>
        <sfp.TransferList destList={destList} setDestList={setDestList} />
      </div>
      <div>
        <AccountRestButton />
      </div>
      <div>
        <AddNewAccount />
      </div>
      <div>
        <RegistedParams />
      </div>
    </div>
  )
}

export default Hoge;