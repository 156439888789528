import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as mui from '../common/materialUi';
import * as comMod from '../../commonModule';
import * as Actions from '../../Actions';
import * as sfp from '../common/StdFormParts';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  cntRow: {
    display: 'flex',
    justifyContent: 'center',
    '& .date' : {
      marginTop: 4,
      marginRight: 8,
      padding: '12px 12px 0 ',
      height: 'calc(1rem + 20px)',
    },
    '& .offSchool' : {
      backgroundColor: 'rgb(248, 227, 203)',
    },
    '& .off': {
      backgroundColor: 'rgb(202,202,217)',
    },
  },
  formHead: {
    display: 'flex',
    justifyContent: 'center',
    '& .title': {
      width: 160,
      padding: '12px 12px 8px',
    },
    '& .time': {
      width: 120,
      padding: '12px 0 8px',
    },
  },

});

const OneDay = (props) =>{
  const classes = useStyles();
  const {schedule, did, uid, ...other} = props;
  const dObj = comMod.convDid(did);
  const dStr = comMod.formatDate(dObj, 'MM月DD日 AAA');
  const start = schedule[uid][did].start;
  const end = schedule[uid][did].end;
  // 休校日、休日に対応したクラス名を取得
  let offClass = ['', 'offSchool', 'off'][schedule[uid][did].offSchool];
  offClass = (!offClass)? '' : offClass;

  const nameStart = did + '-start';
  const nameEnd = did + '-end';
  // 欠席扱いの場合
  if (schedule[uid][did].absence) return null;
  return(
    <div className={classes.cntRow}>
      <div className={'date ' + offClass}>{dStr}</div>
      <sfp.TimeInput 
        size='middle' name={nameStart} label={'開始'} def={start} noLabel 
      />
      <sfp.TimeInput 
        size='middle' name={nameEnd} label={'終了'} def={end} noLabel 
      />
    </div>
  )
}

export const SchByUserStartEndInput = (props)=>{
  const classes = useStyles();
  const {uid, ...othe} = props;
  const UID = 'UID' + uid
  const schedule = useSelector(state=>state.schedule);
  const dispatch = useDispatch();
  const path = useLocation().pathname;

  const handleSubmit = (e) => {

    console.log('handlesubmit');
    const inputs = document.querySelectorAll('#wky78 input');
    const fDt = comMod.getFormDatas([inputs]);
    const target = {};
    // fdt={D20201010-start:'10:30', D20201010-end:'17:30'}
    // target={D20201010:{start:'10:30, end:'17:30}}
    Object.keys(fDt).map(e => {
      const s = e.split('-');
      if (target[s[0]] == undefined) target[s[0]] = {};
      target[s[0]][s[1]] = fDt[e];
    });
    console.log(target);

    Object.keys(target).map(e=>{
      const sch = schedule[UID][e];
      const newsch = {
        ...sch, 
        start: target[e].start, 
        end: target[e].end,
      }
      dispatch(Actions.replaceSchedule(UID, e, newsch));
    });
    comMod.setSchedleLastUpdate(dispatch, path);
  }
  const cancelSubmit = () => {
    // console.log('cancelSubmit');
    props.close();
  }


  const ptn = /D2[0-9]+/; // didのパターン
  // uidが持つdidの配列
  const dids = Object.keys(schedule[UID]).filter(e=>e.match(ptn));
  dids.sort((a, b)=>a > b ? 1: -1);
  const days = dids.map(e=>{
    return (<OneDay key={uid} schedule={schedule} did={e} uid={UID} />)
  });
  return (<>
    <div className={classes.formHead}>
      <div className='title'>日付</div>
      <div className='time'>開始</div>
      <div className='time'>終了</div>
    </div>

    <form id="wky78" className="dialogForm withSpace">
      {days}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonGP
        color='secondary'
        label='キャンセル'
        onClick={cancelSubmit}
      />
      <mui.ButtonGP
        color='primary'
        label='書き込み'
        type="submit"
        onClick={handleSubmit}
      />

    </div>
  </>)
}

export default SchByUserStartEndInput;